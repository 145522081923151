@import url(https://fonts.googleapis.com/css?family=Varela+Round);

.slider1 {
 width: 100%;
 height: 35rem;
 /* margin-right: 30rem; */
 margin-top: 2rem;
 border: none;
 background-color: var(--pure-white);
}
.slider_images {
 width: 99.8rem;
 height: 30.8vh;
}

.slides {
 padding: 0;
 width: 100rem;
 height: 35rem;
 display: block;
 margin: 0 0;
 position: relative;
}

.slides * {
 user-select: none;
 -ms-user-select: none;
 -moz-user-select: none;
 -khtml-user-select: none;
 -webkit-user-select: none;
 -webkit-touch-callout: none;
}

.slides input {
 display: none;
}

.slide-container {
 display: block;
}
.slide {
 top: 0;
 opacity: 0;
 width: 100rem;
 height: 35rem;
 display: block;
 position: absolute;
 transform: scale(0);
 transition: all 0.7s ease-in-out;
}

.slide img {
 width: 100rem;
 height: 35rem;
 transition: all 0.3s;
}

.nav label {
 display: none;
 position: absolute;
 opacity: 0;
 z-index: 9;
 cursor: pointer;
 transition: opacity 0.2s;
 color: rgb(0, 0, 0);
 font-size: 8rem;
 line-height: 35rem;
 font-family: "Varela Round", sans-serif;
 text-shadow: 0px 0px 15px rgb(255, 255, 255);
}

.slide:hover + .nav label {
 opacity: 0.5;
}

.nav label:hover {
 opacity: 1;
}

.nav .next {
 right: 0;
}

input:checked + .slide-container .slide {
 opacity: 1;
 transform: scale(1);
 transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .nav label {
 display: block;
}

.nav-dots {
 width: 100rem;
 bottom: 10px;
 height: 11px;
 display: block;
 position: absolute;
 text-align: center;
}

.nav-dots .nav-dot {
 top: -5px;
 width: 2rem;
 height: 2rem;
 margin: 0 4px;
 position: relative;
 border-radius: 100%;
 display: inline-block;
 background-color: rgba(247, 243, 243, 0.6);
}

.nav-dots .nav-dot:hover {
 cursor: pointer;
 background-color: rgba(0, 0, 0, 0.8);
}

input#img-1:checked ~ .nav-dots label#img-dot-1,
input#img-2:checked ~ .nav-dots label#img-dot-2,
input#img-3:checked ~ .nav-dots label#img-dot-3,
input#img-4:checked ~ .nav-dots label#img-dot-4,
input#img-5:checked ~ .nav-dots label#img-dot-5,
input#img-6:checked ~ .nav-dots label#img-dot-6 {
 background: rgba(0, 0, 0, 0.8);
}
