.grid-container {
    margin: 10rem 10rem;
    display: grid;
    grid-template-columns: 25% 60%;
    grid-gap: 2rem;
    /* position: absolute; */
    justify-content: center;
}

.grid-container>div {
    background-color: var(--light-grey2);
    padding: 2rem 2rem;
    border-radius: 15px;
    width: 100%;
}

.item5 {
    grid-area: 5/2/1;
}

.appointment_top_box {
    display: flex;
    flex-direction: column;
}

.appointment_subheading1 {
    font-size: 1rem;
    font-weight: 500;
    /* float: right; */
}

.appointment_subheading {
    display: flex;
    flex-direction: row-reverse;
}

.button_for_date {
    border: none;
    outline: none;
    background-color: var(--pure-white);
    cursor: pointer;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

.button_for_date:hover {
    transform: scaley(1.1);
    transition: all 0.5s;
    background-color: black;
    color: var(--pure-white);
}

.appointment_header1 {
    font-size: 1.8rem;
}

.appointment_heading2 {
    font-size: 1.4rem;
}

.download_options_inner {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
}

.download_options_store {
    color: white;
    background: black;
    display: flex;
    padding: 0.5rem 1.2rem;
    border-radius: 15px;
    width: 16rem;
    cursor: pointer;
    gap: 0.5rem;
    text-align: center;
    align-items: center;
}

.filter__input--icon {
    height: 4rem;
    width: 4rem;
    fill: white;
    margin: 1rem 0;
}

.appointment_heading4 {
    font-size: 1rem;
    font-weight: 500;
}

.appointment_subheading4 {
    font-size: 1.2rem;
}

.services_outer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 73vh;
    overflow: auto;
}

.servies_top {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0rem;
    align-items: flex-end;
}

.our_services {
    background-color: white;
    border-radius: 15px;
}

.service_name2 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.service_name {
    display: flex;
    justify-content: space-between;
    background: var(--primary-color);
    border-radius: 1rem 1rem 0 0;
    padding-left: 20px;
}

.barber_info {
    display: flex;
    gap: 2rem;
    /* margin-top: 2rem; */
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.barber_image {
    height: 5rem;
    width: 5rem;
    border-radius: 100%;
    object-fit: cover;
}

.barber_image1 {
    height: 5rem;
    /* width: 5rem; */
    /* border-radius: 100%; */
    /* object-fit: cover; */
}

.barber_info_name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.time_intervals {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 2rem;
    margin: 2rem 0;
}

.time {
    outline: none;
    border: none;
    background-color: var(--light-grey2);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
}


.time-btn span {
    display: none;
    font-size: 1rem;
    color: var(--pure-white);
    background-color: var(--black);
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    position: absolute;
    top: -3rem;
    width: 140px;
    /* left: 0; */
    /* transform: translate(-50%, -50%); */
    /* transition: all 1s; */
    /* right: 1rem; */
    z-index: 1000;
}

.time-btn:hover span {
    display: block;
}

.time-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 10px;
}

.time-booked {
    outline: none;
    border: none;
    background-color: rgba(218, 255, 162, 0.72);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
}

.time-confirmed {
    outline: none;
    border: none;
    background-color: rgba(218, 255, 162, 0.72);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
}

.time-canceled {
    outline: none;
    border: none;
    background-color: rgba(255, 162, 162, 0.72);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;

}


.time-waiting {
    outline: none;
    border: none;
    background-color: rgb(242, 202, 141);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;

}

.time:hover {
    background-color: black;
    color: white;
    transition: all 0.4s;
}

.popup_heading {
    padding: 0 2rem;
    margin-top: -4rem;
}

.popup_total_outer {
    display: flex;
    justify-content: space-between;
}

/* legend */
.legend-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.box-available {
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-confirmed {
    border: 1px solid #000;
    background-color: rgb(141, 242, 149);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-waiting {
    border: 1px solid #000;
    background-color: rgb(242, 202, 141);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-canceled {
    border: 1px solid #000;
    background-color: rgb(255, 121, 121);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-legend {
    display: flex;
    gap: 0.5rem;
    margin-left: 10px;
}

.stylist-detail {
    display: flex;
    gap: 1rem;
}

@media (max-width: 1400px) {
    .grid-container {
        margin: 10rem 10rem;
    }

    .download_options_inner {
        /* display: flex; */
        justify-content: space-between;
        justify-content: center;
        margin-top: 2rem;
    }

    .download_options_store {
        padding: 0.5rem 1rem;
        width: 10rem;
    }

    .filter__input--icon {
        height: 2rem;
        width: 2rem;
        fill: white;
        margin: 1rem 0;
    }

    .download_options {
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 1230px) {
    .grid-container {
        margin: 10rem 1rem;
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
        align-items: center;
    }

    .download_options_inner {
        /* display: flex; */
        justify-content: space-between;
        justify-content: center;

        margin-top: 2rem;
    }

    .rmdp-calendar {
        height: max-content !important;
        padding: 4px !important;
        width: 100% !important;
    }

    .item1 {
        order: -1;
        width: 90vw;
    }

    .item2 {
        width: 90vw;
    }

    .item5 {
        order: -1;
    }

    .download_options_store {
        padding: 0.5rem 1rem;
        width: 10rem;
    }

    .filter__input--icon {
        height: 2rem;
        width: 2rem;
        fill: white;
        margin: 1rem 0;
    }

    .download_options {
        justify-content: center;
        text-align: center;
    }

    .rmdp-calendar {
        height: max-content;
        padding: 4px;

    }

}

@media (max-width: 768px) {
    .grid-container {
        margin: 10rem 1rem;
        /* width: 100%; */
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
    }

    .grid-container>.item5 {
        padding: 2rem 0.5rem;
    }

    .item1 {
        order: -1;
    }

    .item5 {
        order: -1;
    }

    .download_options_inner {
        /* display: flex; */
        justify-content: space-between;
        justify-content: center;
        margin-top: 2rem;
    }

    .download_options_store {
        padding: 0.5rem 1rem;
        width: 10rem;
    }

    .filter__input--icon {
        height: 2rem;
        width: 2rem;
        fill: white;
        margin: 1rem 0;
    }

    .download_options {
        justify-content: center;
        text-align: center;
    }

    .servies_top {
        padding: 0rem 2rem;
        gap: 3rem;
    }

}

@media (max-width: 500px) {
    .servies_top {
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0rem;
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
    }

    .stylist-detail {
        display: flex;
        grid-gap: 0rem;
        gap: 0rem;
        flex-direction: column;
    }
}

.appointment-box {
    display: flex;
    gap: 2rem;
}



.appointment-icon {
    width: 15px;
    height: 15px;
    fill: #2e2e2e;
}

.stylist-detail-icon {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.thankyou {
    margin: 1rem;
    background-color: #fff;
    width: 40vw;
    height: 40vh;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.thankyou-img {
    width: 100px;
}

.appointment-box1 {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: flex-start;
    margin: 10px 0;
    flex-direction: column;
    align-items: center;
}

.appointment-box2 {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
}

.appointment-box3 {
    display: flex;
    margin: 10px 0;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.appointment-box3 p {
    width: 200px;
}

.salon_info_name {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rmdp-top-class {
    display: flex;
    justify-content: center !important;
}

.download_options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.salon_heading2 {
    font-size: 1.9rem;
}

.appointment_subheading4 {
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
    /* float: right; */
}

.appointment_select select {
    width: fit-content;
    height: 30px;
    font-size: 12px;
    font-family: inherit;
    outline: none;
    border-radius: 5px;
}

.appointment_select select:hover {
    border: 3px solid #000;
}