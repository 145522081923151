.form_input {
	display: flex;
  gap: 2rem;
  background-color: #323232;
  padding: 0.5rem 1.5rem;
    padding-bottom: 0.5rem;
  padding-bottom: 1.5rem;
  border-radius: 10rem;
  margin-bottom: 2rem;
  width: 100%;
}
.form_input__label {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.btn_file {
	margin-top: 1rem;
	padding: 2rem 3rem;
	border-radius: 2rem;
	height: 17rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	/* background-color: var(--white); */
	background-color: #121212;
	/* border-radius: 1rem; */
	border-radius: 50%;
	cursor: pointer;
	padding: 0rem 2rem;
	/* min-height: 9rem;
	min-width: 16rem; */
	width: 100%;
	position: relative;
}

.btn_file input[type=file] {
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block;
	border-radius: 45rem;
	height: 100%;
}
.form_input__container {
	display: flex;
	align-items: center;
	gap: 1rem;
	background-color: var(--pure-white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}
.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.form_input__box {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}
.form_input__box::-webkit-input-placeholder {
	color: var(--light-grey);
}
.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
}

.modalContainer__form--sections {
	display: flex;
	gap: 2rem;
	align-items: center;
}

.fileUploadText{
	padding-right: 2rem;
}


.fileUpload__label_image{
	height: 17.4rem;
	border-radius: 50%;
	width: 17rem;
	position: absolute;
}


@media (max-width: 690px) {
	.form_input{
		width: 56%;
  padding-top: 0rem;
  padding: 0rem 1rem;
  padding-bottom: 0.8rem;
  margin-left: 3rem;
    margin-top: 4rem;
	}

	.btn_file{
		height: 6rem;
	}

	.fileUpload__label_image{
		height: 6rem;
        border-radius: 50%;
        width: 6rem;
        margin-left: -2rem;
	}
}
