.videoDetails {
	margin-top: 1rem;
	display: flex;
	gap: 2rem;
	justify-content: space-between;
}
.videoDetails__subTitle {
	margin: 1rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--light-grey);
}
.videoDetailsSection video {
	/* height: 55vh; */
	width: 52vw;
	border-radius: 2rem;
	box-shadow: var(--box-shadow1);
}
