.order_history {
    width: 100%;
    height: 80vh;
    padding: 5rem 30rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
}

.order-container {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    cursor: pointer;
    background-color: #fff;
}

.order-container:hover {
    background-color: rgb(244, 244, 244);
}

.titleOrder {
    width: 40%;
}

.order-status-complete {
    width: fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 10%;
    font-size: 12px;
    color: #fff;
    background-color: green;
}

.order-status-pending {
    width: fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 10%;
    font-size: 12px;
    color: #fff;
    background-color: var(--primary-color);
}

.order-container span {
    font-size: 14px;
    font-family: 600;
    color: var(--primary-color);
}

.order-image img {
    width: 8rem;
    height: 8rem;
    border-radius: 1rem;
}


@media screen and (max-width:950px) {
    .order_history {
        width: 100%;
        height: 70vh;
        padding: 5rem 5rem;
    }

    .shipping_detail {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        justify-content: flex-start !important;
        width: 100%;
        grid-gap: 2rem;
        gap: 2rem;
        margin-top: 4rem;
        align-items: flex-start !important;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:600px) {
    .order_history {
        width: 100%;
        height: 70vh;
        padding: 5rem 2rem;
    }

    .order-container {
        flex-direction: column;
        padding: 1rem 1rem;
        align-items: flex-start;
        gap: 1rem;

    }

    .order-image {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .titleOrder {
        width: 100%;
    }


}