/* GoalModal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #121212;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    text-align: left;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h2 {
    margin: 0;
  }
  
  .closeButton {
    background-color: #ff9900;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0rem 0.4rem;
   border-radius: 1.2rem;
  }
  
  .modalBody {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
    overflow: auto;
    height: 30rem;
}
  
  .modalFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .closeModalButton {
    background-color: #ff9900;
    color: #fff;
    border: none;
    padding: 1rem 2.2rem;
    cursor: pointer;
    border-radius: 0.8rem;
  }


  .goalItem {
    background-color: #333; 
    color: #fff;
    padding: 15px;
    border-radius: 2rem; 
    font-size: 14px;
}

.goalDescription{
    font-size: 1.4rem;
    color: #f90;
}

.goalAction{
    font-size: 1.4rem;
}