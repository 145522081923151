.pie_chart_container {
  background: #121212;
  width: 30%;
  /* height: 34rem; */
  /* height: 44rem; */
  border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.2s ease-in-out;
}

.dashboard_p {
  color: white;
  font-size: 2rem;
  font-weight: 600;
}

.dot_container{
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.percenatage_container{
    width: 100%;
    gap: 2rem;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.male_percentage_Text{
    color: #FF9000
}

.dot {
    width: 1rem;
    height: 1rem;
    background-color: #FF9000; /* You can set the color of the dot */
    border-radius: 50%; /* Makes the div round */
  }

  .male_percentage_Tex1{
    color: #FFFFFF
}

.dot1 {
    width: 1rem;
    height: 1rem;
    background-color: #FFFFFF; /* You can set the color of the dot */
    border-radius: 50%; /* Makes the div round */
  }

.chart8_circle_chart {
  height: 25rem;
  width: 25rem;
  padding: 0.8rem;
}

.chart9 {
  grid-column: 4 / 5;
  grid-row: 4/-1;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.chart8_chart {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  font-weight: 600;
}

.chart8_circle {
  position: relative;
  background-color: #a15b00;
  border-radius: 50%;
  height: 25rem;
  width: 25rem;
  padding: 0.8rem;

  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.chart8_circle_info {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--white);
}
.chart8_circle_info_num {
  text-align: center;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-family: "Dosis", sans-serif;
}
.chart8_circle_info_num span {
  font-size: 1rem;
  line-height: 1.4rem;
}
.chart8_circle_info_text {
  font-size: 2.2rem;
  line-height: 2.5rem;
  text-align: center;
}




@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .male_percentage_Text{
    font-size: 1rem;
  }
  .male_percentage_Tex1{
    font-size: 1rem;
  }
  
}

/* Dashboard screen responsive - Aniket */
@media (max-width: 1024px) {
  .pie_chart_container {
    margin-top: 1rem;
    width: 100%;
    /* height: 100%; */
  }
}

@media  (max-width: 820px) {
  .section{
    width: 100%;
  }
}