.content {
	display: flex;
	gap: 2rem;
	align-items: flex-start;
}
.prevNotifications {
	background-color: #353434b5;
	color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.prevNotificationsHeading {
	font-size: 1.5rem;
	font-weight: 600;
}
.prevNotification {
	background-color: #1a1919e8;
	border-radius: 2rem;
	padding: 1rem 1.5rem;
}
.prevNotificationTitle {
	font-size: 1.6rem;
	font-weight: 600;
}
.prevNotificationSubTitle {
	font-size: 1.1rem;
	font-weight: 500;
	color: #ffffff;
}
.prevNotificationTime {
	font-size: 1rem;
	font-weight: 600;
	color: #038D7A;
	margin-top: 1rem;
}

.input{
	margin-top: 2rem;
}

.input div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
}

.input label{
	color: #ffffff;
}

.textArea{
	margin-top: 2rem;
}

@media all and (min-width: 810px) and (max-width: 1080px) {
	.content{
		flex-direction: column;
	}
	.prevNotifications{
		width: 100%;
	}
}

@media (max-width: 811px) {
    .content{
		flex-direction: column;
	}
}