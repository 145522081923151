.form_input {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: flex-start;
}

.form_input__label {
	color: var( --lightest-white);
	font-size: 1.5rem;
	margin-top: 1rem;
	/* margin-left: 2rem; */
}

.form_input__container {
	display: flex;
	align-items: center;
	gap: 1rem;
	/* background-color: var(--white); */
	/* border-radius: 1rem; */
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
	/* padding-bottom: 3rem; */
	border-bottom: 1px solid var( --light-grey);
	/* margin: 2.3rem; */
}

.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #756969;
}

.form_input__box {
	font-family: inherit;
  background-color: transparent;
  color: #363636;
  border: none;
  outline: none;  
  width: 100%;
  height: 100%;
  fill: white;
}
input[type="time"]::-webkit-calendar-picker-indicator{
	filter: invert(100%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
  }
.form_input__box::-webkit-input-icon {
	color: var(--lightest-white) !important;
}
.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
	width: 25rem;
}


.form_input_coupon_box::hover {
	 border:none !important;
}

.form_input_coupon_box {
	border:none !important;
}


@media (max-width: 767px) {
	.form_input__container {
		width: 100%;
	} 
}

@media only  screen and (max-width: 767px){
	.form_input{
		min-width: 9rem;
	}
}