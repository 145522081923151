.productCards {
	display: flex;
	align-items: center;
	gap: 2rem;
	flex-wrap: wrap;
}

.productCard {
	text-decoration: none;
	color: currentColor;
	background-color: var(--pure-white);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
	width: 25rem;
}

.productCard:hover {
	transform: translateY(-.2rem);
}

.productCard__iImageIcon {
	position: relative;
}

.productCard__iImageIcon_image {
	width: 20rem;
	height: 20rem;
	border-radius: 2rem;
	object-fit: cover;
	border-radius: 2rem;
}

.productCard__iImageIcon_icon_container {
	background-color: var(--primary-color);
	display: flex;
	padding: .5rem;
	border-radius: 50%;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-40%, -40%);
}

.productCard__iImageIcon_icon {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
	transition: all .4s;
}

.productCard__iImageIcon_icon:hover {
	height: 2.5rem;
	width: 2.5rem;
}

.productCard__title {
	text-decoration: none;
	color: var(--dark-grey);
	font-size: 1.5rem;
	font-weight: 600;
	margin-top: 1rem;
}

.productCard__subTitle {
	font-size: 1.1rem;
	font-weight: 400;
	color: var(--dark-grey);
}

.productCardCategory {
	border: 1px solid var(--blue);
	padding: .2rem 1rem;
	border-radius: 2rem;
	color: var(--blue);
	font-size: 1.2rem;
}

.productCardStats {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productCardStatsCount {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.productCardStat {
	display: flex;
	align-items: center;
	gap: .5rem;
	color: var(--blue);
	font-size: 1.5rem;
}

.productCardStatIcon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--blue);
}

.productCardActions {
	border-top: 1px solid var(--white);
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productCardActionBtn {
	display: flex;
	align-items: center;
	gap: .5rem;
}

.productCardAction {
	background-color: transparent;
	border: 1px solid var(--dark-grey);
	padding: .6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}

.productCardActionIcon {
	height: 1.2rem;
	width: 1.2rem;
	fill: var(--dark-grey);
	transition: all .4s;
}

.productCardAction:hover {
	background-color: var(--dark-grey);
	border: 1px solid var(--dark-grey);
}

.productCardAction:hover .productCardActionIcon {
	fill: var(--pure-white);
}