.shopping-cartsidebar {
    display: none;
}


.navigationbar {
    position: fixed;
    width: 40rem;
    top: 0;
    right: 0;
    bottom: 0;
    float: right;
    background-color: var(--white);
    overflow: hidden;
    z-index: 1000;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius:0;
    background-color: white;
    top: 0;
    z-index: 999;  
    box-shadow: 0 0 16px 0 rgba(46, 46, 46, 0.6);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


.navigationbar.active {
    width: 0;
    transition: all 0.5s ease;
}

.cart--header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 2px solid #dee2e6;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pincode--container {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
 
.cart__product-listContainer {
    display: flex;
    gap: 1rem;
    padding: 2rem 2rem 2rem 0rem;
    border-bottom: 1px dashed #787474;
}
 


.cart-product_image {
    height: 6rem;
    background-color: var(--pure-white);
    border-radius: 15px;
}

.cart--productDetail__container {
    width: 100%;
    padding: 0 2rem 0 0;
}

.item-name_new {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    width: 80%;
}

.coupon--container {
    border-top: 1px solid;
}

.coupon--container h3 {
    margin-top: 1rem;
    padding-left: 1rem;
}

.cart--items__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.quantity--input {
    width: 8rem;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;

}

.cart--items__price--summary {
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid rgb(128, 128, 128); */
}

.cart--items__charges {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 12px;
}

.cart--order__Summary {
    padding: 1rem;
}

.cart--footer {

    padding: 1rem;
    padding-bottom: 4rem;
    position: absolute;
    width: 100%;
    /* height: 15%; */
    right: 0;
    bottom: 0;
    left: 0;
    color: var(--primary-color);
    background-color: var(--bg-secondary);
    clear: both;
}

.cart--total {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: white;
}