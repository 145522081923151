.container {
	display: grid;
	grid-template-rows: 5rem 1fr auto;
	gap: 2rem;
	background: #fff;
}

.content {
	/* min-height: 90vh; */
	grid-row: 1 / 3;
	background: #fff;
}
