/*Desktop View*/

.website_header {
    grid-row: 1 / 2;
    background-color: #fff;
    padding: 2rem 6rem;
    position: fixed;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.logo_website {
    height: 8.4rem;
    position: absolute;
    padding: 10px;
    /* background: black; */
}

.navigation_website {
    margin-left: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation__links {
    list-style: none;
    font-size: 1.2rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    cursor: pointer;
}

.navigation__link {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
}

.navigation__link:hover {
    text-decoration: none;
    font-weight: 600;
    color: #ff6308;
}

.navigation__active {
    color: #ff0000 !important;
}

.header-icon-in {
    fill: #000000;
    height: 2rem;
    width: 2rem;
}

.header-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.mobile-option {
    display: none;
}

.mobile-menu {
    display: none;
}

/* end cart css */


@media (max-width: 1700px) {
    .website_header {
        padding: 2rem 2rem;
    }
    /* .logo_website{
        height: 4rem;
    } */
}

@media (max-width: 1536px) {
    .website_header {
        padding: 2rem 2rem;
    }
    /* .logo_website{
        height: 4rem;
    } */
}

@media (max-width: 1400px) {
    .website_header {
        padding: 2rem 2rem;
    }
    /* .logo_website{
        height: 4rem;
    } */

    .navigation__links {
        gap: 3rem;
    }

    .navigation_website {
        margin-left: 15%;
    }
}

@media (max-width: 1230px) {
    /* .logo_website{
        height: 4rem;
    } */
    .website_header {
        padding: 2rem 2rem;
        width: 100% !important;
        display: flex;
    }

    .navigation__links {
        gap: 1rem;
    }

    .navigation_website {
        margin-left: 25%;
    }
}

@media (max-width: 900px) and (min-width: 768px) {
    /* .logo_website{
        height: 4rem;
    } */
}


@media (max-width: 768px) {
    .navigation__links--contact {
        display: none !important;
    }

    .website_header {
        padding: 2rem 1rem;

        padding-top: 1rem;
        padding-bottom: 0;
    }

    .mobile-menu {
        display: flex;
    }

    .icon-box {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-right: 1rem;
    }

    .logo_website{
        height: 5.4rem;
    }

    .menu-icon2 {
        height: 2rem;
        width: 2rem;
    }

    .nav-options {
        display: flex;
        width: 100%;
        min-height: 25rem;
        position: absolute;
        top: 10rem;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 1.5rem;
        padding: 5rem;
    }

    .nav-options.active {
        background: #f9f9f9;
        left: 0rem;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding-left: 2rem;
        box-shadow: var(--box-shadow1);
    }

    .menu-icon {
        width: 4rem;
        height: 4rem;
    }

    .menu-icon3 {
        width: 2rem;
        height: 2rem;
    }

    .option {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vw;
        padding: 30px 0px;
    }

    .navigation__links {
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        grid-gap: 3rem;
        gap: 3rem;
        align-items: flex-start;
        justify-content: center;
    }

    .navigationLoginSidebar {
        left: 25% !important;
    }
}