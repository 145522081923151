.form_input {
	display: flex;
	grid-gap: 2rem;
	/* gap: 2rem; */
	/* background-color: var(--light-grey);
	/* background-color: #121212; */
	/* padding: 1.5rem 2rem; */
	/* padding-bottom: 2.5rem; */
	/* padding-bottom: 10rem; */
	/* border-radius: 15rem; */
	/* margin-bottom: 2rem; */
	/* object-fit: cover;  */
	/* position: relative; */
	width: 300px; /* Fixed width of the container */
	height: 200px; /* Fixed height of the container */
	overflow: hidden; /* Ensure content doesn't overflow the container */
	border-radius: 20%; /* Makes the container rounded */
	border: 2px solid #ccc; /* Example border style */
}
.form_input__label {
	color: var(--light-grey);
	font-size: 1.2rem;
}
.form_input__container {
	display: flex;
	align-items: center;
	/* gap: 1rem; */
	background-color: var(--pure-white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
	object-fit: cover;
}
.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.form_input__box {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.form_input__box::-webkit-input-placeholder {
	color: var(--light-grey);
}
.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
}

.modalContainer__form--sections {
	display: flex;
	/* gap: 2rem; */
	align-items: center;
}

.fileUploadText{
	/* padding-right: 2rem; */
	color: var(--pure-white);
	text-align: center;
}

.fileUpload__label--images{   
	width: 50%; 
    height: 50%;        
    object-fit: fill;
}