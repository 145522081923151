    .product_main_container {
        margin: 5rem 4rem;
        display: flex;
        gap: 5rem;
        align-items: flex-start;
    }

    .product_left_sidebar {
        width: 20%;
    }

    .product_right_container {  
        width: 80%;
        display: inline-flex;
        margin: 2rem 0;
        gap: 4rem;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }


    .collection-filter-block {
        margin-bottom: 30px;
        padding: 0 30px;
        border: 1px solid #dddddd;
        background-color: #ffffff;
    }


    .products_discount {
        display: flex;
        margin-top: 8rem;
        background-color: black;
        padding: 1rem 30rem;
        color: var(--pure-white);
        justify-content: space-between;
    }

    .slideside {
        display: flex;
        gap: 2rem;
        background-color: var(--light-grey2);
        width: 100%;
        height: 100%;
    }

    .product_discount_heading {
        font-size: 1.6rem;
        font-weight: 500;
    }

    .prod--main {
        width: 100%;

    }

    .products {
        padding: 1rem;
        padding-top: 1rem;
        background-color: var(--light-grey2);
        border-radius: 15px;
        justify-content: center;
        text-align: center;
        width: 30rem;
        cursor: pointer;
    }

    .product_image {
        height: 28vh;
        width: 100%;
        background-color: var(--pure-white);
        border-radius: 15px;
    }

    .product_name {
        font-size: 16px;
        font-weight: 600;
        color: var(--yellow);
    }

    .product_price {
        font-size: 1.8rem;
        font-weight: 800;
    }

    .product_price1 {
        display: flex;
        justify-content: space-around;
        gap: 4rem;
    }

    .product_cart {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product_button {
        font-size: 1.4rem;
        font-weight: 600;
        padding: 0.5rem;
        border: none;
        outline: none;
        border-radius: 5px;
        text-transform: uppercase;
        background-color: black;
        color: var(--pure-white);
    }

    .product_button:hover {
        background-color: var(--pure-white);
        color: black;
    }

    .productList--card {
        text-decoration: none;
        /* position: relative; */
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background: #fff;
        content: "";
        z-index: 1;
        border-radius: 2px;
        box-shadow: inset 0 0 0 1px #ededed;
        transition: all .4s;
        align-items: center;
        padding: 1rem;
        border-radius: 0.8rem;
    }

    .productList--card:hover {
        --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        --tw-border-opacity: 1;
        transform: var(--tw-transform);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        --tw-shadow: 0 6px 12px rgba(0, 0, 0, .08);
    }

    .list--card--image--container {
        max-height: 15rem;
        max-width: 15rem;
        position: relative;
    }

    .list--card--product--image {
        width: 15rem;
        height: 15rem;
        object-fit: cover;
        border-radius: 2rem;
    }

    .product--card--info__container {
        padding: 1rem 2rem 0 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .recommendation--header {
        font-size: 16px;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-left: 10rem;
    }

    .prod--list-paginater {
        float: right;
        padding: 0 4rem 2rem 2rem;

        position: absolute;
        right: 0;
        bottom: 0;
        clear: both;
    }

    .recommendation--cart--button {
        background-image: linear-gradient(195deg, #42424a, #191919);
        border: 1px solid var(--dark-grey);

        border-radius: 0.5rem;
        font-family: inherit;
        font-size: 1.3rem;
        padding: 0.5rem;
        color: var(--white);
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.4s;
    }

    .list--header {
        font-size: 16px;
        font-weight: 600;
        margin-top: 2rem;

    }

    .card--inStock {
        background-color: var(--blue);
        padding: 0.25rem 1rem;
        border: none;
        border-radius: 10px;
        text-align: center;
        font-size: 12px;
    }

    .card--outofStock {
        background-color: var(--red);
        padding: 0.25rem 1rem;
        border: none;
        border-radius: 10px;
        text-align: center;
        font-size: 12px;
    }

    @media (max-width: 1024px) {}

    /*mobile view*/
    @media (max-width: 768px) {
        .main_outer {
            width: 100%;
        }

        /* .all_products_list {
        margin: 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    } */

        .list--header {
            margin-bottom: 2rem;
            text-align: center;
        }

        .productList--card {
            width: 30rem;
        }

        .prod--list-paginater {
            padding: 1rem;
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
        }

        .products_discount {
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            margin-top: 6.5rem;
        }

        .product_discount_heading {
            font-size: 1.4rem;
            text-align: center;
            justify-content: center;
        }

        .slideside {
            flex-direction: column;
        }
    }

    .infinite-scroll-component {
        overflow: hidden !important;
    }

    .collection-collapse-block .collapse-block-title {
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        color: #444444;
        font-weight: 600;
        margin: 3rem 0 1rem 0;

    }

    .collection-collapse-block .collapse-block-title:after {
        position: absolute;
        display: block;
        top: 0;
        right: -3px;
        font-family: FontAwesome;
        color: #444444;
    }

    .collection-filter-block {
        margin: 30px;
        padding: 0 30px;
        border: 1px solid #dddddd;
        background-color: #ffffff;
    }

    .collection-filter-block .product-service {
        padding: 30px 0;
    }

    .collection-filter-checkbox label {
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
        color: #777777;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding-left: 1.5rem;

    }

    .input-range {
        height: 1rem;
        position: relative;
        width: 100%;
    }

    .input-range__label--min, .input-range__label--max {
        bottom: -2.5rem !important;
        position: absolute;
    }

    .input-range__label {
        color: #000000 !important;
        font-size: 1.2rem !important;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        white-space: nowrap;
    }

    .input-range__label--value {
        top: -2.8rem !important;
    }

    .input-range__track--background {
        left: 0;
        margin-top: -0.15rem;
        position: absolute;
        right: 0;
        top: 50%;
    }

    .input-range__slider {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 100%;
        cursor: pointer;
        display: block;
        height: 1.5rem !important;
        margin-left: -0.5rem;
        margin-top: -0.9rem !important;
        outline: none;
        position: absolute;
        top: 50%;
        -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
        transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
        width: 1.5rem !important;
    }

    .input-range__track {
        background: #4b4b4b !important;
        border-radius: 0.3rem;
        cursor: pointer;
        display: block;
        height: 0.5rem !important;
        position: relative;
        -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
        transition: left 0.3s ease-out, width 0.3s ease-out;
    }

    .input-range__track--active {
        background: #ff9000 !important;
    }

    .input-range__slider {
        background: #ff9000 !important;
        border: 1px solid #ff9000 !important;
    }

    .block-price-content .custom-control {
        padding-right: 1.5rem;
        padding-bottom: 18px;
        margin-top: 2.5rem;
    }

    .collection-filter-checkbox label:before {
        top: 1px;
        height: 15px;
        width: 15px;
        background: #ffffff;
        border: 1px solid #777777;
    }

    .collection-filter-checkbox label:after {
        top: 1px;
        height: 15px;
        width: 15px;
        background-size: 65%;
    }

    .custom-control {
        position: relative;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        margin: 2rem 0;
    }

    .custom-control-inline {
        display: inline-flex;
        margin-right: 1rem;
    }

    .custom-control-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .actualPrice {
        text-decoration-line: line-through;
        color: #b1b1b1;
        font-size: 1.5rem;
        padding-left: 1rem;
    }


    .custom-control-input:active~.custom-control-label::before {
        color: #fff;
        background-color: #b3d7ff;
    }

    .custom-control-input:disabled~.custom-control-label {
        color: #6c757d;
    }

    .custom-control-input:disabled~.custom-control-label::before {
        background-color: #e9ecef;
    }

    .custom-control-label {
        position: relative;
        margin-bottom: 0;
    }

    .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        user-select: none;
        background-color: #dee2e6;
    }

    .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    .custom-control-input:checked~.custom-control-label:after {
        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
    }

    .product-category {
        display: none;
    }

    @media screen and (max-width:900px) {
        .product_main_container {
            margin: 1rem 1rem;
            display: flex;
            justify-content: center;
        }

        .product-category {
            display: flex;
            gap: 1rem;
            width: 100vw;
            overflow-x: auto;
            height: 5rem;
            /* margin-top: 8rem; */
            align-items: center;
            padding: 10px;
        }

        .product-category {
            -ms-overflow-style: none;
            /* for Internet Explorer, Edge */
            scrollbar-width: none;
            /* for Firefox */
            overflow-y: scroll;
        }

        .product-category::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }

        .product_left_sidebar {
            display: none;
        }

        .product_right_container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .product-list-chip {
            max-width: 50rem;
            height: 3rem;
            padding: 5px;
            background-color: #fff;
            border: 1px solid #000;
            color: #000;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            /* overflow: hidden; */
            white-space: nowrap;

        }

        .product-list-chip-active {
            max-width: 50rem;
            height: 3rem;
            padding: 5px;
            background-color: #000;
            border: 1px solid #000;
            color: #fff;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            /* overflow: hidden; */
            white-space: nowrap;
        }
    }