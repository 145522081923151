.contentSection {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.logo {
	height: 8rem;
}
