:root {
 --bg-primary: linear-gradient(to right bottom, #e8962e, #e45131) !important;
 --bg-secondary: #0c1014;
 --primary-color: #ff9000;
 --white: #e7e7ea;
 --pure-white: #ffffff;
 --lightest-white: #ececec;
 --black: #000000;
 --light-grey: #8b8b8b;
 --light-grey2: #f3f3f3;
 --light-grey3: #f1f1f1;
 --dark-grey: #12151a;
 --red: #ff3366;
 --yellow: #e1a82d;
 --gold: #ff9000;
 --blue: #09baa6;
 --dark-blue: #007c6e;
 --light-blue: #09baa567;
 --lightest-blue: #ffffff;
 --box-shadow1: 0 3px 3px 0 rgb(0 0 0 / 15%), 0 3px 1px -2px rgb(35 35 35 / 20%),
  0 1px 5px 0 rgb(0 0 0 / 15%);
 --box-shadow-input: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);

 --tw-translate-y: -0.375rem;
 --tw-translate-x: 0;
 --tw-translate-y: 0;
 --tw-rotate: 0;
 --tw-skew-x: 0;
 --tw-skew-y: 0;
 --tw-scale-x: 1;
 --tw-scale-y: 1;
}

* {
 margin: 0px;
 padding: 0px;
 /* box-sizing: inherit; */
 box-sizing: border-box;
}

html {
 font-size: 62.5%;
}

body {
 box-sizing: border-box;
 background-color: #e7e7ea;
 color: var(--dark-grey);
 font-family: "Poppins", sans-serif;
 font-weight: 500;
 line-height: 1.7;
}

.Toastify {
 z-index: 99999999999;
}

.Toastify__toast--success {
 background-color: var(--blue) !important;
}

.Toastify__toast--error {
 background-color: #ff3366 !important;
}

.PricingTable{
  background-color: red!important;
}


.Toastify__toast--warning {
 background-color: var(--yellow) !important;
}

.Toastify__toast-body {
 font-size: 1.4rem;
 font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
 width: 1rem;
 height: 1rem;
}

::-webkit-scrollbar-trackk {
 -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
 box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track-piece {
 background-color: var(--pure-white);
}

::-webkit-scrollbar-thumb {
 background-color: var(--light-grey);
}


.PriceColumn-description{
    color: #007c6e !important;
}