.dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.dashboard_section_1{
    width: 100%;
    height: 30rem;
    display: flex;
    gap: 2rem;
}

.dashboard_section_2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    padding: 2rem;
    justify-content: flex-start;
}


.dashboard_section_2 canvas{
    margin: 1rem;
    width: 28vw !important;
    height: 22rem !important;
}

.dashboard_section_3{
    width: 100%;
    display: flex;
    gap: 2rem;
    
}



.dashboard_cards{
    width: 60%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    /* background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5); */
}

.dashboard_cards1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
    justify-content: space-between;
    
    /* background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5); */
}

.dashboard_card {
    width: 47%;
    height: 46%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    transition: all .2s ease-in-out
}

.dashboard_card1 {
    width: 23%;
    height: 12rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    
    padding: 1rem;
    transition: all .2s ease-in-out
}

.dashboard_card:hover{
    transform: scale(1.010);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.dashboard_bar{
    width: 40%;
    height: 30rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all .2s ease-in-out
}

.dashboard_bar:hover{
    transform: scale(1.010);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.dashboard_bar canvas{
    width: auto !important;
    height: 28rem !important;
}

.dashboard_box{
    width: 50%;
    height:100%;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.stylist-section{
    display: flex;
    gap:1rem;
    align-items: center;
    /* justify-content: center; */
}
.dashboard_box canvas{
    /* width: auto !important;
    height:35rem !important; */
}

.card_black{
    width: 10rem;
    height: 10rem;
    background-color: #000;
    border-radius: 1rem;
    display: grid;
    place-content: center;
}

.dashboard_icon {
	height: 3rem;
	width: 3rem;
	fill: var(--white);
    stroke:var(--white) ;
}

.card_details{
    /* width: 100%;s */
    width: fit-content;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.card_details span{
    font-size: 5rem;
    font-weight: 700;
    line-height: 0.8;
    /* color: var(--primary-color); */
}

.card_details p{
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    /* color: var(--primary-color); */

}

.dashboard_p{
    font-size: 2rem;
    font-weight: 600;
}

.stylist_list{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.stylist_info{
    width: 100%;
    height: 8rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
}

.stylist_info:hover{
    border: 1px solid #ccc;
    border-radius: 1rem;
}

.stylist_text{
    font-size: 1.8rem;
    font-weight: 800;
}

.stylist_count{
    min-width: 12rem;
    padding: 1rem;
    background-color: #000;
    border-radius: 1rem;
    text-align: center;
    font-size: 1.3rem;
    color: #fff;
    
}

.react-google-flight-datepicker .dialog-header{
	display: none;
}

.react-google-flight-datepicker .date-picker-input{
	height: 40px;
	width:35rem;
}

/* .react-google-flight-datepicker .dialog-date-picker {
	z-index: 999 !important;
	width: 290px !important;
	top: 75px;
} */
 
.react-google-flight-datepicker .icon-calendar {
    width: 24px;
    height: 24px;
    fill: #ff9000 !important;
    margin-right: 12px;
}

.react-google-flight-datepicker .day {
	width: 30px;
	height: 30px;
}

.react-google-flight-datepicker .date {
	min-width: 140px;
}

.dialog-header .date-picker-input {
	min-width: 350px !important;
	width: 100% !important;
}

.react-google-flight-datepicker .reset-button {
	display: none;
}

.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
	border: 2px solid #000000 !important;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
	background-color: #000000 !important; 
}

.react-google-flight-datepicker .day.selected.hovered::after,
.react-google-flight-datepicker .day.selected::after {
	border-radius: 100%;
	background-color: #000000 !important;
}

.react-google-flight-datepicker .calendar-wrapper {
	min-height: 250px;
}

.react-google-flight-datepicker .day::after {
	height: 3rem;
	width: 3rem;
}

.react-google-flight-datepicker .day.hovered{
	background-color: #d8d8d8c2;
}

.react-google-flight-datepicker .icon-calendar{
	fill: #000000;
}
.react-google-flight-datepicker .day.selected.hovered:hover {
    color: #ffffff;
}
.react-google-flight-datepicker .flipper-button {
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -15px;
    pointer-events: all;
}
.react-google-flight-datepicker .month-calendar {
    width: 220px;
    color: #3c4043;
    margin-right: 43px;
}

span{
    font-size: 1.4rem;
}

.salon_status{
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    font-size:1.2rem;
}

.react-google-flight-datepicker .date-picker {
    width: 409px !important;
}

.colorGreen{
    color:green !important;
}

.colorRed{
    color: red !important;
}