@import url(https://fonts.googleapis.com/css?family=Varela+Round);
:root {
 --bg-primary: linear-gradient(to right bottom, #e8962e, #e45131) !important;
 --bg-secondary: #0c1014;
 --primary-color: #ff9000;
 --white: #e7e7ea;
 --pure-white: #ffffff;
 --lightest-white: #ececec;
 --black: #000000;
 --light-grey: #8b8b8b;
 --light-grey2: #f3f3f3;
 --light-grey3: #f1f1f1;
 --dark-grey: #12151a;
 --red: #ff3366;
 --yellow: #e1a82d;
 --gold: #ff9000;
 --blue: #09baa6;
 --dark-blue: #007c6e;
 --light-blue: #09baa567;
 --lightest-blue: #ffffff;
 --box-shadow1: 0 3px 3px 0 rgb(0 0 0 / 15%), 0 3px 1px -2px rgb(35 35 35 / 20%),
  0 1px 5px 0 rgb(0 0 0 / 15%);
 --box-shadow-input: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);

 --tw-translate-y: -0.375rem;
 --tw-translate-x: 0;
 --tw-translate-y: 0;
 --tw-rotate: 0;
 --tw-skew-x: 0;
 --tw-skew-y: 0;
 --tw-scale-x: 1;
 --tw-scale-y: 1;
}

* {
 margin: 0px;
 padding: 0px;
 /* box-sizing: inherit; */
 box-sizing: border-box;
}

html {
 font-size: 62.5%;
}

body {
 box-sizing: border-box;
 background-color: #e7e7ea;
 color: #12151a;
 color: var(--dark-grey);
 font-family: "Poppins", sans-serif;
 font-weight: 500;
 line-height: 1.7;
}

.Toastify {
 z-index: 99999999999;
}

.Toastify__toast--success {
 background-color: #09baa6 !important;
 background-color: var(--blue) !important;
}

.Toastify__toast--error {
 background-color: #ff3366 !important;
}

.PricingTable{
  background-color: red!important;
}


.Toastify__toast--warning {
 background-color: #e1a82d !important;
 background-color: var(--yellow) !important;
}

.Toastify__toast-body {
 font-size: 1.4rem;
 font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
 width: 1rem;
 height: 1rem;
}

::-webkit-scrollbar-trackk {
 box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track-piece {
 background-color: #ffffff;
 background-color: var(--pure-white);
}

::-webkit-scrollbar-thumb {
 background-color: #8b8b8b;
 background-color: var(--light-grey);
}


.PriceColumn-description{
    color: #007c6e !important;
}
.Button_button__h5S_F,
.Button_button__delete__3OBaZ {
	border-radius: .5rem;
  font-family: inherit;
  font-size: 1.5rem;
  padding: 1rem 3rem;
  color: var(--pure-white);
  /* color: var(--white); */
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  grid-gap: .5rem;
  gap: .5rem;
  transition: all .4s;
  font: bold;
}

.Button_button__h5S_F {
	/* background-image: linear-gradient(195deg, #42424a, #191919); */
	background-color: var(--primary-color);
	/* border: 1px solid var(--dark-grey); */
	margin-left: 2rem;
	background-color: #FF9000;
	border: 1px solid #FF9000	;
}

.Button_button__h5S_F .Button_button__icon__r3R7X {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
	transition: all .4s;
}

/* .button:hover {
	background-color: transparent !important;
	border: 1px solid var(--dark-grey);
	color: #000;
} */

/* .button:hover .button__icon {
	fill: #000;
} */

.Button_button__delete__3OBaZ {
	border: 0px solid;
	/* box-shadow: 0 3px 3px 0 rgb(233 30 99 / 15%), 0 3px 1px -2px rgb(233 30 99 / 20%), 0 1px 5px 0 rgb(233 30 99 / 15%); */
	/* background-image: linear-gradient(195deg, #ec407a, #d81b60); */
	background-color: var(--primary-color);
	
}

.Button_button__delete__3OBaZ .Button_button__icon__r3R7X {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
	transition: all .4s;
}

.Button_button__delete__3OBaZ:hover .Button_button__icon__r3R7X {
	fill: var(--pure-white);
}

@media screen and (max-width:500px) {
	.Button_button__h5S_F,
.Button_button__delete__3OBaZ {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

}
.TextBox_form_input1__lRlbc {
  display: flex;
  flex-direction: column;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.37);
  width: 100%;
  padding-left: 5px;
  padding-bottom: 10px;
  display: flex;
}

.TextBox_apply_Coupon_Text__2bWuv {
  color: white;
}

.TextBox_modal_Container__2lU1K {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TextBox_input_Coupon__2aiP6 {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.TextBox_update_modal_container__13nH_ {
  border-radius: 10px;
  width: 550px;
  max-width: 91%;
  text-align: center;
  margin: 0 auto;
}

.TextBox_loader_Quantity__CxBG3 {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TextBox_amount_Container__1xIOg {
  display: flex;
  justify-content: space-between;
}

.TextBox_dropDown_Handle__30TVS {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  background-color: #3d3d3d;
  border-radius: 5px;
  border: 1px solid #0000003d;
  color: #fff;
  font-family: Poppins, sans-serif;
  width: 70%;
  height: 40px;
  margin-left: 10px;
}

.TextBox_stylist_Container__2kOhI {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  color: #efefef;
}

.TextBox_errorText__3NbzX {
  color: red;
  margin-top: 0.5rem;
}

.TextBox_price_Label__OutIA {
  color: #ffa500;
}

.TextBox_price_Container__yek2R {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 0.2rem;
}

.TextBox_price_Box__3zOeS {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.TextBox_list_Price__1uCfo {
  padding: 0.5rem;
  cursor: pointer;
  color: #fff;
}

.TextBox_form_input__label1__2LN_t {
  color: var(--lightest-white);
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #efefef;
  font-size: 1.2rem; 
  margin: 0.5rem;
  margin-bottom: 0px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;  
}

.TextBox_form_input__label__3ut0P {
  color: var(--lightest-white);
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #efefef;
  font-size: 1.2rem; 
  margin: 0.5rem;
   margin-bottom: 0px; 
 cursor: pointer;
  /* color: #fff;
  margin-bottom: 10px; */
}

.TextBox_remove_Coupon__1HZfe {
  border: none;
  border-radius: 9px;
  background-color: #ff9000;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 10px;
}

.TextBox_input_Coupon__2aiP6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TextBox_input_Coupon_Container__TwcA7 {
  display: flex;
  align-items: center;
  width: 100%;
}

.TextBox_form_input__container__1PlzI {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  /* background-color: var(--white); */
  /* border-radius: 1rem; */
  padding: 1rem 2rem;
  padding-left: 1rem;
  width: 25rem;
  /* padding-bottom: 3rem; */
  border-bottom: 1px solid var(--light-grey);
  /* margin: 2.3rem; */
}

.TextBox_loading_spinner__qAniD {
  border: 2px solid #c5a377;
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: TextBox_spin__3yg5L 1s linear infinite;
          animation: TextBox_spin__3yg5L 1s linear infinite;
  margin: 0 auto;
}

.TextBox_input_Coupon_Label__2gVTN {
  background-color: #3d3d3d;
  width: 65%;
  margin-right: 1rem;
  border-radius: 5px;
}

.TextBox_loader_container__1ykHV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

@-webkit-keyframes TextBox_spin__3yg5L {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes TextBox_spin__3yg5L {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.TextBox_form_input__icon__2VDav {
  height: 2rem;
  width: 2rem;
  fill: var(--lightest-white);
  fill: #b7b2b2;
  margin-right: 5px;
}

.TextBox_form_input__box__2afRG {
  font-family: inherit;
  background-color: transparent;
  color: var(--lightest-white);
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  fill: white;
}



.TextBox_form_input__box1__3CoaH {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  width: 100%;
  fill: white;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  background-color: transparent !important;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill {
  color: white !important;
  background-color: transparent !important;
}


.TextBox_update_Button__2Him9 {
  margin-top: 1rem;
  border: none;
  border-radius: 9px;
  background-color: #ff9000;
  color: #fff;
  cursor: pointer;
  width: 90px;
  height: 34px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.TextBox_unit_Amount_Label__2ST77 {
  font-size: 1.2rem;
  font-weight: normal;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(100%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
          filter: invert(100%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}
.TextBox_form_input__box__2afRG::-webkit-input-icon {
  color: var(--lightest-white) !important;
}
.TextBox_form_input__error__3GcKX {
  font-size: 1.2rem;
  color: #ff3366;
  width: 25rem;
}

.TextBox_form_input_coupon_box__18jME::hover {
  border: none !important;
}

.TextBox_form_input_coupon_box__18jME {
  border: none !important;
}

@media (max-width: 767px) {
  .TextBox_form_input__container__1PlzI {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .TextBox_form_input__2TiYl {
    min-width: 9rem;
  }
}

.login_container {
	grid-column: 1 / -1;
	grid-row: 1 / -1;

	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;

	display: flex;
	justify-content: center;
}

.login_container__form {
	background-color: #fff;
	box-shadow: var(--box-shadow1);
	padding: 3rem 5rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #6e6b7b;
	border: 1px solid #d8d6de;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.login_container__logo {
	height: 10rem;
}

.login_container__heading {
	color: #6e6b7b;
	font-weight: 200;
	font-size: 3rem;
	text-align: center;
}

.login_container__subheading {
	color: #6e6b7b;
	font-weight: 500;
	font-size: 1.3rem;
	text-align: center;
	margin-bottom: 1rem;
}



.login_container_side_image {
	display: flex;
	justify-content: center;
	align-items: center; 
	max-width: 100%; 
	height: 100%;
    width: 82%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}


.login__copyright {
	margin-top: 2rem;
}
.login__copyright-text {
	text-align: center;
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--light-grey);
}
.login__copyright-text a {
	text-decoration: none;
	color: var(--primary-color);
	font-weight: 600;
}

.login-register{
	text-decoration: none;
	color: var(--primary-color);
	font-size: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

.login__register {
    margin-top: 2rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

@media screen and (max-width:500px) {
	.login_container_side_image{
		display: none;
	}
}

@media (max-width:920px) {
	.content {
		margin-top: 3.5rem;
		height: 85vh;
		overflow-y: auto;
		overflow-x: hidden;
		padding-left: 4rem;
		padding-right: 4rem;
		padding-bottom: 3rem;
		padding-top: 1rem;
		display: flex;
		grid-gap: 2rem;
		grid-gap: 2rem;
		gap: 2rem;
		flex-direction: column;
	}


	.appointment_box {
		width: 100% !important;
		height: 100% !important;
		border-radius: 1rem;
		background-color: #000000;
		padding: 20px;
		display: flex;
		flex-direction: column;
		grid-gap: 2rem;
		grid-gap: 2rem;
		gap: 2rem;
		/* height: 80vh; */
		overflow-y: visible !important;
		overflow-y: initial !important;
	}
}
.Skeletons_skeleton__AeFqS {
 border-radius: 1rem;
 position: relative;
 overflow: hidden;
}
.Skeletons_skeleton__AeFqS::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background: linear-gradient(
  90deg,
  rgba(240, 240, 241, 0.041),
  rgba(211, 219, 219, 0.24), rgba(127, 126, 126, 0.1) 
 );
 -webkit-animation: Skeletons_progress__1g-HH 1.5s ease-in-out infinite;
         animation: Skeletons_progress__1g-HH 1.5s ease-in-out infinite;
}
@-webkit-keyframes Skeletons_progress__1g-HH {
 0% {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
 }
 100% {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
 }
}
@keyframes Skeletons_progress__1g-HH {
 0% {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
 }
 100% {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
 }
}
.Skeletons_SidebarSkeleton__38kAw {
 margin-left: 2rem;
}
.Skeletons_navigation__logo_skeleton__F2N0c {
 height: 6rem;
 width: 15rem;
 background-color: var(--lightest-white);
 margin-bottom: 4rem;
}
.Skeletons_navigation__list__2yXAs {
 height: 3rem;
 width: 18rem;
 background-color: var(--lightest-white);
 margin-top: 1.2rem;
}
.Skeletons_chartSkeleton__1_54h {
 margin: 2rem 0;
 height: 30.5rem;
 width: 100%;
 background-color: var(--pure-white);
 border-radius: 2rem;
}
.Skeletons_cardSkeletons__2uCcE {
 display: flex;
 grid-gap: 2rem;
 gap: 2rem;
 width: 100%;
 flex-direction: column;
}
.Skeletons_cardSkeleton__du-OT {
 height: 13rem;
 width: 100%;
 background-color: #121212;
}

.Skeletons_cardSkeleton1__1sjg_ {
    height: 25rem;
    width: 100%;
    background-color: #121212;;
   }
.Skeletons_category__3boVU {
 height: 2rem;
 width: 50%;
 background-color: var(--white);
}
.Skeletons_appointmentSection__Smg3u {
 background-color: white;
 border-radius: 15px;
 padding: 2rem 2rem;
}
.Skeletons_service__3FJ-p {
 height: 2rem;
 width: 15rem;
 background-color: var(--white);
}
.Skeletons_profile__2Fzwd {
 height: 6rem;
 width: 6rem;
 background-color: var(--white);
 border-radius: 50%;
}

.Skeletons_profile1__Wuxxn {
    height: 6rem;
    width: 6rem;
    background-color: #545454;
    border-radius: 5px;
   }

.Skeletons_timeslot__3Qfnn {
 height: 3rem;
 width: 8rem;
 background-color: var(--white);
}
.Skeletons_labelSkeleton__2fYwH {
 height: 6rem;
 width: 30rem;
 background-color: var(--white);
}
.Skeletons_textBoxSkeleton__1wVyT {
 margin-top: 0.5rem;
 margin-bottom: 1rem;
 height: 6rem;
 background-color: var(--white);
}
.Skeletons_labelSkeleton1__1ykjH {
    height: 1rem;
    width: 10rem;
    background-color: #545454;
   }
   .Skeletons_labelSkeleton2__2RaET {
    height: 1rem;
    width: 6rem;
    background-color: #545454;
   }
   .Skeletons_textBoxSkeleton1__9owIn {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 4rem;
    width: 20rem;
    background-color: #5454541f;
   }
   .Skeletons_textBoxSkeleton2__1CwJw{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 4rem;
    width: 6rem;
    background-color: #545454;
   }
.Skeletons_buttonSkeleton__1V3z2 {
 height: 4rem;
 width: 10rem;
 background-color: var(--white);
 margin-bottom: 2rem;
}
.Skeletons_textSkeleton__14aA7 {
 height: 5rem;
 width: 18rem;
 background-color: var(--white);
 margin-bottom: 1rem;
}

.Skeletons_container__zdeLy{
    width: 50%;
    height:54.4rem;
    border-radius: 0.3rem;
    position: relative;
    background-color: #545454;
    padding: 2rem
}

.Skeletons_container__zdeLy::after{
    -webkit-animation: Skeletons_progress__1g-HH 1.5s ease-in-out infinite;
            animation: Skeletons_progress__1g-HH 1.5s ease-in-out infinite;   
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.199),
      transparent
    );
}

.Skeletons_iconTextComponent__2p8tL{
height: 2rem;
width: 20rem;
background-color: #353535;
margin-top: 1rem;
margin-left: 19rem;
}


.Skeletons_textInputSkeleton__3MXlW{
        margin-top: 2rem;
        margin-bottom: 1rem;
        height: 3rem;
        width: 30rem;
        background-color: #353535;
        border-radius: 0.3rem;
}

.Skeletons_textAreaSkeleton__1m6aG{
   height: 8rem;
    width: 40rem;
    background-color: #353535;
    margin-top: 1rem;
}

.Skeletons_buttonSkeleton__1V3z2{
    margin-top: 1rem;
    height: 5rem;
    width: 15rem;
    background-color: #353535;
    margin-bottom: 1rem;
}
.Skeletons_upadteButtonSkeleton__1I453{
    margin-top: 2rem;
    height: 3rem;
    width: 10rem;
    background-color: #353535;
    margin-bottom: 1rem;
    margin-left:48rem ;
}

.Skeletons_userIconComponentSkeleton__2muvm{
    position: relative;
  height: 20rem;
  width: 21rem;
  background-color: #353535;
  border-radius: 50%;
  margin-left: 18rem;
  margin-top: -11rem;
}

.Skeletons_userIconComponentSkeleton__2muvm ::after{
    -webkit-animation: Skeletons_progress__1g-HH 1.5s ease-in-out infinite;
            animation: Skeletons_progress__1g-HH 1.5s ease-in-out infinite;   
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(-180px);
            transform: translateX(-180px);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.921),
      transparent
    );
}

.Skeletons_addUser__2Vgjq{
    height: 3.5rem;
    width: 10rem;
    background-color: #353535;
    border-radius: 0.2rem;
    margin-bottom: 3rem;
}

.Skeletons_userContainer__14mKA{
    width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	height:100%;
	background-color: #545454;
    position: relative;
}

.Skeletons_userData__13s0M{
    height: 4rem;
    width: 100%;
    background-color: #353535;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
}
.Skeletons_userData__13s0M::after{
    /* animation: progress 1.5s ease-in-out infinite;   
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-180px);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(61, 61, 61, 0.921),
      transparent
    ); */
}

@-webkit-keyframes Skeletons_loading__1ntUD {
    100% {
      -webkit-transform: translateX(70%);
              transform: translateX(70%);
    }
  }

@keyframes Skeletons_loading__1ntUD {
    100% {
      -webkit-transform: translateX(70%);
              transform: translateX(70%);
    }
  }
  
.navigation {
  grid-column: 1 / 2;
  /* padding-top: 2rem; */
  position: fixed;
  width: 22rem;
  height: 98vh;
  z-index: 1000;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s; 
  /* border-radius: 12px; */
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  /* margin: 1rem; */
  /* padding: 1rem; */
  /* top: 0rem; */
  overflow: hidden;
  /* background-image: linear-gradient(195deg, #42424a, #191919); */
  background-color: #000;
  background-size: 150% 150%;
  /* box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9), */
    /* 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important; */
  -webkit-backdrop-filter: saturate(200%) blur(30px);
          backdrop-filter: saturate(200%) blur(30px);
  /* background-color: hsl(0deg 0% 0% / 100%) !important; */
}

.navigation:hover {
	overflow: auto;
}

.navigation::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.navigation::-webkit-scrollbar-track-piece {
  background-color: var(--bg-secondary);
}

.navigation::-webkit-scrollbar-thumb {
  background-color: var(--light-grey);
}

.navigation.active {
  width: 7rem;
  transition: all 0.5s ease;
}

.navigation__logo {
  height: 10rem;
  padding-left: 6rem;
  padding-top: 1rem;
}
.navigation__logo.active {
  height: 5rem;
  /* padding-left: 6rem; */
  /* padding-top: 1rem; */
}
.navigation__logo2 {
  margin-top: 1rem;
  height: 4.5rem;
  padding-left: 1rem;
  display: none;
}

.sidebarnav__button_active {
  left: 4rem !important;
  overflow: visible !important;
  transition: all 0.5s ease;
}

.sidebarnav__button {
  position: absolute;
  top: 12%;
  left: 19rem;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  overflow: visible !important;
}

.appointment__button {
  position: absolute;
  top: 7%;
  right: 0.4rem;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
}

.sidebarRewardIcon{
  display: flex;
  width: 16px;
  height: 42px;
  vertical-align: middle;
  margin-right: -24px;
  padding-bottom: 3px;
}

.appointment_main {
  display: grid;
  grid-column: 3/-1;
  grid-row: 1/3;
  grid-row: 1/-1;
}

.sidebarnav__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-grey);
  left: 18%;
  z-index: 1000;
  transition: all 0.4s;
  /* height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(10%, 150%);
	z-index: 1000;
	transition: all .4s; */
}

.sidebarnav__icon--box {
  height: 8rem;
  width: 3.07rem;
  fill: #fff;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.sidebarnav__button:hover .sidebarnav__icon {
  fill: var(--primary-color);
}

.navigation__list {
  margin-top: 3rem;
  width: 100%;
  padding-left: 0px;
  list-style: none;
}

.currentNav {
  /* display: flex;
	align-items: center; */
  position: relative;
}

.activeNav {
  position: absolute;
  top: 1rem;
  right: 0;
  height: 2.5rem;
  width: 0.5rem;
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
  /* border-radius: 1rem; */
}

.currentNav .navigation__list--itemHover {
  font-weight: 400;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  background-color: hsla(0, 0%, 78%, 0.2);
  /* box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%); */
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.currentNav .navigation__list--itemHover .navigation__list--link {
  color: var(--white);
  font-weight: 500;
}

.currentNav .navigation__list--itemHover .navigation__list--link2 {
  color: var(--white);
  font-weight: 500;
}

.currentNav
  .navigation__list--itemHover
  .navigation__list--link
  .navigation__icon {
  fill: var(--white);
}

.currentNav
  .navigation__list--itemHover
  .navigation__list--link2
  .navigation__icon2 {
  fill: var(--white);
}

.navigation__list--item {
  border-radius: 1rem;
  padding: 1rem 0;
  padding-left: 2rem;
  display: none;
  transition: all 0.4s;
}

.navigation__list--copyright {
  padding: 1rem 0;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  width: 22rem;
  color: #fff;
}

.navigation__list--copyright span {
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
}

.navigation__list--copyright span a {
  font-size: inherit;
  font-weight: inherit;
  color: currentColor;
  text-decoration: none;
  transition: all 0.4s;
}

.navigation__list--copyright span a:hover {
  color: var(--primary-color);
}

.navigation__list--copyrightHidden {
  padding: 1rem 0;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  display: none;
}

.navigation__list--itemHover {
  border-radius: 1rem;
  width: 20rem;
  padding: 1rem 0;
  padding-left: 2rem;
  transition: all 0.4s;
  display: block;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
}

.navigation__list--itemHover:hover,
.navigation__list--item:hover {
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
  -webkit-transform: translateX(0.2rem);
          transform: translateX(0.2rem);
}

.navigation.active .navigation__list--itemHover {
  display: none;
}

.navigation.active .navigation__list--item {
  display: block;
}

.navigation.active .navigation__list--copyright {
  display: none;
}

.navigation.active .navigation__list--copyrightHidden {
  display: block;
}

.navigation.active .navigation__logo {
  /* display: ; */
  height :12px;
}

.navigation.active .navigation__logo2 {
  display: block;
}

.navigation__list--link {
  text-decoration: none;
  color: var(--white);
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.navigation__list--link--span {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.navigation__list--link2 {
  text-decoration: none;
  color: var(--white);
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.navigation__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.navigation__icon2 {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.navigation__icon1 {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}

.hasChildren {
  position: relative;
}

.hasChildren::after {
  cursor: pointer;
  padding-right: 1rem;
  content: " \002B";
  color: var(--white);
  font-size: 1.7rem;
  position: absolute;
  right: 0rem;
  top: 0.7rem;
}

.subMenuActive.hasChildren::after {
  content: " \002D";
}

.subMenuActive.hasChildren ul {
  display: block;
}

.navigation__sub__active {
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
  background-color: hsla(0, 0%, 78%, 0.2);
  color: var(--white) !important;
  font-weight: 600 !important;
  padding: 1rem;
  border-radius: 0.5rem;
}

.navigation__sub__active svg {
  fill: var(--white) !important;
}

.hasChildren ul {
  margin-top: 1rem;
  display: none;
  list-style: none;
}

.hasChildren ul li {
  padding: 0.5rem 1rem;
  transition: all 0.4s;
}

.hasChildren ul li:hover {
  /* background-image:linear-gradient(195deg, #c66f00, #ff9510); */
}

.hasChildren ul li a {
  text-decoration: none;
  color: var(--white);
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.hasChildren ul li a svg {
  height: 1.4rem;
  width: 1.4rem;
  fill: var(--white);
}

.navigation__list--link2--logout {
  background: transparent;
  box-shadow: none;
  border: 0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}


.navigation__logos{
  /* display: contents; */
  /* display: block;
  background-color: var(--light-grey);
  position: relative;
  transition: all 0.5s ease; */
  display: none;
}
.active_logos{
  display: block;
  background-color:#121212;
  position: relative;
  /* transition: all 0.5s ease; */
  transition: all 0.4s;
  margin-left: 1rem;
  margin-top: 1rem;
}


@media (max-width: 809px) {
	.navigation__icon {
		height: 1.5rem;
		width: 1.5rem;
	  }
	.navigation__icon span {
		font-size: 1rem;
	  }
    .navigation{
      width: 18rem;
    }

    .sidebarnav__button {
      left: 14rem;
    }

    .navigation__list--copyright span{
     font-size: 0.8rem; 
    }

    .currentNav .navigation__list--itemHover{
      width: 14rem;
    }

    .navigation__logos{
      /* display: contents; */
      /* display: block;
      background-color: var(--light-grey);
      position: relative;
      transition: all 0.5s ease; */
      display: none;
    }
    .active_logos{
      display: block;
      background-color:#121212;
      position: relative;
      /* transition: all 0.5s ease; */
      transition: all 0.4s;
      margin-left: 1rem;
      margin-top: 1rem;
    }
}
.header {
	padding-top: 2rem;
	padding-right: 4rem;
	padding-left: 4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header__left {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	position: relative;
}

.header__button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.header__icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--primary-color);
}

.header__container {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.header__container--backBtn {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	display: flex;
	align-items: center;
}

.header__container--backBtnIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
}

.header__heading {
	font-size: 1.5rem;
	font-weight: 600;
}


.header__container--icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--dark-grey);
}

.header__container--btn {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: .5rem;
	border-radius: 2rem;
	width: 2.8rem;
	position: relative;
	transition: all 1s ease;
}

.header__container--button {
	border: 0px solid var(--primary-color);
	box-shadow: 0 3px 3px 0 rgb(0 0 0 / 15%), 0 3px 1px -2px rgb(35 35 35 / 20%), 0 1px 5px 0 rgb(0 0 0 / 15%);
	/* background-image: linear-gradient(195deg, #42424ab3, #191919); */
	background-color: #000;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 1rem;
	border-radius: 0.8rem;
	/* width: 15rem; */
	position: relative;
	transition: all 1s ease;
	color: var(--pure-white);
	display: flex;
	align-items: center;
	justify-content: space-around;
}


.header__container--button .header__container--icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
}


.header__container--btn p {
	display: flex;
	align-items: center;
}

.header__container--icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--dark-grey);
}

.header__container--btn span {
	font-weight: 600;
	color: var(--dark-grey);
	position: absolute;
	top: 0.4rem;
	left: 2.5rem;
	transition: opacity 1000ms;
	opacity: 0;
	white-space: nowrap;
	font-size: 0;
	width: 0;
	margin: 0;
}

.header__container--btn:hover {
	transition: all 400ms cubic-bezier(.62, .1, .5, 1);
	border-radius: 1rem;
	width: 13.5rem;
}

.header__container--btn:hover span {
	font-size: 1.2rem;
	opacity: 1;
	width: auto;
	transition: opacity 700ms, width 1ms linear 270ms, font-size 1ms linear 270ms;
}

.header__image {
	height: 4rem;
}

.main_header_heading {
	color: var(--primary-color);
	text-transform: capitalize;
	font-size: 2rem;
	font-weight: 600;
}

.dropdown_container {
	position: relative;
	width: -webkit-max-content;
	width: max-content;
	z-index: 101;
}

.dropdown_container .dropdown {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: flex-end;
	grid-gap: 1rem;
	gap: 1rem;
}

.dropdown_container .dropdown .role_title {
	font-size: 1.8rem;
	margin: 0 1rem;
	color: var(--secondary);
	text-transform: capitalize;
}

.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #000000;
}

.list_container {
	background-color: #e7e7ea;
	color: #000;
	margin-top: 12rem;
	border-radius: 0.5rem;
	padding: 0rem;
	position: absolute;
	box-shadow: var(--box-shadow-input);
	width: 20rem;
	right: 0;
	max-height: 15rem;
	overflow-y: auto;
	margin-right: -1rem;
}

.dropdown_item {
	cursor: pointer;
	margin: .5rem 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	transition: all .5s ease;
}

.selected_dropdown_item {
	cursor: pointer;
	margin: .5rem 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	transition: all .5s ease;
	background-color: var(--primary-color);
	color: #fff;
	font-size: 1.5rem;
	text-transform: capitalize;
}

.dropdown_item:hover {
	background-color: var(--dark-white);
	color: var(--secondary);
}

.dropdown_item .dropdown_item_userName {
	font-size: 1.2rem;
	text-transform: capitalize;
}

.arrow_up_down {
	height: 1.8rem;
	width: 1.8rem;
}

/* 769 */
@media (max-width: 860px) {
	.main_header_heading {
		font-size: 1.5rem;
	}

	.dropdown_container .dropdown .role_title {
		font-size: 1.3rem;
		margin: 0 0.5rem;
	}

	.dropdown_item .dropdown_item_userName {
		font-size: 1.3rem;
	}

	.selected_dropdown_item {
		font-size: 1.3rem;
	}
}

@media (max-width: 500px) {
	.main_header_heading {
		font-size: 1.3rem;
	}

	.main_header_menu_icon {
		height: 2rem;
		width: 2rem;
	}

	.role_image {
		height: 1.3rem;
		width: 1.3rem;
	}

	.arrow_up_down {
		height: 1.3rem;
		width: 1.3rem;
	}

	.dropdown_container .dropdown .role_title {
		font-size: 1.1rem;
	}

	.dropdown_item .dropdown_item_userName {
		font-size: 1.1rem;
	}

	.selected_dropdown_item {
		font-size: 1.1rem;
	}
}

.role_image {
	height: 2rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.typeSelectBox {
    background-color: #fff;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    padding: 1rem 1rem;
    font-family: inherit;
    font-size: 1.5rem;
    width: 15rem;
}
/* Modal */
.modalContainer {
    display: block;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.61) !important;
    transition: all 0.5s ease-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99999;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #121212;
    border-radius: 2rem;
    overflow: hidden;
    transition: 1.1s ease-out;
    box-shadow: var(--box-shadow1);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
    visibility: visible;
    justify-content: center;
    min-width: 25%;
    padding-bottom: 2rem;
}

.modal__heading {
    border-bottom: 1px solid var(--bg-primary);
    padding: 0.5rem 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.modal__heading--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.modal__heading2 {
    font-weight: 600;
    color: var(--pure-white);
}

.modal__heading--btn {
    font-family: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.4s;
}

.modal__heading--icon {
    height: 2rem;
    width: 2rem;
    fill: #FF9000;
}

.modal__heading--btn:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.modal__text {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.modal__text2 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: var(--red);
}

.modal__data {
    padding-bottom: 2rem;
    margin: 1rem 2rem;
    border-bottom: 1px solid var(--table-heading);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal__data--completed {
    background-color: var(--primary-color);
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    color: #e7e7ea;
    font-size: 1.2rem;
}

.modal__data--completedIcon {
    height: 2rem;
    width: 2rem;
    fill: #e7e7ea;
}

.modal__data--timedate {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1.5rem;
    color: var(--light-dark);
    font-size: 1.2rem;
}

.modal__data--timedateIcon {
    height: 2rem;
    width: 2rem;
    fill: var(--light-dark);
}

.modal__data--content {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    align-items: flex-start;
    color: var(--primary-color);
    font-size: 1.1rem;
}

.modal__data--contentIcon {
    height: 2rem;
    width: 2rem;
    fill: var(--primary-color);
}

.modal__data--user {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 2rem;
    margin-bottom: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--light-dark);
}

.modalContainer__form {
    margin: 1rem 0rem;
}

.modal__actions {
    padding: 1rem;
    padding-left: 2rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.modal__button {
    font-family: inherit;
    border-radius: 1.5rem;
    padding: 1rem 3rem;
    box-shadow: var(--box-shadow-input);
    font-size: 1.3rem;
    line-height: 1;
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
}

.modal__button--delete {
    border: 1px solid #ff3366;
    background: #ff3366;
    color: #e7e7ea;
}

.modal__button--delete:hover {
    background: transparent;
    border: 1px solid #ff3366;
    color: #ff3366;
}

.modal__button--update {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: #e7e7ea;
}

.modal__button--update:hover {
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.modal__button--cancel {
    font-family: inherit;
    border-radius: 1.5rem;
    padding: 1rem 3rem;
    box-shadow: var(--box-shadow-input);
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    font-size: 1.3rem;
    line-height: 1;
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
}

.modal__button--cancel:hover {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #e7e7ea;
}

.modal__text {
    /* color: var(--light-dark); */
    font-size: 1.2rem;
    color: #ffffff;
}

.modal__text span {
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--primary-color);
}

.modalContainer__form--section {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    background-color: #353434b5;
    padding: 1.5rem 2rem;
    padding-bottom: 2.5rem;
    border-radius: 2rem;
    margin-bottom: 2rem;
}

.modalContainer__form--section1 {
    /* display: flex; */
    grid-gap: 2rem;
    gap: 2rem;
    padding: 0rem 2rem 1.5rem;
    border-radius: 2rem;
}

.modalContainer__form--section-column {
    /* display: flex; */
    /* flex-direction: row; */
    align-items: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
    /* background-color: #353434b5; */
    padding: 1.5rem 2rem;
    padding-bottom: 2.5rem;
    border-radius: 2rem;
    margin-bottom: 2rem;
}

.modalContainer__form--sections {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

.modal__radioButtons,
.modal__radioButton {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.modal__radioButtons {
    grid-gap: 1rem;
    gap: 1rem;
    grid-gap: 4rem;
    gap: 4rem;
    display: flex;
    padding-left: 2rem;
}

.modal__radioButton {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    position: relative;
}

.modal__radioButton--label {
    font-size: 1.2rem;
    font-weight: 600;
    /* padding-left: 35px; */
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
}

.modal__radioButton--input {
    position: absolute;
    opacity: 1;
    z-index: 100;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}

.modal__radioButton--radio {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: transparent;
    border: 3px solid #ffffff;
}

.modal__radioButton--radio:after {
    content: "";
    position: absolute;
    display: none;
}

.modal__radioButton--input:checked~.modal__radioButton--radio {
    border: 3px solid var(--dark-grey);
}

.modal__radioButton--input:checked~.modal__radioButton--radio:after {
    display: block;
}

.modal__radioButton--radio:after {
    top: 0.5rem;
    left: 0.5rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: var(--dark-grey);
}

@media (max-width: 767px) {
    .modalContainer {
        overflow: scroll;
    }

    .modal {
        width: 90%;
        height: auto;
        overflow: auto;
    }

    .modalContainer__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .modalContainer__form--section {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        grid-gap: 1rem;
        gap: 1rem; 
        margin-bottom: 1rem;
    }

    .input__uploadContainer {
        display: flex;
        flex-direction: column;
    }

    .modal_input {
        align-items: center;
    }
}

.fileUpload {
    margin-top: 1rem;
    background-color: var(--pure-white);
    padding: 2rem 3rem;
    border-radius: 2rem;
    width: 23rem;
    height: 16rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.fileUpload__label {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
}

.fileUpload__label--icon {
    height: 5rem;
    width: 5rem;
    fill: var(--dark-grey);
}

.fileUpload__label--image {
    height: 12rem;
    border-radius: 2rem;
}
.CheckinUserScreen_contentSection__1jhg_ {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.CheckinUserScreen_logo__ScqL3 {
	height: 8rem;
}

.RadioButtons_form_input__33m54 {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
	width: 25rem;
}

.RadioButtons_form_input__label__euO8p {
	color: var(--light-grey);
	font-size: 1.2rem;

}

.RadioButtons_form_input__error__JbaLD {
	font-size: 1.2rem;
	color: #ff3366;
}

.RadioButtons_modal_input__G1oke{
	width: 25rem;
}

.RadioButtons_form_input__container__2V11I {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--bg-primary);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;

}

.RadioButtons_form_input__icon__3qrKm {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}

.RadioButtons_form_input__box__ft8bn {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}

.RadioButtons_form_input__box__ft8bn::-webkit-input-placeholder {
	color: var(--light-grey);
}

.RadioButtons_form_input__error__JbaLD {
	font-size: 1.2rem;
	color: #ff3366;
}
.textBox {
	position: relative;
}

.form_input__error {
	font-size: 1.2rem;
	color: var(--red);
}

.css-166bipr-Input::after {
	visibility: visible !important;
	color: #fff !important;
}

.textBox__labels {
	position: absolute;
	/* top: -0.5rem; */
	left: 0;
	z-index: 10;
	display: flex;
	/* padding-top: 3rem; */
	padding-left: 1.3rem;
}

.textBox__label--icon {
	height: 2.0rem;
	width: 2.0rem;
	fill:  var(--light-grey);
	margin-top: 10px;
}

.textBox__input {
	/* background-color: #333; */
	/* border-radius: 0.5rem; */
	/* overflow: hidden; */
	width: 25rem;
	display: flex;
	/* padding-right: 1rem; */
	border-bottom: 1px solid var(--light-grey);
	/* margin-top: 1rem; */
}
.textBox__input1 {
	/* background-color: #333; */
	/* border-radius: 0.5rem; */
	overflow: hidden;
	width: 25rem;
	display: flex;
	padding-right: 1rem;
	border-bottom: 1px solid var(--light-grey);
	margin-top: 2.4rem;
}
.textBox__inputs {
	/* background-color: #333; */
	/* border-radius: 0.5rem; */
	overflow: hidden;
	width: 25rem;
	display: flex;
	padding-right: 1rem;
	/* border-bottom: 1px solid var(--light-grey); */
	margin-top: 1rem;
}
.textBox__input--box {
	width: 100%;
	margin-left: 5rem;
	background-color: transparent;
	font-family: inherit;
	border: none;
	outline: none;
	padding: 0.6rem;
	color: #fff;
}
.textBox__input--box option {
	background-color: #333;
	border: none;
	border-color: var(--dark-grey);
}

.textBox__input--show {
	margin-right: 1rem;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.textBox__input--show:hover .textBox__input--showIcon {
	fill: #ff3366;
}

.textBox__input--showIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	transition: all 0.4s;
}

.form_input {
	display: flex;
	flex-direction: column;
	grid-gap: 1.5rem;
	gap: 1.5rem;
	align-items: flex-start;
}

.form_input__label {
	color: var(--light-grey);
	font-size: 1.2rem;
	margin:0.5rem,;
	color: #fff
	/* padding-top: 0.3rem; */
	/* padding-left: 1rem; */
}

.css-1pndypt-Input input {
	opacity: 1 !important;
}
.css-vwja0k input {
	opacity: 1 !important;
}

@media only screen and (max-width: 767px) {
  .form_input {
    min-width: 9rem;
  }
}

.TextBox_form_input__NJV-D {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
}
.TextBox_form_input__label__3wdr- {
	color: var(--lightest-white);
	margin-top: 1rem;
	font-size: 1.7rem;
	margin-left: 2rem;
}
.TextBox_form_input__container__3WsXy {
	display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  /* background-color: var(--white); */
  /* border-radius: 1rem; */
  border-bottom: 1px solid var(--light-grey);
  padding: 1rem 2rem;
  padding-left: 2rem;
  padding-left: 1rem;
	margin: 1.5rem;
	width: 500px;
}
.TextBox_form_input__icon__3kmJo {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.TextBox_form_input__box__2VNVT {
	font-family: inherit;
	background-color: transparent;
	color: #ffffff;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
	line-height: 1.2rem;
	font-size: 1.4rem;
}
.TextBox_form_input__box__2VNVT::-webkit-input-placeholder {
	color: var(--light-grey);
}
.TextBox_form_input__error__2tN2v {
	font-size: 1.2rem;
	color: #ff3366;
}

.NoData_noDataContainer__1iWFe {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #8b8b8b;
}
.NoData_noDataContainerIcon__Y2A-2 {
	height: 7rem;
	width: 7rem;
	fill: #8b8b8b;
}
.NoData_noDataContainerText__1BFGQ {
	font-size: 3rem;
	font-weight: 600;
}
.NoData_noDataContainerSubText__VM5LZ {
	font-size: 1.3rem;
	font-weight: 500;
}

.FileUpload_form_input__IWhjy {
	display: flex;
	grid-gap: 2rem;
	/* gap: 2rem; */
	/* background-color: var(--light-grey);
	/* background-color: #121212; */
	/* padding: 1.5rem 2rem; */
	/* padding-bottom: 2.5rem; */
	/* padding-bottom: 10rem; */
	/* border-radius: 15rem; */
	/* margin-bottom: 2rem; */
	/* object-fit: cover;  */
	/* position: relative; */
	width: 300px; /* Fixed width of the container */
	height: 200px; /* Fixed height of the container */
	overflow: hidden; /* Ensure content doesn't overflow the container */
	border-radius: 20%; /* Makes the container rounded */
	border: 2px solid #ccc; /* Example border style */
}
.FileUpload_form_input__label__3qBal {
	color: var(--light-grey);
	font-size: 1.2rem;
}
.FileUpload_form_input__container__1hpMd {
	display: flex;
	align-items: center;
	/* gap: 1rem; */
	background-color: var(--pure-white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
	object-fit: cover;
}
.FileUpload_form_input__icon__2CKE8 {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.FileUpload_form_input__box__3RT9O {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.FileUpload_form_input__box__3RT9O::-webkit-input-placeholder {
	color: var(--light-grey);
}
.FileUpload_form_input__error__3GDBA {
	font-size: 1.2rem;
	color: #ff3366;
}

.FileUpload_modalContainer__form--sections__16Btz {
	display: flex;
	/* gap: 2rem; */
	align-items: center;
}

.FileUpload_fileUploadText__2XnHL{
	/* padding-right: 2rem; */
	color: var(--pure-white);
	text-align: center;
}

.FileUpload_fileUpload__label--images__V7v3I{   
	width: 50%; 
    height: 50%;        
    object-fit: fill;
}
.Salon_form_input__label__1_oaw {
	color: var(--light-grey);
	font-size: 1.2rem;
}
.Videos_videoCards__2tzxk {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
	padding: 1rem 0;
}
.Videos_videoCard__1e-vJ {
	text-decoration: none;
	color: currentColor;
	background-color: var(--pure-white);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
}
.Videos_videoCard__1e-vJ:hover {
	-webkit-transform: translateY(-.2rem);
	        transform: translateY(-.2rem);
}
.Videos_videoCard__iImageIcon__3Exsx {
	position: relative;
}
.Videos_videoCard__iImageIcon_image__2vSZm {
	height: 10rem;
	border-radius: 2rem;
}
.Videos_videoCard__iImageIcon_icon_container__21itN {
	background-color: var(--primary-color);
	display: flex;
	padding: .5rem;
	border-radius: 50%;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-40%, -40%);
	        transform: translate(-40%, -40%);
}
.Videos_videoCard__iImageIcon_icon__2VHLw {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
	transition: all .4s;
}
.Videos_videoCard__iImageIcon_icon__2VHLw:hover {
	height: 2.5rem;
	width: 2.5rem;
}
.Videos_videoCard__title__2jrY8 {
	text-decoration: none;
	color: var(--dark-grey);
	font-size: 1.5rem;
	font-weight: 600;
	margin-top: 1rem;
}
.Videos_videoCard__subTitle__3Wcfz {
	font-size: 1.1rem;
	font-weight: 400;
	color: var(--dark-grey);
}
.Videos_videoCardCategory__3b98u {
	border: 1px solid var(--blue);
	padding: .2rem 1rem;
	border-radius: 2rem;
	color: var(--blue);
	font-size: 1.2rem;
}
.Videos_videoCardStats__3THtM {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 1rem;
	gap: 1rem;
}
.Videos_videoCardStatsCount__1vc8H {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.Videos_videoCardStat__3eeo8 {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	color: var(--blue);
}
.Videos_videoCardStatIcon__8GFDk {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--blue);
}
.Videos_videoCardActions__1KHaC {
	border-top: 1px solid var(--white);
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;

	display: flex;
	align-items: center;
	justify-content: space-between;
}
.Videos_videoCardActionBtn__1--HO {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
}
.Videos_videoCardAction__1JDWu {
	background-color: var(--dark-grey);
	border: 1px solid var(--dark-grey);
	padding: .5rem .7rem;
	padding-bottom: .8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}
.Videos_videoCardActionIcon__3GNGK {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
	transition: all .4s;
}
.Videos_videoCardAction__1JDWu:hover {
	background-color: transparent;
	border: 1px solid var(--dark-grey);
}
.Videos_videoCardAction__1JDWu:hover .Videos_videoCardActionIcon__3GNGK {
	fill: var(--dark-grey);
}


.VideoDetails_videoDetails__12nuW {
	margin-top: 1rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	justify-content: space-between;
}
.VideoDetails_videoDetails__subTitle__1fPqf {
	margin: 1rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--light-grey);
}
.VideoDetails_videoDetailsSection__bSEpN video {
	/* height: 55vh; */
	width: 52vw;
	border-radius: 2rem;
	box-shadow: var(--box-shadow1);
}

.Products_productCards__RwY1- {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}

.Products_productCard__3K9Ew {
	text-decoration: none;
	color: currentColor;
	background-color: var(--pure-white);
	box-shadow: var(--box-shadow1);
	border-radius: 2rem;
	overflow: hidden;
	padding: 2rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: all .4s;
	width: 25rem;
}

.Products_productCard__3K9Ew:hover {
	-webkit-transform: translateY(-.2rem);
	        transform: translateY(-.2rem);
}

.Products_productCard__iImageIcon__2Nx4h {
	position: relative;
}

.Products_productCard__iImageIcon_image__2m4rW {
	width: 20rem;
	height: 20rem;
	border-radius: 2rem;
	object-fit: cover;
	border-radius: 2rem;
}

.Products_productCard__iImageIcon_icon_container__2bPfe {
	background-color: var(--primary-color);
	display: flex;
	padding: .5rem;
	border-radius: 50%;

	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-40%, -40%);
	        transform: translate(-40%, -40%);
}

.Products_productCard__iImageIcon_icon__2m4AD {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
	transition: all .4s;
}

.Products_productCard__iImageIcon_icon__2m4AD:hover {
	height: 2.5rem;
	width: 2.5rem;
}

.Products_productCard__title__3XxpL {
	text-decoration: none;
	color: var(--dark-grey);
	font-size: 1.5rem;
	font-weight: 600;
	margin-top: 1rem;
}

.Products_productCard__subTitle__1hel9 {
	font-size: 1.1rem;
	font-weight: 400;
	color: var(--dark-grey);
}

.Products_productCardCategory__2PJJm {
	border: 1px solid var(--blue);
	padding: .2rem 1rem;
	border-radius: 2rem;
	color: var(--blue);
	font-size: 1.2rem;
}

.Products_productCardStats__3-yGx {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Products_productCardStatsCount__hWhWL {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.Products_productCardStat__2kLzj {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	color: var(--blue);
	font-size: 1.5rem;
}

.Products_productCardStatIcon__26GYe {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--blue);
}

.Products_productCardActions__1DGAo {
	border-top: 1px solid var(--white);
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Products_productCardActionBtn__RBsIr {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}

.Products_productCardAction__1Ji_h {
	background-color: transparent;
	border: 1px solid var(--dark-grey);
	padding: .6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}

.Products_productCardActionIcon__1HrDX {
	height: 1.2rem;
	width: 1.2rem;
	fill: var(--dark-grey);
	transition: all .4s;
}

.Products_productCardAction__1Ji_h:hover {
	background-color: var(--dark-grey);
	border: 1px solid var(--dark-grey);
}

.Products_productCardAction__1Ji_h:hover .Products_productCardActionIcon__1HrDX {
	fill: var(--pure-white);
}
    .readmore_text__2-cQe {
        display: inline;
        width: 100%;
    }

    .readmore_readorhide__21Kc1 {
        color: var(--primary-color);
        cursor: pointer; 
    }
.ProductDetails_productDetails__2FO0X {
	margin-bottom: 1rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
}
.ProductDetails_productTitle__RX30I {
	font-size: 2rem;
	font-weight: 600;
}
.ProductDetails_productDots__2I34b {
	font-size: 1.3rem;
	color: var(--primary-color);
}
.ProductDetails_productPriceQuantity__3GmqJ {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}
.ProductDetails_productPrice__1TL4R {
	font-size: 3rem;
}
.ProductDetails_productQuantity__2Eojr {
	font-size: 1.3rem;
}
.ProductDetails_productDesc__3o8p5 {
	font-size: 1.3rem;
}
.ProductDetails_productCategory__18uLn {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	color: var(--primary-color);
	font-size: 1.3rem;
}
.ProductDetails_productCategory__18uLn span {
	color: var(--dark-grey);
}
.ProductDetails_productCategoryIcon__2qxPT {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--primary-color);
}

.ProductDetails_productDetails__subTitle__tGcPh {
	margin: 1rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--light-grey);
}
.ProductDetails_productDetailsSection__BYmaj product {
	/* height: 55vh; */
	width: 52vw;
	border-radius: 2rem;
	box-shadow: var(--box-shadow1);
}

.ProductDetails_product_detail_screen_main--content__3uigK {
	display: flex;
}

.ProductDetails_product_detail_screen_main-left__2v7WX {
	border-radius: 25px;
	transition: 0.3s;
	padding: 3rem 4rem;
	width: 60%;
}

.ProductDetails_product_detail_screen_main_right__2BtZX {
	border-radius: 5rem;
	transition: 0.3s;
	padding-top: 2rem;
	display: flex;
}

.ProductDetails_product_detail_screen_main_image__16qpf {
	border-radius: 2rem;
	height: 25rem;
	max-width: 30rem;
}


.ProductDetails_productDetails__4r5eN {
	margin-top: 1rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	justify-content:space-around;
}
.ProductDetails_productDetails__subTitle__3QRM5 {
	margin: 1rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--light-grey);
}
.ProductDetails_productDetailsSection__1P7Fi product {
	/* height: 55vh; */
	width: 52vw;
	border-radius: 2rem;
	box-shadow: var(--box-shadow1);
}


.ProductDetails_product_detail_screen_main--content__3u7VE {
    display: flex; 
}

.ProductDetails_product_detail_screen_main-left__2IJSX {
    border-radius: 25px;
    transition: 0.3s;
    padding: 3rem 4rem;
    width: 60%;
}

.ProductDetails_product_detail_screen_main_right__3to1z { 
    border-radius: 5rem;
    transition: 0.3s;
    padding-top: 2rem;
    display: flex;
}

.ProductDetails_product_detail_screen_main_image__ZpYPQ{
	width: 100%;
    max-width: 30rem;
    border-radius: 2rem;
}
.Content_appointmentBox__3huap{
	border-radius: 1rem;
	background-color: #121212;
	padding: 12px;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	/* height: 80vh; */
	overflow-y: auto;
	transition: all 0.5s linear;
	
}

.Content_container__3lF2C{
    display: grid;
  grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
  background-color: #000000;
}


.Content_content__3FZEj{
background-color: #000000;
  margin-top: 3.5rem;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-left: 4rem; */
  padding-bottom: 3rem;
  padding-top: 1rem;
  display: flex;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.Content_content_dashboard__3jVW3{
	background-color: #000000;
	  margin-top: 3.5rem;
	  height: 85vh;
	  overflow-y: auto;
	  overflow-x: hidden;
	  /* padding-left: 4rem; */
	  padding-bottom: 3rem;
	  padding-top: 1rem;
	  display: grid;
	  grid-gap: 2rem;
	  gap: 2rem;
	  grid-template-columns: repeat(2, 2fr);
	  /* grid-template-rows: 18rem minmax(max-content, 59vh); */
	}

	.Content_content_box_dashboard__3pshn {
		width: 100%;
	  grid-column: 1 / 3;
	  grid-row: 1 / -1;
	  display: flex;
	  grid-gap: 2rem;
	  gap: 2rem;
	  flex-direction: column;
	}

.Content_mainContent__3_NEM {
	grid-column: 2 / -1;
	overflow: hidden;
	transition: all 0.5s ease;
	margin: 1.5rem 0;
	margin-right: 1.5rem;
	background-color:#000000;
	/* box-shadow: var(--box-shadow1); */
	border-radius: 2rem;
	position: absolute;
	left: 22rem;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;
	/* display: flex; */
}

.Content_mainContent__3_NEM.Content_mainContent__active__3sgyo{
	fill:0;
	background-color: #000000;
}
.Content_navigation__logos__6ttKR{
	display: block;
}
.Content_active_logos__1Cief{
	display: contents;
}

@media (max-width: 809px) {
	.Content_mainContent__3_NEM{
		left: 18rem;
	}

	.Content_mainContent__3_NEM.Content_mainContent__active__3sgyo{
		fill:0;
		background-color: #000000;
	}
	.Content_navigation__logos__6ttKR{
		display: block;
	}
	.Content_active_logos__1Cief{
		display: contents;
	}

}

/* Dashboard screen responsive - Aniket */
@media (max-width: 1280px) {
	.Content_appointmentBox__3huap{
display: none;
}
}
.SideBarNew_navigation__2GmoZ {
	grid-column: 1 / 2;
	padding-top: 2rem;
	position: relative;
	width: 20rem;
	height: 96vh;
	z-index: 500;
	transition: all 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: all .4s;
	/* border-radius: 12px; */
	margin-left: 1rem;
	/* padding: 1rem; */
	top: 0rem;
	overflow: hidden;
	/* background-image: linear-gradient(195deg, #42424a, #191919); */
	background-color: #121212;
	background-size: 150% 150%;
	-webkit-backdrop-filter: saturate(200%) blur(30px);
	        backdrop-filter: saturate(200%) blur(30px);
	/* background-color: hsl(0deg 0% 0% / 100%) !important; */
	margin-top: 1rem;
  border-radius: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: rgba(255, 255, 255, 0.362) rgba(255, 255, 255, 0.362);
}

/* .navigation:hover {
	overflow: auto;
} */

.SideBarNew_navigation__2GmoZ::-webkit-scrollbar {
	width: 0.1rem;
	height: 1.5rem;
}

.SideBarNew_navigation__2GmoZ::-webkit-scrollbar-track-piece {
	background-color: #ffffff36!important;
}

.SideBarNew_navigation__2GmoZ::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.362)!important;
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF {
	width: 8rem;
	transition: all 0.5s ease;
}

.SideBarNew_navigation__logo__TXhvt {
	height: 10rem;
	padding-left: 6rem;
	padding-top: 1rem;
}
.SideBarNew_navigation__logo__TXhvt.SideBarNew_active__2X_LF {
	height: 5rem;
	/* padding-left: 6rem; */
	/* padding-top: 1rem; */
}

.SideBarNew_navigation__logo2__1IKQd {
	margin-top: 1rem;
	height: 4.5rem;
	padding-left: 1rem;
	display: none;
}

.SideBarNew_sidebarnav__button_active__3giUf {
	left: 1rem !important;
}

.SideBarNew_sidebarnav__button__3Ur3n {
	position: absolute;
	top: 10%;
	left: 0.4rem;
	-webkit-transform: translateY(-40%);
	        transform: translateY(-40%);
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	background: rgba(255, 255, 255, 0.829);
	box-shadow: var(--box-shadow-input);
	z-index: 999;
	height: 3rem;
	width: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 26px;
}

.SideBarNew_appointment__button__3rQ2I {
	position: absolute;
	top: 7%;
	right: 0.4rem;
	-webkit-transform: translateY(-40%);
	        transform: translateY(-40%);
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	background: rgba(255, 255, 255, 0.829);
	box-shadow: var(--box-shadow-input);
	z-index: 999;
	height: 3rem;
	width: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 26px;
}

.SideBarNew_appointment_main__gqWC_{
	position: relative;
}

.SideBarNew_sidebarnav__icon__NqPxM {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	left: 18%;
	z-index: 1000;
	transition: all .4s;
	/* height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(10%, 150%);
	z-index: 1000;
	transition: all .4s; */
}

.SideBarNew_sidebarnav__icon--box__Y9NcD {
	height: 8rem;
	width: 3.07rem;
	fill: #fff;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.SideBarNew_sidebarnav__button__3Ur3n:hover .SideBarNew_sidebarnav__icon__NqPxM {
	fill: var(--primary-color);
}

.SideBarNew_navigation__list__2-pdH {
	margin-top: 3rem;
	width: 100%;
	padding-left: 0px;
	list-style: none;
}

.SideBarNew_currentNav__1ix9e {
	/* display: flex;
	align-items: center; */
	position: relative;
}

.SideBarNew_activeNav__2sEYx {
	position: absolute;
	top: 1rem;
	right: 0;
	height: 2.5rem;
	width: 0.5rem;
	background-image: linear-gradient(195deg, #c66f00, #ff9510);
	border-radius: 1rem;
}

.SideBarNew_currentNav__1ix9e .SideBarNew_navigation__list--itemHover__3zCM6 {
	font-weight: 400;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
	background-color: hsla(0, 0%, 78%, .2);
	/* box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%); */
	transition: background .3s, width .3s cubic-bezier(.2, 0, 0, 1) 0s;
}

.SideBarNew_currentNav__1ix9e .SideBarNew_navigation__list--itemHover__3zCM6 .SideBarNew_navigation__list--link__OifWI {
	color: var(--white);
	font-weight: 500;
}


.SideBarNew_currentNav__1ix9e .SideBarNew_navigation__list--itemHover__3zCM6 .SideBarNew_navigation__list--link2__3LG25 {
	color: var(--white);
	font-weight: 500;
}

.SideBarNew_currentNav__1ix9e .SideBarNew_navigation__list--itemHover__3zCM6 .SideBarNew_navigation__list--link__OifWI .SideBarNew_navigation__icon__2px8Z {
	fill: var(--white);
}

.SideBarNew_currentNav__1ix9e .SideBarNew_navigation__list--itemHover__3zCM6 .SideBarNew_navigation__list--link2__3LG25 .SideBarNew_navigation__icon2__fUvnw {
	fill: var(--white);
}

.SideBarNew_navigation__list--item__1A3A- {
	border-radius: 1rem;
	padding: 1rem 0;
	padding-left: 2rem;
	display: none;
	transition: all 0.4s;
}

.SideBarNew_navigation__list--copyright__3jRJj {
	padding: 1rem 0;
	padding-left: 2rem;
	font-size: 1.2rem;
	font-weight: 500;
	width: 22rem;
	color: #fff;
}

.SideBarNew_navigation__list--copyright__3jRJj span {
	font-size: 1rem;
	font-weight: 600;
	color: var(--white);
}

.SideBarNew_navigation__list--copyright__3jRJj span a {
	font-size: inherit;
	font-weight: inherit;
	color: currentColor;
	text-decoration: none;
	transition: all .4s;
}

.SideBarNew_navigation__list--copyright__3jRJj span a:hover {
	color: var(--primary-color);
}

.SideBarNew_navigation__list--copyrightHidden__3uy8- {
	padding: 1rem 0;
	padding-left: 2rem;
	font-size: 1.2rem;
	font-weight: 500;
	display: none;
}

.SideBarNew_navigation__list--itemHover__3zCM6 {
	border-radius: 1rem;
	width: 20rem;
	padding: 1rem 0;
	padding-left: 2rem;
	transition: all 0.4s;
	display: block;
	cursor: pointer;
}

.SideBarNew_navigation__list--itemHover__3zCM6:hover,
.SideBarNew_navigation__list--item__1A3A-:hover {
	background-image: linear-gradient(195deg, #c66f00, #ff9510);
	-webkit-transform: translateX(0.2rem);
	        transform: translateX(0.2rem);
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF .SideBarNew_navigation__list--itemHover__3zCM6 {
	display: none;
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF .SideBarNew_navigation__list--item__1A3A- {
	display: block;
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF .SideBarNew_navigation__list--copyright__3jRJj {
	display: none;
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF .SideBarNew_navigation__list--copyrightHidden__3uy8- {
	display: block;
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF .SideBarNew_navigation__logo__TXhvt {
	display: none;
}

.SideBarNew_navigation__2GmoZ.SideBarNew_active__2X_LF .SideBarNew_navigation__logo2__1IKQd {
	display: block;
}

.SideBarNew_navigation__list--link__OifWI {
	text-decoration: none;
	color: var(--white);
	font-size: 1.4rem;
	font-weight: 400;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
}

.SideBarNew_navigation__list--link--span__2l38H {
	display: flex;
    align-items: center;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.SideBarNew_navigation__list--link2__3LG25 {
	text-decoration: none;
	color: var(--white);
	font-size: 1.4rem;
	font-weight: 400;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
}

.SideBarNew_navigation__icon__2px8Z {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}

.SideBarNew_navigation__icon2__fUvnw {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}

.SideBarNew_navigation__icon1__2lgVs{
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
}

.SideBarNew_hasChildren__3sRXF {
	position: relative;
}

.SideBarNew_hasChildren__3sRXF::after {
	cursor: pointer;
	padding-right: 1rem;
	content: ' \002B';
	color: var(--white);
	font-size: 1.7rem;
	position: absolute;
	right: 0rem;
	top: .7rem;
}

.SideBarNew_subMenuActive__1TTXA.SideBarNew_hasChildren__3sRXF::after {
	content: ' \002D';
}

.SideBarNew_subMenuActive__1TTXA.SideBarNew_hasChildren__3sRXF ul {
	display: block;
}

.SideBarNew_navigation__sub__active__1GITV {
	background-image: linear-gradient(195deg, #c66f00, #ff9510);
	background-color: hsla(0, 0%, 78%, .2);
	color: var(--white) !important;
	font-weight: 600 !important;
	padding: 1rem;
	border-radius: 0.5rem;
}

.SideBarNew_navigation__sub__active__1GITV svg {
	fill: var(--white) !important;
}

.SideBarNew_hasChildren__3sRXF ul {
	margin-top: 1rem;
	display: none;
	list-style: none;
}

.SideBarNew_hasChildren__3sRXF ul li {
	padding: .5rem 1rem;
	transition: all .4s;
}

.SideBarNew_hasChildren__3sRXF ul li:hover {
	/* background-image:linear-gradient(195deg, #c66f00, #ff9510); */
}

.SideBarNew_hasChildren__3sRXF ul li a {
	text-decoration: none;
	color: var(--white);
	font-size: 1.1rem;
	font-weight: 500;
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
}

.SideBarNew_hasChildren__3sRXF ul li a svg {
	height: 1.4rem;
	width: 1.4rem;
	fill: var(--white);
}

.SideBarNew_navigation__list--link2--logout__2FaZy {
	background: transparent;
	box-shadow: none;
	border: 0;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
}

@media (max-width: 809px) {
	.SideBarNew_navigation__icon__2px8Z {
		height: 1.5rem;
		width: 1.5rem;
	  }
	  .SideBarNew_navigation__2GmoZ {
        width: 15rem;
	  }
	  
}
.TextBox_form_input__31Msv {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
}

.TextBox_form_input__label__IKasP {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.TextBox_form_input__container__IqtZO {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}

.TextBox_form_input__icon__1JQxq {
	height: 2rem;
	width: 2rem;
	fill: #ffffff !important;
}

.TextBox_form_input__box__1it9R {
	font-family: inherit;
  background-color: transparent;
  color: #ffffff;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.TextBox_form_input__box__1it9R::-webkit-input-placeholder {
	color: var(--light-grey);
}

.TextBox_form_input__error__mZkCl {
	font-size: 1.2rem;
	color: #ff3366;
	width: 25rem;
}


.TextBox_form_input_coupon_box__MlsW-::hover {
	 border:none !important;
}

.TextBox_form_input_coupon_box__MlsW- {
	border:none !important;
}


@media (max-width: 809px) {
	.TextBox_form_input__container__IqtZO {
		width: 100%;
		margin-top:1rem;
	} 
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	.TextBox_form_input__container__IqtZO {
		margin-right: 1rem;
	}
}
.HeaderNew_header__2TzJz {
	/* / padding-top: 2rem; / */
	display: flex;
	/* / justify-content: space-between; / */
	justify-content: space-between;
	align-items: center;
}

.HeaderNew_header__left__M6ttj {
	display: flex;
	grid-gap: 6rem;
	gap: 6rem;
	align-items: center;
	position: relative;
}

.HeaderNew_header__button__2WW8v {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.HeaderNew_header__icon__3Fs_1 {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--primary-color);
}

.HeaderNew_header__container__3JAev {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.HeaderNew_header__container--backBtn__3V_F1 {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.HeaderNew_header__container--backBtnIcon__GOL8f {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
}

.HeaderNew_header__heading__19WLS {
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
	background-color: #121212;
    padding: 1rem 2rem;
    border-radius: 3rem;
}
.HeaderNew_header__headings__1Y2BV {
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
	background-color: #121212;
    padding: 1rem 2rem;
    border-radius: 3rem;
}

.HeaderNew_header__heading_Right__2Dap1{
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
}

.HeaderNew_switch_text__wHumj{
	color: #ffffff!important;
}

.HeaderNew_header__container__icon__2wkPI {
	height: 1.5rem;
	margin-right: 1rem;
	width: 1.5rem;
	fill: var(--dark-grey);
}

.HeaderNew_header__container__btn__13Ug- {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: .5rem;
	border-radius: 2rem;
	width: 2.8rem;
	position: relative;
	transition: all 1s ease;
}

.HeaderNew_header_button__RtFz-{
	margin-left: 3rem;
    margin-top: 5rem;
}

.HeaderNew_header__container__button__1di4D {
	border: 0px solid var(--primary-color);
	box-shadow: 0 3px 3px 0 rgb(0 0 0 / 15%), 0 3px 1px -2px rgb(35 35 35 / 20%), 0 1px 5px 0 rgb(0 0 0 / 15%);
	/* / background-image: linear-gradient(195deg, #42424ab3, #191919); / */
	background-color: #121212;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 1rem;
	border-radius: 0.8rem;
	/* / width: 15rem; / */
	position: relative;
	transition: all 1s ease;
	color: var(--pure-white);
	display: flex;
	align-items: center;
	justify-content: space-around;
}


.HeaderNew_header__container__button__1di4D .HeaderNew_header__container__icon__2wkPI {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
}


.HeaderNew_header__container__btn__13Ug- p {
	display: flex;
	align-items: center;
}

.HeaderNew_header__container__icon__2wkPI {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--dark-grey);
}

.HeaderNew_header__container__btn__13Ug- span {
	font-weight: 600;
	color: var(--dark-grey);
	position: absolute;
	top: 0.4rem;
	left: 2.5rem;
	transition: opacity 1000ms;
	opacity: 0;
	white-space: nowrap;
	font-size: 0;
	width: 0;
	margin: 0;
}

.HeaderNew_header__container__btn__13Ug-:hover {
	transition: all 400ms cubic-bezier(.62, .1, .5, 1);
	border-radius: 1rem;
	width: 13.5rem;
}

.HeaderNew_header__container__btn__13Ug-:hover span {
	font-size: 1.2rem;
	opacity: 1;
	width: auto;
	transition: opacity 700ms, width 1ms linear 270ms, font-size 1ms linear 270ms;
}

.HeaderNew_header__image__3PwAA {
	height: 4rem;
}

.HeaderNew_main_header_heading__1qAuj {
	color: var(--primary-color);
	text-transform: capitalize;
	font-size: 2rem;
	font-weight: 600;
}

.HeaderNew_dropdown_container__1SgPf {
	position: relative;
	width: -webkit-max-content;
	width: max-content;
	z-index: 101;
	background-color: #121212;
    padding: 1rem 2rem;
    border-radius: 3rem;
}

.HeaderNew_dropdown_container__1SgPf .HeaderNew_dropdown__TLo4S {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: flex-end;
	grid-gap: 1rem;
	gap: 1rem;
}

.HeaderNew_dropdown_container__1SgPf .HeaderNew_dropdown__TLo4S .HeaderNew_role_title__1XvzY {
	font-size: 1.8rem;
	margin: 0 1rem;
	color: var(--secondary);
	text-transform: capitalize;
}

.HeaderNew_form_input__icon__1ePIf {
	height: 2rem;
	width: 2rem;
	fill: #ffffff;
}

.HeaderNew_list_container__Ksqa- {
	background-color: #e7e7ea;
	color: #000;
	margin-top: 12rem;
	border-radius: 0.5rem;
	padding: 0rem;
	position: absolute;
	box-shadow: var(--box-shadow-input);
	width: 20rem;
	right: 0;
	max-height: 15rem;
	overflow-y: auto;
	margin-right: -1rem;
}

.HeaderNew_dropdown_item__3zVSk {
	cursor: pointer;
	margin: .5rem 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	transition: all .5s ease;
}

.HeaderNew_selected_dropdown_item__2DlRf {
	cursor: pointer;
	margin: .5rem 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	transition: all .5s ease;
	background-color: var(--primary-color);
	color: #fff;
	font-size: 1.5rem;
	text-transform: capitalize;
}

.HeaderNew_dropdown_item__3zVSk:hover {
	background-color: var(--dark-white);
	color: var(--secondary);
}

.HeaderNew_dropdown_item__3zVSk .HeaderNew_dropdown_item_userName__RxZ1r {
	font-size: 1.2rem;
	text-transform: capitalize;
}

.HeaderNew_arrow_up_down__3tlHR {
	height: 1.8rem;
	width: 1.8rem;
}

.HeaderNew_typeSelectBox__8uou0 {
    background-color: #121212;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    padding: 0.6rem 1rem;
    font-family: inherit;
    font-size: 1.5rem;
    width: 15rem;
	color: #ffffff;
}

/* .coinContainer{
	padding-top:2px;
	padding-right: 4px;
}

.coinIcon {
	width: 18px;
	height: 18px;
	vertical-align: middle;
	fill: #ffd600;
	margin-right: 1px;
	padding-bottom: 3px;
}

.coinNumber{
	color: #fff;
} */

/* @media (max-width: 860px) {
	.header_heading {
		font-size: 1.5rem;
	}

	.dropdown_container .dropdown .role_title {
		font-size: 1.3rem;
		margin: 0 0.5rem;
	}

	.dropdown_item .dropdown_item_userName {
		font-size: 1.3rem;
	}

	.selected_dropdown_item {
		font-size: 1.3rem;
	}
}

@media (max-width: 500px) {
	.header_heading {
		font-size: 1.3rem;
	}

	.main_header_menu_icon {
		height: 2rem;
		width: 2rem;
	}

	.role_image {
		height: 1.3rem;
		width: 1.3rem;
	}

	.arrow_up_down {
		height: 1.3rem;
		width: 1.3rem;
	}

	.dropdown_container .dropdown .role_title {
		font-size: 1.1rem;
	}

	.dropdown_item .dropdown_item_userName {
		font-size: 1.1rem;
	}

	.selected_dropdown_item {
		font-size: 1.1rem;
	}
}

.role_image {
	height: 2rem;
	display: flex;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.typeSelectBox {
    background-color: #121212;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    padding: 1rem 1rem;
    font-family: inherit;
    font-size: 1.5rem;
    width: 15rem;
	color: #ffffff;
}

/* @media (max-width: 400px) {
	.header{
		flex-direction: column;
		grid-gap: 2rem;
	}
} */

 @media only screen and (min-width: 680px) and (max-width: 1080px) {
	.HeaderNew_header__left__M6ttj {
		flex-direction: row;
		grid-gap: 3rem;
		gap: 3rem;
	}

	.HeaderNew_header__heading__19WLS{
		display: none;
	}

	.HeaderNew_header__headings__1Y2BV {
		font-size: 1.5rem;
		font-weight: 600;
		color: #ffffff;
		background-color: #121212;
		padding: 1rem 2rem;
		border-radius: 3rem;
	}
} 

@media (max-width: 400px) {
    .HeaderNew_header__left__M6ttj {
		flex-direction: row;
		grid-gap: 3rem;
		gap: 3rem;
	}
	.HeaderNew_HeaderNew_header__heading__2ysGY{
		font-size: 10px;
	}
}
.AppointmentSectionNew_appointmentCard__2s3AK{
	background: hsl(0, 0%, 0%);
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 1.5rem;
  margin: 0rem -1.2rem;
}
.SalonStaff_tableContainer__8Rjn- {
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
	height: 35vh;
	overflow-y: auto;
	overflow-x: auto;
}


.SalonStaff_table__38Y-S {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	overflow: hidden;
    color:white;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.SalonStaff_table__38Y-S tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.SalonStaff_table__38Y-S tr {
	transition: all 0.8s;
	font-size: 1.2rem;
    background-color: #121212;
	border-bottom: 1px solid #000;
}

.SalonStaff_table__38Y-S tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}
.SalonStaff_input__29kHP{
	color: #ffffff;
}

.SalonStaff_input__29kHP div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
	margin-top: 1rem;
}

.SalonStaff_input__29kHP label{
	color: #ffffff;
}

.SalonStaff_textArea__1jtlF div{
	background-color:transparent;
	border: 1px solid #585858;
	border-radius: 1rem;
	margin-top: 1rem;
}
.SalonStaff_textArea__1jtlF label{
	color: #ffffff;
	font-size: 1.2rem;
}

.SalonStaff_selectBox__1q_n3 div{
	background-color:transparent;
	border-bottom: 1px solid #585858;
}

.SalonStaff_selectBox__1q_n3 label{
	font-size: 1.2rem;
}

.SalonStaff_selectBox__1q_n3 div label svg{
	position: absolute;
  top: 1rem;
}



@media only screen and (min-width: 900px) and (max-width: 1088px) {
	.SalonStaff_form__2N1Lw{
		height: 62vh!important;
		overflow:auto;
	}
}
/* Modal */
.modalContainer {
    display: block;
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.61) !important;
    transition: all 0.5s ease-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99999;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #121212;
    border-radius: 2rem;
    overflow: hidden;
    transition: 1.1s ease-out;
    box-shadow: var(--box-shadow1);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
    visibility: visible;
    justify-content: center;
    min-width: 25%;
    padding-bottom: 1rem;
}

.modal__heading {
    border-bottom: 1px solid var(--bg-primary);
    padding: 0.5rem 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.modal__heading--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.modal__heading2 {
    font-weight: 600;
    color: #ffffff;
}

.modal__heading--btn {
    font-family: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.4s;
}

.modal__heading--icon {
    height: 2rem;
    width: 2rem;
    fill: #FF9000;
}

.modal__heading--btn:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.modal__text {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.modal__text2 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: var(--red);
}

.modal__data {
    padding-bottom: 2rem;
    margin: 1rem 2rem;
    border-bottom: 1px solid var(--table-heading);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal__data--completed {
    background-color: var(--primary-color);
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    color: #e7e7ea;
    font-size: 1.2rem;
}

.modal__data--completedIcon {
    height: 2rem;
    width: 2rem;
    fill: #e7e7ea;
}

.modal__data--timedate {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1.5rem;
    color: var(--light-dark);
    font-size: 1.2rem;
}

.modal__data--timedateIcon {
    height: 2rem;
    width: 2rem;
    fill: var(--light-dark);
}

.modal__data--content {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    align-items: flex-start;
    color: var(--primary-color);
    font-size: 1.1rem;
}

.modal__data--contentIcon {
    height: 2rem;
    width: 2rem;
    fill: var(--primary-color);
}

.modal__data--user {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 2rem;
    margin-bottom: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--light-dark);
}

.modalContainer__form {
    margin: 1rem 2rem;
}

.modal__actions {
    padding: 1rem;
    padding-left: 2rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.modal__button {
    font-family: inherit;
    border-radius: 1.5rem;
    padding: 1rem 3rem;
    box-shadow: var(--box-shadow-input);
    font-size: 1.3rem;
    line-height: 1;
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
}

.modal__button--delete {
    border: 1px solid #ff3366;
    background: #ff3366;
    color: #e7e7ea;
}

.modal__button--delete:hover {
    background: transparent;
    border: 1px solid #ff3366;
    color: #ff3366;
}

.modal__button--update {
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    color: #e7e7ea;
}

.modal__button--update:hover {
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.modal__button--cancel {
    font-family: inherit;
    border-radius: 1.5rem;
    padding: 1rem 3rem;
    box-shadow: var(--box-shadow-input);
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    font-size: 1.3rem;
    line-height: 1;
    outline: none;
    cursor: pointer;
    transition: all 0.4s;
}

.modal__button--cancel:hover {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: #e7e7ea;
}

.modal__text {
    /* color: var(--light-dark); */
    font-size: 1.2rem;
    color: #ffffff;
}

.modal__text span {
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--primary-color);
}

.modalContainer__form--section {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    /* background-color: #353434b5; */
    padding: 1.5rem 1rem;
    padding-bottom: 2.5rem;
    border-radius: 2rem;
    margin-bottom: 2rem;
}

.modalContainer__form--section-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
    /* background-color: #353434b5; */
    padding-left: 1.5rem ;
    padding-bottom: 2.5rem;
    border-radius: 2rem;
    /* margin-bottom: 2rem; */
}

.modalContainer__form--sections {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

.modal__radioButton {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.modal__radioButtons {
    grid-gap: 1rem;
    gap: 1rem;
    grid-gap: 4rem;
    gap: 4rem;
    display: flex;
    padding-left: 2rem;
}

.modal__radioButton {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    position: relative;
}

.modal__radioButton--label {
    font-size: 1.2rem;
    font-weight: 600;
    /* padding-left: 35px; */
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: white;
}

.modal__radioButton--input {
    position: absolute;
    opacity: 1;
    z-index: 100;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}

.modal__radioButton--radio {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: transparent;
    border: 3px solid #ffffff;
}

.modal__radioButton--radio:after {
    content: "";
    position: absolute;
    display: none;
}

.modal__radioButton--input:checked~.modal__radioButton--radio {
    border: 3px solid var(--dark-grey);
}

.modal__radioButton--input:checked~.modal__radioButton--radio:after {
    display: block;
}

.modal__radioButton--radio:after {
    top: 0.5rem;
    left: 0.5rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: var(--dark-grey);
}

@media (max-width: 767px) {
    .modalContainer {
        overflow: scroll;
    }

    .modal {
        width: 90%;
        height: auto;
        overflow: auto;
    }

    .modalContainer__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .modalContainer__form--section {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        grid-gap: 1rem;
        gap: 1rem; 
        margin-bottom: 1rem;
    }

    .input__uploadContainer {
        display: flex;
        flex-direction: column;
    }

    .modal_input {
        align-items: center;
    }
}

.fileUpload {
    margin-top: 1rem;
    background-color: var(--pure-white);
    padding: 2rem 3rem;
    border-radius: 2rem;
    width: 23rem;
    height: 16rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.fileUpload__label {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
}

.fileUpload__label--icon {
    height: 5rem;
    width: 5rem;
    fill: var(--dark-grey);
}

.fileUpload__label--image {
     /* max-width: 200px; /* Set a maximum width */
      /* max-height: 200px;  */
    /* border-radius: 50%; */
    /* object-fit: cover;  */
    /* width: 100%;  */
    height: 100%;        
    object-fit:  fill;
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {

    
    .modalContainer__form--section-column{
        display: flex;
        flex-direction: column;
         align-items: flex-start
    }
}


/* @media  only screen and (min-width: 810px) and (max-width: 1500px) {
    .modal{
        width: 61%;
        overflow-y: scroll;
        height: 80%;
    }
    .modalContainer__form--section{
        width: 100%;
    }
}

@media  only screen and (min-width: 810px) and (max-width: 1400px) {
    .modal{
        width: 70%;
        overflow-y: scroll;
        height: 80%;
    }
    .modalContainer__form--section{
        width: 100%;
    }
}
*/
@media  only screen and (min-width: 1184px) and (max-width: 1280px) {
    .modalContainer__form--section{
        width: 100%;
    }
} 



.Button_button__1hwkA,
.Button_button__delete__3V3ka {
	border-radius: .5rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem 3rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  grid-gap: .5rem;
  gap: .5rem;
  transition: all .4s;
  /* margin-top: 2rem; */
}

.Button_button__1hwkA {
	/* background-image: linear-gradient(195deg, #42424a, #191919); */
	background-color: #FF9000;
	border: 1px solid var(--dark-grey);
}

.Button_button__1hwkA .Button_button__icon__1FZwV {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}

/* .button:hover {
	background-color: transparent !important;
	border: 1px solid var(--dark-grey);
	color: #000;
} */

/* .button:hover .button__icon {
	fill: #000;
} */

.Button_button__delete__3V3ka {
	border: 0px solid;
	box-shadow: 0 3px 3px 0 rgb(233 30 99 / 15%), 0 3px 1px -2px rgb(233 30 99 / 20%), 0 1px 5px 0 rgb(233 30 99 / 15%);
	/* background-image: linear-gradient(195deg, #ec407a, #d81b60); */
	background-color: #FF9000;
}

.Button_button__delete__3V3ka .Button_button__icon__1FZwV {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}

.Button_button__delete__3V3ka:hover .Button_button__icon__1FZwV {
	fill: var(--pure-white);
}

@media screen and (max-width:500px) {
	.Button_button__1hwkA,
.Button_button__delete__3V3ka {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

}
.TextBox_form_input__IsCeH {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
}
.TextBox_form_input__label__2zwUA {
	color: #ffffff;
	font-size: 1.6rem;
}
.TextBox_form_input__container__19D-2 {
	display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 1rem 2rem;
    padding-left: 2rem;
  padding-left: 1rem;
  width: 25rem;
}
.TextBox_form_input__icon__2kfOh {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.TextBox_form_input__box__22i-c {
	font-family: inherit;
	background-color: transparent;
	color: #ffffff;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
}
.TextBox_form_input__box__22i-c::-webkit-input-placeholder {
	color: var(--light-grey);
}
.TextBox_form_input__error__3CeWY {
	font-size: 1.2rem;
	color: #ff3366;
}


.StylistAvailability_month__KXMvX {
	width: 100%;
	background: var(--pure-white);
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: var(--box-shadow1);
	overflow-y: auto;
	overflow-x: auto;
	background-color: var(--pure-white);
	padding: 2rem;
}

.StylistAvailability_month__KXMvX ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.StylistAvailability_headers__37T0z {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	margin-bottom: 2rem;
}

.StylistAvailability_dayMonth__xplVq {
	text-align: center;
	font-size: 1.3rem;
	line-height: 1.3;
}

.StylistAvailability_dayMonth__xplVq span {
	font-size: 1.5rem;
	font-weight: 600;
	display: block;
}

.StylistAvailability_prevNextBtn__11J_3 {
	background-color: #e7e7ea;
	border: none;
	outline: none;
	color: var(--primary-color);
	font-family: inherit;
	font-size: 1rem;
	cursor: pointer;
	padding: 1rem;
	/* border-radius: 0.5rem; */
	font-weight: 600;
}

.StylistAvailability_weekdays__1x8g5 {
	margin: 0;
	background-color: var(--light-grey);
	display: flex;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
}

.StylistAvailability_weekdays__1x8g5 li {
	width: 13.6%;
	padding: 1.2rem 0;
	font-size: 1.5rem;
	color: var(--dark-grey);
	text-align: center;
}

.StylistAvailability_days__3mN1O {
	padding: 10px 0;
	background: var(--white);
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
}

.StylistAvailability_days__3mN1O li {
	min-width: 13.6%;
	height: 10rem;
	border: 1px solid var(--pure-white);
	text-align: center;
	font-size: 2rem;
	color: var(--dark-grey);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	line-height: 1;
	position: relative;
}

.StylistAvailability_lockIcon__m9jzH {
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	fill: var(--red);
}

.StylistAvailability_sessionData__2GQ7S {
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}

.StylistAvailability_sessionData__2GQ7S span {
	font-size: 3rem;
	color: var(--blue);
}

.StylistAvailability_days__3mN1O li .StylistAvailability_active__3OcWv {
	/* padding: .8rem;
	line-height: 1;
	background: var(--primary-color);
	border-radius: 50%; */
	color: var(--primary-color);
	/* border-bottom: 1px solid var(--primary-color); */
}

.StylistAvailability_totalSection__1-jpk {
	margin: 2rem 0;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
}

.StylistAvailability_totalSectionData__1sbKO {
	font-size: 1.3rem;
	color: var(--blue);
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
}

.StylistAvailability_totalSectionData__1sbKO span {
	font-size: 1.5rem;
	font-weight: 600;
}

.StylistAvailability_sessionDataAddBtn__M0t_S {
	margin-top: .5rem;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.1rem;
	color: var(--dark-grey);
	font-weight: 500;
	background-color: transparent;
	border-bottom: 1px solid var(--blue);
	padding: .2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;

	display: flex;
	align-items: center;
	transition: all .4s;
}

.StylistAvailability_sessionDataAddBtnIcon__hjAfR {
	height: 1rem;
	width: 1rem;
	fill: var(--dark-grey);
}

.StylistAvailability_sessionDataAddBtn__M0t_S:hover {
	border-bottom: 1px solid var(--dark-grey);
	color: var(--blue);
}

.StylistAvailability_sessionDataBtn__3Nw3T {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.StylistAvailability_sessionDataIcon__361-e {
	height: 1rem;
	width: 1rem;
	fill: var(--primary-color);
}

.StylistAvailability_sessionInputs__Kythb {
	margin-top: .5rem;
	background-color: var(--black);
	border-radius: 1rem;
	padding: .2rem 1rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.StylistAvailability_sessionInput__1Ptyz {
	color: var(--white);
	border: none;
	background-color: transparent;
	width: 5rem;
	font-family: inherit;
	outline: none;
}

.StylistAvailability_sessionInputBtn__2y7p4 {
	background-color: transparent;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.StylistAvailability_sessionInputIcon_check__2tW83 {
	height: 1.3rem;
	width: 1.3rem;
	fill: var(--primary-color);
}

.StylistAvailability_sessionInputIcon_close__1vbWT {
	height: 1rem;
	width: 1rem;
	fill: var(--red);
}

.StylistAvailability_errorText__21Ci3 {
	margin-top: .5rem;
	color: var(--red);
	font-size: .8rem;
}

/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {

	.StylistAvailability_weekdays__1x8g5 li,
	.StylistAvailability_days__3mN1O li {
		width: 13.1%;
	}
}

@media screen and (max-width: 420px) {

	.StylistAvailability_weekdays__1x8g5 li,
	.StylistAvailability_days__3mN1O li {
		width: 12.5%;
	}

	.StylistAvailability_days__3mN1O li .StylistAvailability_active__3OcWv {
		padding: 2px;
	}
}

@media screen and (max-width: 290px) {

	.StylistAvailability_weekdays__1x8g5 li,
	.StylistAvailability_days__3mN1O li {
		width: 12.2%;
	}
}

.StylistAvailability_form_input__label__1Hs6T {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.StylistAvailability_prevAvailablitys__QBuL1 {
	background-color: var(--pure-white);
	border-radius: 2rem;
	padding: 2rem;

	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-direction: column;
}

.StylistAvailability_prevAvailablityHeading__nASv5 {
	font-size: 1.5rem;
	font-weight: 600;
}

.StylistAvailability_prevAvailablity__1MQlG {
	background-color: var(--lightest-white);
	border-radius: 2rem;
	padding: 1rem 1.5rem;
}

.StylistAvailability_prevAvailablityTitle__dLhia {
	font-size: 1.3rem;
	font-weight: 600;
}

.StylistAvailability_prevAvailablitySubTitle__28J5G {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--light-grey);
}

.StylistAvailability_prevAvailablityTime__3f3Cu {
	font-size: 1rem;
	font-weight: 600;
	color: var(--dark-blue);
	margin-top: 1rem;
}


.StylistAvailability_calendarCSS__1d9_F {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}


.StylistAvailability_weekdaysStyle__1EUWv {
	width: 12%;
	padding: 1.2rem 0 !important;
	/* font-size: 1.5rem !important; */
	color: var(--dark-grey) !important;
	text-align: center !important;
}


.StylistAvailability_dynamicRow__2DbB- {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 1rem;
	gap: 1rem;
}

.StylistAvailability_addButtonslot__2EyOU {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.8rem;
	width: 13rem;
	position: relative;
	transition: all 1s ease;
	box-shadow: var(--box-shadow-input);
	box-shadow: var(--box-shadow-input) !important;
	background-image: linear-gradient(195deg, #c66f00, #ff9510);
	color: #fff;
}

.StylistAvailability_dayContainer__hnvb1 {
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 2rem;
	gap: 2rem;
	font-size: 1.8rem;
	padding: 10px;
}

.StylistAvailability_slotRow__1hp2L {
	padding: 0rem;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.StylistAvailability_busBtn__3AyNS{
	display: flex;
	flex-direction: column;
}

.StylistAvailability_copySlot__1M7H2{
	background-color: transparent;
	border: none;
	font-size: 12px;
	margin-top: 5px;
	cursor: pointer;
	text-decoration: underline;
	color: #000;
}

.StylistAvailability_slotLoading__gy3nQ{
	width: 55vw;
	height: 48vh;
	position: absolute;
	z-index: 100;
	background-color: #fffdfdaf;
	border-radius: 20px;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	
}

.StylistAvailability_slotLoadingTxt__2NenO{
	font-size: 1.5rem;
	position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.StylistAvailability_undoButtonslot__1SMtt{
	display: flex;
	justify-content: flex-end;
}
.businessHours_container {
 background-color: #e7e7ea;
 color: #000;
 margin-top: 4rem;
 border-radius: 0.5rem;
 padding: 0rem;
 position: absolute;
 box-shadow: var(--box-shadow-input);
 width: 20rem;
 max-height: 15rem;
 overflow-y: auto;
 margin-right: -1rem;
 left: 80%;
 z-index: 9999;
}

/* .fc-day-past {
 background-color: grey !important;
} */
.eventimage {
 width: 30px;
}

.refreshButton{
  background-color: #FF9000;
  border: 1px solid #FF9000;
  position: absolute;
  left: 20rem;
  font-family: inherit;
  font-size: 1.2rem;
  margin-top: .2rem;
  padding: 0.6rem 1rem;
  color: var(--pure-white);
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  gap: .5rem;
  transition: all .4s;
}

.event-text {
 text-decoration: none;
 font-size: 1.4rem;
 font-weight: 400;
 display: flex;
 grid-gap: 1rem;
 grid-gap: 1rem;
 gap: 1rem;
 color: white!important;
}

.event-box {
    padding: 5px 0;
    width: 100%;
    background-image:linear-gradient(195deg, #42424a, #191919);
    margin-bottom: 5px;
    border-radius: 2px;
   }
   
   .event-box1 {
       padding: 5px 0;
       width: 100%;
       background-image:none;
       background-color: #8b8b8b77;
       margin-bottom: 5px;
       border-radius: 2px;
      }

      .event-box2 {
        padding: 5px;
    width: 100%;
    background-image: none;
    /* margin-bottom: 5px; */
    border-radius: 2px;
       }

.rmdp-wrapper {
 width: 100% !important;
}
.event-text {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    /* padding-left: 4px; */
    display: flex;
    justify-content: center;
}

.fc-timegrid-slot {
width: 100% !important;
 height: 9em !important;
 border-bottom: 0 !important;
}

/* .fc-daygrid-event-harness{
    background-image:linear-gradient(195deg, #42424a, #191919);
    margin-bottom: 5px;
    border-radius: 2px;
    margin: 10px;
} */

.fc-daygrid-event-harness .event-box .event-text{
    color: #fff;
}

.event-appointment{
    background-color: #191919;
    /* background-image: linear-gradient(195deg, #42424a, #191919); */
    color: #fff;
    font-size: 0.9rem;
    font-weight: 2rem;
    width: 100%;
    margin-top: 1px;
    border-radius: 2px;
    padding: 0 5px;
}

.event-readmore{
    color: #ffffff;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.table__iconBox1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.event-btn{
    display: flex;
    
}

.event-btn button{
    width: 22px !important;
    height: 22px !important;
    margin-left: 2px;
}

/* .fc-day-header {
    visibility: hidden;
  }
  
  .fc-day-top .fc-day-number {
    display: none;
  }
  
  .fc-day-top .fc-day-number.fc-day-number-NOT-today {
    display: none;
  }
  
  .fc-day-top .fc-day-number.fc-day-number-NOT-today.fc-day-number-SAT,
  .fc-day-top .fc-day-number.fc-day-number-NOT-today.fc-day-number-SUN {
    display: none;
  }
  
  .fc-day-top .fc-day-number.fc-day-number-today {
    display: none;
  }
  
  .fc-day-top .fc-day-number.fc-day-number-today.fc-day-number-SAT,
  .fc-day-top .fc-day-number.fc-day-number-today.fc-day-number-SUN {
    display: none;
  } */

  @media only screen and (min-width: 810px) and (max-width: 1080px) {
    .refreshButton{
      left: 0.8rem;
      top: 5.2rem;
    }
  } 
  
.waitlist__table__button--second {
 text-decoration: none;
 color: var(--dark-grey);
 border: 1px solid var(--dark-grey);
 border-radius: 5rem;
 font-size: 1.1rem;
 cursor: pointer;
 transition: all 0.4s;
 min-width: 10rem;
 text-align: center;
 padding: 0.5rem;
}

.waitlist--statusContainer {
 background-color: transparent;
 outline: none;
 border: none;
}
.editWaitlist--timeslot {
 display: flex;
 align-items: center;
 grid-gap: 2rem;
 gap: 2rem;
}
.waitlisttableContainer {
 margin: 1rem;
 /* background-color: var(--pure-white); */
 border-radius: 1rem;
 box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
 height: 35vh;
 overflow-y: auto;
 overflow-x: auto;
}
.selectBox {
 font-family: inherit;
 border: none;
 outline: none;
 background-color: var(--primary-color);
 color: var(--black);
 padding: 0.5rem 1rem;
 border-radius: 0.5rem;
}

/* .header{
    background-color: black;
} */
.orders_signDataAddBtn__LeT2b {
	font-family: inherit;
	font-size: 1.2rem;
	color: var(--black);
	font-weight: 500;
	background-color: transparent;
	border: 1px solid var(--black);
	padding: .5rem 2rem;
	border-radius: 2rem;
	cursor: pointer;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	transition: all .4s;
}
.orders_signDataAddBtnIcon__c_U65 {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--black);
	transition: all .4s;
}
.orders_signDataAddBtn__LeT2b:hover {
	background-color: var(--black);
	border: 1px solid var(--black);
	color: var(--white);
}
.orders_signDataAddBtn__LeT2b:hover .orders_signDataAddBtnIcon__c_U65 {
	fill: var(--white);
}
.orders_selectBox__1KyrZ {
	font-family: inherit;
	border: none;
	outline: none;
	background-color: var(--dark-blue);
	color: var(--white);
	padding: .5rem 1rem;
	border-radius: .5rem;
}


.NoData_noDataContainer__f9TAr {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #8b8b8b;
}
.NoData_noDataContainerIcon__vLgl0 {
	height: 7rem;
	width: 7rem;
	fill: #8b8b8b;
}
.NoData_noDataContainerText__1-ZG9 {
	font-size: 3rem;
	font-weight: 600;
}
.NoData_noDataContainerSubText__X5rGX {
	font-size: 1.3rem;
	font-weight: 500;
}

.BookAppointment_appointment_header1__3k613 {
	font-size: 1.5rem;
	font-weight: 600;
}

.BookAppointment_appointment_header2__1Jatx {
	font-size: 1.5rem;
    font-weight: 500;
}

.Content_container__3fU3K {
	display: grid;
	grid-template-rows: 5rem 1fr auto;
	grid-gap: 2rem;
	gap: 2rem;
	background: #fff;
}

.Content_content__RKYag {
	/* min-height: 90vh; */
	grid-row: 1 / 3;
	background: #fff;
}

/*Desktop View*/

.website_header {
    grid-row: 1 / 2;
    background-color: #fff;
    padding: 2rem 6rem;
    position: fixed;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.logo_website {
    height: 8.4rem;
    position: absolute;
    padding: 10px;
    /* background: black; */
}

.navigation_website {
    margin-left: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation__links {
    list-style: none;
    font-size: 1.2rem;
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    align-items: center;
    cursor: pointer;
}

.navigation__link {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
}

.navigation__link:hover {
    text-decoration: none;
    font-weight: 600;
    color: #ff6308;
}

.navigation__active {
    color: #ff0000 !important;
}

.header-icon-in {
    fill: #000000;
    height: 2rem;
    width: 2rem;
}

.header-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.mobile-option {
    display: none;
}

.mobile-menu {
    display: none;
}

/* end cart css */


@media (max-width: 1700px) {
    .website_header {
        padding: 2rem 2rem;
    }
    /* .logo_website{
        height: 4rem;
    } */
}

@media (max-width: 1536px) {
    .website_header {
        padding: 2rem 2rem;
    }
    /* .logo_website{
        height: 4rem;
    } */
}

@media (max-width: 1400px) {
    .website_header {
        padding: 2rem 2rem;
    }
    /* .logo_website{
        height: 4rem;
    } */

    .navigation__links {
        grid-gap: 3rem;
        gap: 3rem;
    }

    .navigation_website {
        margin-left: 15%;
    }
}

@media (max-width: 1230px) {
    /* .logo_website{
        height: 4rem;
    } */
    .website_header {
        padding: 2rem 2rem;
        width: 100% !important;
        display: flex;
    }

    .navigation__links {
        grid-gap: 1rem;
        gap: 1rem;
    }

    .navigation_website {
        margin-left: 25%;
    }
}

@media (max-width: 900px) and (min-width: 768px) {
    /* .logo_website{
        height: 4rem;
    } */
}


@media (max-width: 768px) {
    .navigation__links--contact {
        display: none !important;
    }

    .website_header {
        padding: 2rem 1rem;

        padding-top: 1rem;
        padding-bottom: 0;
    }

    .mobile-menu {
        display: flex;
    }

    .icon-box {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        margin-right: 1rem;
    }

    .logo_website{
        height: 5.4rem;
    }

    .menu-icon2 {
        height: 2rem;
        width: 2rem;
    }

    .nav-options {
        display: flex;
        width: 100%;
        min-height: 25rem;
        position: absolute;
        top: 10rem;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 1.5rem;
        padding: 5rem;
    }

    .nav-options.active {
        background: #f9f9f9;
        left: 0rem;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        align-content: center;
        padding-left: 2rem;
        box-shadow: var(--box-shadow1);
    }

    .menu-icon {
        width: 4rem;
        height: 4rem;
    }

    .menu-icon3 {
        width: 2rem;
        height: 2rem;
    }

    .option {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vw;
        padding: 30px 0px;
    }

    .navigation__links {
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        grid-gap: 3rem;
        grid-gap: 3rem;
        gap: 3rem;
        align-items: flex-start;
        justify-content: center;
    }

    .navigationLoginSidebar {
        left: 25% !important;
    }
}
.shopping-cartsidebar {
    display: none;
}


.navigationbar {
    position: fixed;
    width: 40rem;
    top: 0;
    right: 0;
    bottom: 0;
    float: right;
    background-color: var(--white);
    overflow: hidden;
    z-index: 1000;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius:0;
    background-color: white;
    top: 0;
    z-index: 999;  
    box-shadow: 0 0 16px 0 rgba(46, 46, 46, 0.6);
    transition: all 0.5s ease;
}


.navigationbar.active {
    width: 0;
    transition: all 0.5s ease;
}

.cart--header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 2px solid #dee2e6;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.pincode--container {
    padding: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
 
.cart__product-listContainer {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 2rem 2rem 2rem 0rem;
    border-bottom: 1px dashed #787474;
}
 


.cart-product_image {
    height: 6rem;
    background-color: var(--pure-white);
    border-radius: 15px;
}

.cart--productDetail__container {
    width: 100%;
    padding: 0 2rem 0 0;
}

.item-name_new {
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
    width: 80%;
}

.coupon--container {
    border-top: 1px solid;
}

.coupon--container h3 {
    margin-top: 1rem;
    padding-left: 1rem;
}

.cart--items__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.quantity--input {
    width: 8rem;
    padding: 0.5rem;
    border: none;
    border-radius: 10px;

}

.cart--items__price--summary {
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid rgb(128, 128, 128); */
}

.cart--items__charges {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 12px;
}

.cart--order__Summary {
    padding: 1rem;
}

.cart--footer {

    padding: 1rem;
    padding-bottom: 4rem;
    position: absolute;
    width: 100%;
    /* height: 15%; */
    right: 0;
    bottom: 0;
    left: 0;
    color: var(--primary-color);
    background-color: var(--bg-secondary);
    clear: both;
}

.cart--total {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: white;
}
.Button_button__2R95l {
 border-radius: 0.5rem;
 font-family: inherit;
 font-size: 1.3rem;
 padding: 1rem 3rem;
 color: var(--white);
 cursor: pointer;

 display: flex;
 align-items: center;
 grid-gap: 0.5rem;
 gap: 0.5rem;
 transition: all 0.4s;
}
.Button_button__delete__2Ga9w {
 border-radius: 0.5rem;
 font-family: inherit;
 font-size: 1.3rem;
 padding: 1rem 3rem;
 color: var(--bg-secondary);
 cursor: pointer;

 display: flex;
 align-items: center;
 grid-gap: 0.5rem;
 gap: 0.5rem;
 transition: all 0.4s;
}

.Button_button__2R95l {
 background-image: linear-gradient(195deg, #42424a, #191919);
 border: 1px solid var(--dark-grey);
}

.Button_button__2R95l .Button_button__icon__1DrVD {
 height: 1.5rem;
 width: 1.5rem;
 fill: var(--white);
 transition: all 0.4s;
}

.Button_button__2R95l:hover {
 background-color: transparent !important;
 border: 1px solid var(--dark-grey);
 color: white;
}

.Button_button__2R95l:hover .Button_button__icon__1DrVD {
 fill: white;
}

.Button_button__delete__2Ga9w {
 border: 0px solid;
 box-shadow: 0 3px 3px 0 rgb(233 30 99 / 15%),
  0 3px 1px -2px rgb(233 30 99 / 20%), 0 1px 5px 0 rgb(233 30 99 / 15%);
 /* background-image: linear-gradient(195deg, #ec407a, #d81b60); */
 background-color: var(--gold);
}

.Button_button__delete__2Ga9w .Button_button__icon__1DrVD {
 height: 1.5rem;
 width: 1.5rem;
 fill: var(--white);
 transition: all 0.4s;
}

.Button_button__delete__2Ga9w:hover .Button_button__icon__1DrVD {
 fill: var(--pure-white);
}

.QuantityInput_form_input__3dOM- {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
	width: 100%;
	margin: 2rem 1rem;
} 

.QuantityInput_form_input__label__1tLQx {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.QuantityInput_form_input__container__35ZTj {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}

.QuantityInput_form_input__icon__2blK9 {
	height: 3rem;
	width: 2rem;
	fill: var(--dark-grey);
	margin: 0 1rem;
}

.QuantityInput_form_input__box__2jzyg {
	font-family: inherit;
	background-color: transparent;
	color: var(--dark-grey);
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}

.QuantityInput_form_input__box__2jzyg::-webkit-input-placeholder {
	color: var(--light-grey);
}

.QuantityInput_form_input__error__sDWIu {
	font-size: 1.2rem;
	color: #ff3366;
	width: 25rem;
}
/* cart css */
.cartContainer {
    margin: auto;
    width: 100%;
}

.shopping-cart {
    display: none;
    margin: 20px 0;
    float: right;
    background: white;
    width: 100%;
    position: relative;
    border-radius: 3px;
    padding: 20px;
}

.shopping-cart-active {
    position: absolute;
    width: 40vh;
    margin: 3rem 0;
    float: right;
    background: white;
    box-shadow: var(--box-shadow1);

    border-radius: 3px;
    padding: 20px;
}

.shopping-cart-header {
    border-top: 1px solid #e8e8e8;
    padding-bottom: 25px;
}

.shopping-cart-total {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}

.cart--productDetail__container {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.cart__product--nameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__product--countContainer {
    display: flex;
    justify-content: space-between;

    grid-gap: 2rem;

    gap: 2rem;
}
/* 
.cart-product_image {
    height: 6vh;
    background-color: var(--pure-white);
    border-radius: 15px;
} */

.shopping-cart-items {
    padding-top: 3rem;
}

.shopping-cart-items li {
    margin-bottom: 18px;
    list-style-type: none;
}

.shopping-cart-items img {
    float: left;
    margin-right: 12px;
}

.item-name {
    display: block;

    font-size: 16px;
}

.item-price {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.item-price__total {
    font-size: 16px;
    font-weight: bold;
}

.item-quantity {
    color: green;
}
.TextBox_form_input__FEy3w {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
	width: 100%;
} 

.TextBox_form_input__label__32x42 {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.TextBox_form_input__container__3HtO3 {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}

.TextBox_form_input__icon__215wu {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
}

.TextBox_form_input__box__--RFU {
	font-family: inherit;
	background-color: transparent;
	color: var(--dark-grey);
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}

.TextBox_form_input__box__--RFU::-webkit-input-placeholder {
	color: var(--light-grey);
}

.TextBox_form_input__error__2Lo2d {
	font-size: 1.2rem;
	color: #ff3366;
	width: 25rem;
}


@media (max-width: 767px) {
	.TextBox_form_input__container__3HtO3 {
		width: 100% !important;
	} 
}
    .product_main_container {
        margin: 5rem 4rem;
        display: flex;
        grid-gap: 5rem;
        gap: 5rem;
        align-items: flex-start;
    }

    .product_left_sidebar {
        width: 20%;
    }

    .product_right_container {  
        width: 80%;
        display: inline-flex;
        margin: 2rem 0;
        grid-gap: 4rem;
        gap: 4rem;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }


    .collection-filter-block {
        margin-bottom: 30px;
        padding: 0 30px;
        border: 1px solid #dddddd;
        background-color: #ffffff;
    }


    .products_discount {
        display: flex;
        margin-top: 8rem;
        background-color: black;
        padding: 1rem 30rem;
        color: var(--pure-white);
        justify-content: space-between;
    }

    .slideside {
        display: flex;
        grid-gap: 2rem;
        gap: 2rem;
        background-color: var(--light-grey2);
        width: 100%;
        height: 100%;
    }

    .product_discount_heading {
        font-size: 1.6rem;
        font-weight: 500;
    }

    .prod--main {
        width: 100%;

    }

    .products {
        padding: 1rem;
        padding-top: 1rem;
        background-color: var(--light-grey2);
        border-radius: 15px;
        justify-content: center;
        text-align: center;
        width: 30rem;
        cursor: pointer;
    }

    .product_image {
        height: 28vh;
        width: 100%;
        background-color: var(--pure-white);
        border-radius: 15px;
    }

    .product_name {
        font-size: 16px;
        font-weight: 600;
        color: var(--yellow);
    }

    .product_price {
        font-size: 1.8rem;
        font-weight: 800;
    }

    .product_price1 {
        display: flex;
        justify-content: space-around;
        grid-gap: 4rem;
        gap: 4rem;
    }

    .product_cart {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product_button {
        font-size: 1.4rem;
        font-weight: 600;
        padding: 0.5rem;
        border: none;
        outline: none;
        border-radius: 5px;
        text-transform: uppercase;
        background-color: black;
        color: var(--pure-white);
    }

    .product_button:hover {
        background-color: var(--pure-white);
        color: black;
    }

    .productList--card {
        text-decoration: none;
        /* position: relative; */
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background: #fff;
        content: "";
        z-index: 1;
        border-radius: 2px;
        box-shadow: inset 0 0 0 1px #ededed;
        transition: all .4s;
        align-items: center;
        padding: 1rem;
        border-radius: 0.8rem;
    }

    .productList--card:hover {
        --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        --tw-border-opacity: 1;
        -webkit-transform: var(--tw-transform);
                transform: var(--tw-transform);
        box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        --tw-shadow: 0 6px 12px rgba(0, 0, 0, .08);
    }

    .list--card--image--container {
        max-height: 15rem;
        max-width: 15rem;
        position: relative;
    }

    .list--card--product--image {
        width: 15rem;
        height: 15rem;
        object-fit: cover;
        border-radius: 2rem;
    }

    .product--card--info__container {
        padding: 1rem 2rem 0 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .recommendation--header {
        font-size: 16px;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-left: 10rem;
    }

    .prod--list-paginater {
        float: right;
        padding: 0 4rem 2rem 2rem;

        position: absolute;
        right: 0;
        bottom: 0;
        clear: both;
    }

    .recommendation--cart--button {
        background-image: linear-gradient(195deg, #42424a, #191919);
        border: 1px solid var(--dark-grey);

        border-radius: 0.5rem;
        font-family: inherit;
        font-size: 1.3rem;
        padding: 0.5rem;
        color: var(--white);
        cursor: pointer;

        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        transition: all 0.4s;
    }

    .list--header {
        font-size: 16px;
        font-weight: 600;
        margin-top: 2rem;

    }

    .card--inStock {
        background-color: var(--blue);
        padding: 0.25rem 1rem;
        border: none;
        border-radius: 10px;
        text-align: center;
        font-size: 12px;
    }

    .card--outofStock {
        background-color: var(--red);
        padding: 0.25rem 1rem;
        border: none;
        border-radius: 10px;
        text-align: center;
        font-size: 12px;
    }

    @media (max-width: 1024px) {}

    /*mobile view*/
    @media (max-width: 768px) {
        .main_outer {
            width: 100%;
        }

        /* .all_products_list {
        margin: 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    } */

        .list--header {
            margin-bottom: 2rem;
            text-align: center;
        }

        .productList--card {
            width: 30rem;
        }

        .prod--list-paginater {
            padding: 1rem;
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
        }

        .products_discount {
            padding: 0 1rem;
            display: flex;
            flex-direction: column;
            margin-top: 6.5rem;
        }

        .product_discount_heading {
            font-size: 1.4rem;
            text-align: center;
            justify-content: center;
        }

        .slideside {
            flex-direction: column;
        }
    }

    .infinite-scroll-component {
        overflow: hidden !important;
    }

    .collection-collapse-block .collapse-block-title {
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        color: #444444;
        font-weight: 600;
        margin: 3rem 0 1rem 0;

    }

    .collection-collapse-block .collapse-block-title:after {
        position: absolute;
        display: block;
        top: 0;
        right: -3px;
        font-family: FontAwesome;
        color: #444444;
    }

    .collection-filter-block {
        margin: 30px;
        padding: 0 30px;
        border: 1px solid #dddddd;
        background-color: #ffffff;
    }

    .collection-filter-block .product-service {
        padding: 30px 0;
    }

    .collection-filter-checkbox label {
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
        color: #777777;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding-left: 1.5rem;

    }

    .input-range {
        height: 1rem;
        position: relative;
        width: 100%;
    }

    .input-range__label--min, .input-range__label--max {
        bottom: -2.5rem !important;
        position: absolute;
    }

    .input-range__label {
        color: #000000 !important;
        font-size: 1.2rem !important;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        white-space: nowrap;
    }

    .input-range__label--value {
        top: -2.8rem !important;
    }

    .input-range__track--background {
        left: 0;
        margin-top: -0.15rem;
        position: absolute;
        right: 0;
        top: 50%;
    }

    .input-range__slider {
        -webkit-appearance: none;
        appearance: none;
        border-radius: 100%;
        cursor: pointer;
        display: block;
        height: 1.5rem !important;
        margin-left: -0.5rem;
        margin-top: -0.9rem !important;
        outline: none;
        position: absolute;
        top: 50%;
        transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
        transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
        width: 1.5rem !important;
    }

    .input-range__track {
        background: #4b4b4b !important;
        border-radius: 0.3rem;
        cursor: pointer;
        display: block;
        height: 0.5rem !important;
        position: relative;
        transition: left 0.3s ease-out, width 0.3s ease-out;
    }

    .input-range__track--active {
        background: #ff9000 !important;
    }

    .input-range__slider {
        background: #ff9000 !important;
        border: 1px solid #ff9000 !important;
    }

    .block-price-content .custom-control {
        padding-right: 1.5rem;
        padding-bottom: 18px;
        margin-top: 2.5rem;
    }

    .collection-filter-checkbox label:before {
        top: 1px;
        height: 15px;
        width: 15px;
        background: #ffffff;
        border: 1px solid #777777;
    }

    .collection-filter-checkbox label:after {
        top: 1px;
        height: 15px;
        width: 15px;
        background-size: 65%;
    }

    .custom-control {
        position: relative;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        margin: 2rem 0;
    }

    .custom-control-inline {
        display: inline-flex;
        margin-right: 1rem;
    }

    .custom-control-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .actualPrice {
        -webkit-text-decoration-line: line-through;
                text-decoration-line: line-through;
        color: #b1b1b1;
        font-size: 1.5rem;
        padding-left: 1rem;
    }


    .custom-control-input:active~.custom-control-label::before {
        color: #fff;
        background-color: #b3d7ff;
    }

    .custom-control-input:disabled~.custom-control-label {
        color: #6c757d;
    }

    .custom-control-input:disabled~.custom-control-label::before {
        background-color: #e9ecef;
    }

    .custom-control-label {
        position: relative;
        margin-bottom: 0;
    }

    .custom-control-label::before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        background-color: #dee2e6;
    }

    .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    .custom-control-input:checked~.custom-control-label:after {
        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
    }

    .product-category {
        display: none;
    }

    @media screen and (max-width:900px) {
        .product_main_container {
            margin: 1rem 1rem;
            display: flex;
            justify-content: center;
        }

        .product-category {
            display: flex;
            grid-gap: 1rem;
            gap: 1rem;
            width: 100vw;
            overflow-x: auto;
            height: 5rem;
            /* margin-top: 8rem; */
            align-items: center;
            padding: 10px;
        }

        .product-category {
            -ms-overflow-style: none;
            /* for Internet Explorer, Edge */
            scrollbar-width: none;
            /* for Firefox */
            overflow-y: scroll;
        }

        .product-category::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }

        .product_left_sidebar {
            display: none;
        }

        .product_right_container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .product-list-chip {
            max-width: 50rem;
            height: 3rem;
            padding: 5px;
            background-color: #fff;
            border: 1px solid #000;
            color: #000;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            /* overflow: hidden; */
            white-space: nowrap;

        }

        .product-list-chip-active {
            max-width: 50rem;
            height: 3rem;
            padding: 5px;
            background-color: #000;
            border: 1px solid #000;
            color: #fff;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            /* overflow: hidden; */
            white-space: nowrap;
        }
    }


.Footer_footor_content__1Cb64 {
    /* margin-top: 2rem; */
    display: flex;
    padding: 2rem 30rem;
    justify-content: space-between;
    text-align: center;
    align-self: center;
    align-items: center;
    border-top: 0.5rem solid;
    background: #f9f9f9;
    width: 100%;
}
 
 @media screen and (max-width:1440px) {
    .Footer_footor_content__1Cb64 {
        /* margin-top: 2rem; */
        padding: 2rem 10rem;
    }
 }


 @media screen and (max-width:900px) {
    .Footer_footor_content__1Cb64 {
        /* margin-top: 2rem; */
        flex-direction: column;
        padding: 2rem 2rem;
        justify-content: center;
    }
    
 }

 @media (max-width:300px) {
    .Footer_footor_content__1Cb64 {
        /* margin-top: 2rem; */
        flex-direction: column;
        padding: 2rem 0rem;
        justify-content: center;
    }
}

.grid-container {
    margin: 10rem 10rem;
    display: grid;
    grid-template-columns: 25% 60%;
    grid-gap: 2rem;
    /* position: absolute; */
    justify-content: center;
}

.grid-container>div {
    background-color: var(--light-grey2);
    padding: 2rem 2rem;
    border-radius: 15px;
    width: 100%;
}

.item5 {
    grid-area: 5/2/1;
}

.appointment_top_box {
    display: flex;
    flex-direction: column;
}

.appointment_subheading1 {
    font-size: 1rem;
    font-weight: 500;
    /* float: right; */
}

.appointment_subheading {
    display: flex;
    flex-direction: row-reverse;
}

.button_for_date {
    border: none;
    outline: none;
    background-color: var(--pure-white);
    cursor: pointer;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

.button_for_date:hover {
    -webkit-transform: scaley(1.1);
            transform: scaley(1.1);
    transition: all 0.5s;
    background-color: black;
    color: var(--pure-white);
}

.appointment_header1 {
    font-size: 1.8rem;
}

.appointment_heading2 {
    font-size: 1.4rem;
}

.download_options_inner {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
}

.download_options_store {
    color: white;
    background: black;
    display: flex;
    padding: 0.5rem 1.2rem;
    border-radius: 15px;
    width: 16rem;
    cursor: pointer;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-align: center;
    align-items: center;
}

.filter__input--icon {
    height: 4rem;
    width: 4rem;
    fill: white;
    margin: 1rem 0;
}

.appointment_heading4 {
    font-size: 1rem;
    font-weight: 500;
}

.appointment_subheading4 {
    font-size: 1.2rem;
}

.services_outer {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    max-height: 73vh;
    overflow: auto;
}

.servies_top {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0rem;
    align-items: flex-end;
}

.our_services {
    background-color: white;
    border-radius: 15px;
}

.service_name2 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.service_name {
    display: flex;
    justify-content: space-between;
    background: var(--primary-color);
    border-radius: 1rem 1rem 0 0;
    padding-left: 20px;
}

.barber_info {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    /* margin-top: 2rem; */
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.barber_image {
    height: 5rem;
    width: 5rem;
    border-radius: 100%;
    object-fit: cover;
}

.barber_image1 {
    height: 5rem;
    /* width: 5rem; */
    /* border-radius: 100%; */
    /* object-fit: cover; */
}

.barber_info_name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.time_intervals {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 2rem;
    margin: 2rem 0;
}

.time {
    outline: none;
    border: none;
    background-color: var(--light-grey2);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
}


.time-btn span {
    display: none;
    font-size: 1rem;
    color: var(--pure-white);
    background-color: var(--black);
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    position: absolute;
    top: -3rem;
    width: 140px;
    /* left: 0; */
    /* transform: translate(-50%, -50%); */
    /* transition: all 1s; */
    /* right: 1rem; */
    z-index: 1000;
}

.time-btn:hover span {
    display: block;
}

.time-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 10px;
}

.time-booked {
    outline: none;
    border: none;
    background-color: rgba(218, 255, 162, 0.72);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
}

.time-confirmed {
    outline: none;
    border: none;
    background-color: rgba(218, 255, 162, 0.72);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
}

.time-canceled {
    outline: none;
    border: none;
    background-color: rgba(255, 162, 162, 0.72);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;

}


.time-waiting {
    outline: none;
    border: none;
    background-color: rgb(242, 202, 141);
    cursor: pointer;
    border-radius: 15px;
    width: 8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    margin-right: 5px;
    margin-bottom: 5px;

}

.time:hover {
    background-color: black;
    color: white;
    transition: all 0.4s;
}

.popup_heading {
    padding: 0 2rem;
    margin-top: -4rem;
}

.popup_total_outer {
    display: flex;
    justify-content: space-between;
}

/* legend */
.legend-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.box-available {
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-confirmed {
    border: 1px solid #000;
    background-color: rgb(141, 242, 149);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-waiting {
    border: 1px solid #000;
    background-color: rgb(242, 202, 141);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-canceled {
    border: 1px solid #000;
    background-color: rgb(255, 121, 121);
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.box-legend {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-left: 10px;
}

.stylist-detail {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

@media (max-width: 1400px) {
    .grid-container {
        margin: 10rem 10rem;
    }

    .download_options_inner {
        /* display: flex; */
        justify-content: space-between;
        justify-content: center;
        margin-top: 2rem;
    }

    .download_options_store {
        padding: 0.5rem 1rem;
        width: 10rem;
    }

    .filter__input--icon {
        height: 2rem;
        width: 2rem;
        fill: white;
        margin: 1rem 0;
    }

    .download_options {
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 1230px) {
    .grid-container {
        margin: 10rem 1rem;
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
        align-items: center;
    }

    .download_options_inner {
        /* display: flex; */
        justify-content: space-between;
        justify-content: center;

        margin-top: 2rem;
    }

    .rmdp-calendar {
        height: -webkit-max-content !important;
        height: max-content !important;
        padding: 4px !important;
        width: 100% !important;
    }

    .item1 {
        order: -1;
        width: 90vw;
    }

    .item2 {
        width: 90vw;
    }

    .item5 {
        order: -1;
    }

    .download_options_store {
        padding: 0.5rem 1rem;
        width: 10rem;
    }

    .filter__input--icon {
        height: 2rem;
        width: 2rem;
        fill: white;
        margin: 1rem 0;
    }

    .download_options {
        justify-content: center;
        text-align: center;
    }

    .rmdp-calendar {
        height: -webkit-max-content;
        height: max-content;
        padding: 4px;

    }

}

@media (max-width: 768px) {
    .grid-container {
        margin: 10rem 1rem;
        /* width: 100%; */
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
    }

    .grid-container>.item5 {
        padding: 2rem 0.5rem;
    }

    .item1 {
        order: -1;
    }

    .item5 {
        order: -1;
    }

    .download_options_inner {
        /* display: flex; */
        justify-content: space-between;
        justify-content: center;
        margin-top: 2rem;
    }

    .download_options_store {
        padding: 0.5rem 1rem;
        width: 10rem;
    }

    .filter__input--icon {
        height: 2rem;
        width: 2rem;
        fill: white;
        margin: 1rem 0;
    }

    .download_options {
        justify-content: center;
        text-align: center;
    }

    .servies_top {
        padding: 0rem 2rem;
        grid-gap: 3rem;
        gap: 3rem;
    }

}

@media (max-width: 500px) {
    .servies_top {
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0rem;
        align-items: flex-start;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .stylist-detail {
        display: flex;
        grid-gap: 0rem;
        grid-gap: 0rem;
        gap: 0rem;
        flex-direction: column;
    }
}

.appointment-box {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}



.appointment-icon {
    width: 15px;
    height: 15px;
    fill: #2e2e2e;
}

.stylist-detail-icon {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
}

.thankyou {
    margin: 1rem;
    background-color: #fff;
    width: 40vw;
    height: 40vh;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.thankyou-img {
    width: 100px;
}

.appointment-box1 {
    display: flex;
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: flex-start;
    margin: 10px 0;
    flex-direction: column;
    align-items: center;
}

.appointment-box2 {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
}

.appointment-box3 {
    display: flex;
    margin: 10px 0;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.appointment-box3 p {
    width: 200px;
}

.salon_info_name {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rmdp-top-class {
    display: flex;
    justify-content: center !important;
}

.download_options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.salon_heading2 {
    font-size: 1.9rem;
}

.appointment_subheading4 {
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
    /* float: right; */
}

.appointment_select select {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    font-size: 12px;
    font-family: inherit;
    outline: none;
    border-radius: 5px;
}

.appointment_select select:hover {
    border: 3px solid #000;
}
.SendNotification_content__256M6 {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: flex-start;
}
.SendNotification_prevNotifications__1VMAJ {
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-direction: column;
	width: 50%;
}
.SendNotification_prevNotificationsHeading__3ISZH {
	font-size: 1.5rem;
	font-weight: 600;
}
.SendNotification_prevNotification__Bwziq {
	background-color: #ececec;
	border-radius: 2rem;
	padding: 1rem 1.5rem;
}
.SendNotification_prevNotificationTitle__mk4VK {
	font-size: 1.3rem;
	font-weight: 600;
}
.SendNotification_prevNotificationSubTitle__3GI_R {
	font-size: 1.1rem;
	font-weight: 500;
	color: #8b8b8b;
}
.SendNotification_prevNotificationTime__2UG4y {
	font-size: 1rem;
	font-weight: 600;
	color: #007c6e;
	margin-top: 1rem;
}
@media all and (min-width: 810px) and (max-width: 1080px) {
	.SendNotification_content__256M6{
		flex-direction: column;
	}
	.SendNotification_prevNotifications__1VMAJ{
		width: 100%;
	}
}

@media (max-width: 811px) {
    .SendNotification_content__256M6{
		flex-direction: column;
	}
}

.Service_chartContentHeader__2o6Ip {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	justify-content: space-between;
}


.Service_textBoxInput__2qqMk{
    background-color:#fff;
}

.Service_textBoxInputBox__1F7eJ{
    background-color:#fff;
}


.Service_form_input__label__4xQbJ {
	color: var(--light-grey);
	font-size: 1.2rem;
}
.cart--icon--star {
    fill: var(--gold);
    height: 1.5rem;
    width: 1.5rem;
}

.stars ul {
    list-style: none;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
}

.stars ul li {
    opacity: 0.5;
    cursor: pointer;
}

.stars ul li.selected {
    opacity: 1;
    color: #ff9000;
}

.stars ul .container {
    text-align: center;
}

.product_discount_heading {
    font-size: 1.6rem;
    font-weight: 500;
}


/*mobile view*/
@media (max-width: 768px) {
	.products_discount {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        margin-top: 7.5rem;
    }

    .product_discount_heading {
        font-size: 1.4rem;
        text-align: center;
        justify-content: center;
    }
}

.screen-container{
    width: 100%;
    height: 90vh;
    background-color: #fff;
    border-radius: 10px;

}

.screen-container .footer_container{
    position: relative !important;

}

.appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0;
    display: none;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: absolute;
    right: -10px;
    top: -10px;
}

.appearance-btn2 input[type=file] {
    opacity: 0;
    outline: none;
    background: white;
    cursor: pointer;
    display: block;
    width: 15px;
    height: 15px;
    
}

.appearance-btn2{
    border: none;
    background-color: transparent;
    border: 0px solid var(--primary-color);
    outline: none;
    padding: 0.7rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.4s;
    color: #fff;
    align-items: center;
    position: relative;
    background-image: linear-gradient(195deg, #42424a, #191919);
}

.table__button--icon2 {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--pure-white);
    position: absolute;
    cursor: pointer;
}

.screen_header {
    /* grid-row: 1 / 2; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 2rem 10rem;
    /* position: fixed; */
    width: 100%;
    
}

.logo_website_screen img {
    height: 5rem;
    /* position: absolute; */
}

.logo_website_screen{
    padding: 5px;
    position: relative;
    
}

.logo_website_screen:hover{
    border: 1px dashed #000;
}

.logo_website_screen:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}

.appearance-grid-container {
    margin: 1rem 10rem;
    display: grid;
    grid-template-columns: 35% 63%;
    grid-gap: 2rem;
    /* position: absolute; */
}
.appearance-btn{
    /* position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0;
    display: none; */
        border: none;
        background-color: transparent;
        border: 0px solid var(--primary-color);
        outline: none;
        padding: 0.7rem;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.4s;
        color: #fff;
        align-items: center;
        position: relative;
        background-image: linear-gradient(195deg, #42424a, #191919);
}



.appearancescreen_banner {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* height: 60rem; */
    margin-bottom: 2rem;
    width: 100%;
    background-color: #fff;
}

.appearancescreen_banner__subtitle {
    margin-top: 5rem;
    position: relative;  
    
}

.appearancescreen_banner__subtitle h1{
    font-weight: 600;
    font-size: 5rem;
}

.appearancescreen_banner__subtitle:hover{
    border: 1px dashed #000;
}

.appearancescreen_banner__subtitle:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}

.appearancescreen_banner__desc {
    width: 70%;
    margin: 2rem 0;
    position: relative;  
}



.appearancescreen_banner__desc:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 1;
    display: flex;
}

.appearancescreen_banner__desc:hover{
    border: 1px dashed #000;
}

.appearancescreen_banner__desc h1{
    font-size: 1.5rem;
    font-weight: 400;
}

.appearance-list{
    padding: 5px;
    position: relative
}

.appearance-list:hover{
    border:1px dashed #000;
}

.appearance-list:hover .appearance-btn{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 1;
    display: flex;
}

.appearance_services {
    text-align: center;
    /* padding: 3rem 30rem; */
    background-color: var(--light-grey2);
}

.our_appearance_services_outer {
    display: flex;

    grid-gap: 3rem;

    gap: 3rem;
    margin-top: 3rem;
    justify-content: center;
    flex-wrap: wrap;
}

.our_appearance_services_inner {
    display: flex;
    border-radius: 15px;
    width: 322px;
    padding: 2rem;
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column;
    background-color: var(--pure-white);
    box-shadow: var(--box-shadow1);
}

.our_appearance_services_inner:hover {
    border: 1px dashed #000;
    cursor: pointer;
    position: relative;
   }
   .our_appearance_services_inner:hover > .our_services_logo {
    background-color: black;
    color: white;
    transition: all .4s;
   }

   .our_appearance_services_inner:hover  .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 1;
    display: flex;
}

   .appearance_working_time {
    display: flex;
    margin: 5rem 14%;
    box-shadow: var(--box-shadow1);
    /* width: 100%; */
    
}

.appperance_working_time_left{
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 10rem 0;
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: var(--box-shadow1);
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    color: var(--pure-white);
}

.appperance_working_time_left:hover{
    border: 3px dashed #fff;
    position: relative;
    border-radius: 10px;
}

.appperance_working_time_left:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 1;
    display: flex;
}

.appearance_working_time_heading h1{
    font-size: 2.2rem;
    font-weight: 600;
}

.our_services_appearance_subheading{
    position: relative;
}

.appearance_working_time_heading{
    position: relative;
}

.appearance_working_time_heading:hover{
    border: 1px dashed #000;
}

.appearance_working_time_heading:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 1;
    display: flex;
}

.our_services_appearance_subheading h1 {
    font-size: 1.5rem;
    font-weight: 600;
}
    
.our_services_appearance_subheading:hover{
    border: 1px dashed #000;
    padding: 5px;
}


.our_services_appearance_subheading:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 1;
    display: flex;
}

.banner-image:hover{
border: 1px dashed #000;
}

.banner-image{
    position: relative;
}

.banner-image:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}


.appearancescreen_banner__button{
    padding: 10px;
    position: relative;
}

.appearancescreen_banner__button:hover{
    padding: 10px;
    border: 1px dashed #000;
}
.appearancescreen_banner__button:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}


.appearanceCardHeader{
 position: relative;
 color: white;
 font-size: 1.8rem;
 margin-bottom: 1rem;
 text-align: center;
}

.appearanceCardHeader:hover{
    border: 1px dashed #fff;
}

.appearanceCardHeader:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}

.apperance_servies_heading h1 {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.apperance_servies_heading{
    position: relative;
}

.apperance_servies_heading:hover{
    border: 1px dashed #000;
}

.apperance_servies_heading:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}

.appearance_our_services_subheading1 p{
    font-size: 1.4rem;
    font-weight: 400;
}

.appearance_our_services_subheading1{
    position: relative;
}

.appearance_our_services_subheading1:hover{
    border: 1px dashed #000;
}

.appearance_our_services_subheading1:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}

.apperance_chat_subheading p{
    font-size: 2rem;
    padding-top: 2rem;
    color: white;
}



.apperance_chat_subheading:hover{
    border: 1px dashed #000;
    position: relative;
}


.apperance_chat_subheading:hover .appearnce-button-container{
    position: absolute;
    right: -10px;
    top: -15px;
    opacity: 1;
    display: flex;
}


.appearanceBarText{
    position: relative;
    color: white;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: center;
   }

   .appearanceBarText h1  {
    font-size: 1.6rem;
    font-weight: 500;
}
   
   
   .appearanceBarText:hover{
       border: 1px dashed #fff;
   }
   
   .appearanceBarText:hover .appearnce-button-container{
       position: absolute;
       right: -10px;
       top: -15px;
       opacity: 1;
       display: flex;
   }

   .colorPallete{
       position: absolute;
       z-index: 500;
       top: 8rem;
   }

   .colorPalletText h3{
       font-size: 1.5rem;

   }

   .colorPalletText input{
       width: 20rem;
       height: 3rem;
       border: 1px solid #ccc;
       border-radius: 5px;
       outline: none;
       background-color: var(--light-grey2);
       font-family: inherit;
       padding: 4px;
   }
.drag-title {
    font-size: 2rem;
    text-align: center !important;
    margin-top: 10%;
    color: #4aa1f3;
    font-weight: bold;
  }
  
  .drag-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .drag-container {
    /* transform: translateY(-100%); */
}

.drag-container p {
    color: red;
    text-align: center;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 800px;
    height: 200px;
    border: 4px dashed #4aa1f3;
}

.upload-icon {
    width: 50px;
    height: 50px;
    /* background: url(../images/upload.png) no-repeat center center;  */
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.drop-message {
    text-align: center;
    color: #4aa1f3;
    font-family: Arial;
    font-size: 20px;
}

.file-display-container {
    position: fixed;
    width: 805px;
}

.file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
}

.file-status-bar > div {
    overflow: hidden;
}

.file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: #4aa1f3;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}

.file-type-logo {
    width: 50px;
    height: 50px;
    /* background: url(../images/generic.png) no-repeat center center;  */
    background-size: 100%;
    position: absolute;
}

.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.file-remove  {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
}
.viewCart--main {
 display: flex;
}
.cart--items__container {
 padding: 2rem;
 width: 70%;
}
.order--summary__container {
 width: 30%;
 padding: 2rem;
 font-size: 14px;
}
.cart--item--detail {
 display: flex;
 align-items: center;
}

.cart--items__header2 {
 display: flex;
 justify-content: space-between;
 margin-top: 2rem;
 margin-bottom: 1rem;
}
.cart--item--quantity {
 display: flex;
 grid-gap: 1rem;
 gap: 1rem;
}

.cart--items__couponContainer {
 /* padding: 1rem; */
 margin-top: 2rem;
 border-radius: 8px;
 border: 1px solid rgb(128, 128, 128);
 box-shadow: 3px 3px 10px 2px var(--light-grey);
}
.cart--items__coupons {
 padding: 1rem;
 display: flex;
 align-items: center;
 justify-content: space-between;
 flex-direction: row;
 border-bottom: 1px solid rgb(128, 128, 128);
}

.product--navigation {
    margin-left: 12rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    color: var(--light-grey);
    font-size: 14px;
}

.home--icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--light-grey);
    cursor: pointer;
}

.main--container {
    display: flex;
    justify-content: center;
}

.image--caraousel {
    width: 50%;
    display: flex;
    /* gap: 5rem; */
    margin-left: 12rem;
    grid-gap: 2rem;
    gap: 2rem;
}

.image--carousel--container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.arrow--icon {
    height: 6rem;
    width: 6rem;
    fill: var(--light-grey);
}

.product--imageContainer {
    height: 35rem;
    width: 35rem;
    position: relative;
}

.product--image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product--image-circle__container {
    padding: 2rem 0 2rem 0;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column;
    justify-content: flex-start;
}

.product__image--circle {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;

    overflow: hidden;
}

.product--InfoContainer {
    width: 50%;
    margin-right: 15rem;
    margin-left: 10rem;
    /* background-color: #c59b08; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.product--name {
    font-size: 22px;
}

.Brand-Name {
    font-weight: 700;
    font-size: 18px;
}

.product--rate {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.product--rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.product--rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    color: #ccc;
}

.product--rate:not(:checked)>label:before {
    content: "★ ";
}

.product--rate>input:checked~label {
    color: #ffc700;
}

.product--rate:not(:checked)>label:hover,
.product--rate:not(:checked)>label:hover~label {
    color: #deb217;
}

.product--rate>input:checked+label:hover,
.product--rate>input:checked+label:hover~label,
.product--rate>input:checked~label:hover,
.product--rate>input:checked~label:hover~label,
.product--rate>label:hover~input:checked~label {
    color: #c59b08;
}

.price-container {
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.save-percent {
    font-size: 16px;
}

.striked--price {
    font-size: 32px;
    text-decoration: line-through;
    color: var(--light-grey);
}

.main--price {
    font-size: 32px;
    color: var(--gold);
}

.order--notice {
    font-size: 14px;
    color: var(--gold);
    margin-bottom: 1rem;
}

.buttonContainer {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

.cart--button {
    background-image: linear-gradient(195deg, #42424a, #191919);
    border: 1px solid var(--dark-grey);
    border-radius: 0.5rem;
    font-family: inherit;
    font-size: 1.3rem;
    padding: 1rem 3rem;
    color: var(--white);
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    transition: all 0.4s;
    letter-spacing: 0.05em;
    font-weight: 600;
}

.cart--checkout__button {
    background-color: var(--gold); 
    border-radius: 0.5rem;
    font-family: inherit;
    font-size: 1.3rem;
    padding: 1rem 3rem;
    color: var(--dark-grey);
    cursor: pointer; 
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border: 2px solid #ff9000;
    letter-spacing: 0.05em;
    font-weight: 600;
}

.info--belowButton {
    display: flex;
    grid-gap: 5rem;
    gap: 5rem;
    margin-top: 2rem;
}

.info--belowButton p {
    font-size: 12px;
    font-weight: 600;
}

.fast-delivery {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.cart--icon {
    height: 2.5rem;
    width: 2.5rem;
}

.support--icon {
    height: 3rem;
    width: 3rem;
}

.description--container {
    margin-top: 5rem;
    margin-left: 10rem;
    width: 100%;
}

.description--header {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 16px;
    justify-content: flex-start;
    width: 100%;
}

.selected--header {
    cursor: pointer;
    font-weight: 600;
    -webkit-filter: drop-shadow(0px 3px 5px var(--light-grey));
            filter: drop-shadow(0px 3px 5px var(--light-grey));
    border: none;
}

.unselected--header {
    cursor: pointer;
}

.description--content {
    margin-top: 2rem;
}

.product--desc {
    width: 60%;
    font-size: 13px;
}

.product--specs {
    font-size: 13px;
}

.review--container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4rem;
    gap: 4rem;
}

.review--content {
    border-bottom: 1px solid black;
    width: 40%;
}

.reviewer-name {
    font-size: 14px;
    font-weight: 600;
}

.review--message {
    font-size: 13px;
    padding-bottom: 1rem;
}

.recommendation--card {
    text-decoration: none;
    color: currentColor;
    background-color: var(--pure-white);
    box-shadow: var(--box-shadow1);
    border-radius: 2rem;
    overflow: hidden;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .4s;
    width: 25rem;
}

.card--image--container {
    height: 15rem;
    width: 15rem;
    position: relative;
}

.recomendation--product--image {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
    border-radius: 2rem;
}

.card--slider {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10rem;
    margin-right: 10rem;
}

.item--name {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    /* padding: 0.5rem; */
}

.item--desc {
    font-size: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.price-button {
    width: 100%;
    /* padding: 1rem 2rem 0 2rem; */
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: flex-end;

}

.price-button p {
    font-size: 14px;
    color: var(--gold);
}

.recommendation--header {
    font-size: 16px;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 10rem;
}

.recommendation--cart--button {
    background-image: linear-gradient(195deg, #42424a, #191919);
    border: 1px solid var(--dark-grey); 
    border-radius: 0.5rem;
    font-family: inherit;
    font-size: 1.3rem;
    padding: 0.5rem;
    color: var(--white);
    cursor: pointer; 
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    transition: all 0.4s;
    letter-spacing: 0.5rem;
    font-weight: 600;
}

.inStock {
    background-color: var(--blue); 
    padding: 0.25rem;
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    width: 10rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outofStock {
    background-color: var(--red); 
    padding: 0.25rem;
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    width: 10rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1440px) {
    .product--navigation {
        margin: 0;
        width: 100%;
        align-items: flex-start;
    }

    .product_main_container {
        margin: 1rem 10rem;
    }

    .main--container {

        flex-direction: column;
    }

    .image--caraousel {
        margin-left: 0;

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product--image-circle__container {
        display: none;
    }

    .image--carousel--container {
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .product--InfoContainer {
        margin: 0;
        width: 100%;
        align-items: center;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }


    .description--container {
        margin: 0;
        margin-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .product--desc {
        width: 100%;
    }

    .card--slider {
        margin: 0;
    }

    .recommendation--header {
        font-size: 16px;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0;
        text-align: center;
    }

    .card--slider {
        display: flex;
        grid-gap: 2rem;
        grid-gap: 2rem;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 1000px) {
    .product_main_container {
        margin: 1rem 10rem;
        flex-direction: column;
    }

    .product--InfoContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width:600px) {
    .product_main_container {
        margin: 1rem 2rem;
        flex-direction: column;
    }

    .product--InfoContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width:300px) {
    .product_main_container {
        margin: 1rem 1rem;
        flex-direction: column;
    }
}
.TextBox_form_input__Q07T- {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: flex-start;
    width: 100%;
}

.TextBox_form_input__label__2JZxa {
    color: var(--dark-grey);
    font-size: 1.2rem;
    font-weight: 500;
}

.TextBox_form_input__container__A4wys {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: rgb(255 255 255);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    padding-left: 1rem;
    width: 25rem;
    -webkit-filter: drop-shadow(0px 0px 1px var(--light-grey));
            filter: drop-shadow(0px 0px 1px var(--light-grey));
}

.TextBox_form_input__icon__2lQse {
    height: 2rem;
    width: 2rem;
    fill: var(--dark-grey);
}

.TextBox_form_input__box__37gyz {
    font-family: inherit;
    background-color: transparent;
    color: var(--dark-grey);
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
}

.TextBox_form_input__box__37gyz::-webkit-input-placeholder {
    color: var(--light-grey);
}

.TextBox_form_input__error__3LLY6 {
    font-size: 1.2rem;
    color: #ff3366;
}


@media (max-width: 767px) {
	.TextBox_form_input__container__A4wys {
		width: 100% !important;
	} 
}
.main--containers {
    display: flex;
    justify-content: space-between;
    min-height: 60rem;
    margin: 5rem 30rem;
}

.all--address--container {
    width: 65%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.back--container {
    display: flex;
    cursor: pointer;

    align-items: center;
    margin-top: 7rem;
    margin-left: 7rem;
}

.back--container p {
    color: var(--gold);
    font-size: 12px;
}

.back-to-cart {
    height: 3rem;
    width: 3rem;
    fill: var(--gold);
}

.checkout--flex-inputs {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
    margin-top: 1rem;
}

.payment--toggle {
    /* background-color: red; */
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin-top: 2rem;
}

.input--container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.input--off {
    display: none;
}

.shipping--header {
    font-size: 16px;
    font-weight: 600;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.edit--icon--checkout {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.shpping--address {
    margin-top: 5rem;
    border-radius: 10px;
    padding: 2rem 3rem 2rem 3rem;
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #dddddd;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.order--summary--container {
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #dddddd;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 10px;
    padding: 2rem 3rem 2rem 3rem;
    width: 32%;
    height: 100%;
}

.successfull-payment{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.shipping_detail{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 10rem;
}

.sub_span{
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.ship1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item-name_new1 {
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
    /* width: 80%; */
}
.summary-items {
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
}

.summary--content {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}

.address__button-container {
    margin-top: 2rem;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

.loginmodal_forgot{
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.button--checkout {
    display: flex;
    justify-content: center;
}

.total--price {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
}

.policy--content {
    padding-top: 1rem;
    font-size: 12px;
}

.input--box {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem
}

.label--card {
    color: var(--dark-grey);
    font-size: 1.3rem;
    font-weight: 600;
}

.cardInput {
    grid-gap: 1rem;
    gap: 1rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    padding-left: 2rem;
    width: 100%;
    -webkit-filter: drop-shadow(0px 0px 1px var(--light-grey));
            filter: drop-shadow(0px 0px 1px var(--light-grey));
}


.checkOut-container {
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.675);
    border-radius: 0 1rem 1rem 0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

@media (max-width: 1024px) {
    .main--containers {
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: column;
        margin: 1rem;
    }

    .all--address--container {
        margin-top: 1rem;
        margin-left: 0;
    }

    .order--summary--container {
        width: 60%;
        margin: 2rem;
        border-radius: 10px;
        padding: 2rem;
        height: 100%;
    }

    .address__button-container {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .back--container {
        margin-left: 0.5rem;
        margin-top: 8rem
    }

    .all--address--container {
        width: 100%;

    }

    .order--summary--container {
        width: 100%;
        margin: 2rem;

    }

    .shipping_detail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        grid-gap: 2rem;
        gap: 2rem;
        margin-top: 4rem;
        align-items: center;
    }
}

.CheckOutForm_container__3EeYh {
  display: flex;
  justify-content: center;
}


.CheckOutForm_step__3Z281 {
  display: flex;
  align-items: center;
  padding-left: 15px;
  grid-gap: 1rem;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  /* width: 100%; */
  text-align: center;
  text-transform: capitalize;
}

.CheckOutForm_bullet__2g0YT {
  position: relative;
  width: 3rem;
  height: 3rem;
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  color: #000;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.CheckOutForm_step__3Z281 p {
  font-size: 12px;
  text-transform: capitalize;
}

.CheckOutForm_bullet__2g0YT::before,
.CheckOutForm_bullet__2g0YT::after {
  position: absolute;
  content: '';
  bottom: 11px;
  right: 25px;
  height: 6px;
  width: 90px;
  background: #c4c4c4;
  z-index: -1; 
}

.CheckOutForm_step__3Z281:first-child .CheckOutForm_bullet__2g0YT::before,
.CheckOutForm_step__3Z281:first-child .CheckOutForm_bullet__2g0YT::after {
  display: none;
}

.CheckOutForm_Complete__274dp .CheckOutForm_bullet__2g0YT {
  width: 3rem;
  height: 3rem;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #0000;
}

.CheckOutForm_Complete__274dp .CheckOutForm_bullet__2g0YT::after {
  position: absolute;
  content: '';
  bottom: 11px;
  right: 25px;
  height: 6px;
  width: 90px;
  background: #000;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-animation: CheckOutForm_animate__3NIDn 0.8s linear forwards;
          animation: CheckOutForm_animate__3NIDn 0.8s linear forwards;
}

@-webkit-keyframes CheckOutForm_animate__3NIDn {
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1)
  }
}

@keyframes CheckOutForm_animate__3NIDn {
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1)
  }
}
.homescreen_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 60rem; */
    padding: 2rem 0rem;
    grid-gap: 25rem;
    gap: 25rem;
    /* margin-top: 8rem; */
    width: 100%;

    /* margin-top: 5rem; */
}

.filter__input--icon1 {
    height: 4rem;
    width: 4rem;
    fill: black;
    color: black;
    margin: 1rem 1rem;
}

.homescreen_motive {
    /* width: 40%; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.img-responsive {
    width: 27vw;

    /* height: 35vw; */
    /* height: auto; */
}

.homesceeen_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* padding-left: 10rem; */
}

.salon_product {
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.product_img {
    background: linear-gradient(45deg, #ffbc61b3, #fffe7c80);
    align-items: center;
    /* opacity: .7; */
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 5rem;
    border: 0px solid rgb(175, 163, 163);
    box-shadow: var(--box-shadow-input);
    height: 8rem;
    width: 8rem;
    align-items: center;
    justify-content: center;
}

.salon_images {
    display: flex;
    border-radius: 50%;
    width: 6rem;
}

.salon_images_text {
    font-size: 1.5rem;
}

.homescreen_banner_button {
    background-color: var(--sky-blue);
    border: 0px;
    color: var(--pure-white);
    height: 5rem;
    width: 25rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.homescreen_banner_button:hover {
    background-color: var(--black);
    border: 0px;
    color: var(--pure-white);
    height: 5rem;
    width: 25rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.monthly_bundle_text {
    font-size: 1.6rem;
    font-weight: 400;
}

.hero--image--container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.monthly_bundle {
    background: linear-gradient(45deg, #ffbc61b3, #fffe7c80);
    position: absolute;
    align-items: center;
    /* opacity: .7; */
    color: black;
    width: 32rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 3rem;
    border: 0px solid rgb(175, 163, 163);
    bottom: 20%;
    box-shadow: var(--box-shadow-input);
    /* height: 10rem; */
}

/* .monthly_bundle {
    background: linear-gradient(45deg, #ffbc61b3, #fffe7c80);
    align-items: center;
    color: black;
    width: 32rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 3rem;
    border: 0px solid rgb(175, 163, 163);
    margin-top: 46rem;
    margin-left: -80rem;
    box-shadow: var(--box-shadow-input);
   } */
.homescreen_banner_outer {
    display: flex;
    width: 40%;
    justify-content: flex-start;
    flex-direction: column;
}

.monthly_bundle_price {
    font-size: 4rem;
    font-weight: 600;
}

.homescreen_banner__subtitle {
    font-weight: 500;
    font-size: 4rem;
    margin-top: 5rem;
}

.homescreen_banner__title {
    font-weight: 300;
}

.homescreen_banner__desc {
    width: 70%;
    font-weight: 400;
    margin: 2rem 0;
    font-size: 1.5rem;
}

.homescreen_banner_left_padding {
    padding: 0rem 55rem 0rem 18rem;
}

.homescreen_products {
    background-color: #000;
    height: 28rem;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 2rem 10rem;
}

.homescreen--bestProducts {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.CardHeader {
    background: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.product_card--layout {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 30rem;
    cursor: pointer;
}

.product-container {
    display: flex;
    justify-content: space-between;
}

.porduct-desc {
    display: flex;
    flex-direction: column;
    width: 20rem;
    align-items: flex-start;
}

.product_card {
    width: 10rem;
    border-radius: 8rem;
    height: 10rem;
    border: 2px solid #e7e7e7;
    box-shadow: var(--box-shadow-input);
    object-fit: cover;
}

.productCard__title {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
}

/* stars */
.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: "★ ";
}

.rate>input:checked~label {
    color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #c59b08;
}

.img-responsive1 {
    display: none;
}

.services {
    text-align: center;
    padding: 3rem 3rem;
    background-color: var(--light-grey2);
}

.servies_main_heading {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.our_services_outer {
    display: flex;
    grid-gap: 3rem;
    grid-gap: 3rem;
    gap: 3rem;
    margin-top: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px;
}

.our_services_inner {
    display: flex;
    border-radius: 15px;
    width: 42rem;
    padding: 2rem;
    flex-direction: column;
    background-color: var(--pure-white);
    box-shadow: var(--box-shadow1);
}

.our_services_inner:hover {
    transition: all .4s;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
}

.our_services_inner:hover>.our_services_logo {
    background-color: black;
    color: white;
    transition: all .4s;
}

.our_services_logo {
    background-color: var(--light-grey2);
    width: 10rem;
    height: 10rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
}

.our_services_heading {
    font-size: 1.8rem;
    font-weight: 700;
}

.our_services_subheading {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
}

.our_services_subheading1 {
    font-size: 1.4rem;
    font-weight: 400;
}

.our_services_subheading2 {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: start;
}

.working_time {
    display: flex;
    padding: 5rem 30rem;
    box-shadow: var(--box-shadow1);
    width: 100%;
}

.working_time_left {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 10rem 0;
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: var(--box-shadow1);
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    color: var(--pure-white);
}

.days_we_work {
    display: flex;
    background-color: rgba(127, 128, 118, 0.247);
    width: 35rem;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.days_we_work-saturday {
    padding: 1rem 2rem;
    display: flex;
    width: 35rem;
    justify-content: space-between;
}

.days_we_work-sunday {
    padding: 1rem 2rem;
    display: flex;
    width: 35rem;
    justify-content: space-between;
    background-color: rgba(127, 128, 118, 0.247);
    color: gold;
}

.working_time_right {
    background-color: var(--pure-white);
    width: 50%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: var(--box-shadow1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
}

.working_time_heading {
    font-size: 2.2rem;
    font-weight: 600;
}

.hr {
    width: 35rem;
    margin: 3rem 0;
}

.bookings {
    display: flex;
    width: 35rem;
    justify-content: space-between;
    flex-direction: flex-start;
}

.chat_section {
    padding: 2rem 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.chat_subheading {
    font-size: 2rem;
    padding-top: 2rem;
}

.chat_contact_details {
    display: flex;
    padding: 2rem 30rem;
    justify-content: space-between;
    text-align: center;
    align-self: center;
    align-items: center;
}

.calling {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    width: 20rem;
}

.calling:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all .4s;
    cursor: pointer;
}

.all_products {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.all_products_button {
    padding: 1rem;
    display: inline;
    font-size: 1.2rem;
    font-weight: 700;
    text-decoration: none;
    background-color: transparent;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    border: none;
}


/* .all_products_button:hover {
    transition: all 0.3s;
    transform: scaleY(1.1);
   } */
/*Mobile View */
@media (max-width: 1400px) {
    .homescreen_banner {
        padding: 0 0rem;
    }

    .homesceeen_right {
        padding-left: 5rem;
    }

    .homescreen_products {
        padding: 2rem 20rem;
    }

    .homescreen_products {
        padding: 2rem 8rem;
    }

    .working_time {
        padding: 2rem 20rem;
    }

    .chat_contact_details {
        padding: 3rem 20rem;
    }

    .chat_section {
        padding: 3rem 20rem;
    }

    .monthly_bundle {
        margin-left: -50rem;
    }

    .all_products_button {
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-self: center;
    }

    .product_card {
        width: 7rem;
        border-radius: 8rem;
        height: 7rem;
        border: 2px solid #e7e7e7;
        box-shadow: var(--box-shadow-input);
        object-fit: cover;
    }

    .product_card--layout {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 25rem;
        cursor: pointer;
    }

    .product-container {
        display: flex;
        justify-content: space-between;
    }

    .porduct-desc {
        display: flex;
        flex-direction: column;
        width: 18rem;
        align-items: flex-start;
    }
}

@media (max-width: 1230px) {
    .homescreen_banner {
        padding: 0rem 0rem;
    }

    .contact_input input{
        width: inherit !important;
    }

    .contact_button_container{
        width: inherit !important;
    }

    .contact_input textarea{
        width: inherit !important;
    }

    .homescreen_banner__subtitle {
        font-weight: 500;
        font-size: 3rem;
    }

    .homescreen_products {
        padding: 2rem 10rem;
    }

    .services {
        padding: 3rem 10rem;
    }

    .working_time {
        padding: 2rem 10rem;
    }

    .chat_contact_details {
        padding: 3rem 10rem;
    }

    .chat_section {
        padding: 3rem 10rem;
    }

    .monthly_bundle {
        margin-left: -45rem;
        margin-top: 40rem;
        width: 20rem;
    }
}


@media (max-width: 900px) {
    .homescreen_banner {
        padding: 0rem 0rem;
        display: inherit;
        width: 100%;
    }
    .navigation__link {
        font-size: 1.2rem;
    }

    .navigation_website {
        margin-left: 22%;
      }

    .homescreen_banner_outer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
      }

      .homescreen_motive {
        width: 100%;
        padding: 10rem 2rem;
      }

    .img-responsive{
        display: none;
    }

    .new_book{
        display: none;
    }

    .homescreen_banner__subtitle {
        font-weight: 500;
        font-size: 4rem;
        margin-top: 0rem;
        line-height: 1;
    }

    .img-responsive {
        width: 45vw;
    }

    .homescreen_products {
        width: 100vw;
        padding: 2rem 1rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .all_products {
        justify-content: space-around;
    }

    .product-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-gap: 4rem;
        gap: 4rem;

    }

    .services {
        /* display: none; */
        padding: 3rem 3rem;
        width: 100vw;
    }

    .service_list {
        display: flex;
        grid-gap: 1rem;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
        flex-wrap: wrap;
        overflow: scroll;
        justify-content: flex-start;
    }

    .our_services_outer {
        display: flex;
        grid-gap: 3rem;
        grid-gap: 3rem;
        grid-gap: 3rem;
        gap: 3rem;
        margin-top: 3rem;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px;
    }

    .working_time {
    display: flex;
    padding: 2rem;
    box-shadow: var(--box-shadow1);
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }

  .working_time_left {
    padding: 5rem 2rem;
    width: 100%;
    border-radius: 10px;
  }

    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }

    .contact_input_container {
        width: 90%;
    }

    .contact_input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .contact_input input {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_input textarea {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_button_container {
        width: 100vw !important;
        justify-content: center !important;
    }

    .chat_contact_details {
        padding: 3rem 3rem;
        flex-direction: column;
    }

    .calling {
        width: 20rem;
    }

    .our_services_subheading {
        overflow-wrap: break-word;
    }

}

@media (max-width: 500px) {
    .homescreen_banner {
        display: inherit;
        padding: 0rem 0rem;
        width: 100%;
    }

    .homescreen_banner_outer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
    }

    .homescreen_motive {
        width: 100%;
        padding: 10rem 2rem;
    }

    .homescreen_banner__subtitle {
        font-weight: 600;
        font-size: 4rem;
        margin-top: 0rem;
        line-height: 1;
    }



    .img-responsive {
        display: none;
    }

    .product-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-gap: 4rem;
        gap: 4rem;
    }

    .service_list {
        overflow-x: scroll;
        justify-content: flex-start !important;
    }

    .servies_main_heading {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .our_services_outer {
        display: flex;
        grid-gap: 3rem;
        grid-gap: 3rem;
        grid-gap: 3rem;
        gap: 3rem;
        margin-top: 3rem;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px;
    }

    .service_list ::-webkit-scrollbar {
        width: 0 !important;
        /* Remove scrollbar space */
        background: transparent !important;
        /* Optional: just make scrollbar invisible */
    }

    /* Optional: show position indicator in red */
    .service_list ::-webkit-scrollbar-thumb {
        background: transparent !important;
    }


    .working_time {
        display: flex;
        padding: 2rem;
        box-shadow: var(--box-shadow1);
        width: 100vw;
        flex-direction: column;
        align-items: center;
    }

    .working_time_left {
        padding: 5rem 2rem;
        width: 100%;
        border-radius: 10px;
    }

    .working_time_right {
        width: 100%;
        margin-top: 2rem;
        border-radius: 10px;
        padding: 3rem 1rem;
    }

    .contact-container {
        width: 100vw;
    }

    .contact_input_container {
        width: 90%;
    }

    .contact_input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .contact_input input {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_input textarea {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_button_container {
        width: 100vw !important;
        justify-content: center !important;
    }

    .chat_section {
        padding: 3rem 3rem;
    }

    .chat_subheading {
        font-size: 1.5rem;
        padding-top: 2rem;
    }
}


.service_list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
}

.serviceBtn {
    padding: 10px 25px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 20px;
    color: #000;
    outline: none;
    cursor: pointer;
}

.serviceBtn:hover {
    background-color: rgb(224, 224, 224);
    color: #fff;
}

.activeBtn {
    padding: 10px 25px;
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    border: none;
}

.text_price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  grid-gap: 2rem;
  gap: 2rem;

}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_input_container {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contact_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact_input label {
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
}

.contact_input input {
    width: 50rem;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #000;
    outline: none;
    padding: 10px;
    font-family: inherit;
    font-size: 1.5rem;
}

.contact_input textarea {
    width: 102rem;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #000;
    outline: none;
    padding: 10px;
    font-family: inherit;
    font-size: 1.5rem;
}

.contact_button_container {
    margin: 2rem 0;
    display: flex;
    width: 102rem;
    justify-content: flex-start;
}

.contact_button_container button {
    background-color: #000;
    border: 1px solid var(--dark-grey);
    border-radius: 0.5rem;
    font-family: inherit;
    font-size: 1.3rem;
    padding: 1rem 3rem;
    color: var(--white);
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    transition: all .4s;
}

.contact_button_container button:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}

.footer_container {
    background-color: #000;
    width: 100%;
    height: 5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* bottom: 10px; */
    grid-gap: 3px;
    gap: 3px;
}

.footer_container a {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 600;
}
.usersignup-container{
    width: 100%;
    height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userSP_box{
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 1rem;
  /* background-color: white; */
  padding:0 20px;
  background-color: rgba(255, 255, 255, 0.675);
      /* opacity: 0.8; */
    border-radius:1rem;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}
.main--card__Container {
    /* background-color: red; */
    /* background-color: var(--bg-secondary); */
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.single__discountCard {
    /* background-color: var(--pure-white); */
    background-color:  #121212;
    width: 18vw;
    min-height: 15vh;
    border-radius: 10px;
    box-shadow: var(--box-shadow1);
}

.card--coupons {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
}

.card--coupons h1 {
    font-size: 30px;
    color: var(--pure-white);
}

.card--coupons p {
    font-size: 14px;
    color: var(--yellow);
    font-weight: 400;
}

.card--coupons h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--pure-white)
}

.coupon--card_bottom {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coupon--edit_delete {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    fill: var(--pure-white);
}

.cardCouponIcon__button {
    background-color: transparent;
    border: none;
    border: 0px solid var(--primary-color);
    outline: none;
    padding: .7rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    position: relative;
    /* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.cardCouponIcon__button span {
    display: none;
    font-size: 1rem;
    color: var(--pure-white);
    background-color: var(--black);
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    position: absolute;
    top: -2rem;
    /* left: 0; */
    /* transform: translate(-50%, -50%); */
    transition: all 1s;
    right: 1rem;
    z-index: 1000;
}

.cardCouponIcon__button:hover span {
    display: block;
}

.cardCouponIcon__button:hover {
    background-color: var(--primary-color);
    border: 0px solid var(--primary-color);
}

.edit--coupon_icon {
    height: 2rem;
    width: 2rem;
    fill: var(--pure-white);
}

.cardCoupon--icon-red {
    height: 2rem;
    width: 2rem;
    fill: var(--red);
}

/* Modal Styles */

.delete--modal--text {
    font-size: 12px;
    padding-bottom: 1rem;
}
.form{
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 767px) {
    .form{
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1088px) {
	.form{
		height: 62vh!important;
		overflow:auto;
	}
}
.Packages_pricing_cards__NmbtW {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.Packages_pricing_card__2X3V7 {
	padding: 2rem 3rem;
	background-color:#ffffff;
	/* box-shadow: ; */
	border-radius: 2rem;
}
.Packages_pricing_header__2UUIm {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.Packages_pricing_card_icon_box__1Jr4n {
	height: 3rem;
	width: 3rem;
	background-color: var(--primary-color);
	border-radius: 1rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_icon__39NS3 {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}
.Packages_pricing_card_heading__1aIEF {
	font-size: 2rem;
	font-weight: 700;
}
.Packages_pricing_card_price__2PS1U {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	font-size: 1.3rem;
	color: var(--grey);
}
.Packages_pricing_card_price_amount__2LhSt {
	font-size: 2rem;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
}
.Packages_pricing_card_features__17FuT {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}
.Packages_pricing_card_feature__2UHKb {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.Packages_pricing_card_feature_icon_box__2bAiJ {
	height: 2rem;
	width: 2rem;
	background-color: var(--black);
	border-radius: .7rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_feature_icon__jmCp- {
	height: 1.2rem;
	width: 1.2rem;
	fill: var(--white);
}
.Packages_pricing_card_feature_text__3_Vrg {
	font-size: 1.3rem;
	font-weight: 600;
}

.Packages_pricing_card_actions__11-D_ {
	margin-top: 2rem;
	border-top: 1px solid var(--light-grey);
	padding-top: 1rem;

	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	justify-content: flex-end;
}
.Packages_pricing_card_action_btn__3JYtq,
.Packages_pricing_card_action_btn_delete__3YooC {
	padding: .5rem;
	border-radius: .8rem;
	cursor: pointer;
	transition: all .4s;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_action_btn__3JYtq {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
}
.Packages_pricing_card_action_icon__1sDR6 {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}
.Packages_pricing_card_action_btn__3JYtq:hover,
.Packages_pricing_card_action_btn_delete__3YooC:hover {
	background-color: transparent;
}
.Packages_pricing_card_action_btn__3JYtq:hover .Packages_pricing_card_action_icon__1sDR6 {
	fill: var(--primary-color);
}
.Packages_pricing_card_action_btn_delete__3YooC {
	background-color: var(--red);
	border: 1px solid var(--red);
}
.Packages_pricing_card_action_btn_delete__3YooC:hover .Packages_pricing_card_action_icon__1sDR6 {
	fill: var(--red);
}
.Packages_pricing_card_add_plan_btn__24_S6 {
	font-family: inherit;
	background-color: transparent;
	border: 1px solid var(--primary-color);
	cursor: pointer;

	font-size: 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	margin-left: 3rem;
}

.login-container {
    margin: 10rem 30rem;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 2rem;
    justify-content: center;
}

.login-container>div {
    background-color: #fff; 
    border-radius: 5px;
    padding: 30px;
    border: 1px solid #dddddd;
    line-height: 1;
}

.login-left-inside{
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.login-container .modalContainer__form--section{
    padding: 0 !important;
    margin-bottom: 3rem;
}

.forgot-password{
    width: 25rem;
    justify-content: center;
    background: transparent;
    border: 0;
    text-align: start;
    cursor: pointer;
    margin-left: 0.5rem;
    font-weight: 600;
}


.forgot-password:hover{
   text-decoration: underline;
}


@media (max-width: 1400px) {
    .login-container {
        margin: 10rem 10rem;
    }

}

@media (max-width: 1230px) {
    .login-container {
        margin: 10rem 1rem;
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
        align-items: center;
    }

    .login-container-left {
        order: -1;
        width: 90vw;
    }

    .login-container-right {
        width: 90vw;
    }

}

@media (max-width: 768px) {
    .login-container {
        margin: 10rem 1rem;
        /* width: 100%; */
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
    }

    .login-container>.item5 {
        padding: 2rem 0.5rem;
    }

    .login-container-left {
        order: -1;
    }

    /* .login-container-right {
        width: 90vw;
    }  */
}
.TextBox_form_input__1WE94 {
	display: flex;
	flex-direction: column;
	grid-gap: 1.5rem;
	gap: 1.5rem;
	align-items: flex-start;
}

.TextBox_form_input__label__3Ca_r {
	color: var( --lightest-white);
	font-size: 1.5rem;
	margin-top: 1rem;
	/* margin-left: 2rem; */
}

.TextBox_form_input__container__25kc7 {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	/* background-color: var(--white); */
	/* border-radius: 1rem; */
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
	/* padding-bottom: 3rem; */
	border-bottom: 1px solid var( --light-grey);
	/* margin: 2.3rem; */
}

.TextBox_form_input__icon__wAPeS {
	height: 2rem;
	width: 2rem;
	fill: #756969;
}

.TextBox_form_input__box__1wWbD {
	font-family: inherit;
  background-color: transparent;
  color: #363636;
  border: none;
  outline: none;  
  width: 100%;
  height: 100%;
  fill: white;
}
input[type="time"]::-webkit-calendar-picker-indicator{
	-webkit-filter: invert(100%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
	        filter: invert(100%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
  }
.TextBox_form_input__box__1wWbD::-webkit-input-icon {
	color: var(--lightest-white) !important;
}
.TextBox_form_input__error__3YKxk {
	font-size: 1.2rem;
	color: #ff3366;
	width: 25rem;
}


.TextBox_form_input_coupon_box__3amLi::hover {
	 border:none !important;
}

.TextBox_form_input_coupon_box__3amLi {
	border:none !important;
}


@media (max-width: 767px) {
	.TextBox_form_input__container__25kc7 {
		width: 100%;
	} 
}

@media only  screen and (max-width: 767px){
	.TextBox_form_input__1WE94{
		min-width: 9rem;
	}
}
.slider1 {
 width: 100%;
 height: 35rem;
 /* margin-right: 30rem; */
 margin-top: 2rem;
 border: none;
 background-color: var(--pure-white);
}
.slider_images {
 width: 99.8rem;
 height: 30.8vh;
}

.slides {
 padding: 0;
 width: 100rem;
 height: 35rem;
 display: block;
 margin: 0 0;
 position: relative;
}

.slides * {
 user-select: none;
 -ms-user-select: none;
 -moz-user-select: none;
 -khtml-user-select: none;
 -webkit-user-select: none;
 -webkit-touch-callout: none;
}

.slides input {
 display: none;
}

.slide-container {
 display: block;
}
.slide {
 top: 0;
 opacity: 0;
 width: 100rem;
 height: 35rem;
 display: block;
 position: absolute;
 -webkit-transform: scale(0);
         transform: scale(0);
 transition: all 0.7s ease-in-out;
}

.slide img {
 width: 100rem;
 height: 35rem;
 transition: all 0.3s;
}

.nav label {
 display: none;
 position: absolute;
 opacity: 0;
 z-index: 9;
 cursor: pointer;
 transition: opacity 0.2s;
 color: rgb(0, 0, 0);
 font-size: 8rem;
 line-height: 35rem;
 font-family: "Varela Round", sans-serif;
 text-shadow: 0px 0px 15px rgb(255, 255, 255);
}

.slide:hover + .nav label {
 opacity: 0.5;
}

.nav label:hover {
 opacity: 1;
}

.nav .next {
 right: 0;
}

input:checked + .slide-container .slide {
 opacity: 1;
 -webkit-transform: scale(1);
         transform: scale(1);
 transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .nav label {
 display: block;
}

.nav-dots {
 width: 100rem;
 bottom: 10px;
 height: 11px;
 display: block;
 position: absolute;
 text-align: center;
}

.nav-dots .nav-dot {
 top: -5px;
 width: 2rem;
 height: 2rem;
 margin: 0 4px;
 position: relative;
 border-radius: 100%;
 display: inline-block;
 background-color: rgba(247, 243, 243, 0.6);
}

.nav-dots .nav-dot:hover {
 cursor: pointer;
 background-color: rgba(0, 0, 0, 0.8);
}

input#img-1:checked ~ .nav-dots label#img-dot-1,
input#img-2:checked ~ .nav-dots label#img-dot-2,
input#img-3:checked ~ .nav-dots label#img-dot-3,
input#img-4:checked ~ .nav-dots label#img-dot-4,
input#img-5:checked ~ .nav-dots label#img-dot-5,
input#img-6:checked ~ .nav-dots label#img-dot-6 {
 background: rgba(0, 0, 0, 0.8);
}

.order_history {
    width: 100%;
    height: 80vh;
    padding: 5rem 30rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    overflow-y: auto;
}

.order-container {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    cursor: pointer;
    background-color: #fff;
}

.order-container:hover {
    background-color: rgb(244, 244, 244);
}

.titleOrder {
    width: 40%;
}

.order-status-complete {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 10%;
    font-size: 12px;
    color: #fff;
    background-color: green;
}

.order-status-pending {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 10%;
    font-size: 12px;
    color: #fff;
    background-color: var(--primary-color);
}

.order-container span {
    font-size: 14px;
    font-family: 600;
    color: var(--primary-color);
}

.order-image img {
    width: 8rem;
    height: 8rem;
    border-radius: 1rem;
}


@media screen and (max-width:950px) {
    .order_history {
        width: 100%;
        height: 70vh;
        padding: 5rem 5rem;
    }

    .shipping_detail {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        justify-content: flex-start !important;
        width: 100%;
        grid-gap: 2rem;
        grid-gap: 2rem;
        gap: 2rem;
        margin-top: 4rem;
        align-items: flex-start !important;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:600px) {
    .order_history {
        width: 100%;
        height: 70vh;
        padding: 5rem 2rem;
    }

    .order-container {
        flex-direction: column;
        padding: 1rem 1rem;
        align-items: flex-start;
        grid-gap: 1rem;
        gap: 1rem;

    }

    .order-image {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .titleOrder {
        width: 100%;
    }


}
    .discount_main_container {
        display: flex;
        grid-gap: 2rem;
        grid-gap: 2rem;
        gap: 2rem;
        flex-wrap: wrap;
        margin: 5rem 30rem;
        align-items: center;
        justify-content: center;
    }

    .couponCard {
        background-color: var(--pure-white);
        width: 18vw;
        min-height: 15vh;
        border-radius: 10px;
        box-shadow: var(--box-shadow1);
        border: 2px dashed #d5d5d5;
        padding: 25px 30px 30px;
        align-items: center;
        width: 18vw;
        min-height: 15vh;
        display: flex;
        flex-direction: column;
    }

    .couponCard h1 {
        font-size: 30px;
    }

    .couponCard p {
        font-size: 14px;
        color: var(--yellow);
        font-weight: 400;
    }

    .couponCard h4 {
        font-size: 14px;
        font-weight: 600;
    }


    @media screen and (max-width:900px) {
        .discount_main_container {
            margin: 2rem 0rem;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .couponCard {
            margin: 1rem;
            width: 100%;
        }
    }
.PromoCodes_promoCodes__1uvWX {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1rem;
	gap: 1rem;
}
.PromoCodes_promoCodeCard__3eQ7y {
	background-color: var(--white);
	padding: 2rem 3rem;
	border-radius: 2rem;

	text-align: center;
}
.PromoCodes_promoCodeCard_title__2aHfR {
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;
	min-width: 20rem;
	line-height: 1.4;
}
.PromoCodes_promoCodeCard_validity__2f-2u {
	font-size: 1.3rem;
	font-weight: 600;
}
.PromoCodes_promoCodeCard_code__21s0t {
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--primary-color);
}
.PromoCodes_promoCodeCard_code__21s0t span {
	font-weight: 800;
}
.PromoCodes_promoCodeCard_actions__3wA1w {
	margin-top: 1.5rem;
	border-top: 1px solid var(--light-grey);
	padding-top: 2rem;

	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	justify-content: center;
}
.PromoCodes_promoCodeCard_action_button__35t6o,
.PromoCodes_promoCodeCard_action_button_delete__yi0iH {
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .5rem;
	cursor: pointer;
	transition: all .4s;
}
.PromoCodes_promoCodeCard_action_button__35t6o {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
}
.PromoCodes_promoCodeCard_action_button_delete__yi0iH {
	background-color: var(--red);
	border: 1px solid var(--red);
}
.PromoCodes_promoCodeCard_action_buttonicon__2deDW {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}
.PromoCodes_promoCodeCard_action_button__35t6o:hover {
	background-color: transparent;
}
.PromoCodes_promoCodeCard_action_button_delete__yi0iH:hover {
	background-color: transparent;
}

.PromoCodes_promoCodeCard_action_button__35t6o:hover .PromoCodes_promoCodeCard_action_buttonicon__2deDW {
	fill: var(--primary-color);
}
.PromoCodes_promoCodeCard_action_button_delete__yi0iH:hover .PromoCodes_promoCodeCard_action_buttonicon__2deDW {
	fill: var(--red);
}

.main--card__Container {
    /* background-color: red; */
    /* background-color: var(--bg-secondary); */
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.single__discountCard {
    /* background-color: var(--pure-white); */
    background-color:   #121212;
    width: 18vw;
    min-height: 15vh;
    border-radius: 10px;
    box-shadow: var(--box-shadow1);
}

.card--coupons {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
}

.card--coupons h1 {
    font-size: 30px;
    color: var(--pure-white);
}

.card--coupons p {
    font-size: 14px;
    color: var(--yellow);
    font-weight: 400;
}

.card--coupons h4 {
    font-size: 14px;
    font-weight: 600;
    color: var(--pure-white)
}

.coupon--card_bottom {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coupon--edit_delete {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    fill: var(--pure-white);
}

.cardCouponIcon__button {
    background-color: transparent;
    border: none;
    border: 0px solid var(--primary-color);
    outline: none;
    padding: .7rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    position: relative;
    /* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.cardCouponIcon__button span {
    display: none;
    font-size: 1rem;
    color: var(--pure-white);
    background-color: var(--black);
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    position: absolute;
    top: -2rem;
    /* left: 0; */
    /* transform: translate(-50%, -50%); */
    transition: all 1s;
    right: 1rem;
    z-index: 1000;
}

.cardCouponIcon__button:hover span {
    display: block;
}

.cardCouponIcon__button:hover {
    background-color: var(--primary-color);
    border: 0px solid var(--primary-color);
}

.edit--coupon_icon {
    height: 2rem;
    width: 2rem;
    fill: var(--pure-white);
}

.cardCoupon--icon-red {
    height: 2rem;
    width: 2rem;
    fill: var(--pure-white);
}

/* Modal Styles */

.delete--modal--text {
    font-size: 12px;
    padding-bottom: 1rem;
    color : var(--pure-white)
}

@media (max-width: 809px) {

    .single__discountCard{
        width: 46vw;
    }

    .card--coupons h1{
        font-size: 2rem;
    }
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	.main--card__Container{
		grid-gap: 3rem;
		gap: 3rem;
    padding-left: 2rem;
	}

	.single__discountCard{
	width: 30vw;
	}
}
.ProgressFormTab_container__2U3Bd{
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-right: 2rem;
}

.ProgressFormTab_salonRegister__LlWFA{
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
}

.ProgressFormTab_step__3BgtS{
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: white;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  width: 100px;
  text-align: center;
}

/* .bullet{
    width: 3rem;
    height: 3rem;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    font-size: 16px;
    display: flex;
    
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}



.Complete .bullet{
    width: 3rem;
    height: 3rem;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

} */

.ProgressFormTab_bullet__1-yDd{
    position: relative;
    width: 3rem;
    height: 3rem;
    background-color: #c4c4c4;
    border: 1px solid #c4c4c4;
    color: #121212;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

  }

  .ProgressFormTab_step__3BgtS p{
    font-size: 10.2px;
    text-transform: uppercase;
}
  
  /* .step:last-child .bullet::before,
  .step:last-child .bullet::after{
    display: none;
  } */
  
  .ProgressFormTab_bullet__1-yDd::before,
  .ProgressFormTab_bullet__1-yDd::after{
    position: absolute;
    content: '';
    bottom: 11px;
    right: 20px;
    height: 6px;
    width: 90px;
    background: #c4c4c4;
    z-index: -1;

  }
  
  .ProgressFormTab_step__3BgtS:first-child .ProgressFormTab_bullet__1-yDd::before,
  .ProgressFormTab_step__3BgtS:first-child .ProgressFormTab_bullet__1-yDd::after{
    display: none;
  }
  
  /* .Complete{
    text-align: center;
    width: 262px;
  }
   */
  .ProgressFormTab_Complete__1rt1m .ProgressFormTab_bullet__1-yDd{
    /* background-color: var(--primary);
    border: 2px solid var(--primary);
    color: #fff; */
    width: 3rem;
    height: 3rem;
    background-color: rgb(255, 144, 0);
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #0000;
  }
  
  .ProgressFormTab_Complete__1rt1m .ProgressFormTab_bullet__1-yDd::after{
    position: absolute;
    content: '';
    bottom: 11px;
    right: 20px;
    height: 6px;
    width: 90px;
    background: rgb(255, 144, 0);
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-animation: ProgressFormTab_animate__kLEoy 0.8s linear forwards;
            animation: ProgressFormTab_animate__kLEoy 0.8s linear forwards;
  }
  
  @-webkit-keyframes ProgressFormTab_animate__kLEoy {
    100%{
      -webkit-transform: scaleX(1);
              transform: scaleX(1)
    }
  }
  
  @keyframes ProgressFormTab_animate__kLEoy {
    100%{
      -webkit-transform: scaleX(1);
              transform: scaleX(1)
    }
  }

  @media (max-width:500px){
    .ProgressFormTab_container__2U3Bd{
      display: flex;
      justify-content: center;
      grid-gap: 1rem;
      gap: 1rem;
      margin-right: 1rem;
  } 

  .ProgressFormTab_step__3BgtS{
    padding-left: 12px;
  }
  .ProgressFormTab_onboard_down__CiaGc {
    margin-top: 0rem;
  }
  }
.onboarding {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-container {
  width: 85%;
  height: auto;
  border-radius: 1rem;
  display: flex;
}

.onboarding_price {
  display: flex;
  justify-Content: space-between;
  flex-Direction: "row";
  width: 100%
}

.onboard_img {
  width: 40%;
  background-image: url(/static/media/background_onboard.e6df642b.png);
  background-size: cover;
  border-radius: 4rem 0 0 4rem;
  background-repeat: no-repeat;
}

.onboard_container {
  width: 70%;
  padding: 8rem 5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
  background-color: #121212e8;
  /* opacity: 0.8; */
  border-radius: 0 4rem 4rem 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  height: 62rem;
}

.onboard_form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.onboard_form_input{
  width: 100%;
  display: flex;
  grid-gap: 6rem;
  gap: 6rem;
}

.onboard_baseInput{
width: 85%;
}

.onbaord_nodata {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 5rem;
}


.onboard_input {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
}

.form_section {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.price_details {
  width: 100%;
}

.price_row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.price_label {
  color: #fff;
  font-weight: normal;
}

.price_value {
  color: #ff9000;
  font-weight: normal;
}

.original_price {
  color: #b7894d;
  font-weight: normal;
  text-decoration: line-through;
  margin-right: 10px;
}

.discounted_price {
  color: #ff9000;
  font-weight: normal;
}

.coupon_info {
  font-size: 12px;
}

.divider {
  border-bottom: 0.5px solid #555;
  width: 100%;
  margin-top: 13px;
}

.total_row {
  margin-top: 10px;
}

.total_label {
  color: #fff;
  font-size: 20px;
}

.total_value {
  color: #ff9000;
  font-size: 20px;
}

.onboard_button {
  margin-top: 20px;
}

.prev {
  margin-right: 10px;
}


.onboard_input label {
  font-size: 12px;
  color: white;
  transition: all .4s;
}

.onboard_input input {
  font-size: 12px;
  width: 95%;
  height: 40px;
  padding: 1rem;
  border-radius: 20px;
  background-color: #e7e7ea;
  transition: all .4s;
  font-family: inherit;
  outline: none;
  border: none;
  box-shadow: 0rem 0.2rem 2rem -1rem black;
}

.onboard_card_input {
  width: 100%;
  height: 40px;
  padding: 1rem;
  border-radius: 5px;
  border: none;
  background-color: '#3D3D3D'; /* Transparent black */
  transition: all .4s;
  border: 1px solid rgba(255, 255, 255, 0.37);
}


.onboard_input textarea {
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  height: 60px;
  padding: 1rem;
  border-radius: 5px;
  background-color: #e7e7ea;
  transition: all .4s;
  outline: none;
  border: 1px solid #000;
}

.onboard_input textarea:hover,
.onboard_input input:hover {
  border: 3px solid #000;
}

.form_container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 28rem;
  overflow-y: auto;
  justify-content: flex-start;
  overflow-x: hidden;
}

.abcd{
   color: "#fff" ; 
   font-Size:"20px"

}

.form_container_new {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  grid-gap: 1rem;
  gap: 1rem;
  height: 34rem;
  overflow-y: auto;
  justify-content: flex-start;
  overflow-x: hidden;
}

.form_container_new .form_section{
  border-bottom: 1px solid rgba(0, 0, 0, 0.356);
  background: #3D3D3D;
  padding: 2rem;
  border-radius: 1rem;
}

.form_section {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;

}

.onboard_button {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
}

.onboard_button button {
  border-radius: 2rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem 5rem;
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all .4s;
  background-color: white;
  border: none;
  min-width: 100px;
  height: 40px;
}

.onboard_button .button1 {
  border-radius: 2rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem 5rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all .4s;
  background-color: #ff9000;
  border: none;
  min-width: 100px;
  height: 40px;
}

.slot_button button {
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all .4s;
  background-color: #000;
  border: none;
  min-width: 100px;
  height: 40px;
}


.onboard_button button:hover {
  border: 1px solid #000;
  background-color: #ff9000;
  color: #ffffff;
}

.onboard_error {
  color: red;
  height: 10px;
  font-size: 1.2rem;
}

.congrats_onboard_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  position: relative;
}

.congrats_img_onboard {
  width: 10rem;
}


.noData_icon{
  height: 4rem; 
  margin: 1rem;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subscription_btn_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

.subs_text {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.subs_text p {
  font-size: 12px;
  text-align: center;
}

.sub-container {
  width: 100%;
  height: 100px;
  background-color: #5656566e;;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.sub-container-active {
  width: 100%;
  height: 100px;
  background-color: #5656566e;
  color: white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 3px solid #0000004c;
  cursor: pointer;
}

.sub-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment_btn_list {
  display: flex;
  flex-direction: row;
  /* gap: 1rem; */
  border-radius: 1rem;
  border: 1px solid #0000003d;
  /* box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6, 4px 4px 4px 0px #d1d9e6 inset, -2px -4px 1px 0px #ffffff inset; */
  text-align: center;
  align-items: center;
  justify-content: center;
}

.payment-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  flex-direction: column;
  cursor: pointer;
}

.payment-container-active {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  flex-direction: column;
  border: 2px solid #000;
  cursor: pointer;
}

.payment-price {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
}

.payment-price p {
  font-size: 12px;
}

.cancel {
  text-decoration: line-through
}

.payment-price-div {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.payment-price-div h2 {
  font-weight: 500;
}

.congrats_btn_list1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-animation: FadeIn 0.30s linear;
          animation: FadeIn 0.30s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

}


.congrats_btn_list2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-animation: FadeIn 0.30s linear;
          animation: FadeIn 0.30s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

}

.congrats_btn_list3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-animation: FadeIn 0.30s linear;
          animation: FadeIn 0.30s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

}


.payment_btn_list h3 {
  font-size: 14px;
  font-weight: 600;

}


/* @keyframes example {
  0%   { right:-80px;}
  100% { right:0px;}
}


@media screen and (min-width: 1440px) {
  .form_container{
    height: 50rem;
    grid-gap:1rem;
    gap:1rem;
  }
} */

@-webkit-keyframes FadeIn {
  from {
    opacity: 0.2;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes FadeIn {
  from {
    opacity: 0.2;
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

.congrats_btn_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;

}

.congrats_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;

  /* animation-fill-mode: both; */

}

.congrats_btn_list1 {
  -webkit-animation-delay: .5s;
          animation-delay: .5s
}

.congrats_btn_list2 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s
}

.congrats_btn_list3 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s
}

/* .myClass img:nth-child(4) { animation-delay: 2s } */


.payment-coupon-container {
  width: 100%;
  border-radius: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.payment-price-container {
  width: 100%;
  padding: 10px;
  border-radius: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  align-items: center;
  position: relative;
}

.payment-icon {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-grey);
}

.couponlist-container-active {
  width: 100%;
  max-height: 300px;
  position: absolute;
  background-color: #fff;
  border-radius: 1rem;
  z-index: 100;
  margin-top: 10px;
  overflow-y: auto;
}

.couponlist-container {
  display: none;
}

.coupon-container {
  position: relative;
}

.counpon-detail {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.counpon-name {
  display: flex;
  flex-direction: column;
  width: inherit;
}

.price_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price_section h3 {
  font-size: 15px;
}

.price_section p {
  font-size: 12px;
}

.onboard-password {
  display: flex;
  position: relative;
  align-items: center;
}

.onboard-password-icon {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 10px;
}

.onboard_down {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}
.onboard_down1 {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}

.onboard_down p {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.onboard_down span {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.onboard_down1 span {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.payment_checkbox {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 12px;
  align-items: center;
}
 

input:checked~.checkmark {
  background-color: #000;
}




/* @media only screen and (max-width:800px) {
  .onboarding-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  .form_container{
    height: 100%;
  }
} */
  /* For tablets: */
  /* .onboard_container {
    width: 100%; 
  }

  .form_section {
    flex-direction: column; 
  } */
 
/* @media only screen and (max-width:1000px) {
  .onboarding-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  .form_container{
    height: 100%;
  }
} */
  /* For tablets: */
  /* .onboard_container {
    width: 100%; 
  }

  .form_section {
    flex-direction: column; 
  } */


  @media only screen and (min-width:360px) and (max-width: 500px){
    .onboard_img {   
      background-image: none;
    }

    .onboard_container {
      width: 100%;
      border-radius:4rem;
    }

    .onboarding {
     background-image: none;
    }

    
    .onboard_container {
      width: 100%;
      border-radius: 4rem;
      height: 90rem;
    }

    .onboard_form_input{
      width: 100%;
      grid-gap: 6rem;
      gap: 6rem;
      flex-wrap: wrap;
    }
  }

 


  @media only screen and (min-width: 810px) and (max-width: 1080px){


    .onboard_img {
      width: 30%;
      background-image: url(/static/media/background_onboard.e6df642b.png);
      background-size: cover;
      border-radius: 4rem 0 0 4rem;
      background-position-x: -46rem;
      background-repeat: no-repeat;
    }

    .onboard_form_input {
      display: block;
    }
    .onboard_form {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      grid-gap: 1rem;
      grid-gap: 1rem;
      gap: 1rem;
      margin-bottom: 3rem;
      margin-top: 3rem;
      line-height: 3rem;
    }

    .onboard_container {
      width: 70%;
      padding: 8rem 5rem;
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      grid-gap: 2rem;
      gap: 2rem;
      justify-content: center;
      border-radius: 0 4rem 4rem 0;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      height: 92rem;
    }

  }
 
.Payment_onboarding_price__Mrtrw {
  display: flex;
  justify-content: space-between;
  flex-direction: "row";
  width: 100%;
}

.Payment_priceText__1DyGR {
  color: #fff;
  font-weight: normal;
}

.Payment_finalPrice__1tg7f {
  color: #ff9000;
  font-weight: normal;
}

.Payment_testing__39drH {
  font-size: 12px;
}

.Payment_total_Price__2GQEs {
  flex-direction: row;
}

.Payment_discountedPrice__35Kwp {
  color: #ff9000;
  font-weight: normal;
  text-decoration: line-through;
  margin-right: 10px;
}

.Payment_totalAmount__gPlgH {
  color: #ff9000;
  font-size: 20px;
}

.Payment_subTotal__a13Nh {
  color: #fff;
  font-weight: normal;
}

.Payment_fees__DLIRV{
  font-Size: 12px
}

.Payment_divider__3BSfz {
  border-bottom: 0.5px solid #555;
  width: 100%;
  margin-top: 13px;
}

.Payment_totalRow__2VO_g {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}

.Payment_totalText__1758X {
  color: #fff;
  font-size: 20px;
}

.Payment_totalAmount__gPlgH {
  color: #FF9000;
  font-size: 20px;
}

.Payment_flexRowContainer__1lzWH {
  flex-direction: row;
  justify-content: flex-start; 
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.Payment_onboardInputView__2bNWN {
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.BaseInput_inputContent__jBQ9r{
    display: flex;
    box-shadow: 0rem 0.2rem 2rem -1.5rem black;
    border: none;
    width: 100%;
    border-radius: 3rem;
    outline: none;
    padding: 1.2rem 1rem;
    justify-content: center;
    margin-left: 2rem;
    padding-right: 5rem
}

.BaseInput_baseInput__2hYhS::-webkit-input-placeholder{
color: white;
font-weight: 600;
}

.BaseInput_baseInput__2hYhS:-ms-input-placeholder{
color: white;
font-weight: 600;
}

.BaseInput_baseInput__2hYhS::placeholder{
color: white;
font-weight: 600;
}

.BaseInput_baseInput__2hYhS{
  color: white;
}

.BaseInput_title__TZ8d0{
    display: inline-block;
    margin-bottom: 0.6rem;
    color: white;
    font-size: 1.5rem;
    margin-left: 2.8rem;
}

.BaseInput_baseInput__2hYhS{
    background-color: transparent !important;
    color: white;
    width: 100%;
    outline: none;
    margin-left:1.8rem;
    color: white;
    padding-left: 1rem;
    align-items: center;
    border: none;
    border-left: 1px solid #C6C6C6;
    display: flex;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    -webkit-background-clip: text;
    background-color: transparent !important;
  }

  input:-moz-autofill{
    color: white !important;
    background-color: transparent !important;
  }

  .BaseInput_errorMessage__1Bsho{
    color: red;
    margin-left: 3rem;
  }

  .BaseInput_input__icon__1Vlg8{
    height: 2rem;
    width: 2rem;
    fill: #ffffff;
    position: absolute;
    right: 3rem;
  }
 
  @media (max-width:1000px){
    .BaseInput_inputContent__jBQ9r{
        width: 85%;
    }
  }

  .BaseInput_navigation__icons__eozjI{
    fill: #ff9000;
    height: 2rem;
    width: 2rem;
    grid-gap: 15px;
    gap: 15px;
  }
  
.BaseInput_toggle-password-button__Azg07{
  position: absolute;
  right: -28px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.Packages_pricing_cards__3GVni {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.Packages_pricing_card__1z64_ {
	padding: 2rem 3rem;
	background-color:#ffffff;
	/* box-shadow: ; */
	border-radius: 2rem;
}
.Packages_pricing_header__2xrRJ {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.Packages_stripe-pricing-table__39ZBq{
	background-color: red;
}

.Packages_pricing_card_icon_box__1MQZm {
	height: 3rem;
	width: 3rem;
	background-color: var(--primary-color);
	border-radius: 1rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_icon__Tv9nX {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}
.Packages_pricing_card_heading__1kcQk {
	font-size: 2rem;
	font-weight: 700;
}
.Packages_pricing_card_price__t1G9l {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	font-size: 1.3rem;
	color: var(--grey);
}
.Packages_pricing_card_price_amount__3MGiq {
	font-size: 2rem;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
}
.Packages_pricing_card_features__3Gsp7 {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}
.Packages_pricing_card_feature__3eL92 {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.Packages_pricing_card_feature_icon_box__ORFUo {
	height: 2rem;
	width: 2rem;
	background-color: #000000bf;
	border-radius: .7rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_feature_icon__1Nyvq {
	height: 1.2rem;
	width: 1.2rem;
	fill: var(--white);
}
.Packages_pricing_card_feature_text__1bomO {
	font-size: 1.3rem;
	font-weight: 600;
}

.Packages_pricing_card_actions__36vDx {
	margin-top: 2rem;
	border-top: 1px solid var(--light-grey);
	padding-top: 1rem;

	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	justify-content: flex-end;
}
.Packages_pricing_card_action_btn__3Ufyc,
.Packages_pricing_card_action_btn_delete__3OvSy {
	padding: .5rem;
	border-radius: .8rem;
	cursor: pointer;
	transition: all .4s;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_action_btn__3Ufyc {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
}
.Packages_pricing_card_action_icon__2f6n_ {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}
.Packages_pricing_card_action_btn__3Ufyc:hover,
.Packages_pricing_card_action_btn_delete__3OvSy:hover {
	background-color: transparent;
}
.Packages_pricing_card_action_btn__3Ufyc:hover .Packages_pricing_card_action_icon__2f6n_ {
	fill: var(--primary-color);
}
.Packages_pricing_card_action_btn_delete__3OvSy {
	background-color: var(--red);
	border: 1px solid var(--red);
}
.Packages_pricing_card_action_btn_delete__3OvSy:hover .Packages_pricing_card_action_icon__2f6n_ {
	fill: var(--red);
}
.Packages_pricing_card_add_plan_btn__20D-s {
	font-family: inherit;
	background-color: transparent;
	border: 1px solid var(--primary-color);
	cursor: pointer;

	font-size: 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	margin-left: 3rem;
}

.AppSetting_screenCards__1tl2Z {
	margin-top: 2rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-wrap: wrap;
}

.AppSetting_flex__3INPs {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	/* align-items: center; */
	flex-direction: column;
	width: 400px;
}

.AppSetting_screenCard__1LFIT {
	/* background-color: #ececec; */
	background-color: #121212;
	border-radius: 2rem;
	padding: 2rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.AppSetting_screenCardTitle__p6pY3 {
	margin-bottom: 2rem;
	font-size: 1.5rem;
	font-weight: 600;
	color: var(--white)
}

.AppSetting_newScreenCard__3mj3F {
	background-color: #121212;
	padding: 1.2rem 1.6rem;
	width: 30rem;
	border-radius: 1.2rem;
}

.AppSetting_newScreenCardContainer__K41gL {
	display: flex;
	padding: 1rem;
	grid-gap: 1rem;
	gap: 1rem;
}

.AppSetting_cardTitle__2CNmT{
	color: white;
	margin-bottom: 0.8rem;
	display: block;
	line-height: 3rem;
	font-weight: 600;
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	.AppSetting_screenCards__1tl2Z {
		width: 200px;
	}

	.AppSetting_flex__3INPs {
		flex-direction: row;
	}

}

@media (min-width: 809px) {
	.AppSetting_screenCards__1tl2Z {
		width: 400px;
	}

}
.dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.dashboard_section_1{
    width: 100%;
    height: 30rem;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

.dashboard_section_2{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    padding: 2rem;
    justify-content: flex-start;
}


.dashboard_section_2 canvas{
    margin: 1rem;
    width: 28vw !important;
    height: 22rem !important;
}

.dashboard_section_3{
    width: 100%;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    
}



.dashboard_cards{
    width: 60%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
    /* background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5); */
}

.dashboard_cards1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-between;
    
    /* background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5); */
}

.dashboard_card {
    width: 47%;
    height: 46%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    transition: all .2s ease-in-out
}

.dashboard_card1 {
    width: 23%;
    height: 12rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    
    padding: 1rem;
    transition: all .2s ease-in-out
}

.dashboard_card:hover{
    -webkit-transform: scale(1.010);
            transform: scale(1.010);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.dashboard_bar{
    width: 40%;
    height: 30rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all .2s ease-in-out
}

.dashboard_bar:hover{
    -webkit-transform: scale(1.010);
            transform: scale(1.010);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.dashboard_bar canvas{
    width: auto !important;
    height: 28rem !important;
}

.dashboard_box{
    width: 50%;
    height:100%;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.stylist-section{
    display: flex;
    grid-gap:1rem;
    gap:1rem;
    align-items: center;
    /* justify-content: center; */
}
.dashboard_box canvas{
    /* width: auto !important;
    height:35rem !important; */
}

.card_black{
    width: 10rem;
    height: 10rem;
    background-color: #000;
    border-radius: 1rem;
    display: grid;
    place-content: center;
}

.dashboard_icon {
	height: 3rem;
	width: 3rem;
	fill: var(--white);
    stroke:var(--white) ;
}

.card_details{
    /* width: 100%;s */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.card_details span{
    font-size: 5rem;
    font-weight: 700;
    line-height: 0.8;
    /* color: var(--primary-color); */
}

.card_details p{
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    /* color: var(--primary-color); */

}

.dashboard_p{
    font-size: 2rem;
    font-weight: 600;
}

.stylist_list{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
}

.stylist_info{
    width: 100%;
    height: 8rem;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
}

.stylist_info:hover{
    border: 1px solid #ccc;
    border-radius: 1rem;
}

.stylist_text{
    font-size: 1.8rem;
    font-weight: 800;
}

.stylist_count{
    min-width: 12rem;
    padding: 1rem;
    background-color: #000;
    border-radius: 1rem;
    text-align: center;
    font-size: 1.3rem;
    color: #fff;
    
}

.react-google-flight-datepicker .dialog-header{
	display: none;
}

.react-google-flight-datepicker .date-picker-input{
	height: 40px;
	width:35rem;
}

/* .react-google-flight-datepicker .dialog-date-picker {
	z-index: 999 !important;
	width: 290px !important;
	top: 75px;
} */
 
.react-google-flight-datepicker .icon-calendar {
    width: 24px;
    height: 24px;
    fill: #ff9000 !important;
    margin-right: 12px;
}

.react-google-flight-datepicker .day {
	width: 30px;
	height: 30px;
}

.react-google-flight-datepicker .date {
	min-width: 140px;
}

.dialog-header .date-picker-input {
	min-width: 350px !important;
	width: 100% !important;
}

.react-google-flight-datepicker .reset-button {
	display: none;
}

.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
	border: 2px solid #000000 !important;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
	background-color: #000000 !important; 
}

.react-google-flight-datepicker .day.selected.hovered::after,
.react-google-flight-datepicker .day.selected::after {
	border-radius: 100%;
	background-color: #000000 !important;
}

.react-google-flight-datepicker .calendar-wrapper {
	min-height: 250px;
}

.react-google-flight-datepicker .day::after {
	height: 3rem;
	width: 3rem;
}

.react-google-flight-datepicker .day.hovered{
	background-color: #d8d8d8c2;
}

.react-google-flight-datepicker .icon-calendar{
	fill: #000000;
}
.react-google-flight-datepicker .day.selected.hovered:hover {
    color: #ffffff;
}
.react-google-flight-datepicker .flipper-button {
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -15px;
    pointer-events: all;
}
.react-google-flight-datepicker .month-calendar {
    width: 220px;
    color: #3c4043;
    margin-right: 43px;
}

span{
    font-size: 1.4rem;
}

.salon_status{
    width: 100%;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    font-size:1.2rem;
}

.react-google-flight-datepicker .date-picker {
    width: 409px !important;
}

.colorGreen{
    color:green !important;
}

.colorRed{
    color: red !important;
}
.TemplateAssign_muscleType___WnF0 {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-end;
}

.TemplateAssign_muscleTypeGoBtn__3t1-C {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	background-color: #ff9000;
	padding: .9rem;
	cursor: pointer;
}

.TemplateAssign_muscleTypeGoBtnIcon__vAKMu {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}


.TemplateAssign_muscleTypeGoBtn__3t1-C,
.TemplateAssign_muscleTypeEditBtn__-285a {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	cursor: pointer;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	padding: 0.9rem;
}

.TemplateAssign_muscleTypeEditBtn__-285a {
	background-color: #09baa6;
}

.TemplateAssign_muscleTypeEditBtnIcon__2143u {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
}

/* Round Cards */
.TemplateAssign_roundCards__2N-Sh {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 2rem;
}

.TemplateAssign_roundCard__sbD5L {
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;

	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}

.TemplateAssign_roundCardHeading__3JM9X {
	font-size: 1.5rem;
	font-weight: 600;
}



.TemplateAssign_templates__3rty_ {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.TemplateAssign_template__1LMHw {
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	text-decoration: none;
	color: #000000;
	width: 22rem;
	transition: all .4s;
}
.TemplateAssign_template__1LMHw:hover {
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
}
.TemplateAssign_templateData__1dhJ1 {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem; 
	height: 25rem;
	width: 28rem;
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem 3rem;
}
.TemplateAssign_templateImage__2L5KG {
	padding: 2rem;
	border-radius: 50%;
	background-color: #ff9000;
	display: flex;
	align-items: center;
}
.TemplateAssign_templateImageIcon__2QmZ4 {
	height: 4rem;
	width: 4rem;
	fill: #e7e7ea;
}
.TemplateAssign_templateName__2aFL7 {
	font-size: 1.4rem;
	font-weight: 600;
	text-align: center;
}
.TemplateAssign_templateButtonIcon__2q-98 {
	height: 3rem;
	width: 3rem;
}
.TemplateAssign_templateBar__UMR4h {
	height: .5rem;
	margin: 1.5rem 1rem;
	width: 26rem;
	background-color: #ff9000;
}

.TemplateAssign_templateCardActions__38MtC {
	border-top: 1px solid #e7e7ea;
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: auto;
}

.TemplateAssign_templateCardActionBtn__3ubgY {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}

.TemplateAssign_templateCardAction__3Ylfq {
	background-color: transparent;
	border: 1px solid #12151a;
	padding: .6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}
.TemplateAssign_templateCardActionIcon__29uS5 {
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
	transition: all .4s;
}
.TemplateAssign_templateCardAction__3Ylfq:hover {
	background-color: #12151a;
	border: 1px solid #12151a;
}
.TemplateAssign_templateCardAction__3Ylfq:hover .TemplateAssign_templateCardActionIcon__29uS5 {
	fill: #ffffff;
}

.TemplateAssign_roundCardExercise__1_9Xw {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	font-size: 1.2rem;
	font-weight: 600;
}
.TemplateAssign_roundCardExerciseName__2NwJz {
	font-style: italic;
}
.TemplateAssign_roundCardRepType__F_8vI {
	font-size: 1.3rem;
	font-weight: 600;
}
.TemplateAssign_roundCardRepType2__EfF8Z {
	font-size: 1.1rem;
	font-weight: 600;
}
.TemplateAssign_roundCardNote__tpYhK {
	font-size: 1.1rem;
	font-weight: 600;
}
.TemplateAssign_roundCardNextRound__p58R8 {
	color: #09baa6;
	font-size: 1.2rem;
	font-weight: 600;
}

.TemplateAssign_roundDetail__2lcpd{
	height: 15rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

.currency_stat{
    font-size: 2rem!important;
}

.dashboard_section_1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
}

.dashboard_section_2{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #121212;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    padding: 2rem;
    justify-content: flex-start;
}
.dashboard_section_3{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 1rem;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    justify-content: flex-start;
}
.dashboard_section_4{
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #121212;
    flex-wrap: nowrap;
    grid-gap: 1rem;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    justify-content: flex-start;
}

    .dashboard_span{
        background: #121212;
        color: white;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        display: flex;
        margin-top: -10px;
        align-items: center;
        font-size: 2rem;
        font-weight: 400;
    } 

.dashboard_section_2 canvas{
    margin: 1rem;
    height: 23rem !important;
}

.dashboard_section_3 canvas {
    margin: 1rem;
    height: 33rem !important;
    font-size: 2rem;
}
.dashboard_section_4 canvas {
    margin: 1rem;
    height: 33rem !important;
    font-size: 2rem;
}

.dashboard_section_2_1{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #121212;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    transition: all .2s ease-in-out;
    padding: 2rem;
    justify-content: flex-start;
}

.dashboard_section_2_1 canvas{
    margin: 1rem;
    height: 38rem !important;
}


.dashboard_section_3{
    width: 100%;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    
}
.dashboard_section_4{
    width: 100%;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    
}



.dashboard_cards{
    width: 60%;
    border-radius: 2rem;
    height: 100%;
    padding: 1rem;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: nowrap;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    width: 70%;
    background: #121212;
    /* background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5); */
}

.dashboard_cards1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-between;
    
    /* background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5); */
}



.dashboard_small_cards{
    height: 100%;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
}

.dashboard_big_card{
    height: 100%;
    width: 29%
}


.dashboard_card_new {
    width: 31%;
    background-color: #040404;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    transition: all .2s ease-in-out
}
.dashboard_card_new1 {
    width: 100%;
    height: 100%;
    background-color: #040404;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    justify-content: center;
    transition: all .2s ease-in-out
}

.dashboard_card1 {
    width: 23%;
    height: 12rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    background-color: #121212;
    padding: 1rem;
    transition: all .2s ease-in-out
}

.dashboard_card_new:hover{
    -webkit-transform: scale(1.010);
            transform: scale(1.010);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.dashboard_bar{
    width: 40%;
    height: 30rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all .2s ease-in-out
}

.dashboard_bar:hover{
    -webkit-transform: scale(1.010);
            transform: scale(1.010);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
}

.dashboard_bar canvas{
    width: auto !important;
    height: 28rem !important;
}

.dashboard_box{
    width: 50%;
    height:100%;
    background-color: #121212;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.stylist-section{
    display: flex;
    grid-gap:1rem;
    gap:1rem;
    align-items: center;
    /* justify-content: center; */
}
.dashboard_box canvas{
    width: auto !important;
    height: 25rem !important;
}

.card_black_new{
    width: 100%;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    place-content: center;
    flex-wrap: nowrap;
    /* flex-wrap: wrap-reverse; */
    /* flex-grow: inherit; */
    /* flex-wrap: nowrap; */
}

.dashboard_icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
    stroke:var(--white) ;
}

.card_details{
    height: 10rem;
    display: flex;
    /* flex-direction: column; */
   flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.card_details span{
    line-height: 1rem;
    font-size: 4rem;
}

.card_details1{
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: nowrap;
}

.card_details span{
    font-weight: 600;
    line-height: 1.3;
    color: #ffffff;
}

.card_details p{
    font-size: 1.1rem;  
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
    text-transform: uppercase;
    color: #ffffff;
    /* color: var(--primary-color); */

}

.card_details1 span{
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.3;
    color: #ffffff;
}

.card_details1 p{
    font-size: 1.5rem;
    display: inline-block;
    font-weight: 400;
    text-transform: uppercase;
    color: #ffffff;
    /* color: var(--primary-color); */

}

.dashboard_p{
    background: #121212;
    color: white;
    font-size: 2rem;
    font-weight: 600;
}
.dashboard_p1 {
    background: #121212;
    color: white;
    font-size: 2rem;
    font-weight: 600;
}

.stylist_list{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
}

.stylist_info{
    width: 100%;
    height: 8rem;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
}

.stylist_info:hover{
    border: 1px solid #ccc;
    border-radius: 1rem;
}

.stylist_text{
    font-size: 1.8rem;
    font-weight: 800;
    color: white;
}

.stylist_count{
    min-width: 12rem;
    padding: 1rem;
    background-color: #000;
    border-radius: 1rem;
    text-align: center;
    font-size: 1.3rem;
    color: #fff;
    
}

.react-google-flight-datepicker .dialog-header{
	display: none;
}

.react-google-flight-datepicker .date-picker-input{
	height: 40px;
	width:35rem;
}

/* .react-google-flight-datepicker .dialog-date-picker {
	z-index: 999 !important;
	width: 290px !important;
	top: 75px;
} */
 
.react-google-flight-datepicker .icon-calendar {
    width: 24px;
    height: 24px;
    fill: #ff9000 !important;
    margin-right: 12px;
}

.react-google-flight-datepicker .day {
	width: 30px;
	height: 30px;
}

.react-google-flight-datepicker .date {
	min-width: 140px;
}

.dialog-header .date-picker-input {
	min-width: 350px !important;
	width: 100% !important;
}

.react-google-flight-datepicker .reset-button {
	display: none;
}

.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
	border: 2px solid white !important;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
	background-color: #ff9000 !important; 
    font-weight: 600;
    font-size: 1.2rem;
}



.react-google-flight-datepicker .day.selected.hovered::after,
.react-google-flight-datepicker .day.selected::after {
	border-radius: 100%;
	background-color: #000000 !important;
}

.react-google-flight-datepicker .calendar-wrapper {
	min-height: 250px;
}

.react-google-flight-datepicker .day::after {
	height: 3rem;
	width: 3rem;
}

.react-google-flight-datepicker .day.hovered{
	background-color: #d8d8d8c2;
}

.react-google-flight-datepicker .icon-calendar{
	fill: #000000;
}
.react-google-flight-datepicker .day.selected.hovered:hover {
    color: #ffffff;
}
.react-google-flight-datepicker .flipper-button {
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;
    pointer-events: all;
}
.react-google-flight-datepicker .month-calendar {
    width: 220px;
    color: #3c4043;
    margin-right: 43px;
}

span{
    font-size: 1.4rem;
}

.salon_status{
    width: 100%;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    font-size:1.2rem;
}

.react-google-flight-datepicker .date-picker {
    width: 409px !important;
    background: #121212;
    padding: 1rem;
}

.react-google-flight-datepicker .date {
    background: #121212;

}

.react-google-flight-datepicker .selected-date{
    color: white;
}

.react-google-flight-datepicker .date.is-focus{
    background: #121212;
}


.colorGreen{
    color:green !important;
}

.colorRed{
    color: red !important;
}

.wid{
    width: 49%;
}

/* Dashboard screen responsive - Aniket */
@media (max-width: 1024px) {
    .dashboard_section_1{
        display: block;
    }
    .dashboard_cards{
        width: 100%;
        height: 50%;
    }
    .section{
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
    .wid{
        width: 100%;
    }
    .dashboard_section_2 canvas{
        width: 100% !important;
        height: 100% !important;
    }
}
@media screen and (min-width: 1152px) and (max-width: 1408px) {
    .card_details span{
        font-size: 3rem;
    }
    .card_details1 span{
        font-size: 5rem;
    }
}

@media screen and (min-width: 810px) and (max-width: 1080px) {
    .card_details span{
        font-size: 4rem;
    }
    .card_details1 span{
        font-size: 4rem;
    }

    .card_details p{
        font-size: 0.9rem;
    }
}


.NewStyles_pie_chart_container__1AqV3 {
  background: #121212;
  width: 30%;
  /* height: 34rem; */
  /* height: 44rem; */
  border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.2s ease-in-out;
}

.NewStyles_dashboard_p__BK1VU {
  color: white;
  font-size: 2rem;
  font-weight: 600;
}

.NewStyles_dot_container__BMLjK{
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.NewStyles_percenatage_container__2uPtF{
    width: 100%;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.NewStyles_male_percentage_Text__1K3al{
    color: #FF9000
}

.NewStyles_dot__t8nPV {
    width: 1rem;
    height: 1rem;
    background-color: #FF9000; /* You can set the color of the dot */
    border-radius: 50%; /* Makes the div round */
  }

  .NewStyles_male_percentage_Tex1__1ny-F{
    color: #FFFFFF
}

.NewStyles_dot1__13kN9 {
    width: 1rem;
    height: 1rem;
    background-color: #FFFFFF; /* You can set the color of the dot */
    border-radius: 50%; /* Makes the div round */
  }

.NewStyles_chart8_circle_chart__3W5sE {
  height: 25rem;
  width: 25rem;
  padding: 0.8rem;
}

.NewStyles_chart9__32DZD {
  grid-column: 4 / 5;
  grid-row: 4/-1;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.NewStyles_chart8_chart__3gT8H {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 1.3rem;
  font-weight: 600;
}

.NewStyles_chart8_circle__3cgvq {
  position: relative;
  background-color: #a15b00;
  border-radius: 50%;
  height: 25rem;
  width: 25rem;
  padding: 0.8rem;

  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.NewStyles_chart8_circle_info__1G8mP {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: var(--white);
}
.NewStyles_chart8_circle_info_num__2roWg {
  text-align: center;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-family: "Dosis", sans-serif;
}
.NewStyles_chart8_circle_info_num__2roWg span {
  font-size: 1rem;
  line-height: 1.4rem;
}
.NewStyles_chart8_circle_info_text__2zwBJ {
  font-size: 2.2rem;
  line-height: 2.5rem;
  text-align: center;
}




@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .NewStyles_male_percentage_Text__1K3al{
    font-size: 1rem;
  }
  .NewStyles_male_percentage_Tex1__1ny-F{
    font-size: 1rem;
  }
  
}

/* Dashboard screen responsive - Aniket */
@media (max-width: 1024px) {
  .NewStyles_pie_chart_container__1AqV3 {
    margin-top: 1rem;
    width: 100%;
    /* height: 100%; */
  }
}

@media  (max-width: 820px) {
  .NewStyles_section__2rT5I{
    width: 100%;
  }
}
.TemplateAssign_muscleType__2qKXO {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-end;
}

.TemplateAssign_muscleType__2qKXO.TemplateAssign_date-picker__2x9P5{
	background: red;
}

.TemplateAssign_muscleTypeGoBtn__1xn7o {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	background-color: #ff9000;
	padding: .9rem;
	cursor: pointer;
}

.TemplateAssign_muscleTypeGoBtnIcon__2uW8C {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}


.TemplateAssign_muscleTypeGoBtn__1xn7o,
.TemplateAssign_muscleTypeEditBtn__CydlZ {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	cursor: pointer;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	padding: 0.9rem;
}

.TemplateAssign_muscleTypeEditBtn__CydlZ {
	background-color: #09baa6;
}

.TemplateAssign_muscleTypeEditBtnIcon__2__8P {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
}

/* Round Cards */
.TemplateAssign_roundCards__3uHOl {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 2rem;
}

.TemplateAssign_roundCard__1bFlr {
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;

	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}

.TemplateAssign_roundCardHeading__12DUW {
	font-size: 1.5rem;
	font-weight: 600;
}



.TemplateAssign_templates__16Jx3 {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.TemplateAssign_template__2XvdY {
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	text-decoration: none;
	color: #000000;
	width: 22rem;
	transition: all .4s;
}
.TemplateAssign_template__2XvdY:hover {
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
}
.TemplateAssign_templateData__1eYpZ {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem; 
	height: 25rem;
	width: 28rem;
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem 3rem;
}
.TemplateAssign_templateImage__1IE8U {
	padding: 2rem;
	border-radius: 50%;
	background-color: #ff9000;
	display: flex;
	align-items: center;
}
.TemplateAssign_templateImageIcon__j0DTp {
	height: 4rem;
	width: 4rem;
	fill: #e7e7ea;
}
.TemplateAssign_templateName__DMucZ {
	font-size: 1.4rem;
	font-weight: 600;
	text-align: center;
}
.TemplateAssign_templateButtonIcon__3B9jW {
	height: 3rem;
	width: 3rem;
}
.TemplateAssign_templateBar__1jkDB {
	height: .5rem;
	margin: 1.5rem 1rem;
	width: 26rem;
	background-color: #ff9000;
}

.TemplateAssign_templateCardActions__2Ezyk {
	border-top: 1px solid #e7e7ea;
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: auto;
}

.TemplateAssign_templateCardActionBtn__3HY-w {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}

.TemplateAssign_templateCardAction__1AVmR {
	background-color: transparent;
	border: 1px solid #12151a;
	padding: .6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}
.TemplateAssign_templateCardActionIcon__2jDzd {
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
	transition: all .4s;
}
.TemplateAssign_templateCardAction__1AVmR:hover {
	background-color: #12151a;
	border: 1px solid #12151a;
}
.TemplateAssign_templateCardAction__1AVmR:hover .TemplateAssign_templateCardActionIcon__2jDzd {
	fill: #ffffff;
}

.TemplateAssign_roundCardExercise__2CNRE {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	font-size: 1.2rem;
	font-weight: 600;
}
.TemplateAssign_roundCardExerciseName__2ZL-K {
	font-style: italic;
}
.TemplateAssign_roundCardRepType__dSTDh {
	font-size: 1.3rem;
	font-weight: 600;
}
.TemplateAssign_roundCardRepType2__2MJCw {
	font-size: 1.1rem;
	font-weight: 600;
}
.TemplateAssign_roundCardNote__2wECm {
	font-size: 1.1rem;
	font-weight: 600;
}
.TemplateAssign_roundCardNextRound__2emQS {
	color: #09baa6;
	font-size: 1.2rem;
	font-weight: 600;
}

.TemplateAssign_roundDetail__2Drqq{
	height: 15rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}


.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_dialog-header__aKk9g{
	display: none;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_date-picker-input__3xpHP{
	height: 40px;
	width:35rem;
}

/* .react-google-flight-datepicker .dialog-date-picker {
	z-index: 999 !important;
	width: 290px !important;
	top: 75px;
} */
 
.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_icon-calendar__W_0_i {
    width: 24px;
    height: 24px;
    fill: #ff9000 !important;
    margin-right: 12px;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_day__110Q0 {
	width: 30px;
	height: 30px;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_date__y_mEr {
	min-width: 140px;
}

.TemplateAssign_dialog-header__aKk9g .TemplateAssign_date-picker-input__3xpHP {
	min-width: 350px !important;
	width: 100% !important;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_reset-button__eZi87 {
	display: none;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_date__y_mEr:focus::after,
.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_date__y_mEr.TemplateAssign_is-focus__2XWjL::after {
	border: 2px solid white !important;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_dialog-footer__1nX4n .TemplateAssign_submit-button__LqSmU {
	background-color: #000000 !important; 
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_day__110Q0.TemplateAssign_selected__1TESc.TemplateAssign_hovered__2Sy61::after,
.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_day__110Q0.TemplateAssign_selected__1TESc::after {
	border-radius: 100%;
	background-color: #000000 !important;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_calendar-wrapper__ry9G2 {
	min-height: 250px;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_day__110Q0::after {
	height: 3rem;
	width: 3rem;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_day__110Q0.TemplateAssign_hovered__2Sy61{
	background-color: #d8d8d8c2;
}

.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_icon-calendar__W_0_i{
	fill: #000000;
}
.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_day__110Q0.TemplateAssign_selected__1TESc.TemplateAssign_hovered__2Sy61:hover {
    color: #ffffff;
}
.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_flipper-button__1Wp1h {
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 24%);
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;
    pointer-events: all;
}
.TemplateAssign_react-google-flight-datepicker__21tRr .TemplateAssign_month-calendar__2puWT {
    width: 220px;
    color: #3c4043;
    margin-right: 43px;
}

.LoginContent_loginCotnent__13Nw3{
    display: flex;
    width: 100%;
    margin: 6rem;
    justify-content: center;
}

.LoginContent_loginBox__1yNAs{
    display: flex;
    background-image: url(/static/media/bookb-Login-Image.a723314e.png);
    justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 100%;
  width: 95%;
  background-position-y: 58%;
  background-position-x: 4rem;
  box-shadow: 1rem -1rem 7rem -4rem black
}
@media (max-width:412px){

}

@media only screen and (min-width: 810px) and (max-width: 1080px){
  
  .LoginContent_loginBox__1yNAs{
    display: flex;
  background-image: url(/static/media/bookb-Login-Image.a723314e.png);
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 100%;
  width: 100%;
  background-position-x: -26rem;
  box-shadow: 1rem -1rem 7rem -4rem black;
  border-radius: 4rem;
}
}
.LoginCard_loginCard__DM62n {
  width: 30%;
  height: 100%;
  padding: 2rem;
  background-color: #1E1D1D;
  position: absolute;
  top: 1rem;
  border-radius: 4rem;
  left: 3rem;
  overflow: auto;
  box-shadow: -1rem 0rem 7rem -2rem rgb(42, 42, 42);
}

.LoginCard_bookbLogo__2lRK- {
  display: flex;
  justify-content: center;
}

.LoginCard_bookb_logo__1lIzC {
  width: 18%;
}

.LoginCard_signIn_text__204kz {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  font-weight: lighter;
  margin-top: 1.4rem;
  color: #ffffff;
}

.LoginCard_credential_text__3HYUq {
  display: flex;
  justify-content: center;
  color: #6e6b7b;
}

.LoginCard_baseInput1__zNXIp {
  margin-top: 1.5rem;
  width: 85%;
}

.LoginCard_baseInput__2cWr9 {
  display: flex;
  box-shadow: 0rem 0.2rem 2rem -0.6rem black;
  border: none;
  width: 100%;
  border-radius: 3rem;
  color: white;
  outline: none;
  padding: 1.2rem 1rem;
  justify-content: center;
  margin-left: 2.8rem;
  padding-right: 4rem;
  background: none;
}

.LoginCard_account_register__2HXnv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.4rem;
}

.LoginCard_onlick_register__3qGce {
  color: #ff9000;
  font-size: 1.4rem;
  text-decoration: none;
}

.LoginCard_copyright_section__BgI9a {
  color: white;
  justify-content: end;
}

.LoginCard_copyright_section__BgI9a span {
  color: white;
}

.LoginCard_baseButton__3C-yS {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.LoginCard_bookb__1dHJP {
  color: #6e6b7b;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.LoginCard_powered_by__1WByG {
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
}

.LoginCard_onlcik_the_algorithm__3q-UC {
  color: #ff9000;
  text-decoration: none;
}

@media (max-width: 809px) {
  .LoginCard_credential_text__3HYUq {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 1.1rem;
  }

  .LoginCard_baseInput__2cWr9{
    margin-left: 0rem;
  }

  .LoginCard_baseInput1__zNXIp {
    margin-top: 3rem;
    width: 100%;
  }

  .LoginCard_account_register__2HXnv {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
  }

  .LoginCard_baseButton__3C-yS {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }

  .LoginCard_bookb__1dHJP {
    color: #6e6b7b;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .LoginCard_loginCard__DM62n {
    width: 85%;
    padding: 2rem;
    overflow: auto;
    background-color: #1E1D1D;
    position: absolute;
    top: 3rem;
    border-radius: 4rem;
    left: 3rem;
    box-shadow: -1rem 0rem 7rem -2rem;
  }
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
  .LoginCard_loginCard__DM62n {
    width: 42%;
    padding: 2rem;
    background-color: #1E1D1D;
    position: absolute;
    top: 3rem;
    overflow: auto;
    border-radius: 4rem;
    left: 3rem;
    box-shadow: -1rem 0rem 7rem -2rem;
  }

  .LoginCard_baseInput1__zNXIp {
    margin-top: 3rem;
    width: 100%;
  }

  .LoginCard_bookb_logo__1lIzC {
    width: 25%;
    margin-top: 10rem;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .LoginCard_credential_text__3HYUq {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .LoginCard_copyright_section__BgI9a {
    color: white;
    margin-top: 2rem;
  }
  .LoginCard_loginCard__DM62n {
    height: 95%;
  }
}

@media only screen and (min-width: 1701px) {

}

.BaseButton_baseButton__12NcY{
 background-color:#000000;
color: #ffffff;
padding: 1.3rem 5rem 1.3rem;
font-size: 1.6rem;
  border-radius: 3rem;
  font-weight: 600;
  border: none;
}
.LoginScreeenNew_container__1GBvd{
    display: grid;
    grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
    height: 100vh;
    width: 100%;
    transition: all 0.5s ease;
}

.LoginScreeenNew_loginContainer__2fgX9{
    height: 100vh;
    background: black;
    width: 100%;
    grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: flex;
  justify-content: center;
}
.Users_tableContainer__18VXH {
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
	height: 35vh;
	overflow-y: auto;
	overflow-x: auto;
}


.Users_table__2sVNo {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.Users_table__2sVNo tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.Users_table__2sVNo tr {
	transition: all 0.8s;
	font-size: 1.2rem;
    background-color: #121212;
	border-bottom: 1px solid #000;
}

.Users_table__2sVNo tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

.Users_input__3MF2Q label {
	color: #ffffff;
}

.Users_input__3MF2Q div{
    background-color: transparent;
    border-bottom: 1px solid #58585882;
    border-radius: 0rem;
}

.Users_selectBox__2PXA3 div{
	background-color:transparent;
	border-bottom: 1px solid #585858;
	margin-top: 3rem;
}

.Users_selectBox__2PXA3 label{
	font-size: 1.2rem;
}

.Users_selectBox__2PXA3 div label svg{
	position: absolute;
  top: 1rem;
}

.Users_coinIcon__1QqUI {
	width: 18px;
	height: 18px;
	vertical-align: middle;
	fill: #ffd600;
	margin-right: 1px;
	padding-bottom: 3px;
}

@media only screen and (min-width: 900px) and (max-width: 1088px) {
	.Users_form__3wbhS{
		height: 62vh!important;
		overflow:auto;
	}
}

.RadioButtons_form_input__kDhcB {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
	width: 25rem;
}

.RadioButtons_form_input__label__1N72p {
	color: #ffffff;
	font-size: 1.2rem;

}

.RadioButtons_form_input__error__36_Mz {
	font-size: 1.2rem;
	color: #ff3366;
}

.RadioButtons_modal_input__iJEHv{
	width: 25rem;
	/* margin-top: 3rem; */
}

.RadioButtons_form_input__container__LSvoH {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--bg-primary);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;

}

.RadioButtons_form_input__icon__1Jq0C {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}

.RadioButtons_form_input__box__24gI0 {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}

.RadioButtons_form_input__box__24gI0::-webkit-input-placeholder {
	color: var(--light-grey);
}

.RadioButtons_form_input__error__36_Mz {
	font-size: 1.2rem;
	color: #ff3366;
}

.RadioButtons_modal__radioButtons__28-WC {
    grid-gap: 1rem;
    gap: 1rem;
    grid-gap: 4rem;
    gap: 4rem;
    display: flex;
}
.SendNotification_content__NslvU {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: flex-start;
}
.SendNotification_prevNotifications__3w4yw {
	background-color: #353434b5;
	color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.SendNotification_prevNotificationsHeading__3DZPL {
	font-size: 1.5rem;
	font-weight: 600;
}
.SendNotification_prevNotification__2X9KD {
	background-color: #1a1919e8;
	border-radius: 2rem;
	padding: 1rem 1.5rem;
}
.SendNotification_prevNotificationTitle__2P2d6 {
	font-size: 1.6rem;
	font-weight: 600;
}
.SendNotification_prevNotificationSubTitle__jZ9Iz {
	font-size: 1.1rem;
	font-weight: 500;
	color: #ffffff;
}
.SendNotification_prevNotificationTime__hljiH {
	font-size: 1rem;
	font-weight: 600;
	color: #038D7A;
	margin-top: 1rem;
}

.SendNotification_input__2KYR3{
	margin-top: 2rem;
}

.SendNotification_input__2KYR3 div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
}

.SendNotification_input__2KYR3 label{
	color: #ffffff;
}

.SendNotification_textArea__1zSRn{
	margin-top: 2rem;
}

@media all and (min-width: 810px) and (max-width: 1080px) {
	.SendNotification_content__NslvU{
		flex-direction: column;
	}
	.SendNotification_prevNotifications__3w4yw{
		width: 100%;
	}
}

@media (max-width: 811px) {
    .SendNotification_content__NslvU{
		flex-direction: column;
	}
}
.Packages_pricing_cards__19Uc6 {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.Packages_pricing_card__aNVjS {
	padding: 2rem 3rem;
	background-color:#121212;
	/* box-shadow: ; */
	border-radius: 2rem;
}
.Packages_pricing_header__3m6Yo {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.Packages_pricing_card_icon_box__3OeLa {
	height: 3rem;
	width: 3rem;
	background-color: var(--primary-color);
	border-radius: 1rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_icon__7DNZZ {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
}
.Packages_pricing_card_heading__3MTBa {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;
}
.Packages_pricing_card_price__24WyW {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	font-size: 1.3rem;
	color: var(--grey);
}
.Packages_pricing_card_price__24WyW p {
	color: #ffffff;
}
.Packages_pricing_card_price_amount__QGU8C {
	font-size: 2rem;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	color: #ffffff;
}
.Packages_pricing_card_features__1O1_f {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}
.Packages_pricing_card_feature__1Gd6m {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}
.Packages_pricing_card_feature_icon_box__1tKSn {
	height: 2rem;
	width: 2rem;
	background-color: var(--black);
	border-radius: .7rem;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_feature_icon__m0dVr {
	height: 1.2rem;
	width: 1.2rem;
	fill: var(--white);
}
.Packages_pricing_card_feature_text__38Ylh {
	font-size: 1.3rem;
	font-weight: 600;
	color: #ffffff;
}

.Packages_pricing_card_actions__sdg5g {
	margin-top: 2rem;
	border-top: 1px solid var(--light-grey);
	padding-top: 1rem;

	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	justify-content: flex-end;
}
.Packages_pricing_card_action_btn__16FaL,
.Packages_pricing_card_action_btn_delete__2b9iM {
	padding: .5rem;
	border-radius: .8rem;
	cursor: pointer;
	transition: all .4s;

	display: flex;
	align-items: center;
	justify-content: center;
}
.Packages_pricing_card_action_btn__16FaL {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
}
.Packages_pricing_card_action_icon__3fIiu {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}
.Packages_pricing_card_action_btn__16FaL:hover,
.Packages_pricing_card_action_btn_delete__2b9iM:hover {
	background-color: transparent;
}
.Packages_pricing_card_action_btn__16FaL:hover .Packages_pricing_card_action_icon__3fIiu {
	fill: var(--primary-color);
}
.Packages_pricing_card_action_btn_delete__2b9iM {
	background-color: var(--red);
	border: 1px solid var(--red);
}
.Packages_pricing_card_action_btn_delete__2b9iM:hover .Packages_pricing_card_action_icon__3fIiu {
	fill: var(--red);
}
.Packages_pricing_card_add_plan_btn__1pBIS {
	font-family: inherit;
	background-color: #ff9000;
	/* border: 1px solid var(--primary-color); */
	cursor: pointer;
color: #ffffff;
	font-size: 1.2rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	margin-left: 3rem;
}


.Packages_input__360_t div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
	margin-top: 2.5rem;
}

.Packages_input__360_t label{
	color: #ffffff;
	margin:  3rem 1rem;
	font-size: 1.4rem;
}

.Packages_textArea__1WvyP label {
	font-size: 1.4rem;
}

.Packages_textArea__1WvyP div{
	background-color: transparent;
	border-bottom: 1px solid #585858;
	border-radius: 0rem;
	margin-top:.2rem;
	width: 500px;
}

.Packages_textArea__1WvyP {
	height: 1rem;
}

.Packages_selects__UEj8t label{
	margin-top: -1rem;
}
.PromoCodes_card__Container__RBdD-{
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.PromoCodes_single__discountCard__1JTTq{
	background-color:  #121212;
    width: 18vw;
    min-height: 15vh;
    border-radius: 10px;
    box-shadow: var(--box-shadow1);
}

.PromoCodes_promoCodes__xvV_B {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1rem;
	gap: 1rem;
}
.PromoCodes_promoCodeCard__1v7jq {
	background-color: var(--white);
	padding: 2rem 3rem;
	border-radius: 2rem;

	text-align: center;
}
.PromoCodes_promoCodeCard_title__3Bdju {
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;
	min-width: 20rem;
	line-height: 1.4;
}
.PromoCodes_promoCodeCard_validity__3uqcy {
	font-size: 1.3rem;
	font-weight: 600;
}
.PromoCodes_promoCodeCard_code__193o2 {
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--primary-color);
}
.PromoCodes_promoCodeCard_code__193o2 span {
	font-weight: 800;
}
.PromoCodes_promoCodeCard_actions__1MZKy {
	margin-top: 1.5rem;
	border-top: 1px solid var(--light-grey);
	padding-top: 2rem;

	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	justify-content: center;
}
.PromoCodes_promoCodeCard_action_button__3aUcg,
.PromoCodes_promoCodeCard_action_button_delete__W0Tmy {
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .5rem;
	cursor: pointer;
	transition: all .4s;
}
.PromoCodes_promoCodeCard_action_button__3aUcg {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
}
.PromoCodes_promoCodeCard_action_button_delete__W0Tmy {
	background-color: var(--red);
	border: 1px solid var(--red);
}
.PromoCodes_promoCodeCard_action_buttonicon__2ygrY {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}
.PromoCodes_promoCodeCard_action_button__3aUcg:hover {
	background-color: transparent;
}
.PromoCodes_promoCodeCard_action_button_delete__W0Tmy:hover {
	background-color: transparent;
}

.PromoCodes_promoCodeCard_action_button__3aUcg:hover .PromoCodes_promoCodeCard_action_buttonicon__2ygrY {
	fill: var(--primary-color);
}
.PromoCodes_promoCodeCard_action_button_delete__W0Tmy:hover .PromoCodes_promoCodeCard_action_buttonicon__2ygrY {
	fill: var(--red);
}

@media (max-width: 809px) {
	.PromoCodes_single__discountCard__1JTTq{
		width: 42vw;
		}
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	.PromoCodes_card__Container__RBdD-{
		grid-gap: 3rem;
		gap: 3rem;
    padding-left: 2rem;
	}

	.PromoCodes_single__discountCard__1JTTq{
	width: 30vw;
	}
}

.PromoCodes_SelectLabel__1UF8p label{
	top: -1.2rem;
}
.Appversion_input__ne9xv div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
}

.Appversion_input__ne9xv label{
	color: #ffffff;
}

.Appversion_textArea__1DTX5 label {
	font-size: 1.4rem;
}

.Appversion_textArea__1DTX5 div{
	background-color: transparent;
	border-bottom: 1px solid #585858;
	border-radius: 0rem;
}

.Appversion_tableContainer__21Wxf {
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
	height: 35vh;
	overflow-y: auto;
	overflow-x: auto;
}


.Appversion_table__1-SWU {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.Appversion_table__1-SWU tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.Appversion_table__1-SWU tr {
	transition: all 0.8s;
	font-size: 1.2rem;
    background-color: #121212;
	border-bottom: 1px solid #000;
}

.Appversion_table__1-SWU tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

@media only screen and (min-width: 900px) and (max-width: 1088px) {
	.Appversion_form__fy8RW{
		height: 62vh!important;
		overflow:auto;
	}
}
.Salon_form_input__label__wDtYZ {
	color: #ffffff ;
	font-size: 1.2rem;
}

.Salon_tableContainer__1BR7g {
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
	height: 35vh;
	overflow-y: auto;
	overflow-x: auto;
}


.Salon_table__2hIZw {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.Salon_table__2hIZw tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.Salon_table__2hIZw tr {
	transition: all 0.8s;
	font-size: 1.2rem;
    background-color: #121212;
	border-bottom: 1px solid #000;
}

.Salon_table__2hIZw tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

.Salon_input__1-LbU div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
}

.Salon_input__1-LbU label{
	color: #ffffff;
}

.Salon_fileUpload__2ieHf{
    display: flex;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	background-color: #353434b5;
	border-radius: 50%;
	margin-bottom: 2rem;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 1rem 2rem;
	padding-bottom: 2rem;
}

.Salon_fileUpload__2ieHf div {
    border-radius: 50%;
	height: 17rem;
	margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #121212;
  cursor: pointer;
  padding: 0rem 2rem;
  position: relative;
  object-fit: cover;
}

.Salon_fileUpload__2ieHf img {
	height: 17rem;
    width: 18rem;
	border-radius: 50%;
	margin-left: -2rem;
	margin-right: -2rem;
}

.Salon_textArea__3iF3b label {
	font-size: 1.4rem;
}

.Salon_textArea__3iF3b div{
	background-color: transparent;
	border-bottom: 1px solid #585858;
	border-radius: 0rem;
}
.FileUpload_form_input__3ac8P {
	display: flex;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	background-color: var(--pure-white);
	padding: 1.5rem 2rem;
	padding-bottom: 2.5rem;
	border-radius: 2rem;
	margin-bottom: 2rem;
}
.FileUpload_form_input__label__3iT9E {
	color: var(--light-grey);
	font-size: 1.2rem;
}
.FileUpload_form_input__container__TZiur {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--pure-white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}
.FileUpload_form_input__icon__1tqjI {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.FileUpload_form_input__box__3ESkS {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}
.FileUpload_form_input__box__3ESkS::-webkit-input-placeholder {
	color: var(--light-grey);
}
.FileUpload_form_input__error__2QOfq {
	font-size: 1.2rem;
	color: #ff3366;
}

.FileUpload_modalContainer__form--sections__781TC {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
}

.FileUpload_fileUploadText__1fmr8{
	padding-right: 2rem;
	color: #ffffff;
}

.MyProfileScreen_container__G1EzC{
    width: 50%;
    height: 120%;
    display: flex;
    align-items: center;
}

.MyProfileScreen_content__2Zotu{
    background-color: #121212;
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  color: #ffffff;
}

.MyProfileScreen_text_input__qVwfv{
margin-top: 3rem;
}

.MyProfileScreen_change_password_text__3-bSy{
    color: #FF9000;
    font-size: 1.3rem;
    margin-left: 1rem;
    border-bottom: 1px solid #323232;
    cursor: pointer;
}

.MyProfileScreen_update_profile_button__Yjlvv{
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
}

.MyProfileScreen_update_button__3j9jg{
    background-color: #FF9000;
    color: #ffffff;
    padding: 0.6rem 3.6rem 0.6rem;
    font-size: 1.4rem;
    border-radius: 0.7rem;
    border: none;
    margin-right: 1.6rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.MyProfileScreen_timeDurationComponent__2b_Bj{
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


/* .form_input_box{
    font-family: inherit;
	background-color: #ffffff;
	color: var(--dark-grey);
	border: none;
	outline: none;
	width: 50%;
	height: 50%;
} */

/* .coinSection{
   display: flex;
}

.coinIcon{
    display: flex;
    width: 16px;
	height: 42px;
	vertical-align: middle;
	fill: #ffd600;
	margin-right: -24px;
	padding-bottom: 3px;
}

.iconInfo{
    height: 1.5rem;
    width: 1.8rem;
    fill: var(--pure-white);
    top: 13px;
    position: relative;
    right: 8px;
    cursor: pointer;
} */

@media (max-width: 809px) {
    .MyProfileScreen_container__G1EzC{
        width: 100%;
    }
}


@media only screen and (min-width: 810px) and (max-width: 1080px) {

    .MyProfileScreen_container__G1EzC{
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1550px) {
    .MyProfileScreen_container__G1EzC{
        height: 200rem;
    }
}
.UserIconComponent_edit_profile__14cDX {
  margin-top: -10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserIconComponent_icon_my_profile__3IlXO {
  left: 24.6rem;
  width: 20rem;
}

.UserIconComponent_edit_form__1BFDl {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  cursor: pointer;
}

.UserIconComponent_icon_edit__HJXI4 {
  fill: #fff;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}

.UserIconComponent_edit_text__3auc7 {
  color: #ffffff;
  font-size: 1.2rem;
}

@media (max-width: 809px) {
  .UserIconComponent_edit_text__3auc7 {
    font-size: 1rem;
  }

  .UserIconComponent_icon_edit__HJXI4 {
    width: 1.2rem;
  }

}
.ImageUploadComponent_form_input__2A2AP {
	display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  background-color: #323232;
  padding: 0.5rem 1.5rem;
    padding-bottom: 0.5rem;
  padding-bottom: 1.5rem;
  border-radius: 10rem;
  margin-bottom: 2rem;
  width: 100%;
}
.ImageUploadComponent_form_input__label__IxS4_ {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.ImageUploadComponent_btn_file__1m4fW {
	margin-top: 1rem;
	padding: 2rem 3rem;
	border-radius: 2rem;
	height: 17rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	/* background-color: var(--white); */
	background-color: #121212;
	/* border-radius: 1rem; */
	border-radius: 50%;
	cursor: pointer;
	padding: 0rem 2rem;
	/* min-height: 9rem;
	min-width: 16rem; */
	width: 100%;
	position: relative;
}

.ImageUploadComponent_btn_file__1m4fW input[type=file] {
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block;
	border-radius: 45rem;
	height: 100%;
}
.ImageUploadComponent_form_input__container__3CfGh {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--pure-white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}
.ImageUploadComponent_form_input__icon__o4YRE {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.ImageUploadComponent_form_input__box__19anX {
	font-family: inherit;
	background-color: transparent;
	color: #e7e7ea;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}
.ImageUploadComponent_form_input__box__19anX::-webkit-input-placeholder {
	color: var(--light-grey);
}
.ImageUploadComponent_form_input__error__1knLp {
	font-size: 1.2rem;
	color: #ff3366;
}

.ImageUploadComponent_modalContainer__form--sections__3RQeP {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
}

.ImageUploadComponent_fileUploadText__3BZgo{
	padding-right: 2rem;
}


.ImageUploadComponent_fileUpload__label_image__2hlnh{
	height: 17.4rem;
	border-radius: 50%;
	width: 17rem;
	position: absolute;
}


@media (max-width: 690px) {
	.ImageUploadComponent_form_input__2A2AP{
		width: 56%;
  padding-top: 0rem;
  padding: 0rem 1rem;
  padding-bottom: 0.8rem;
  margin-left: 3rem;
    margin-top: 4rem;
	}

	.ImageUploadComponent_btn_file__1m4fW{
		height: 6rem;
	}

	.ImageUploadComponent_fileUpload__label_image__2hlnh{
		height: 6rem;
        border-radius: 50%;
        width: 6rem;
        margin-left: -2rem;
	}
}

.MyProfileInputComponent_content__1561n{
    display: flex;
    width: 50%;
    outline: none;
    padding: 0.8rem 1rem;
    justify-content: center;
}

.MyProfileInputComponent_textarea_content__2oD-i{
    width: 60%;
    outline: none;
    padding: 1.2rem 1rem;
    justify-content: center;
}

.MyProfileInputComponent_textarea_box__2hVlH{
    outline: none;
    margin-top: 1rem;
    align-items: center;
    border: 1px solid #323232;
    height: 10rem;
    width: 100%;
    display: flex;
    color: #fff;
    background-color: transparent;
    font-size: 1.4rem;
    padding: 1rem;
}

.MyProfileInputComponent_title__1wK4S{
    font-size: 1.4rem;
    font-weight: 600;
    width: 10rem;
}

.MyProfileInputComponent_input_box__axjrq{
        width: 100%;
        outline: none;
        margin-left: 1.2rem;
        align-items: center;
        border: none;
        display: flex;
        color: #fff;
        background-color: transparent;
        border-bottom: 1px solid #323232;
        font-size: 1.3rem;
        flex:1 1
}

.MyProfileInputComponent_input_wrapper__FVQF- {
    display: flex;
    align-items: center;
}

.MyProfileInputComponent_country_code__1WV-s {
    margin-right: 2px; 
}

.MyProfileInputComponent_errorMessage__3MXKe{
        color: red;
        margin-left: 1rem;
}

@media (max-width: 712px) {

    .MyProfileInputComponent_content__1561n{
    width: 100%;
    display: block;
       
    }
    .MyProfileInputComponent_title__1wK4S{
        font-size: 1rem;
    }

    .MyProfileInputComponent_input_box__axjrq{
        margin-top: 1rem;
        margin-left: 0rem;
    }
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {

    .MyProfileInputComponent_content__1561n{
width: 60%;
display: flex;
    justify-content: flex-start;
    }

}
.TextBox_form_input__29J1i {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: flex-start;
}
.TextBox_form_input__label__UaGox {
	color: var(--light-grey);
	font-size: 1.2rem;
}
.TextBox_form_input__container__3Ay77 {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
	background-color: var(--white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}
.TextBox_form_input__icon__3B54D {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
}
.TextBox_form_input__box__2Kj52 {
	font-family: inherit;
	background-color: transparent;
	color: var(--dark-grey);
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}
.TextBox_form_input__box__2Kj52::-webkit-input-placeholder {
	color: var(--light-grey);
}
.TextBox_form_input__error__32kCv {
	font-size: 1.2rem;
	color: #ff3366;
}

.TimeDurationComponent_timeComponent__2AS6J{
    display: flex;
    align-items: center;
}

.TimeDurationComponent_timeDurationLabel__2xrSy{
    display: flex;
    margin-left: 2rem;
    justify-content: flex-start;
    align-items: center;
}

.TimeDurationComponent_start_time__2-7-W{
    background-color: transparent;
    border: none;
    color: #fff;
    width: 90%;
    outline: none;
    border-bottom: 1px solid #323232;
    padding-left: 1.4rem;

}

.TimeDurationComponent_end_time__1CHMm{
    background-color: transparent;
    border: none;
    color: #fff;
    /* width: 50%; */
    outline: none;
    border-bottom: 1px solid #323232;
    padding-left: 1.4rem;
    margin-left: 3rem;
}

.TimeDurationComponent_inputLabel__1kGQA{
    width: 10rem;
}

@media (max-width: 809px) {
    .TimeDurationComponent_timeComponent__2AS6J span{
        font-size: 1rem;
    }
}
.PasswordChangeComponent_container__7s28M{
    width: 50%;
    height: 100%;
}

.PasswordChangeComponent_content__es96Z{
    background-color: #121212;
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    color: #ffffff;
}


.PasswordChangeComponent_edit_form__1BikC{
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-left: 2rem;
 }
 
 .PasswordChangeComponent_icon_edit__VDv6u{
    fill: #FF9000;
    width: 2.4rem;
    cursor: pointer;
    height: 8rem;
    margin-left: 0.4rem;
 }
 
 
.PasswordChangeComponent_edit_text__2XNc5{
     color: #FF9000;
     font-size: 1.4rem;
}

.PasswordChangeComponent_text_input__3hRK_{
    margin-top: 3rem;
}

button{
    background: none;
    outline-style: none;
    border: none;
    cursor: pointer;
}

.PasswordChangeComponent_baseInput_input__2Yr6c{
    width: 60%;
    outline: none;
    padding: 0.5rem 1.8rem 0.7rem;
    align-items: center;
    border: none;
    display: flex;
    margin-left: -2.9rem;
    margin-bottom: 1rem;
    position: relative;
}


.PasswordChangeComponent_baseInput_input__2Yr6c input {
        color: #ffffff;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #323232;
        padding-left: 0rem;
        padding-right: 4rem;
}

.PasswordChangeComponent_baseInput_input_title__15IE-{
        display: inline-block;
        font-size: 1.5rem;
        margin-left: 2.8rem;
}


.PasswordChangeComponent_baseInput_input__2Yr6c div svg{
    fill: #ffffff;
}

.PasswordChangeComponent_baseInput_input__2Yr6c div button{
right: 0px!important
}

.PasswordChangeComponent_update_profile_button__6eWu0{
        margin-top: 10rem;
        display: flex;
        justify-content: flex-end;
}
    
.PasswordChangeComponent_update_button__25D1n{
        background-color: #FF9000;
        color: #ffffff;
        padding: 0.6rem 3.6rem 0.6rem;
        font-size: 1.4rem;
        border-radius: .7rem;
        border: none;
        margin-right: 1.6rem;
        margin-bottom: 1rem;
        cursor: pointer;
}
.BaseInput_inputContent__1Dx1w{
    display: flex;
    box-shadow: 0rem 0.2rem 2rem -1.5rem black;
    border: none;
    width: 100%;
    border-radius: 3rem;
    outline: none;
    padding: 1.2rem 1rem;
    justify-content: center;
    margin-left: 2rem;
    padding-right: 5rem
}

.BaseInput_title__XiL5C{
    display: inline-block;
    margin-bottom: 0.6rem;
    color: white;
    font-size: 1.5rem;
    margin-left: 2.8rem;
}

.BaseInput_baseInput__2R_15{
    width: 100%;
    outline: none;
    margin-left: 1.2rem;
    padding-left: 1rem;
    padding: 0.5rem 1.8rem 0.7rem;
    align-items: center;
    border: none;
    border-left: 1px solid #C6C6C6;
    display: flex;
  }

  .BaseInput_errorMessage__PeUtj{
    color: red;
    margin-left: 3rem;
  }

  .BaseInput_input__icon__2uFHO{
    height: 2rem;
    width: 2rem;
    fill: #ffffff;
    position: absolute;
    right: 3rem;
  }
 
  @media (max-width:1000px){
    .BaseInput_inputContent__1Dx1w{
        width: 85%;
    }
  }

  .BaseInput_navigation__icons__3FbrN{
    fill: black;
    height: 2rem;
    width: 2rem;
    grid-gap: 15px;
    gap: 15px;
  }
  
.BaseInput_toggle-password-button__10rZ8{
  position: absolute;
  right: -28px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
/* GoalModal.module.css */
.RewardModal_modalOverlay__2udFO {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .RewardModal_modalContent__Fr2GO {
    background-color: #121212;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    text-align: left;
  }
  
  .RewardModal_modalHeader__3y3tA {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h2 {
    margin: 0;
  }
  
  .RewardModal_closeButton__1j9Vr {
    background-color: #ff9900;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0rem 0.4rem;
   border-radius: 1.2rem;
  }
  
  .RewardModal_modalBody__3TBOA {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; 
    overflow: auto;
    height: 30rem;
}
  
  .RewardModal_modalFooter__2XHps {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .RewardModal_closeModalButton__2TLiK {
    background-color: #ff9900;
    color: #fff;
    border: none;
    padding: 1rem 2.2rem;
    cursor: pointer;
    border-radius: 0.8rem;
  }


  .RewardModal_goalItem__1RMRZ {
    background-color: #333; 
    color: #fff;
    padding: 15px;
    border-radius: 2rem; 
    font-size: 14px;
}

.RewardModal_goalDescription__dnfpp{
    font-size: 1.4rem;
    color: #f90;
}

.RewardModal_goalAction__3BZ3X{
    font-size: 1.4rem;
}
.NewProductsCategoriesScreen_tableContainer__gApmd{
    width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}

.NewProductsCategoriesScreen_table__35V-O {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	background-color: #121212;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.NewProductsCategoriesScreen_table__35V-O tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.NewProductsCategoriesScreen_table__35V-O tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

.NewProductsCategoriesScreen_table__35V-O tr {
	transition: all 0.8s;
	font-size: 1.2rem;
	border-bottom: 1px solid #000;
}

.NewProductsCategoriesScreen_input__aaB6G div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;
}

.NewProductsCategoriesScreen_input__aaB6G label{
	color: #ffffff;
}
.orders_signDataAddBtn__2fkEC {
	font-family: inherit;
	font-size: 1.2rem;
	color: var(--black);
	font-weight: 500;
	background-color: #FF9000;
	border: 1px solid var(--black);
	padding: .5rem 2rem;
	border-radius: 2rem;
	cursor: pointer;
	color: #ffffff;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	transition: all .4s;
}
.orders_signDataAddBtnIcon__1M7SL {
	height: 1.5rem;
	width: 1.5rem;
	fill: #ffffff;
	transition: all .4s;
}
.orders_signDataAddBtn__2fkEC:hover {
	background-color: #333333;
	border: 1px solid var(--black);
	color: var(--white);
}
.orders_signDataAddBtn__2fkEC:hover .orders_signDataAddBtnIcon__1M7SL {
	fill: var(--white);
}
.orders_selectBox__30p4a {
	font-family: inherit;
	border: none;
	outline: none;
	background-color: #FF9000;
	color: var(--white);
	padding: .5rem 1rem;
	border-radius: .5rem;
}

.orders_tableContainer__1iZwb{
    width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}

.orders_table__1Hm8a {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	background-color: #121212;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.orders_table__1Hm8a tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.orders_table__1Hm8a tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

.orders_table__1Hm8a tr {
	transition: all 0.8s;
	font-size: 1.2rem;
	border-bottom: 1px solid #000;
}
.orders_productSummaryTableContainer__2d4ZW{
    width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  background-color: #2d2d2d;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}

.orders_productSummaryTable__3DN-6 {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	background-color: #121212;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.orders_productSummaryTable__3DN-6 tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.orders_productSummaryTable__3DN-6 tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

.orders_productSummaryTable__3DN-6 tr {
	transition: all 0.8s;
	font-size: 1.2rem;
	background-color: #2d2d2d;
}

.Products_productCards__2c61- {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.Products_productCard__19oHx {
  text-decoration: none;
  color: currentColor;
  background-color: #121212;
  box-shadow: var(--box-shadow1);
  border-radius: 2rem;
  overflow: hidden;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.4s;
  width: 25rem;
}

.Products_productCard__19oHx:hover {
  -webkit-transform: translateY(-0.2rem);
          transform: translateY(-0.2rem);
}

.Products_productCard__iImageIcon__QB7RK {
  position: relative;
}

.Products_productCard__iImageIcon_image__2ZQIb {
  width: 20rem;
  height: 20rem;
  border-radius: 2rem;
  object-fit: cover;
  border-radius: 2rem;
}

.Products_productCard__iImageIcon_icon_container__2p_fX {
  background-color: var(--primary-color);
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-40%, -40%);
          transform: translate(-40%, -40%);
}

.Products_productCard__iImageIcon_icon__jNXZN {
  height: 2rem;
  width: 2rem;
  fill: #e7e7ea;
  transition: all 0.4s;
}

.Products_productCard__iImageIcon_icon__jNXZN:hover {
  height: 2.5rem;
  width: 2.5rem;
}

.Products_productCard__title__3Cxee {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
}

.Products_productCard__subTitle__2Esgg {
  font-size: 1.1rem;
  font-weight: 400;
  color: #ffffff;
}

.Products_productCardCategory__16bNt {
  border: 1px solid #ff9000;
  padding: 0.2rem 1rem;
  border-radius: 2rem;
  color: #ffffff;
  font-size: 1.2rem;
}

.Products_productCardStats__3Rask {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Products_productCardStatsCount__ssh3Z {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.Products_productCardStat__3ejj4 {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #ffffff;
  font-size: 1.5rem;
}

.Products_productCardStatIcon__3ZXah {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--blue);
}

.Products_productCardActions__1nD0P {
  border-top: 1px solid var(--white);
  width: 100%;
  margin-top: 1.5rem;
  padding-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Products_productCardActionBtn__3dqMq {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.Products_productCardAction__JjB_l {
  background-color: transparent;
  border: 1px solid #ffffff;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.4s;
  cursor: pointer;
}

.Products_productCardActionIcon__uRH72 {
  height: 1.2rem;
  width: 1.2rem;
  fill: #ffffff;
  transition: all 0.4s;
}

.Products_productCardAction__JjB_l:hover {
  background-color: #ff9000;
  border: 1px solid #ff9000;
}

.Products_productCardAction__JjB_l:hover .Products_productCardActionIcon__uRH72 {
  fill: var(--pure-white);
}

.Products_input__1fSH2 {
  color: #ffffff;
}

.Products_input__1fSH2 div {
  background-color: transparent;
  border-bottom: 1px solid #585858;
  border-radius: 0rem;
  margin-top: 3rem;
}

.Products_input__1fSH2 label {
  color: #ffffff;
  margin-top: 1rem;
}

.Products_textArea__2lNKV div {
  background-color: transparent;
  border: 1px solid #585858;
  border-radius: 1rem;
  margin-top: 1rem;
}
.Products_textArea__2lNKV label {
  color: #ffffff;
  font-size: 1.2rem;
}

.Products_selectBox__24T_M div {
  background-color: transparent;
  border-bottom: 1px solid #585858;
  margin-top: 3rem;
}

.Products_selectBox__24T_M label {
  font-size: 1.2rem;
}

.Products_selectBox__24T_M label svg {
  top: -0.6rem;
  position: absolute;
}

.Products_fileUpload__2CATQ {
  display: flex;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  background-color: #353434b5;
  border-radius: 50%;
  margin-bottom: 2rem;
  grid-gap: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
}

.Products_fileUpload__2CATQ div {
  border-radius: 50%;
  height: 19rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #121212;
  cursor: pointer;
  padding: 0rem 2rem;
  position: relative;
  object-fit: cover;
  width: 20rem;
}

.Products_fileUpload__2CATQ img {
  height: 19rem;
  width: 20rem;
  border-radius: 50%;
  position: absolute;
}

@media (max-width: 809px) {
  .Products_productCard__iImageIcon_image__2ZQIb {
    width: 13rem;
    height: 13rem;
  }

  .Products_productCardCategory__16bNt {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
  .Products_productCards__2c61- {
    grid-gap: 3rem;
    gap: 3rem;
    margin-left: 2rem;
  }
}

@media only screen and (min-width: 810px) and (max-width: 1500px) {
  .Products_form__1rE16 {
    height: 70vh !important;
    overflow: auto;
  }
}

.ProductDetails_productDetails__zLEfQ {
	margin-bottom: 1rem;
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
}
.ProductDetails_productTitle__3oqmo {
	font-size: 2rem;
	font-weight: 600;
	color: white;
}
.ProductDetails_productDots__1sXlw {
	font-size: 1.3rem;
	color: var(--primary-color);
}
.ProductDetails_productPriceQuantity__1CH0g {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}
.ProductDetails_productPrice__yTOKy {
	font-size: 3rem;
	color: white;
}
.ProductDetails_productQuantity__20NpG {
	font-size: 1.3rem;
	color: white;
}
.ProductDetails_productDesc__1QlcY {
	font-size: 1.3rem;
	color: white;
}
.ProductDetails_productCategory__pyVt6 {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	color: var(--primary-color);
	font-size: 1.3rem;
}
.ProductDetails_productCategory__pyVt6 span {
	color: white;
}
.ProductDetails_productCategoryIcon__1y6T- {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--primary-color);
}

.ProductDetails_productDetails__subTitle__XE4RH {
	margin: 1rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--light-grey);
}
.ProductDetails_productDetailsSection__3XJ-m product {
	/* height: 55vh; */
	width: 52vw;
	border-radius: 2rem;
	box-shadow: var(--box-shadow1);
}

.ProductDetails_product_detail_screen_main--content__3KiLt {
	display: flex;
}

.ProductDetails_product_detail_screen_main-left__2emUy {
	border-radius: 25px;
	transition: 0.3s;
	padding: 3rem 4rem;
	width: 60%;
}

.ProductDetails_product_detail_screen_main_right__23IT9 {
	border-radius: 5rem;
	transition: 0.3s;
	padding-top: 2rem;
	display: flex;
}

.ProductDetails_product_detail_screen_main_image__UWDqt {
	border-radius: 2rem;
	height: 25rem;
	max-width: 30rem;
}

.Stylist_tableContainer__1nyd8{
  width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  /* background-color: #121212; */
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}



.Stylist_table__3Qov- {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border-radius: 1rem;
	background-color: #121212;
	overflow: hidden;
}

th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}


.Stylist_table__3Qov- tr th {
	white-space: nowrap;
	/* background-image: linear-gradient(195deg, #42424a, #191919cf); */
	background-color: #000000;
	color: var(--pure-white);
	padding: 1rem;
	padding-left: 2rem;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.Stylist_table__3Qov- tr td {
	white-space: nowrap;
	padding: 1rem;
	padding-left: 2rem;
	color: #ffffff;
	text-align: left;
}

.Stylist_table__3Qov- tr {
	transition: all 0.8s;
	font-size: 1.2rem;
	border-bottom: 1px solid #000;
}
@media (max-width : 400px){
	.Stylist_table__3Qov- tr td{
		padding: 1px;
		height: 10px;
	}
}

.Stylist_table__iconBox__3z-iT {
	display: flex;
	align-items: center;
	/*change this to center*/
	justify-content: left;
	/*change this to center*/
	grid-gap: 0.5rem;
	gap: 0.5rem;
}

.Stylist_table__button__2SLha {
	border: none;
	background-color: transparent;
	border: 0px solid var(--primary-color);
	outline: none;
	padding: .7rem;
	border-radius: 0.5rem;
	cursor: pointer;
	transition: all 0.4s;
	display: flex;
	align-items: center;
	position: relative;
	/* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.Stylist_table__button__2SLha span {
	display: none;
	font-size: 1rem;
	color: var(--pure-white);
	background-color: var(--black);
	padding: 0.5rem 1rem;
	border-radius: 10rem;
	position: absolute;
	top: -2rem;
	/* left: 0; */
	/* transform: translate(-50%, -50%); */
	transition: all 1s;
	right: 1rem;
	z-index: 1000;
}

.Stylist_table__button__2SLha:hover span {
	display: block;
}

.Stylist_table__button__2SLha:hover {
	background-color: var(--primary-color);
	border: 0px solid var(--primary-color);
}

.Stylist_table__button__2SLha:hover .Stylist_table__button--icon__1evrc {
	fill: var(--pure-white);
}

.Stylist_table__button__2SLha .Stylist_table__button--changepwd__20oNN:hover{
	background-color: #FF9000;
  border: 0px solid #FF9000;
}

.Stylist_table__button--icon__1evrc {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
}

.Stylist_table__button--delete__2__59 {
	border: 0px solid var(--red);
}

.Stylist_table__button--delete__2__59:hover {
	background-color: #FF9000;
	border: 0px solid var(--red);
}

.Stylist_table__button--delete__2__59:hover .Stylist_table__button--icon-red__3vfEC {
	fill: var(--white);
}

.Stylist_table__button--icon-red__3vfEC {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
}

.Stylist_table__button__second__2c6tz {
	margin-right: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	grid-gap: 1rem;
	gap: 1rem;
	text-decoration: none;
	color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 5rem;
	font-size: 1.1rem;
	cursor: pointer;
	transition: all .4s;
}

.Stylist_table__button__second__2c6tz span {
	margin-left: 1rem;
}

.Stylist_table__button__secondIconContainer__3qTml {
	margin: .5rem;
	padding: .5rem;
	background-color: #363636;
	border-radius: 50%;
	display: flex;
	align-items: center;
	transition: all .4s;
}

.Stylist_table__button__secondIcon__1PvSS {
	height: 1rem;
	width: 1rem;
	fill: var(--white);
	transition: all .4s;
}

.Stylist_table__button__second__2c6tz:hover {
	background-color: #FF9000;
	border: 0px solid #FF9000;
	color: var(--white);
}

.Stylist_table__button__second__2c6tz:hover .Stylist_table__button__secondIconContainer__3qTml {
	background-color: var(--white);
}

.Stylist_table__button__second__2c6tz:hover .Stylist_table__button__secondIcon__1PvSS {
	fill: var(--dark-grey);
}

.Stylist_input__2VoMu div{
    background-color: transparent;
    border-bottom: 1px solid #585858;
    border-radius: 0rem;

}

.Stylist_input__2VoMu label{
	color: #ffffff;
	font-size: 1.4rem;
}

.Stylist_fileUpload__1dulK{
    display: flex;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	background-color: #353434b5;
	border-radius: 50%;
	margin-bottom: 2rem;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	padding: 1rem 2rem;
	padding-bottom: 2rem;
}

.Stylist_fileUpload__1dulK div {
    border-radius: 50%;
	height: 17rem;
	margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #121212;
  cursor: pointer;
  padding: 0rem 2rem;
  position: relative;
  object-fit: cover;
  width: 19rem;
}


.Stylist_fileUpload__1dulK div img{
  object-fit: cover; 
  width: 25rem;
  height: 17rem;
  margin-left: 0rem;
}

.Stylist_inputTime__s1dku label{
	color: #ffffff;
}

.Stylist_inputTime__s1dku div {
	background-color:transparent;
	border-bottom: 1px solid #585858;
	border-radius: 0rem;
}

.Stylist_multiSelectBox__24_x7 label{
	margin-top: 0rem;
	font-size: 1.2rem;
}

.Stylist_textArea__rIQV- div{
	background-color:transparent;
	border-bottom: 1px solid #585858;
	border-radius: 0rem;
}
.Stylist_textArea__rIQV- label{
	color: #ffffff;
	font-size: 1.4rem;
}

.Stylist_textArea__rIQV- div{
	padding: 0rem;
}

/* .coinIcon {
	width: 18px;
	height: 18px;
	vertical-align: middle;
	fill: #ffd600;
	margin-right: 1px;
	padding-bottom: 3px;
} */

@media  (min-width: 400px) {
	.Stylist_tableContainer__1nyd8 {
	  margin-right: 2rem;
	  margin-bottom: 1.5rem;
	  height: auto;
	}
  }

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	.Stylist_table__3Qov-{
		font-size: 1rem;
		font-weight: 100;
		max-width: 300px;
		padding: 1px;
	}
	.Stylist_table__3Qov- tr th{
		font-weight: 100;
		font-size: 1rem;
		padding: 1px;
	}
	.Stylist_table__3Qov- tbody tr td{
		font-weight: 100;
		font-size: 1rem;
		padding: 2px;
	}
	.Stylist_table__button__second__2c6tz span{
		font-size: 1rem;
	}
}

/* @media only screen and (min-width: 900px) and (max-width: 1088px) {
	.form{
		height: 62vh!important;
		overflow:auto;
	}
} */

 @media  only screen and (min-width: 810px) and (max-width: 1500px) {
	.Stylist_form__2g4qP{
		height: 70vh!important;
		overflow:auto;
	}
}


.textBox {
 position: relative;
 /* background-color: black; */
}

.form_input__error {
 font-size: 1.2rem;
 color: var(--red);
}

.textBox__label {
 position: absolute;
 top: -0.5rem;
 left: 0;
 z-index: 10;
 display: flex;
 padding-top: 2.3rem;
}

.textBox__label__icon {
 height: 2.5rem;
 width: 2.5rem;
 fill: #ffffff;
}

.textBox__input {
 /* background-color: #353434b5; */
 /* border-radius: 0.5rem; */
 overflow: hidden;
 width: 25rem;
 display: flex;
 padding-right: 1rem;
 border-bottom: 1px solid var(--light-grey);
}   

.textBox__input__box {
 width: 100%;
 margin-left: 5rem;
 /* background-color: blue; */
 font-family: inherit;
 border: none;
 outline: none;
 padding: 1rem 1rem;
 /* color: #ffffff; */
}
.textBox__input__box option {
 background-color: #333;
 border: none;
 /* border-color: #a1a1a1; */
 outline: none;
}

.textBox__input--show {
 margin-right: 1rem;
 border: none;
 background-color: transparent;
 cursor: pointer;
}

.textBox__input--show:hover .textBox__input--showIcon {
 fill: #ff3366;
}

.textBox__input--showIcon {
 height: 2rem;
 width: 2rem;
 fill: var(--dark-grey);
 transition: all 0.4s;
}

.form_input {
 display: flex;
 flex-direction: column;
 grid-gap: 0.5rem;
 gap: 0.5rem;
 align-items: flex-start;
 padding-left: 1rem;
 /* padding-top: 1rem; */
}

.form_input__label {
 color: #ffffff;
 font-size: 1.2rem;
 font-size: 14px;
 margin-top: 1rem;
}

.css-1pndypt-Input input{
    opacity: 1 !important;
}
.css-vwja0k input{
    opacity: 1 !important;
}

.textBox__input{
    
}
.Service_chartContentHeader__3c8Ag {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.Service_textBoxInput__2aPhH {
  background-color: #fff;
}

.Service_textBoxInputBox__3hhr4 {
  background-color: #fff;
}

.Service_form_input__label__8v_rn {
  color: var(--light-grey);
  font-size: 1.2rem;
}

.Service_tableContainer__2HfJP {
  width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  background-color: #121212;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}

.Service_table__nR1m7 {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 1rem;
  background-color: #121212;
  overflow: hidden;
}

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.Service_table__nR1m7 tr th {
  white-space: nowrap;
  /* background-image: linear-gradient(195deg, #42424a, #191919cf); */
  background-color: #000000;
  color: var(--pure-white);
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
}

.Service_tableContainer__2HfJP table tr td {
  padding: 1rem;
  padding-left: 2rem;
  color: #ffffff;
  text-align: left;
}

.Service_tableContainer__2HfJP table tr {
  transition: all 0.8s;
  font-size: 1.2rem;
  background-color: #121212;
}

.Service_table-service-container__3Zxvj tr:nth-child(even) {
  background-color: #121212;
}

.Service_accordianContainer__3unYn {
  border-radius: 0px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgb(0 0 0 / 50%);
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  transition: all 0.4s;
  background-color: #171717;
}

.Service_accordianContainer__button__3P9CH {
  border: none;
  background-color: transparent;
  border: 0px solid var(--primary-color);
  outline: none;
  padding: 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  position: relative;
}

.Service_accordianContainer__button__3P9CH span {
  display: none;
  font-size: 1rem;
  color: var(--pure-white);
  background-color: var(--black);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -2rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}

.Service_accordianContainer--icon-move__1C7pO {
  height: 2rem;
  width: 2rem;
  fill: #ffffff;
}

.Service_expandable__3t5Cp {
  transition: all 0.8s;
  font-size: 1.2rem;
  background-color: #121212;
}

.Service_expandable-icon__3PEpo .Service_accordianContainer__button__3P9CH {
  border: none;
  background-color: transparent;
  border: 0px solid;
  outline: none;
  padding: 0rem;
  margin: 0rem;
  grid-gap: 0rem;
  gap: 0rem;
}

.Service_expandable-icon__3PEpo .Service_accordianContainer__button__3P9CH span {
  right: -12rem;
  top: 0rem;
}

.Service_accordianContainer__3unYn:hover .Service_accordianContainer--icon-move__1C7pO {
  height: 2rem;
  width: 2rem;
  fill: #6b6b6b93;
}

.Service_expandable__3t5Cp .Service_accordianContainer--icon-move__1C7pO {
  height: 2rem;
  width: 2rem;
  fill: #4e4e4e00;
}

.Service_expandable__3t5Cp:hover .Service_accordianContainer--icon-move__1C7pO {
  height: 2.5rem;
  width: 2.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  fill: #ffffffc9;
}

.Service_accordian__1sr1v {
  width: 100%;
  transition: all 0.4s;
}

.Service_form_input__label__8v_rn {
  color: #ffffff;
  font-size: 1.2rem;
}

.Service_input__3gWaB div {
  background-color: transparent;
  border-bottom: 1px solid #585858;
  border-radius: 0rem;
  margin-top: 1.5rem !important;
}

.Service_input__3gWaB label {
  color: #ffffff;
}

.Service_inputTime__1kjyZ label {
  color: #ffffff;
}
.Service_input__3gWaB icon {
  fill: #000000;
}

.Service_inputTime__1kjyZ div {
  background-color: transparent;
  /* border-bottom: 1px solid #585858; */
  border-radius: 0rem;
}

.Service_textArea__12Zgc div {
  background-color: transparent;
  border-bottom: 1px solid #585858;
  border-radius: 0rem;
}
.Service_textArea__12Zgc label {
  color: #ffffff;
  font-size: 1.2rem;
}

.Service_selectBox__1z30L div {
  background-color: transparent;
  border-bottom: 1px solid #585858;
  margin-top: 3rem;
}

.Service_selectBox__1z30L label {
  font-size: 1.2rem;
}

.Service_selectBox__1z30L label svg {
  top: 0.6rem;
  position: absolute;
}

@media only screen and (min-width: 810px) and (max-width: 1500px) {
  .Service_form__u9pq5 {
    height: 70vh !important;
    overflow: auto;
  }
}

.StylistAvailability_month__3pFgW {
	width: 100%;
	background: var(--pure-white);
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: var(--box-shadow1);
	overflow-y: hidden;
	overflow-x: auto;
	background-color: #121212;
	padding: 2rem;
}

.StylistAvailability_month__3pFgW ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.StylistAvailability_headers__2pq3c {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	margin-bottom: 2rem;
}

.StylistAvailability_dayMonth__2GnlH {
	text-align: center;
	font-size: 1.3rem;
	line-height: 1.3;
}

.StylistAvailability_dayMonth__2GnlH span {
	font-size: 1.5rem;
	font-weight: 600;
	display: block;
}

.StylistAvailability_prevNextBtn__1u35Y {
	background-color: #e7e7ea;
	border: none;
	outline: none;
	color: var(--primary-color);
	font-family: inherit;
	font-size: 1rem;
	cursor: pointer;
	padding: 1rem;
	/* border-radius: 0.5rem; */
	font-weight: 600;
}

.StylistAvailability_weekdays__2wnMF {
	margin: 0;
	background-color: var(--light-grey);
	display: flex;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
}

.StylistAvailability_weekdays__2wnMF li {
	width: 13.6%;
	padding: 1.2rem 0;
	font-size: 1.5rem;
	color: var(--dark-grey);
	text-align: center;
}

.StylistAvailability_days__2PxhT {
	padding: 10px 0;
	background: var(--white);
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
}

.StylistAvailability_days__2PxhT li {
	min-width: 13.6%;
	height: 10rem;
	border: 1px solid var(--pure-white);
	text-align: center;
	font-size: 2rem;
	color: var(--dark-grey);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	line-height: 1;
	position: relative;
}

.StylistAvailability_lockIcon__19GxM {
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	fill: var(--red);
}

.StylistAvailability_sessionData__19D8R {
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}

.StylistAvailability_sessionData__19D8R span {
	font-size: 3rem;
	color: var(--blue);
}

.StylistAvailability_days__2PxhT li .StylistAvailability_active__3H4eg {
	/* padding: .8rem;
	line-height: 1;
	background: var(--primary-color);
	border-radius: 50%; */
	color: var(--primary-color);
	/* border-bottom: 1px solid var(--primary-color); */
}

.StylistAvailability_totalSection__24atn {
	margin: 2rem 0;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
}

.StylistAvailability_totalSectionData__1NJtt {
	font-size: 1.3rem;
	color: var(--blue);
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
}

.StylistAvailability_totalSectionData__1NJtt span {
	font-size: 1.5rem;
	font-weight: 600;
}

.StylistAvailability_sessionDataAddBtn__QKJr5 {
	margin-top: .5rem;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.1rem;
	color: var(--dark-grey);
	font-weight: 500;
	background-color: transparent;
	border-bottom: 1px solid var(--blue);
	padding: .2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;

	display: flex;
	align-items: center;
	transition: all .4s;
}

.StylistAvailability_sessionDataAddBtnIcon__S31H1 {
	height: 1rem;
	width: 1rem;
	fill: var(--dark-grey);
}

.StylistAvailability_sessionDataAddBtn__QKJr5:hover {
	border-bottom: 1px solid var(--dark-grey);
	color: var(--blue);
}

.StylistAvailability_sessionDataBtn__3JtEb {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.StylistAvailability_sessionDataIcon__2ccWD {
	height: 1rem;
	width: 1rem;
	fill: var(--primary-color);
}

.StylistAvailability_sessionInputs__1ZRxI {
	margin-top: .5rem;
	background-color: var(--black);
	border-radius: 1rem;
	padding: .2rem 1rem;
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: center;
}

.StylistAvailability_sessionInput__2X9fA {
	color: var(--white);
	border: none;
	background-color: transparent;
	width: 5rem;
	font-family: inherit;
	outline: none;
}

.StylistAvailability_sessionInputBtn__1BoMD {
	background-color: transparent;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.StylistAvailability_sessionInputIcon_check__3yIVe {
	height: 1.3rem;
	width: 1.3rem;
	fill: var(--primary-color);
}

.StylistAvailability_sessionInputIcon_close__1tbI4 {
	height: 1rem;
	width: 1rem;
	fill: var(--red);
}

.StylistAvailability_errorText__26hao {
	margin-top: .5rem;
	color: var(--red);
	font-size: .8rem;
}

/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {

	.StylistAvailability_weekdays__2wnMF li,
	.StylistAvailability_days__2PxhT li {
		width: 13.1%;
	}
}

@media screen and (max-width: 420px) {

	.StylistAvailability_weekdays__2wnMF li,
	.StylistAvailability_days__2PxhT li {
		width: 12.5%;
	}

	.StylistAvailability_days__2PxhT li .StylistAvailability_active__3H4eg {
		padding: 2px;
	}
}

@media screen and (max-width: 290px) {

	.StylistAvailability_weekdays__2wnMF li,
	.StylistAvailability_days__2PxhT li {
		width: 12.2%;
	}
}

.StylistAvailability_form_input__label__vTBJ2 {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.StylistAvailability_prevAvailablitys__EQLky {
	background-color: var(--pure-white);
	border-radius: 2rem;
	padding: 2rem;

	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	flex-direction: column;
}

.StylistAvailability_prevAvailablityHeading__sxXRM {
	font-size: 1.5rem;
	font-weight: 600;
}

.StylistAvailability_prevAvailablity__2vtfY {
	background-color: var(--lightest-white);
	border-radius: 2rem;
	padding: 1rem 1.5rem;
}

.StylistAvailability_prevAvailablityTitle__3caFE {
	font-size: 1.3rem;
	font-weight: 600;
}

.StylistAvailability_prevAvailablitySubTitle__y_VjD {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--light-grey);
}

.StylistAvailability_prevAvailablityTime__2waOt {
	font-size: 1rem;
	font-weight: 600;
	color: var(--dark-blue);
	margin-top: 1rem;
}


.StylistAvailability_calendarCSS__33t3K {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}


.StylistAvailability_weekdaysStyle__YUrJb {
	width: 12%;
	padding: 1.2rem 0 !important;
	/* font-size: 1.5rem !important; */
	color: var(--dark-grey) !important;
	text-align: center !important;
}


.StylistAvailability_dynamicRow__2WA44 {
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 1rem;
	gap: 1rem;
}

.StylistAvailability_addButtonslot__37Ael {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.8rem;
	width: 13rem;
	position: relative;
	transition: all 1s ease;
	box-shadow: var(--box-shadow-input);
	box-shadow: var(--box-shadow-input) !important;
	background-image: linear-gradient(195deg, #c66f00, #ff9510);
	color: #fff;
}

.StylistAvailability_dayContainer__3QtI8 {
	flex-direction: row;
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	gap: 2rem;
	font-size: 1.8rem;
	max-width: 100%;
	padding: 10px;
}

.StylistAvailability_slotRow__1K-qr {
	padding: 0rem;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: center;
}

.StylistAvailability_busBtn__okHpf{
	display: flex;
	flex-direction: column;
}

.StylistAvailability_copySlot__3ZPbE{
	background-color: transparent;
	border: none;
	font-size: 12px;
	margin-top: 5px;
	cursor: pointer;
	text-decoration: underline;
	color: #000;
}

.StylistAvailability_slotLoading__17h81{
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	background-color: #5c5c5caf;
	border-radius: 20px;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	left: 0px;
	
}

.StylistAvailability_slotLoadingTxt__3t4RB{
	font-size: 1.5rem;
	position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
	color: #fff;
}

.StylistAvailability_undoButtonslot__19if3{
	display: flex;
	justify-content: flex-end;
}
.StylistAvailability_inputs__3paFs label{
	margin-left: 1rem;
}
.StylistAvailability_businessHoursForm__1QoPv{
	height: 70vh;
}
.StylistAvailability_day__39WmC{
	width: 5rem;
	color: white;
}
.StylistAvailability_container__gSw3E{
	height: 70vh;
 }
@media  only screen and (min-width: 810px) and (max-width: 1080px) {
	.StylistAvailability_slotRow__1K-qr{ 
		display: flex;
		/* flex-direction: column; */
		/* flex-wrap: wrap; */
		align-items: center;
		min-width: auto;
	 }
	 .StylistAvailability_dayContainer__3QtI8{
		min-width: 135%;
	 }
	 .StylistAvailability_modal__2Uv5u{
		width: -webkit-max-content;
		width: max-content;
		height: 90%;
	 }
	 .StylistAvailability_businessHoursForm__1QoPv{
		height: 42vh
	 }
	 .StylistAvailability_container__gSw3E{
		height: 45vh;
	 }
	 /* .slotRow{ 	
		margin-inline:  auto;
		max-width: 30rem;
	 }  */
}

@media only  screen and (max-width: 767px){
	 /* .dayContainer{
		width: fit-content;
	} 
	.slotRow{ 
		/* width: auto; */
	 /* }  */
	 .StylistAvailability_businessHoursForm__1QoPv{
		height: 42vh
	 }
}
/* Picker.module.css */
 .react-google-flight-datepicker .dialog-date-picker {
  background: #121212;
  left: 6px!important;
}

 .react-google-flight-datepicker .month-name {
  color: white;
}

 .react-google-flight-datepicker .weekdays .weekday {
  color: white;
}

 .react-google-flight-datepicker .day .text-day {
  color: white;
}

 .react-google-flight-datepicker .day.selected.hovered::after, 
 .react-google-flight-datepicker .day.selected::after {
  border-radius: 100%;
  background-color: #ff9000 !important;
}

 .react-google-flight-datepicker .day.hovered {
  background-color: black !important;
}

 .react-google-flight-datepicker .day.selected.hovered {
  background: black;
}

 .react-google-flight-datepicker .day.selected.hovered.end {
  background: linear-gradient(90deg, #000000 50%, #000000 50%);
}

 .react-google-flight-datepicker .day:hover::after {
  background: #000000;
}

 .react-google-flight-datepicker .day.hovered:hover {
  background: #000000 !important;
}

 .react-google-flight-datepicker .day.hovered:hover::after {
  border-radius: 100%;
  background: #ff9000 !important;
}

 .react-google-flight-datepicker .day .background-day.first-day,
 .react-google-flight-datepicker .day .background-day.last-day {
  background: none;
}

.TemplateAssign_muscleType__1cGYK {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	align-items: flex-end;
}

.TemplateAssign_muscleType__1cGYK.TemplateAssign_date-picker__xITiF{
	background: red;
}

.TemplateAssign_muscleTypeGoBtn__1k-2a {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	background-color: #ff9000;
	padding: .9rem;
	cursor: pointer;
}


.TemplateAssign_muscleTypeGoBtnIcon__1IO-J {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}


.TemplateAssign_muscleTypeGoBtn__1k-2a,
.TemplateAssign_muscleTypeEditBtn__6k4pl {
	font-family: inherit;
	border: none;
	border-radius: .5rem;
	cursor: pointer;
	color: #e7e7ea;
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
	padding: 0.9rem;
}

.TemplateAssign_muscleTypeEditBtn__6k4pl {
	background-color: #09baa6;
}

.TemplateAssign_muscleTypeEditBtnIcon__1-bPj {
	height: 1.5rem;
	width: 1.5rem;
	fill: #e7e7ea;
}

/* Round Cards */
.TemplateAssign_roundCards__3Wiid {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 2rem;
}

.TemplateAssign_roundCard__1Iiiv {
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem;

	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem;
}

.TemplateAssign_roundCardHeading__u1BVV {
	font-size: 1.5rem;
	font-weight: 600;
}



.TemplateAssign_templates__1gQtc {
	display: flex;
	align-items: center;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	flex-wrap: wrap;
}
.TemplateAssign_template__2BuaD {
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	grid-gap: 2rem;
	gap: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 2rem;
	text-decoration: none;
	color: #000000;
	width: 22rem;
	transition: all .4s;
}
.TemplateAssign_template__2BuaD:hover {
	-webkit-transform: scale(1.03);
	        transform: scale(1.03);
}
.TemplateAssign_templateData__nXQIE {
	display: flex;
	flex-direction: column;
	grid-gap: .5rem;
	gap: .5rem; 
	height: 25rem;
	width: 28rem;
	background-color: #ffffff;
	border-radius: 2rem;
	padding: 2rem 3rem;
}
.TemplateAssign_templateImage__2LXEf {
	padding: 2rem;
	border-radius: 50%;
	background-color: #ff9000;
	display: flex;
	align-items: center;
}
.TemplateAssign_templateImageIcon__2nnJJ {
	height: 4rem;
	width: 4rem;
	fill: #e7e7ea;
}
.TemplateAssign_templateName__2Su48 {
	font-size: 1.4rem;
	font-weight: 600;
	text-align: center;
}
.TemplateAssign_templateButtonIcon__2s-vx {
	height: 3rem;
	width: 3rem;
}
.TemplateAssign_templateBar__2KPbn {
	height: .5rem;
	margin: 1.5rem 1rem;
	width: 26rem;
	background-color: #ff9000;
}

.TemplateAssign_templateCardActions__2Pd7T {
	border-top: 1px solid #e7e7ea;
	width: 100%;
	margin-top: 1.5rem;
	padding-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: auto;
}

.TemplateAssign_templateCardActionBtn__h8m0H {
	display: flex;
	align-items: center;
	grid-gap: .5rem;
	gap: .5rem;
}

.TemplateAssign_templateCardAction__BS-60 {
	background-color: transparent;
	border: 1px solid #12151a;
	padding: .6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .4s;
	cursor: pointer;
}
.TemplateAssign_templateCardActionIcon__3N7oU {
	height: 1.2rem;
	width: 1.2rem;
	fill: #12151a;
	transition: all .4s;
}
.TemplateAssign_templateCardAction__BS-60:hover {
	background-color: #12151a;
	border: 1px solid #12151a;
}
.TemplateAssign_templateCardAction__BS-60:hover .TemplateAssign_templateCardActionIcon__3N7oU {
	fill: #ffffff;
}

.TemplateAssign_roundCardExercise__1Fien {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	font-size: 1.2rem;
	font-weight: 600;
}
.TemplateAssign_roundCardExerciseName__PiiQP {
	font-style: italic;
}
.TemplateAssign_roundCardRepType__1m_Q3 {
	font-size: 1.3rem;
	font-weight: 600;
}
.TemplateAssign_roundCardRepType2__27Aa5 {
	font-size: 1.1rem;
	font-weight: 600;
}
.TemplateAssign_roundCardNote__Ro83F {
	font-size: 1.1rem;
	font-weight: 600;
}
.TemplateAssign_roundCardNextRound__35Doq {
	color: #09baa6;
	font-size: 1.2rem;
	font-weight: 600;
}

.TemplateAssign_roundDetail__1qD8j{
	height: 15rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
/* RewardDescritionModal.module.css */
.RewardScreen_overlay__1AfNu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.RewardScreen_modal__S1XwZ {
  background: #1E1E1E;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
}

.RewardScreen_header__3VeC2 {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.RewardScreen_header__3VeC2 h2{

  color: #ff9900;
}

.RewardScreen_closeButton__3VGVs {
  background-color: #ff9900;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0rem 0.4rem;
 border-radius: 1.2rem;
}

.RewardScreen_body__2dtDb {
  margin-top: 10px;
  color: #FFF;
}

.RewardScreen_body__2dtDb p {
  font-size: 1.2rem;
}

.RewardScreen_closeActionButton__1EDiV {
  margin-top: 20px;
  padding: 1rem 2.2rem;
  border-radius: 0.8rem;
  background-color: #ff9900;
  color: white;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* RewardDescritionModal.module.css */

.RewardScreen_container__3eJNq {
  padding: 20px;
  background-color: #181818; 
  min-height: 65vh;
  overflow: scroll;
}

.RewardScreen_heading__3bRTk {
  font-size: 24px;
  color: #f1c40f;
  margin-bottom: 30px;
  border-bottom: 1px solid #f90;
  border-bottom-width: 1px;
  border-width: 5px;
  width: 5rem;
}

.RewardScreen_grid__3vdLR {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  justify-items: center;
}

.RewardScreen_card__3pqvC {
  background-color: #1e1e1e;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 200px;
  position: relative;
  text-align: center;
}

.RewardScreen_rewardBadge__13Gki {
  font-size: 32px;
  position: absolute;
  top: -20px;
  left: -10px;
}

.RewardScreen_rewardValue__1VTkQ {
  font-size: 5rem;
  color: #f1c40f;
  font-weight: bold;
  margin-bottom: -1rem;
}


.RewardScreen_customSlider__1XiV8::-moz-range-track {
  background: linear-gradient(to right, #4caf50, #4caf50);
}

.RewardScreen_banner__2be4m{
  background-image: url(/static/media/coverbg1.9139bff9.png);
  background-size: cover;
  background-position-y: -122px;
  background-repeat: repeat;
  padding: 14rem 0px 0px;
}

.RewardScreen_rewardTitle__Xfj07 {
  white-space: nowrap;       
  overflow: hidden;          
  text-overflow: ellipsis;   
  max-width: 100%;          
  display: block;
  font-size: 1.2rem;
}

.RewardScreen_coinsCount__2VKol{
  position: relative;
  line-height: 3rem;
  margin-left: 0.6rem;
}

.RewardScreen_outOfCoins__1yFui{
  display: contents;
  font-weight: normal;
  font-size: 1.6rem;
}

.RewardScreen_coinsContent__fCTX1{
 font-weight: bold;
}

.RewardScreen_totalCoins__3A1xI{
  font-size: 5rem;
}

.RewardScreen_totalCoinsText__3Yff5{
  font-size: 2.6rem;
  margin-left: 2rem;
  font-weight: 600;
}

.RewardScreen_totalCoinsText__3Yff5{
  font-size: 2.6rem;
  margin-left: 1.6rem;
  font-weight: 600;
}

.RewardScreen_readMoreButton__2uewb{
  color: #f90;
font-size: 1.1rem;
font-weight:lighter ;
cursor: pointer;
}

.RewardScreen_goalSlider__3E4Ue{
 margin-top: 1rem;
}

.RewardScreen_completedGoals__1xCQk{
  font-size: 1rem;
  margin-top: 1rem;
}

.RewardScreen_rewardValueContainer__30Zsi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RewardScreen_coinsText__399Ve {
  font-size: 2.4rem;
  color: #f1c40f;
}


.RewardScreen_progressBarBackground__1-LsG {
width: 16rem;
height: 0.5rem;
background-color: #e0e0df;
border-radius: 1rem;
overflow: hidden;
margin-top: 1rem;
}

.RewardScreen_progressBarFill__p0MGQ {
height: 100%;
background-color: #ff9900;
transition: width 0.3s ease-in-out;
}




@media (max-width: 600px) {
  .RewardScreen_grid__3vdLR {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}




.RewardContent_appointmentBox__QZOHx{
	border-radius: 1rem;
	background-color: #121212;
	padding: 12px;
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;
	gap: 2rem;
	overflow-y: auto;
	transition: all 0.5s linear;
	
}

.RewardContent_container__2-ukm{
    display: grid;
  grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
  background-color: #000000;
}


.RewardContent_content__1Az4e{
background-color: #000000;
  height: 85vh;
  padding-bottom: 3rem;
  display: flex;
}

.RewardContent_content_dashboard__3G-GD{
	background-color: #000000;
	  margin-top: 3.5rem;
	  height: 85vh;
	  overflow-y: auto;
	  overflow-x: hidden;
	  padding-bottom: 3rem;
	  padding-top: 1rem;
	  display: grid;
	  grid-gap: 2rem;
	  gap: 2rem;
	  grid-template-columns: repeat(2, 2fr);
	}

	.RewardContent_content_box_dashboard__1yled {
		width: 100%;
	  grid-column: 1 / 3;
	  grid-row: 1 / -1;
	  display: flex;
	  flex-direction: column;
	}

.RewardContent_mainContent__2KFsa {
	grid-column: 2 / -1;;
	overflow: hidden;
	transition: all 0.5s ease;
	margin: 0.8rem 0;
	margin-right: 1.5rem;
	background-color:#181818;
	border-radius: 2rem;
	position: absolute;
	left: 22rem;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;
}

.RewardContent_mainContent__2KFsa.RewardContent_mainContent__active__3yAwd{
	fill:0;
	background-color: #000000;
}
.RewardContent_navigation__logos__2h9Ok{
	display: block;
}
.RewardContent_active_logos__3x2pJ{
	display: contents;
}

@media (max-width: 809px) {
	.RewardContent_mainContent__2KFsa{
		left: 18rem;
	}

	.RewardContent_mainContent__2KFsa.RewardContent_mainContent__active__3yAwd{
		fill:0;
		background-color: #000000;
	}
	.RewardContent_navigation__logos__2h9Ok{
		display: block;
	}
	.RewardContent_active_logos__3x2pJ{
		display: contents;
	}

}

@media (max-width: 1280px) {
	.RewardContent_appointmentBox__QZOHx{
display: none;
}
}
.container {
  display: grid;
  grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}

.PriceColumn-description{
  color: rgb(255, 0, 0)!important;
}

.PricingTable{
  background-color: red!important;
}


.mainContent {
  grid-column: 2 / -1;
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 1.5rem 0;
  margin-right: 1.5rem;
  background-color: var(--black);
  /* box-shadow: var(--box-shadow1); */
  border-radius: 2rem;
  position: absolute;
  left: 22rem;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
  /* display: flex; */
}

.mainContent__active {
  transition: all 0.5s ease;
  left: 10rem;
}

.content {
  margin-top: 3.5rem;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.content_box {
  width: 100%;
}

.content_box_dashboard {
  width: 100%;
  grid-column: 1 / 3;
  grid-row: 1 / -1;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: column;
}

.content_box1 {
  width: 100%;
}

.not_data_found {
  width: 100%;
  padding: 3rem;
  text-align: center;
  margin-top: 20rem;
  text-transform: capitalize;
}

.flex {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.flexs {
	display: flex;
	grid-gap: .5rem;
	gap: .5rem;
	align-items: center;
	/* flex-direction: row; */
}

.custom-pricing-table .PricingTable{
  background-color: red!important;
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	
	.flex{
		/* flex-direction: column; */
		align-items: center;
	}
}

@media (max-width: 809px) {
	.flex{
		flex-direction: column ;
		/* width: 50%; */
	}
	
	
}

.tableContainer--paginater {
  padding-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
}

.tableContainer {
  width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  background-color: var(--pure-white);
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: white !important; /* Inherit the text color to avoid browser-specific changes */
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
  color: white !important;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 1rem;
  background-color: #121212;
  overflow: hidden;
}
.tables {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 1rem;
  background-color: var(--pure-white);
  overflow: hidden;
}
th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  /* background-color: #000000; */
}



.table tr th {
  white-space: nowrap;
  /* background-image: linear-gradient(195deg, #42424a, #191919cf); */
  background-color: #121212;
  color: var(--pure-white);
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
}
.tables tr th {
  white-space: nowrap;
  /* background-image: linear-gradient(195deg, #42424a, #191919cf); */
  background-color: #121212;
  color: var(--pure-white);
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
}
.table tr td {
  white-space: nowrap;
  padding: 1rem;
  padding-left: 2rem;
  color: var(--pure-white);
  text-align: left;
}
.tables tr td {
  white-space: nowrap;
  padding: 1rem;
  padding-left: 2rem;
  color: var(--black);
  text-align: left;
}

.table tr {
  transition: all 0.8s;
  font-size: 1.2rem;
  background-color: #353434b5;
}
.tables tr {
  transition: all 0.8s;
  font-size: 1.2rem;
  /* background-color: #353434b5; */
}
.table__icon {
  height: 1.5rem;
  width: 1.5rem;
}

.table__iconBox {
  display: flex;
  align-items: center;
  /*change this to center*/
  justify-content: left;
  /*change this to center*/
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.table__button {
  border: none;
  background-color: transparent;
  border: 0px solid var(--primary-color);
  outline: none;
  padding: 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  position: relative;
  /* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.table__button span {
  display: none;
  font-size: 1rem;
  color: var(--pure-white);
  background-color: var(--black);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -2rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}

.table__button:hover span {
  display: block;
}

.table__button:hover {
  background-color: var(--primary-color);
  border: 0px solid var(--primary-color);
}

.table__button:hover .table__button--icon {
  fill: var(--pure-white);
}

.table__button--icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--pure-white);
}

.table__button--delete {
  border: 0px solid var(--red);
}

.table__button--delete:hover {
  background-color: #ff9000;
  border: 0px solid var(--red);
}

.table__button--delete:hover .table__button--icon-red {
  fill: var(--white);
}
.table__button--delete:hover .table__button--icon-reds {
	fill: var(--white);
  }
.table__button--icon-red {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--pure-white);
}
.table__button--icon-reds {
	height: 1.5rem;
	width: 1.5rem;
	fill: white;
}

.table__button--second {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;

  text-decoration: none;
  color: var(--dark-grey);
  border: 1px solid var(--dark-grey);
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.4s;
}

.table__button--second span {
  margin-left: 1rem;
}

.table__button--secondIconContainer {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: var(--dark-grey);
  border-radius: 50%;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}

.table__button--secondIcon {
  height: 1rem;
  width: 1rem;
  fill: var(--white);
  transition: all 0.4s;
}

.table__button--second:hover {
  background-color: var(--dark-grey);
  border: 0px solid var(--dark-grey);
  color: var(--white);
}

.table__button--second:hover .table__button--secondIconContainer {
  background-color: var(--white);
}

.table__button--second:hover .table__button--secondIcon {
  fill: var(--dark-grey);
}

/* Toggle Button Start */
.switch {
  position: relative;
  display: inline-block;
  min-width: 4rem;
  height: 2.5rem;
}
@media (max-width: 400px) {
  .switch {
    min-width: 3.5rem;
    height: 2.1rem;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-grey);
    box-shadow: var(--box-shadow-input);
    transition: 0.4s;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-grey);
  box-shadow: var(--box-shadow-input);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.7rem;
  width: 1.7rem;
  left: 4px;
  bottom: 4.5px;
  background-color: var(--dark-grey);
  transition: 0.4s;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .slider {
  background-color: #ff9000;
}

.checkbox:focus + .slider {
  box-shadow: 0 0 1px #42424a;
}

.checkbox:checked + .slider:before {
  background-color: var(--white);
  -webkit-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
}

.slider.round {
  border-radius: 3.4rem;
}

.slider.round:before {
  border-radius: 50%;
}

.tableContainer--paginater {
  padding-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
}

.paginationTop {
  margin-bottom: '-4rem';
}

.paginationActive {
  background-color: none;
}

.paginationActive a {
  color: var(--white) !important;
}

.paginationBttns {
  list-style: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.paginationBttns li {
  height: 3rem;
  width: 3rem;
  /* border: 2px solid #777777b0; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.4s;
  overflow: hidden;
}

.paginationBttns a {
  padding: 2rem;
  /* margin: .8rem; */
  /* border: 1px solid var(--primary-color); */
color: rgba(255, 255, 255, 0.515);
  cursor: pointer;
  transition: all 0.4s;
}

.paginationBttns li:hover {
  color: var(--white);
  border: 1px solid white;
  background-color: var(--dark-grey);
}

.paginationBttns a:hover {
  color: var(--white);
}

.break {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 2rem !important;
}

.break a:hover {
  color: var(--primary-color) !important;
}

.paginationDisabled {
  background-color: transparent;
  /* border: 1px solid var(--light-grey) !important; */
}

.paginationDisabled a {
  color: #fff;
  /* background-color: var(--light-dark); */
  /* border: 1px solid white; */
}

.paginationDisabled a:hover {
  /* background-color: var(--light-grey); */
  border: 1px solid white;
  cursor: unset;
}

.check-in-btn {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  outline: none;
  font-family: inherit;
  cursor: pointer;
  border-radius: 0.5rem;
  position: relative;
  transition: all 1s ease;
  color: white;
  padding: 0.8rem;
}

.check-in-btn:hover {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: relative;
  transition: all 1s ease;
  color: white;
}

.check-out-btn {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 1rem;
  position: relative;
  transition: all 1s ease;
  color: white;
}

.check-out-btn:hover {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 1rem;
  position: relative;
  transition: all 1s ease;
  color: white;
}

.check-in-header {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;

  background-color: var(--bg-secondary);
  border-radius: 1rem;
  box-shadow: var(--box-shadow1);
  margin: 2rem 0;
  padding: 3rem 3rem;
}

.Toastify__toast-container--bottom-right {
  z-index: 999999 !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  -webkit-filter: invert(0.8);
          filter: invert(0.8);
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.btn-file {
	margin-top: 1rem;
	/* padding: 2rem 3rem; */
	/* border-radius: 5rem; */
	/* height: 15rem; */
	 display: flex;
	align-items: center;
	justify-content: center;
	text-align: center; 
	/* background-color: var(--white); */
	/* background-color: #121212; */
	border-radius: 1rem;
	/* border-radius: 10rem;
	cursor: pointer; */
	padding: 0rem 0rem;
	/* min-height: 9rem;
	min-width: 16rem; */
  /* width: 100%;
	position: relative;
	object-fit: cover; */

  max-width: 300px; /* Ensure the image fits within its parent */
  height: 200px; /* Maintain aspect ratio */
  overflow: hidden;
  margin: 0 auto;
}

.btn-file input[type='file'] {
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  border-radius: 45rem;
  height: 5rem;
  margin-left: -24rem;
}

.file_upload-image {
  margin-left: -8rem;
}

.file_upload--icon {
  /* fill: var(--dark-grey); */
  fill: #ffffff;
  height: 3rem;
  width: 3rem;
}

.table__button--icon-green {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--pure-white);
}

.table__button--changepwd {
  border: 0px solid var(--blue);
}

.table__button--changepwd:hover {
  background-color: var(--blue);
  border: 0px solid var(--blue);
}

.table__button--changepwd:hover .table__button--icon-green {
  fill: var(--white);
}

.table__button--icon-doc {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--dark-blue);
}

.table__button--doc {
  border: 0px solid var(--dark-blue);
}

.table__button--doc:hover {
  background-color: var(--dark-blue);
  border: 0px solid var(--dark-blue);
}

.table__button--doc:hover .table__button--icon-doc {
  fill: var(--white);
}

.table__button--icon-setting {
  height: 1.2rem;
  width: 1.2rem;
  fill: #eb2f00;
}

.table__button--setting {
  border: none;
  background-color: transparent;
  border: 0px solid #eb2f00;
  outline: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
}

.table__button--setting:hover {
  background-color: #eb2f00;
  border: 0px solid #eb2f00;
}

.table__button--setting:hover .table__button--icon-setting {
  fill: var(--white);
}

.textCapitalize {
  text-transform: capitalize;
}

.inputBox__toggle {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  color: var(--primary-color);
}

.inputBox__toggle--label {
  font-size: 1.5rem;
}

.sign_hide {
  display: none;
}

.sign_show {
  display: block;
}

.tableContainer_order .textBox__input--box {
  margin-left: 0rem;
}

.text_wrap_desc {
  white-space: pre-wrap !important;
  max-width: 25rem;
}

#progress-spinner {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

#middle-circle {
  position: absolute;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
}

.month_filter {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  background-color: var(--bg-secondary);
  border-radius: 1rem;
  box-shadow: var(--box-shadow1);
  margin: 1rem 0;
}

.month_filter .textBox__input--box {
  margin-left: 0rem;
}

.rmdp-panel {
  min-width: 150px;
}

.timeDiv {
  display: block;
  max-width: 70rem;
  flex-direction: row;
  padding-bottom: 1rem;
}

.timeButton {
  padding: 0.4rem;
  border-radius: 5px;
  min-width: 6rem;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid var(--bg-primary);
  margin-right: 1rem;
  font-family: inherit;
  font-size: 1.1rem;
  float: left;
  margin-bottom: 1rem;
  cursor: pointer;
}

.rmdp-calendar {
  height: -webkit-max-content !important;
  height: max-content !important;
  padding: 4px !important;
  width: 40rem !important;
}

.rmdp-day-picker {
  display: flex !important;
  flex-direction: column !important;
}

.css-1s2u09g-control {
  /* background-color: rgb(255, 255, 255) !important; */
  /* border-color: hsl(240deg 7% 91%) !important; */
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:hover{
  border: none ;
}
.css-t3ipsp-control{
  border: none !important;
  box-shadow: none !important;
}

.rmdp-week-day {
  color: black !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--light-grey) !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: black !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: black !important;
}

.rmdp-arrow-container:hover {
  background-color: black !important;
  color: black !important;
}

.rmdp-arrow {
  border: solid #000000 !important;
  color: black !important;
  border-width: 0 2px 2px 0 !important;
}

/* Full Calendar CSS */
.fc .fc-theme-standard {
  font-size: 1.2rem;
}

.fc-timegrid-slot-label-cushion,
.fc-timegrid-axis-cushion,
.fc-list-day-text,
.fc-list-event-title {
  font-size: 1.2rem;
}

.fc-view-harness {
  height: 70vh !important;
}

.fc .fc-timegrid-axis-frame {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}


.fc-liquid-hack .fc-daygrid-day-frame {
  position: static;

  /* border: 1px solid #ffffff24; */

  border-top: 0.2px solid #ffffff24;

  /* border-right: 0.2px solid #ffffff24; */
}
.fc-liquid-hack td{
  position: static;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}
.fc .fc-timegrid-col-frame{
  position: relative !important;
}

/* .fc-theme-standard td, .fc-theme-standard th {
	border-color: 1px solid #cccccc73;
} */
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #fdfdfd3c;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #fdfdfd3c;
}

.fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 2rem;
  font-size: 1.8rem;
}

.fc-list-event {
  font-size: 1.2rem;
  background: transparent !important;
}

.fc-toolbar-title {
  font-weight: 500;
}

.fc .fc-toolbar {
  margin-bottom: 2rem;
}

.fc .fc-button {
  background-color: #e7e7ea;
  border: none;
  outline: none;
  color: #000000;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: var(--box-shadow1);
}

.fc .fc-scrollgrid {
  border-radius: 1rem;
}

.fc-toolbar-chunk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fc .fc-toolbar .fc--button:empty,
.fc .fc-toolbar .fc-toolbar-chunk:empty {
  display: none;
}

.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
  /* background-color: rgb(255, 255, 255);
	background-color: var(--fc-today-bg-color, rgb(255, 255, 255)); */
  height: 3rem;
}

.fc .fc-daygrid-day {
  background-color: #121212;
}

.fc-scrollgrid-sync-table tr:nth-child(even) {
  background-color: #ffffff;
}

.fc .fc-daygrid-day-number {
  color: white;
}

.fc .fc-daygrid-day.fc-day-today {
  background: #353015;
}

.fc-today-button fc-button fc-button-primary {
  background: #db7d06 !important ;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fc .fc-button-primary:disabled {
  background: #db7d06 !important ;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--primary-color);
}

.fc .fc-button-primary:hover {
  background-color: var(--primary-color);
}

.css-b62m3t-container {
  width: 24rem;
  position: unset !important;
  margin-left: auto !important;
}

.react-select {
  width: 100%;
  max-width: 200px;
}

.css-1s2u09g-control {
  background-color: rgb(255, 255, 255) !important;
  border-color: hsl(240deg 7% 91%) !important;
}

.css-1pndypt-Input {
  color: rgb(0, 0, 0) !important;
}

.css-tlfecz-indicatorContainer {
  color: hsl(0deg 0% 0%) !important;
}
.css-166bipr-Input{
  color: white !important;
}
.css-13cymwt-control{
    visibility: visible !important;
    background-color: transparent !important;
    /* border-color: hsl(0, 0%, 80%); */
    border-style: none !important;
    color: white !important;
    
}
.css-t3ipsp-control {
  background-color: transparent !important;
  border-style: none;
  color: white !important;
} 

 .css-8aqfg3-menu{
  background-color: #222222 !important;
} 
 .css-166bipr-Input{
  color: white !important;
  visibility: visible !important;
}
.css-1fdsijx-ValueContainer{
  /* color: white !important; */

} 
.css-1dimb5e-singleValue{
  color:white;
}
.css-1jqq78o-placeholder{
  color:  white !important;
}
#react-select-2-listbox {
  z-index: 10 !important;
}

.fc-today-button {
  margin: 0rem 0.6rem !important;
}

.dayButton {
  background-color: #e7e7ea;
  border: none;
  outline: none;
  color: var(--primary-color);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: var(--box-shadow1);
  margin: 2rem 1rem;
}

.dayButton:hover {
  background-color: #000000;
  border: none;
  outline: none;
  color: var(--primary-color);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: var(--box-shadow1);
  margin: 2rem 1rem;
}

.fc-bookAppointment-button {
  border: 1px solid var(--primary-color) !important;
  color: #ffffff !important;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.8rem !important;
  width: 13rem;
  position: relative;
  transition: all 1s ease;
  margin-left: 1rem !important;
  box-shadow: var(--box-shadow-input) !important;
  box-shadow: var(--box-shadow-input) !important;
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
}

.table-service-container {
  width: 100%;
  border-radius: 0rem;
}

.table-contain tr td {
  width: 10%;
  white-space: break-spaces;
}

.tdTextAlign {
  text-align: center;
}

/* .table-service-container tr:nth-child(even) {
	background-color: #fff;
} */

.table-contain tbody td:first-child {
  width: 2%;
  text-align: left;
}

.table-contain tbody td:nth-child(2) {
  width: 10%;
  max-width: 10%;
  text-align: left;
}

.main-table-contain th:first-child {
  width: 2.5%;
  text-align: center;
}

.main-table-contain th:nth-child(2) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(3) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(4) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(5) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(6) {
  width: 10%;
  text-align: left;
}

.expandable {
  box-shadow: inset 0 -5em 0em rgb(0 0 0 / 19%), 0 0 0 0px rgb(255 255 255 / 0%), 0 0em 0em rgb(0 0 0 / 19%);
}

.accordianContainer {
  border-radius: 0px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgb(0 0 0 / 50%);
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  transition: all 0.4s;
}

.active {
  /* margin-top: 2rem; */
}

.accordianContainer__button {
  border: none;
  background-color: transparent;
  border: 0px solid var(--primary-color);
  outline: none;
  padding: 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  position: relative;
}

.accordianContainer__button span {
  display: none;
  font-size: 1rem;
  color: var(--pure-white);
  background-color: var(--black);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -2rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}

.accordianContainer--icon-move {
  height: 2rem;
  width: 2rem;
  fill: #ffffff;
}

.expandable-icon .accordianContainer__button {
  border: none;
  background-color: transparent;
  border: 0px solid;
  outline: none;
  padding: 0rem;
  margin: 0rem;
  grid-gap: 0rem;
  gap: 0rem;
}

.expandable-icon .accordianContainer__button span {
  right: -12rem;
  top: 0rem;
}

.accordianContainer:hover .accordianContainer--icon-move {
  height: 2rem;
  width: 2rem;
  fill: #6b6b6b93;
}

.expandable .accordianContainer--icon-move {
  height: 2rem;
  width: 2rem;
  fill: #4e4e4e00;
}

.expandable:hover .accordianContainer--icon-move {
  height: 2.5rem;
  width: 2.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  fill: #ffffffc9;
}

.accordian {
  width: 100%;
  transition: all 0.4s;
}

.panel {
  display: none;
}

.show {
  display: block;
}

.appointment_box {
  width: 30rem;
  height: 90vh;
  border-radius: 1rem;
  background-color: #000000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  /* height: 80vh; */
  overflow-y: auto;
  transition: all 0.5s linear;
}

.appointment_box.active {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appointment_box1 {
  width: 100%;
  height: 90vh;
  border-radius: 1rem;
  background-color: #000000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  height: 80vh;
  overflow-y: auto;
  transition: all 0.5s linear;
}

.stylist_img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.stylist_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.stylist_box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.stylist_text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stylist_text1 span {
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.stylist_text span {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.stylist_text_active {
  display: none;
}

.stylist_text p {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.current_appointment {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
}

.current_appointment_active {
  display: none;
}

.small_appointment {
  display: none;
}

.small_appointment_active {
  display: block;
}

.small_appointment_container {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
}

.small_name {
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
  background-color: #545454;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small_name h1 {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

.fc-col-header {
  width: 100% !important;
}

.fc-timegrid-body {
  width: 100% !important;
}

.fc-timegrid-slots table {
  width: 100% !important;
}

.fc-timegrid-cols table {
  width: 100% !important;
}

@media screen and (max-width: 900px) {
  .fc .fc-toolbar-title {
    font-size: 1em;
    margin: 0;
  }
}

@media screen and (max-width: 920px) {
  .fc-col-header {
    width: 920px !important;
  }

  .fc-timegrid-body {
    width: 920px !important;
  }

  .fc-timegrid-slots table {
    width: 920px !important;
  }

  .fc-timegrid-cols table {
    width: 920px !important;
  }

  .fc-view-harness {
    height: 70vh !important;
    width: 920px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 600px) {
  .fc-col-header {
    width: 600px !important;
  }

  .fc-timegrid-body {
    width: 600px !important;
  }

  .fc-timegrid-slots table {
    width: 600px !important;
  }

  .fc-timegrid-cols table {
    width: 600px !important;
  }

  .fc-view-harness {
    height: 70vh !important;
    width: 600px;
    overflow-x: scroll;
  }
}

.current_appointment h2 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.appointment_card {
  background: black;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
}

.appointment_card_active {
  display: none;
}

.appointment_time {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.time_circle {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #fff;
}

.appointment_name h1 {
  font-size: 22px;

  font-weight: 500;
}

.appointment_name p {
  font-size: 12px;
  font-weight: 200;
}

.appointment__button {
  position: absolute;
  top: 15%;
  /* right: 24%; */
  left: -15px;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
}

.appointment__button1 {
  position: absolute;
  top: 15%;
  /* right: 24%; */
  left: -15px;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
}

.appointment__button_active {
  right: 10.5%;
}

.appointment_upcoming_active {
  display: none;
}

.qty-box .input-group {
  justify-content: center;
  display: flex;
}

.qty-box .input-group span button {
  background: #ffffff !important;
  border: 1px solid #ced4da;
}

.qty-box .input-group .form-control {
  text-align: center;
  width: 80px;
  flex: unset;
  height: 3.4rem;
  background: #ffffff !important;
  border: 1px solid #ced4da;
  font-size: 1.5rem;
  font-weight: 600;
}

.qty-box .input-group button {
  background-color: transparent;
  border: 0;
  color: #777777;
  cursor: pointer;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
}

.qty-box .input-group button i {
  font-weight: 900;
  color: #222222;
}

.qty-box .input-group .icon {
  padding-right: 0;
}

.table__status,
.table__status_deactive {
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  /* width: 8rem; */
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.table__status {
  border: 1px solid var(--light-grey);
}

.table__status span,
.table__status_deactive span {
  font-size: 3rem;
  line-height: 0.9rem;
}

.table__status span {
  color: green;
}

.table__status_deactive {
  border: 1px solid var(--light-grey);
  color: var(--light-grey);
}

.table__status_deactive span {
  color: red;
}

.fc-toolbar-title {
  color: var(--white);
}

.StripeElement.PaymentRequestButton {
  padding: 0;
  height: 40px;
  width: 250px;
  margin-left: 80px;
}

@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .current_appointment h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .table-service-container {
    width: 200px;
  }
  .main-table-contain th:first-child {
    width: 1.5%;
  }
  .main-table-contain th:nth-child(2) {
    width: 10px;
  }
  .table tr th {
    font-weight: 100;
    font-size: 10px;
    padding: 3px;
  }
}

/* Add this CSS to your styles */
.fc-day-header.fc-widget-header {
  display: none;
}




.CongratulationModal_modalOverlay__E0Mab {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.712); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.CongratulationModal_modalContent__1NXO0 {
    background-color: #171717;;
    border-radius: 10px;
    height: 34rem;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 50rem;
    display: flex;
  justify-content: center;
}

.CongratulationModal_coinText__3nRTY {
    color: #fff;
    font-size: 2.6rem;
    display: flex;
    justify-content: center;
}

.CongratulationModal_coins__2mv8o{
    color: #ff9900;
    padding-left: 1rem;
  padding-right: 1rem;
}


.CongratulationModal_animationContainer__1LCKw{
    position: absolute;
    display: flex;
    bottom: 10rem;
    right: 1rem;
}
.CongratulationModal_congratsAnimationContainer__PllLN{
    position: absolute;
    width: 100%;
}

.CongratulationModal_animationTextContainer__1YUAi{
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.CongratulationModal_congratulationText__3wQ-3{
  color: #FFD101;
  font-size: 3rem;
  margin-top: -6rem;
}


.CongratulationModal_closeButton__3gWnh {
  background-color: #ff9900;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0rem 0.4rem;
  z-index: 1;
 border-radius: 1.2rem;
 position: absolute;
  right: 2rem;
  width: 2rem;
}

