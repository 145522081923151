.usersignup-container{
    width: 100%;
    height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userSP_box{
  width: 400px;
  height: fit-content;
  border-radius: 1rem;
  /* background-color: white; */
  padding:0 20px;
  background-color: rgba(255, 255, 255, 0.675);
      /* opacity: 0.8; */
    border-radius:1rem;
    backdrop-filter: blur(5px);
}