.container {
  display: grid;
  grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}

.PriceColumn-description{
  color: rgb(255, 0, 0)!important;
}

.PricingTable{
  background-color: red!important;
}


.mainContent {
  grid-column: 2 / -1;
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 1.5rem 0;
  margin-right: 1.5rem;
  background-color: var(--black);
  /* box-shadow: var(--box-shadow1); */
  border-radius: 2rem;
  position: absolute;
  left: 22rem;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
  /* display: flex; */
}

.mainContent__active {
  transition: all 0.5s ease;
  left: 10rem;
}

.content {
  margin-top: 3.5rem;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
  display: flex;
  gap: 2rem;
}

.content_box {
  width: 100%;
}

.content_box_dashboard {
  width: 100%;
  grid-column: 1 / 3;
  grid-row: 1 / -1;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.content_box1 {
  width: 100%;
}

.not_data_found {
  width: 100%;
  padding: 3rem;
  text-align: center;
  margin-top: 20rem;
  text-transform: capitalize;
}

.flex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.flexs {
	display: flex;
	gap: .5rem;
	align-items: center;
	/* flex-direction: row; */
}

.custom-pricing-table .PricingTable{
  background-color: red!important;
}

@media only screen and (min-width: 810px) and (max-width: 1080px) {
	
	.flex{
		/* flex-direction: column; */
		align-items: center;
	}
}

@media (max-width: 809px) {
	.flex{
		flex-direction: column ;
		/* width: 50%; */
	}
	
	
}

.tableContainer--paginater {
  padding-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
}

.tableContainer {
  width: 100%;
  margin-top: 1rem;
  margin-right: 5rem;
  margin-bottom: 2.5rem;
  background-color: var(--pure-white);
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: white !important; /* Inherit the text color to avoid browser-specific changes */
  transition: background-color 5000s ease-in-out 0s !important;
  color: white !important;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 1rem;
  background-color: #121212;
  overflow: hidden;
}
.tables {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 1rem;
  background-color: var(--pure-white);
  overflow: hidden;
}
th {
  position: sticky;
  top: 0;
  z-index: 100;
  /* background-color: #000000; */
}



.table tr th {
  white-space: nowrap;
  /* background-image: linear-gradient(195deg, #42424a, #191919cf); */
  background-color: #121212;
  color: var(--pure-white);
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
}
.tables tr th {
  white-space: nowrap;
  /* background-image: linear-gradient(195deg, #42424a, #191919cf); */
  background-color: #121212;
  color: var(--pure-white);
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
}
.table tr td {
  white-space: nowrap;
  padding: 1rem;
  padding-left: 2rem;
  color: var(--pure-white);
  text-align: left;
}
.tables tr td {
  white-space: nowrap;
  padding: 1rem;
  padding-left: 2rem;
  color: var(--black);
  text-align: left;
}

.table tr {
  transition: all 0.8s;
  font-size: 1.2rem;
  background-color: #353434b5;
}
.tables tr {
  transition: all 0.8s;
  font-size: 1.2rem;
  /* background-color: #353434b5; */
}
.table__icon {
  height: 1.5rem;
  width: 1.5rem;
}

.table__iconBox {
  display: flex;
  align-items: center;
  /*change this to center*/
  justify-content: left;
  /*change this to center*/
  gap: 0.5rem;
}

.table__button {
  border: none;
  background-color: transparent;
  border: 0px solid var(--primary-color);
  outline: none;
  padding: 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  position: relative;
  /* background-image: linear-gradient(195deg, #42424a, #191919); */
}

.table__button span {
  display: none;
  font-size: 1rem;
  color: var(--pure-white);
  background-color: var(--black);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -2rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}

.table__button:hover span {
  display: block;
}

.table__button:hover {
  background-color: var(--primary-color);
  border: 0px solid var(--primary-color);
}

.table__button:hover .table__button--icon {
  fill: var(--pure-white);
}

.table__button--icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--pure-white);
}

.table__button--delete {
  border: 0px solid var(--red);
}

.table__button--delete:hover {
  background-color: #ff9000;
  border: 0px solid var(--red);
}

.table__button--delete:hover .table__button--icon-red {
  fill: var(--white);
}
.table__button--delete:hover .table__button--icon-reds {
	fill: var(--white);
  }
.table__button--icon-red {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--pure-white);
}
.table__button--icon-reds {
	height: 1.5rem;
	width: 1.5rem;
	fill: white;
}

.table__button--second {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  text-decoration: none;
  color: var(--dark-grey);
  border: 1px solid var(--dark-grey);
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.4s;
}

.table__button--second span {
  margin-left: 1rem;
}

.table__button--secondIconContainer {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: var(--dark-grey);
  border-radius: 50%;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}

.table__button--secondIcon {
  height: 1rem;
  width: 1rem;
  fill: var(--white);
  transition: all 0.4s;
}

.table__button--second:hover {
  background-color: var(--dark-grey);
  border: 0px solid var(--dark-grey);
  color: var(--white);
}

.table__button--second:hover .table__button--secondIconContainer {
  background-color: var(--white);
}

.table__button--second:hover .table__button--secondIcon {
  fill: var(--dark-grey);
}

/* Toggle Button Start */
.switch {
  position: relative;
  display: inline-block;
  min-width: 4rem;
  height: 2.5rem;
}
@media (max-width: 400px) {
  .switch {
    min-width: 3.5rem;
    height: 2.1rem;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-grey);
    box-shadow: var(--box-shadow-input);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-grey);
  box-shadow: var(--box-shadow-input);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.7rem;
  width: 1.7rem;
  left: 4px;
  bottom: 4.5px;
  background-color: var(--dark-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .slider {
  background-color: #ff9000;
}

.checkbox:focus + .slider {
  box-shadow: 0 0 1px #42424a;
}

.checkbox:checked + .slider:before {
  background-color: var(--white);
  -webkit-transform: translateX(1.3rem);
  -ms-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
}

.slider.round {
  border-radius: 3.4rem;
}

.slider.round:before {
  border-radius: 50%;
}

.tableContainer--paginater {
  padding-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
}

.paginationTop {
  margin-bottom: '-4rem';
}

.paginationActive {
  background-color: none;
}

.paginationActive a {
  color: var(--white) !important;
}

.paginationBttns {
  list-style: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.paginationBttns li {
  height: 3rem;
  width: 3rem;
  /* border: 2px solid #777777b0; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.4s;
  overflow: hidden;
}

.paginationBttns a {
  padding: 2rem;
  /* margin: .8rem; */
  /* border: 1px solid var(--primary-color); */
color: rgba(255, 255, 255, 0.515);
  cursor: pointer;
  transition: all 0.4s;
}

.paginationBttns li:hover {
  color: var(--white);
  border: 1px solid white;
  background-color: var(--dark-grey);
}

.paginationBttns a:hover {
  color: var(--white);
}

.break {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 2rem !important;
}

.break a:hover {
  color: var(--primary-color) !important;
}

.paginationDisabled {
  background-color: transparent;
  /* border: 1px solid var(--light-grey) !important; */
}

.paginationDisabled a {
  color: #fff;
  /* background-color: var(--light-dark); */
  /* border: 1px solid white; */
}

.paginationDisabled a:hover {
  /* background-color: var(--light-grey); */
  border: 1px solid white;
  cursor: unset;
}

.check-in-btn {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  outline: none;
  font-family: inherit;
  cursor: pointer;
  border-radius: 0.5rem;
  position: relative;
  transition: all 1s ease;
  color: white;
  padding: 0.8rem;
}

.check-in-btn:hover {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: relative;
  transition: all 1s ease;
  color: white;
}

.check-out-btn {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 1rem;
  position: relative;
  transition: all 1s ease;
  color: white;
}

.check-out-btn:hover {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 1rem;
  position: relative;
  transition: all 1s ease;
  color: white;
}

.check-in-header {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: max-content;

  background-color: var(--bg-secondary);
  border-radius: 1rem;
  box-shadow: var(--box-shadow1);
  margin: 2rem 0;
  padding: 3rem 3rem;
}

.Toastify__toast-container--bottom-right {
  z-index: 999999 !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.btn-file {
	margin-top: 1rem;
	/* padding: 2rem 3rem; */
	/* border-radius: 5rem; */
	/* height: 15rem; */
	 display: flex;
	align-items: center;
	justify-content: center;
	text-align: center; 
	/* background-color: var(--white); */
	/* background-color: #121212; */
	border-radius: 1rem;
	/* border-radius: 10rem;
	cursor: pointer; */
	padding: 0rem 0rem;
	/* min-height: 9rem;
	min-width: 16rem; */
  /* width: 100%;
	position: relative;
	object-fit: cover; */

  max-width: 300px; /* Ensure the image fits within its parent */
  height: 200px; /* Maintain aspect ratio */
  overflow: hidden;
  margin: 0 auto;
}

.btn-file input[type='file'] {
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  border-radius: 45rem;
  height: 5rem;
  margin-left: -24rem;
}

.file_upload-image {
  margin-left: -8rem;
}

.file_upload--icon {
  /* fill: var(--dark-grey); */
  fill: #ffffff;
  height: 3rem;
  width: 3rem;
}

.table__button--icon-green {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--pure-white);
}

.table__button--changepwd {
  border: 0px solid var(--blue);
}

.table__button--changepwd:hover {
  background-color: var(--blue);
  border: 0px solid var(--blue);
}

.table__button--changepwd:hover .table__button--icon-green {
  fill: var(--white);
}

.table__button--icon-doc {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--dark-blue);
}

.table__button--doc {
  border: 0px solid var(--dark-blue);
}

.table__button--doc:hover {
  background-color: var(--dark-blue);
  border: 0px solid var(--dark-blue);
}

.table__button--doc:hover .table__button--icon-doc {
  fill: var(--white);
}

.table__button--icon-setting {
  height: 1.2rem;
  width: 1.2rem;
  fill: #eb2f00;
}

.table__button--setting {
  border: none;
  background-color: transparent;
  border: 0px solid #eb2f00;
  outline: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
}

.table__button--setting:hover {
  background-color: #eb2f00;
  border: 0px solid #eb2f00;
}

.table__button--setting:hover .table__button--icon-setting {
  fill: var(--white);
}

.textCapitalize {
  text-transform: capitalize;
}

.inputBox__toggle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  color: var(--primary-color);
}

.inputBox__toggle--label {
  font-size: 1.5rem;
}

.sign_hide {
  display: none;
}

.sign_show {
  display: block;
}

.tableContainer_order .textBox__input--box {
  margin-left: 0rem;
}

.text_wrap_desc {
  white-space: pre-wrap !important;
  max-width: 25rem;
}

#progress-spinner {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

#middle-circle {
  position: absolute;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background-color: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: bold;
}

.month_filter {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: max-content;
  background-color: var(--bg-secondary);
  border-radius: 1rem;
  box-shadow: var(--box-shadow1);
  margin: 1rem 0;
}

.month_filter .textBox__input--box {
  margin-left: 0rem;
}

.rmdp-panel {
  min-width: 150px;
}

.timeDiv {
  display: block;
  max-width: 70rem;
  flex-direction: row;
  padding-bottom: 1rem;
}

.timeButton {
  padding: 0.4rem;
  border-radius: 5px;
  min-width: 6rem;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid var(--bg-primary);
  margin-right: 1rem;
  font-family: inherit;
  font-size: 1.1rem;
  float: left;
  margin-bottom: 1rem;
  cursor: pointer;
}

.rmdp-calendar {
  height: max-content !important;
  padding: 4px !important;
  width: 40rem !important;
}

.rmdp-day-picker {
  display: flex !important;
  flex-direction: column !important;
}

.css-1s2u09g-control {
  /* background-color: rgb(255, 255, 255) !important; */
  /* border-color: hsl(240deg 7% 91%) !important; */
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:hover{
  border: none ;
}
.css-t3ipsp-control{
  border: none !important;
  box-shadow: none !important;
}

.rmdp-week-day {
  color: black !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--light-grey) !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: black !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: black !important;
}

.rmdp-arrow-container:hover {
  background-color: black !important;
  color: black !important;
}

.rmdp-arrow {
  border: solid #000000 !important;
  color: black !important;
  border-width: 0 2px 2px 0 !important;
}

/* Full Calendar CSS */
.fc .fc-theme-standard {
  font-size: 1.2rem;
}

.fc-timegrid-slot-label-cushion,
.fc-timegrid-axis-cushion,
.fc-list-day-text,
.fc-list-event-title {
  font-size: 1.2rem;
}

.fc-view-harness {
  height: 70vh !important;
}

.fc .fc-timegrid-axis-frame {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}


.fc-liquid-hack .fc-daygrid-day-frame {
  position: static;

  /* border: 1px solid #ffffff24; */

  border-top: 0.2px solid #ffffff24;

  /* border-right: 0.2px solid #ffffff24; */
}
.fc-liquid-hack td{
  position: static;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}
.fc .fc-timegrid-col-frame{
  position: relative !important;
}

/* .fc-theme-standard td, .fc-theme-standard th {
	border-color: 1px solid #cccccc73;
} */
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #fdfdfd3c;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #fdfdfd3c;
}

.fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 2rem;
  font-size: 1.8rem;
}

.fc-list-event {
  font-size: 1.2rem;
  background: transparent !important;
}

.fc-toolbar-title {
  font-weight: 500;
}

.fc .fc-toolbar {
  margin-bottom: 2rem;
}

.fc .fc-button {
  background-color: #e7e7ea;
  border: none;
  outline: none;
  color: #000000;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: var(--box-shadow1);
}

.fc .fc-scrollgrid {
  border-radius: 1rem;
}

.fc-toolbar-chunk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fc .fc-toolbar .fc--button:empty,
.fc .fc-toolbar .fc-toolbar-chunk:empty {
  display: none;
}

.fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot {
  /* background-color: rgb(255, 255, 255);
	background-color: var(--fc-today-bg-color, rgb(255, 255, 255)); */
  height: 3rem;
}

.fc .fc-daygrid-day {
  background-color: #121212;
}

.fc-scrollgrid-sync-table tr:nth-child(even) {
  background-color: #ffffff;
}

.fc .fc-daygrid-day-number {
  color: white;
}

.fc .fc-daygrid-day.fc-day-today {
  background: #353015;
}

.fc-today-button fc-button fc-button-primary {
  background: #db7d06 !important ;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fc .fc-button-primary:disabled {
  background: #db7d06 !important ;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: var(--primary-color);
}

.fc .fc-button-primary:hover {
  background-color: var(--primary-color);
}

.css-b62m3t-container {
  width: 24rem;
  position: unset !important;
  margin-left: auto !important;
}

.react-select {
  width: 100%;
  max-width: 200px;
}

.css-1s2u09g-control {
  background-color: rgb(255, 255, 255) !important;
  border-color: hsl(240deg 7% 91%) !important;
}

.css-1pndypt-Input {
  color: rgb(0, 0, 0) !important;
}

.css-tlfecz-indicatorContainer {
  color: hsl(0deg 0% 0%) !important;
}
.css-166bipr-Input{
  color: white !important;
}
.css-13cymwt-control{
    visibility: visible !important;
    background-color: transparent !important;
    /* border-color: hsl(0, 0%, 80%); */
    border-style: none !important;
    color: white !important;
    
}
.css-t3ipsp-control {
  background-color: transparent !important;
  border-style: none;
  color: white !important;
} 

 .css-8aqfg3-menu{
  background-color: #222222 !important;
} 
 .css-166bipr-Input{
  color: white !important;
  visibility: visible !important;
}
.css-1fdsijx-ValueContainer{
  /* color: white !important; */

} 
.css-1dimb5e-singleValue{
  color:white;
}
.css-1jqq78o-placeholder{
  color:  white !important;
}
#react-select-2-listbox {
  z-index: 10 !important;
}

.fc-today-button {
  margin: 0rem 0.6rem !important;
}

.dayButton {
  background-color: #e7e7ea;
  border: none;
  outline: none;
  color: var(--primary-color);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: var(--box-shadow1);
  margin: 2rem 1rem;
}

.dayButton:hover {
  background-color: #000000;
  border: none;
  outline: none;
  color: var(--primary-color);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  box-shadow: var(--box-shadow1);
  margin: 2rem 1rem;
}

.fc-bookAppointment-button {
  border: 1px solid var(--primary-color) !important;
  color: #ffffff !important;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.8rem !important;
  width: 13rem;
  position: relative;
  transition: all 1s ease;
  margin-left: 1rem !important;
  box-shadow: var(--box-shadow-input) !important;
  box-shadow: var(--box-shadow-input) !important;
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
}

.table-service-container {
  width: 100%;
  border-radius: 0rem;
}

.table-contain tr td {
  width: 10%;
  white-space: break-spaces;
}

.tdTextAlign {
  text-align: center;
}

/* .table-service-container tr:nth-child(even) {
	background-color: #fff;
} */

.table-contain tbody td:first-child {
  width: 2%;
  text-align: left;
}

.table-contain tbody td:nth-child(2) {
  width: 10%;
  max-width: 10%;
  text-align: left;
}

.main-table-contain th:first-child {
  width: 2.5%;
  text-align: center;
}

.main-table-contain th:nth-child(2) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(3) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(4) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(5) {
  width: 10%;
  text-align: left;
}

.main-table-contain th:nth-child(6) {
  width: 10%;
  text-align: left;
}

.expandable {
  box-shadow: inset 0 -5em 0em rgb(0 0 0 / 19%), 0 0 0 0px rgb(255 255 255 / 0%), 0 0em 0em rgb(0 0 0 / 19%);
}

.accordianContainer {
  border-radius: 0px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgb(0 0 0 / 50%);
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  transition: all 0.4s;
}

.active {
  /* margin-top: 2rem; */
}

.accordianContainer__button {
  border: none;
  background-color: transparent;
  border: 0px solid var(--primary-color);
  outline: none;
  padding: 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  position: relative;
}

.accordianContainer__button span {
  display: none;
  font-size: 1rem;
  color: var(--pure-white);
  background-color: var(--black);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -2rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}

.accordianContainer--icon-move {
  height: 2rem;
  width: 2rem;
  fill: #ffffff;
}

.expandable-icon .accordianContainer__button {
  border: none;
  background-color: transparent;
  border: 0px solid;
  outline: none;
  padding: 0rem;
  margin: 0rem;
  gap: 0rem;
}

.expandable-icon .accordianContainer__button span {
  right: -12rem;
  top: 0rem;
}

.accordianContainer:hover .accordianContainer--icon-move {
  height: 2rem;
  width: 2rem;
  fill: #6b6b6b93;
}

.expandable .accordianContainer--icon-move {
  height: 2rem;
  width: 2rem;
  fill: #4e4e4e00;
}

.expandable:hover .accordianContainer--icon-move {
  height: 2.5rem;
  width: 2.5rem;
  gap: 0.5rem;
  fill: #ffffffc9;
}

.accordian {
  width: 100%;
  transition: all 0.4s;
}

.panel {
  display: none;
}

.show {
  display: block;
}

.appointment_box {
  width: 30rem;
  height: 90vh;
  border-radius: 1rem;
  background-color: #000000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* height: 80vh; */
  overflow-y: auto;
  transition: all 0.5s linear;
}

.appointment_box.active {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appointment_box1 {
  width: 100%;
  height: 90vh;
  border-radius: 1rem;
  background-color: #000000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 80vh;
  overflow-y: auto;
  transition: all 0.5s linear;
}

.stylist_img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.stylist_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.stylist_box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.stylist_text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stylist_text1 span {
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.stylist_text span {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.stylist_text_active {
  display: none;
}

.stylist_text p {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.current_appointment {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}

.current_appointment_active {
  display: none;
}

.small_appointment {
  display: none;
}

.small_appointment_active {
  display: block;
}

.small_appointment_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
}

.small_name {
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
  background-color: #545454;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small_name h1 {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

.fc-col-header {
  width: 100% !important;
}

.fc-timegrid-body {
  width: 100% !important;
}

.fc-timegrid-slots table {
  width: 100% !important;
}

.fc-timegrid-cols table {
  width: 100% !important;
}

@media screen and (max-width: 900px) {
  .fc .fc-toolbar-title {
    font-size: 1em;
    margin: 0;
  }
}

@media screen and (max-width: 920px) {
  .fc-col-header {
    width: 920px !important;
  }

  .fc-timegrid-body {
    width: 920px !important;
  }

  .fc-timegrid-slots table {
    width: 920px !important;
  }

  .fc-timegrid-cols table {
    width: 920px !important;
  }

  .fc-view-harness {
    height: 70vh !important;
    width: 920px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 600px) {
  .fc-col-header {
    width: 600px !important;
  }

  .fc-timegrid-body {
    width: 600px !important;
  }

  .fc-timegrid-slots table {
    width: 600px !important;
  }

  .fc-timegrid-cols table {
    width: 600px !important;
  }

  .fc-view-harness {
    height: 70vh !important;
    width: 600px;
    overflow-x: scroll;
  }
}

.current_appointment h2 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.appointment_card {
  background: black;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
}

.appointment_card_active {
  display: none;
}

.appointment_time {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.time_circle {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #fff;
}

.appointment_name h1 {
  font-size: 22px;

  font-weight: 500;
}

.appointment_name p {
  font-size: 12px;
  font-weight: 200;
}

.appointment__button {
  position: absolute;
  top: 15%;
  /* right: 24%; */
  left: -15px;
  transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
}

.appointment__button1 {
  position: absolute;
  top: 15%;
  /* right: 24%; */
  left: -15px;
  transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
}

.appointment__button_active {
  right: 10.5%;
}

.appointment_upcoming_active {
  display: none;
}

.qty-box .input-group {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}

.qty-box .input-group span button {
  background: #ffffff !important;
  border: 1px solid #ced4da;
}

.qty-box .input-group .form-control {
  text-align: center;
  width: 80px;
  -webkit-box-flex: unset;
  -ms-flex: unset;
  flex: unset;
  height: 3.4rem;
  background: #ffffff !important;
  border: 1px solid #ced4da;
  font-size: 1.5rem;
  font-weight: 600;
}

.qty-box .input-group button {
  background-color: transparent;
  border: 0;
  color: #777777;
  cursor: pointer;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
}

.qty-box .input-group button i {
  font-weight: 900;
  color: #222222;
}

.qty-box .input-group .icon {
  padding-right: 0;
}

.table__status,
.table__status_deactive {
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  /* width: 8rem; */
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.table__status {
  border: 1px solid var(--light-grey);
}

.table__status span,
.table__status_deactive span {
  font-size: 3rem;
  line-height: 0.9rem;
}

.table__status span {
  color: green;
}

.table__status_deactive {
  border: 1px solid var(--light-grey);
  color: var(--light-grey);
}

.table__status_deactive span {
  color: red;
}

.fc-toolbar-title {
  color: var(--white);
}

.StripeElement.PaymentRequestButton {
  padding: 0;
  height: 40px;
  width: 250px;
  margin-left: 80px;
}

@media screen and (min-width: 1366px) and (max-width: 1366px) {
  .current_appointment h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .table-service-container {
    width: 200px;
  }
  .main-table-contain th:first-child {
    width: 1.5%;
  }
  .main-table-contain th:nth-child(2) {
    width: 10px;
  }
  .table tr th {
    font-weight: 100;
    font-size: 10px;
    padding: 3px;
  }
}

/* Add this CSS to your styles */
.fc-day-header.fc-widget-header {
  display: none;
}



