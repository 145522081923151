.onboarding {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-container {
  width: 85%;
  height: auto;
  border-radius: 1rem;
  display: flex;
}

.onboarding_price {
  display: flex;
  justify-Content: space-between;
  flex-Direction: "row";
  width: 100%
}

.onboard_img {
  width: 40%;
  background-image: url('../../components/assets/background_onboard.png');
  background-size: cover;
  border-radius: 4rem 0 0 4rem;
  background-repeat: no-repeat;
}

.onboard_container {
  width: 70%;
  padding: 8rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  background-color: #121212e8;
  /* opacity: 0.8; */
  border-radius: 0 4rem 4rem 0;
  backdrop-filter: blur(5px);
  height: 62rem;
}

.onboard_form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.onboard_form_input{
  width: 100%;
  display: flex;
  gap: 6rem;
}

.onboard_baseInput{
width: 85%;
}

.onbaord_nodata {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 5rem;
}


.onboard_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.form_section {
  display: flex;
  gap: 1rem;
}

.price_details {
  width: 100%;
}

.price_row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.price_label {
  color: #fff;
  font-weight: normal;
}

.price_value {
  color: #ff9000;
  font-weight: normal;
}

.original_price {
  color: #b7894d;
  font-weight: normal;
  text-decoration: line-through;
  margin-right: 10px;
}

.discounted_price {
  color: #ff9000;
  font-weight: normal;
}

.coupon_info {
  font-size: 12px;
}

.divider {
  border-bottom: 0.5px solid #555;
  width: 100%;
  margin-top: 13px;
}

.total_row {
  margin-top: 10px;
}

.total_label {
  color: #fff;
  font-size: 20px;
}

.total_value {
  color: #ff9000;
  font-size: 20px;
}

.onboard_button {
  margin-top: 20px;
}

.prev {
  margin-right: 10px;
}


.onboard_input label {
  font-size: 12px;
  color: white;
  transition: all .4s;
}

.onboard_input input {
  font-size: 12px;
  width: 95%;
  height: 40px;
  padding: 1rem;
  border-radius: 20px;
  background-color: #e7e7ea;
  transition: all .4s;
  font-family: inherit;
  outline: none;
  border: none;
  box-shadow: 0rem 0.2rem 2rem -1rem black;
}

.onboard_card_input {
  width: 100%;
  height: 40px;
  padding: 1rem;
  border-radius: 5px;
  border: none;
  background-color: '#3D3D3D'; /* Transparent black */
  transition: all .4s;
  border: 1px solid rgba(255, 255, 255, 0.37);
}


.onboard_input textarea {
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  height: 60px;
  padding: 1rem;
  border-radius: 5px;
  background-color: #e7e7ea;
  transition: all .4s;
  outline: none;
  border: 1px solid #000;
}

.onboard_input textarea:hover,
.onboard_input input:hover {
  border: 3px solid #000;
}

.form_container {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 28rem;
  overflow-y: auto;
  justify-content: flex-start;
  overflow-x: hidden;
}

.abcd{
   color: "#fff" ; 
   font-Size:"20px"

}

.form_container_new {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 1rem;
  height: 34rem;
  overflow-y: auto;
  justify-content: flex-start;
  overflow-x: hidden;
}

.form_container_new .form_section{
  border-bottom: 1px solid rgba(0, 0, 0, 0.356);
  background: #3D3D3D;
  padding: 2rem;
  border-radius: 1rem;
}

.form_section {
  display: flex;
  gap: 1rem;
  align-items: center;

}

.onboard_button {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.onboard_button button {
  border-radius: 2rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem 5rem;
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all .4s;
  background-color: white;
  border: none;
  min-width: 100px;
  height: 40px;
}

.onboard_button .button1 {
  border-radius: 2rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem 5rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all .4s;
  background-color: #ff9000;
  border: none;
  min-width: 100px;
  height: 40px;
}

.slot_button button {
  border-radius: 0.5rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: all .4s;
  background-color: #000;
  border: none;
  min-width: 100px;
  height: 40px;
}


.onboard_button button:hover {
  border: 1px solid #000;
  background-color: #ff9000;
  color: #ffffff;
}

.onboard_error {
  color: red;
  height: 10px;
  font-size: 1.2rem;
}

.congrats_onboard_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  position: relative;
}

.congrats_img_onboard {
  width: 10rem;
}


.noData_icon{
  height: 4rem; 
  margin: 1rem;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subscription_btn_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.subs_text {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.subs_text p {
  font-size: 12px;
  text-align: center;
}

.sub-container {
  width: 100%;
  height: 100px;
  background-color: #5656566e;;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
}

.sub-container-active {
  width: 100%;
  height: 100px;
  background-color: #5656566e;
  color: white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  user-select: none;
  border: 3px solid #0000004c;
  cursor: pointer;
}

.sub-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment_btn_list {
  display: flex;
  flex-direction: row;
  /* gap: 1rem; */
  border-radius: 1rem;
  border: 1px solid #0000003d;
  /* box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6, 4px 4px 4px 0px #d1d9e6 inset, -2px -4px 1px 0px #ffffff inset; */
  text-align: center;
  align-items: center;
  justify-content: center;
}

.payment-container {
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  flex-direction: column;
  cursor: pointer;
}

.payment-container-active {
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  flex-direction: column;
  border: 2px solid #000;
  cursor: pointer;
}

.payment-price {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
}

.payment-price p {
  font-size: 12px;
}

.cancel {
  text-decoration: line-through
}

.payment-price-div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.payment-price-div h2 {
  font-weight: 500;
}

.congrats_btn_list1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  animation: FadeIn 0.30s linear;
  animation-fill-mode: forwards;

}


.congrats_btn_list2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  animation: FadeIn 0.30s linear;
  animation-fill-mode: forwards;

}

.congrats_btn_list3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  animation: FadeIn 0.30s linear;
  animation-fill-mode: forwards;

}


.payment_btn_list h3 {
  font-size: 14px;
  font-weight: 600;

}


/* @keyframes example {
  0%   { right:-80px;}
  100% { right:0px;}
}


@media screen and (min-width: 1440px) {
  .form_container{
    height: 50rem;
    grid-gap:1rem;
    gap:1rem;
  }
} */

@keyframes FadeIn {
  from {
    opacity: 0.2;
    transform: translateX(10%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.congrats_btn_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;

}

.congrats_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  /* animation-fill-mode: both; */

}

.congrats_btn_list1 {
  animation-delay: .5s
}

.congrats_btn_list2 {
  animation-delay: 1s
}

.congrats_btn_list3 {
  animation-delay: 1.5s
}

/* .myClass img:nth-child(4) { animation-delay: 2s } */


.payment-coupon-container {
  width: 100%;
  border-radius: 1rem;
  height: fit-content;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.payment-price-container {
  width: 100%;
  padding: 10px;
  border-radius: 1rem;
  height: fit-content;
  background-color: #fff;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  align-items: center;
  position: relative;
}

.payment-icon {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-grey);
}

.couponlist-container-active {
  width: 100%;
  max-height: 300px;
  position: absolute;
  background-color: #fff;
  border-radius: 1rem;
  z-index: 100;
  margin-top: 10px;
  overflow-y: auto;
}

.couponlist-container {
  display: none;
}

.coupon-container {
  position: relative;
}

.counpon-detail {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.counpon-name {
  display: flex;
  flex-direction: column;
  width: inherit;
}

.price_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price_section h3 {
  font-size: 15px;
}

.price_section p {
  font-size: 12px;
}

.onboard-password {
  display: flex;
  position: relative;
  align-items: center;
}

.onboard-password-icon {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 10px;
}

.onboard_down {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}
.onboard_down1 {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}

.onboard_down p {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.onboard_down span {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.onboard_down1 span {
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.payment_checkbox {
  display: flex;
  gap: 1rem;
  font-size: 12px;
  align-items: center;
}
 

input:checked~.checkmark {
  background-color: #000;
}




/* @media only screen and (max-width:800px) {
  .onboarding-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  .form_container{
    height: 100%;
  }
} */
  /* For tablets: */
  /* .onboard_container {
    width: 100%; 
  }

  .form_section {
    flex-direction: column; 
  } */
 
/* @media only screen and (max-width:1000px) {
  .onboarding-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  .form_container{
    height: 100%;
  }
} */
  /* For tablets: */
  /* .onboard_container {
    width: 100%; 
  }

  .form_section {
    flex-direction: column; 
  } */


  @media only screen and (min-width:360px) and (max-width: 500px){
    .onboard_img {   
      background-image: none;
    }

    .onboard_container {
      width: 100%;
      border-radius:4rem;
    }

    .onboarding {
     background-image: none;
    }

    
    .onboard_container {
      width: 100%;
      border-radius: 4rem;
      height: 90rem;
    }

    .onboard_form_input{
      width: 100%;
      gap: 6rem;
      flex-wrap: wrap;
    }
  }

 


  @media only screen and (min-width: 810px) and (max-width: 1080px){


    .onboard_img {
      width: 30%;
      background-image: url('../../components/assets/background_onboard.png');
      background-size: cover;
      border-radius: 4rem 0 0 4rem;
      background-position-x: -46rem;
      background-repeat: no-repeat;
    }

    .onboard_form_input {
      display: block;
    }
    .onboard_form {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      grid-gap: 1rem;
      gap: 1rem;
      margin-bottom: 3rem;
      margin-top: 3rem;
      line-height: 3rem;
    }

    .onboard_container {
      width: 70%;
      padding: 8rem 5rem;
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      gap: 2rem;
      justify-content: center;
      border-radius: 0 4rem 4rem 0;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      height: 92rem;
    }

  }
 