.header {
	/* / padding-top: 2rem; / */
	display: flex;
	/* / justify-content: space-between; / */
	justify-content: space-between;
	align-items: center;
}

.header__left {
	display: flex;
	gap: 6rem;
	align-items: center;
	position: relative;
}

.header__button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.header__icon {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--primary-color);
}

.header__container {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.header__container--backBtn {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.header__container--backBtnIcon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
}

.header__heading {
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
	background-color: #121212;
    padding: 1rem 2rem;
    border-radius: 3rem;
}
.header__headings {
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
	background-color: #121212;
    padding: 1rem 2rem;
    border-radius: 3rem;
}

.header__heading_Right{
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
}

.switch_text{
	color: #ffffff!important;
}

.header__container__icon {
	height: 1.5rem;
	margin-right: 1rem;
	width: 1.5rem;
	fill: var(--dark-grey);
}

.header__container__btn {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: .5rem;
	border-radius: 2rem;
	width: 2.8rem;
	position: relative;
	transition: all 1s ease;
}

.header_button{
	margin-left: 3rem;
    margin-top: 5rem;
}

.header__container__button {
	border: 0px solid var(--primary-color);
	box-shadow: 0 3px 3px 0 rgb(0 0 0 / 15%), 0 3px 1px -2px rgb(35 35 35 / 20%), 0 1px 5px 0 rgb(0 0 0 / 15%);
	/* / background-image: linear-gradient(195deg, #42424ab3, #191919); / */
	background-color: #121212;
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 1rem;
	border-radius: 0.8rem;
	/* / width: 15rem; / */
	position: relative;
	transition: all 1s ease;
	color: var(--pure-white);
	display: flex;
	align-items: center;
	justify-content: space-around;
}


.header__container__button .header__container__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--pure-white);
}


.header__container__btn p {
	display: flex;
	align-items: center;
}

.header__container__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--dark-grey);
}

.header__container__btn span {
	font-weight: 600;
	color: var(--dark-grey);
	position: absolute;
	top: 0.4rem;
	left: 2.5rem;
	transition: opacity 1000ms;
	opacity: 0;
	white-space: nowrap;
	font-size: 0;
	width: 0;
	margin: 0;
}

.header__container__btn:hover {
	transition: all 400ms cubic-bezier(.62, .1, .5, 1);
	border-radius: 1rem;
	width: 13.5rem;
}

.header__container__btn:hover span {
	font-size: 1.2rem;
	opacity: 1;
	width: auto;
	transition: opacity 700ms, width 1ms linear 270ms, font-size 1ms linear 270ms;
}

.header__image {
	height: 4rem;
}

.main_header_heading {
	color: var(--primary-color);
	text-transform: capitalize;
	font-size: 2rem;
	font-weight: 600;
}

.dropdown_container {
	position: relative;
	width: max-content;
	z-index: 101;
	background-color: #121212;
    padding: 1rem 2rem;
    border-radius: 3rem;
}

.dropdown_container .dropdown {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: flex-end;
	gap: 1rem;
}

.dropdown_container .dropdown .role_title {
	font-size: 1.8rem;
	margin: 0 1rem;
	color: var(--secondary);
	text-transform: capitalize;
}

.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #ffffff;
}

.list_container {
	background-color: #e7e7ea;
	color: #000;
	margin-top: 12rem;
	border-radius: 0.5rem;
	padding: 0rem;
	position: absolute;
	box-shadow: var(--box-shadow-input);
	width: 20rem;
	right: 0;
	max-height: 15rem;
	overflow-y: auto;
	margin-right: -1rem;
}

.dropdown_item {
	cursor: pointer;
	margin: .5rem 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	transition: all .5s ease;
}

.selected_dropdown_item {
	cursor: pointer;
	margin: .5rem 1rem;
	padding: .5rem 1rem;
	border-radius: .5rem;
	transition: all .5s ease;
	background-color: var(--primary-color);
	color: #fff;
	font-size: 1.5rem;
	text-transform: capitalize;
}

.dropdown_item:hover {
	background-color: var(--dark-white);
	color: var(--secondary);
}

.dropdown_item .dropdown_item_userName {
	font-size: 1.2rem;
	text-transform: capitalize;
}

.arrow_up_down {
	height: 1.8rem;
	width: 1.8rem;
}

.typeSelectBox {
    background-color: #121212;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    padding: 0.6rem 1rem;
    font-family: inherit;
    font-size: 1.5rem;
    width: 15rem;
	color: #ffffff;
}

/* .coinContainer{
	padding-top:2px;
	padding-right: 4px;
}

.coinIcon {
	width: 18px;
	height: 18px;
	vertical-align: middle;
	fill: #ffd600;
	margin-right: 1px;
	padding-bottom: 3px;
}

.coinNumber{
	color: #fff;
} */

/* @media (max-width: 860px) {
	.header_heading {
		font-size: 1.5rem;
	}

	.dropdown_container .dropdown .role_title {
		font-size: 1.3rem;
		margin: 0 0.5rem;
	}

	.dropdown_item .dropdown_item_userName {
		font-size: 1.3rem;
	}

	.selected_dropdown_item {
		font-size: 1.3rem;
	}
}

@media (max-width: 500px) {
	.header_heading {
		font-size: 1.3rem;
	}

	.main_header_menu_icon {
		height: 2rem;
		width: 2rem;
	}

	.role_image {
		height: 1.3rem;
		width: 1.3rem;
	}

	.arrow_up_down {
		height: 1.3rem;
		width: 1.3rem;
	}

	.dropdown_container .dropdown .role_title {
		font-size: 1.1rem;
	}

	.dropdown_item .dropdown_item_userName {
		font-size: 1.1rem;
	}

	.selected_dropdown_item {
		font-size: 1.1rem;
	}
}

.role_image {
	height: 2rem;
	display: flex;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.typeSelectBox {
    background-color: #121212;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    padding: 1rem 1rem;
    font-family: inherit;
    font-size: 1.5rem;
    width: 15rem;
	color: #ffffff;
}

/* @media (max-width: 400px) {
	.header{
		flex-direction: column;
		grid-gap: 2rem;
	}
} */

 @media only screen and (min-width: 680px) and (max-width: 1080px) {
	.header__left {
		flex-direction: row;
		gap: 3rem;
	}

	.header__heading{
		display: none;
	}

	.header__headings {
		font-size: 1.5rem;
		font-weight: 600;
		color: #ffffff;
		background-color: #121212;
		padding: 1rem 2rem;
		border-radius: 3rem;
	}
} 

@media (max-width: 400px) {
    .header__left {
		flex-direction: row;
		gap: 3rem;
	}
	.HeaderNew_header__heading{
		font-size: 10px;
	}
}