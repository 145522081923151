.form_input1 {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.37);
  width: 100%;
  padding-left: 5px;
  padding-bottom: 10px;
  display: flex;
}

.apply_Coupon_Text {
  color: white;
}

.modal_Container {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_Coupon {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.update_modal_container {
  border-radius: 10px;
  width: 550px;
  max-width: 91%;
  text-align: center;
  margin: 0 auto;
}

.loader_Quantity {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amount_Container {
  display: flex;
  justify-content: space-between;
}

.dropDown_Handle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  background-color: #3d3d3d;
  border-radius: 5px;
  border: 1px solid #0000003d;
  color: #fff;
  font-family: Poppins, sans-serif;
  width: 70%;
  height: 40px;
  margin-left: 10px;
}

.stylist_Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  color: #efefef;
}

.errorText {
  color: red;
  margin-top: 0.5rem;
}

.price_Label {
  color: #ffa500;
}

.price_Container {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 0.2rem;
}

.price_Box {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.list_Price {
  padding: 0.5rem;
  cursor: pointer;
  color: #fff;
}

.form_input__label1 {
  color: var(--lightest-white);
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #efefef;
  font-size: 1.2rem; 
  margin: 0.5rem;
  margin-bottom: 0px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;  
}

.form_input__label {
  color: var(--lightest-white);
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #efefef;
  font-size: 1.2rem; 
  margin: 0.5rem;
   margin-bottom: 0px; 
 cursor: pointer;
  /* color: #fff;
  margin-bottom: 10px; */
}

.remove_Coupon {
  border: none;
  border-radius: 9px;
  background-color: #ff9000;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 10px;
}

.input_Coupon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_Coupon_Container {
  display: flex;
  align-items: center;
  width: 100%;
}

.form_input__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* background-color: var(--white); */
  /* border-radius: 1rem; */
  padding: 1rem 2rem;
  padding-left: 1rem;
  width: 25rem;
  /* padding-bottom: 3rem; */
  border-bottom: 1px solid var(--light-grey);
  /* margin: 2.3rem; */
}

.loading_spinner {
  border: 2px solid #c5a377;
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.input_Coupon_Label {
  background-color: #3d3d3d;
  width: 65%;
  margin-right: 1rem;
  border-radius: 5px;
}

.loader_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form_input__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--lightest-white);
  fill: #b7b2b2;
  margin-right: 5px;
}

.form_input__box {
  font-family: inherit;
  background-color: transparent;
  color: var(--lightest-white);
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  fill: white;
}



.form_input__box1 {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  width: 100%;
  fill: white;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill {
  color: white !important;
  background-color: transparent !important;
}


.update_Button {
  margin-top: 1rem;
  border: none;
  border-radius: 9px;
  background-color: #ff9000;
  color: #fff;
  cursor: pointer;
  width: 90px;
  height: 34px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.unit_Amount_Label {
  font-size: 1.2rem;
  font-weight: normal;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(100%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}
.form_input__box::-webkit-input-icon {
  color: var(--lightest-white) !important;
}
.form_input__error {
  font-size: 1.2rem;
  color: #ff3366;
  width: 25rem;
}

.form_input_coupon_box::hover {
  border: none !important;
}

.form_input_coupon_box {
  border: none !important;
}

@media (max-width: 767px) {
  .form_input__container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .form_input {
    min-width: 9rem;
  }
}
