
.container{
    width: 50%;
    height: 120%;
    display: flex;
    align-items: center;
}

.content{
    background-color: #121212;
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  color: #ffffff;
}

.text_input{
margin-top: 3rem;
}

.change_password_text{
    color: #FF9000;
    font-size: 1.3rem;
    margin-left: 1rem;
    border-bottom: 1px solid #323232;
    cursor: pointer;
}

.update_profile_button{
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
}

.update_button{
    background-color: #FF9000;
    color: #ffffff;
    padding: 0.6rem 3.6rem 0.6rem;
    font-size: 1.4rem;
    border-radius: 0.7rem;
    border: none;
    margin-right: 1.6rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.timeDurationComponent{
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


/* .form_input_box{
    font-family: inherit;
	background-color: #ffffff;
	color: var(--dark-grey);
	border: none;
	outline: none;
	width: 50%;
	height: 50%;
} */

/* .coinSection{
   display: flex;
}

.coinIcon{
    display: flex;
    width: 16px;
	height: 42px;
	vertical-align: middle;
	fill: #ffd600;
	margin-right: -24px;
	padding-bottom: 3px;
}

.iconInfo{
    height: 1.5rem;
    width: 1.8rem;
    fill: var(--pure-white);
    top: 13px;
    position: relative;
    right: 8px;
    cursor: pointer;
} */

@media (max-width: 809px) {
    .container{
        width: 100%;
    }
}


@media only screen and (min-width: 810px) and (max-width: 1080px) {

    .container{
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1550px) {
    .container{
        height: 200rem;
    }
}