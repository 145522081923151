.form_input {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: flex-start;
	width: 100%;
} 

.form_input__label {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.form_input__container {
	display: flex;
	align-items: center;
	gap: 1rem;
	background-color: var(--white);
	border-radius: .5rem;
	padding: 1rem 2rem;
	padding-left: 1rem;
	width: 25rem;
}

.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
}

.form_input__box {
	font-family: inherit;
	background-color: transparent;
	color: var(--dark-grey);
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
}

.form_input__box::-webkit-input-placeholder {
	color: var(--light-grey);
}

.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
	width: 25rem;
}


@media (max-width: 767px) {
	.form_input__container {
		width: 100% !important;
	} 
}