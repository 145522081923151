.appointmentBox{
	border-radius: 1rem;
	background-color: #121212;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	/* height: 80vh; */
	overflow-y: auto;
	transition: all 0.5s linear;
	
}

.container{
    display: grid;
  grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
  background-color: #000000;
}


.content{
background-color: #000000;
  margin-top: 3.5rem;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-left: 4rem; */
  padding-bottom: 3rem;
  padding-top: 1rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.content_dashboard{
	background-color: #000000;
	  margin-top: 3.5rem;
	  height: 85vh;
	  overflow-y: auto;
	  overflow-x: hidden;
	  /* padding-left: 4rem; */
	  padding-bottom: 3rem;
	  padding-top: 1rem;
	  display: grid;
	  gap: 2rem;
	  grid-template-columns: repeat(2, 2fr);
	  /* grid-template-rows: 18rem minmax(max-content, 59vh); */
	}

	.content_box_dashboard {
		width: 100%;
	  grid-column: 1 / 3;
	  grid-row: 1 / -1;
	  display: flex;
	  gap: 2rem;
	  flex-direction: column;
	}

.mainContent {
	grid-column: 2 / -1;
	overflow: hidden;
	transition: all 0.5s ease;
	margin: 1.5rem 0;
	margin-right: 1.5rem;
	background-color:#000000;
	/* box-shadow: var(--box-shadow1); */
	border-radius: 2rem;
	position: absolute;
	left: 22rem;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: 50%;
	/* display: flex; */
}

.mainContent.mainContent__active{
	fill:0;
	background-color: #000000;
}
.navigation__logos{
	display: block;
}
.active_logos{
	display: contents;
}

@media (max-width: 809px) {
	.mainContent{
		left: 18rem;
	}

	.mainContent.mainContent__active{
		fill:0;
		background-color: #000000;
	}
	.navigation__logos{
		display: block;
	}
	.active_logos{
		display: contents;
	}

}

/* Dashboard screen responsive - Aniket */
@media (max-width: 1280px) {
	.appointmentBox{
display: none;
}
}