.login-container {
    margin: 10rem 30rem;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 2rem;
    justify-content: center;
}

.login-container>div {
    background-color: #fff; 
    border-radius: 5px;
    padding: 30px;
    border: 1px solid #dddddd;
    line-height: 1;
}

.login-left-inside{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.login-container .modalContainer__form--section{
    padding: 0 !important;
    margin-bottom: 3rem;
}

.forgot-password{
    width: 25rem;
    justify-content: center;
    background: transparent;
    border: 0;
    text-align: start;
    cursor: pointer;
    margin-left: 0.5rem;
    font-weight: 600;
}


.forgot-password:hover{
   text-decoration: underline;
}


@media (max-width: 1400px) {
    .login-container {
        margin: 10rem 10rem;
    }

}

@media (max-width: 1230px) {
    .login-container {
        margin: 10rem 1rem;
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
        align-items: center;
    }

    .login-container-left {
        order: -1;
        width: 90vw;
    }

    .login-container-right {
        width: 90vw;
    }

}

@media (max-width: 768px) {
    .login-container {
        margin: 10rem 1rem;
        /* width: 100%; */
        display: flex;
        grid-template-columns: auto auto auto auto auto;
        flex-direction: column;
    }

    .login-container>.item5 {
        padding: 2rem 0.5rem;
    }

    .login-container-left {
        order: -1;
    }

    /* .login-container-right {
        width: 90vw;
    }  */
}