.container{
    width: 50%;
    height: 100%;
}

.content{
    background-color: #121212;
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    color: #ffffff;
}


.edit_form{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 2rem;
 }
 
 .icon_edit{
    fill: #FF9000;
    width: 2.4rem;
    cursor: pointer;
    height: 8rem;
    margin-left: 0.4rem;
 }
 
 
.edit_text{
     color: #FF9000;
     font-size: 1.4rem;
}

.text_input{
    margin-top: 3rem;
}

button{
    background: none;
    outline-style: none;
    border: none;
    cursor: pointer;
}

.baseInput_input{
    width: 60%;
    outline: none;
    padding: 0.5rem 1.8rem 0.7rem;
    align-items: center;
    border: none;
    display: flex;
    margin-left: -2.9rem;
    margin-bottom: 1rem;
    position: relative;
}


.baseInput_input input {
        color: #ffffff;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #323232;
        padding-left: 0rem;
        padding-right: 4rem;
}

.baseInput_input_title{
        display: inline-block;
        font-size: 1.5rem;
        margin-left: 2.8rem;
}


.baseInput_input div svg{
    fill: #ffffff;
}

.baseInput_input div button{
right: 0px!important
}

.update_profile_button{
        margin-top: 10rem;
        display: flex;
        justify-content: flex-end;
}
    
.update_button{
        background-color: #FF9000;
        color: #ffffff;
        padding: 0.6rem 3.6rem 0.6rem;
        font-size: 1.4rem;
        border-radius: .7rem;
        border: none;
        margin-right: 1.6rem;
        margin-bottom: 1rem;
        cursor: pointer;
}