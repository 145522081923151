.container{
    display: grid;
    grid-template-columns: minmax(6rem, 24rem) repeat(1, 1fr);
    height: 100vh;
    width: 100%;
    transition: all 0.5s ease;
}

.loginContainer{
    height: 100vh;
    background: black;
    width: 100%;
    grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: flex;
  justify-content: center;
}