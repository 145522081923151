.form_input {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: flex-start;
}
.form_input__label {
	color: #ffffff;
	font-size: 1.6rem;
}
.form_input__container {
	display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 1rem 2rem;
    padding-left: 2rem;
  padding-left: 1rem;
  width: 25rem;
}
.form_input__icon {
	height: 2rem;
	width: 2rem;
	fill: #e7e7ea;
}
.form_input__box {
	font-family: inherit;
	background-color: transparent;
	color: #ffffff;
	border: none;
	outline: none;
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
}
.form_input__box::-webkit-input-placeholder {
	color: var(--light-grey);
}
.form_input__error {
	font-size: 1.2rem;
	color: #ff3366;
}
