.month {
	width: 100%;
	background: var(--pure-white);
	width: 100%;
	margin-top: 1rem;
	margin-right: 5rem;
	margin-bottom: 2.5rem;
	border-radius: 1rem;
	box-shadow: var(--box-shadow1);
	overflow-y: hidden;
	overflow-x: auto;
	background-color: #121212;
	padding: 2rem;
}

.month ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.headers {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	margin-bottom: 2rem;
}

.dayMonth {
	text-align: center;
	font-size: 1.3rem;
	line-height: 1.3;
}

.dayMonth span {
	font-size: 1.5rem;
	font-weight: 600;
	display: block;
}

.prevNextBtn {
	background-color: #e7e7ea;
	border: none;
	outline: none;
	color: var(--primary-color);
	font-family: inherit;
	font-size: 1rem;
	cursor: pointer;
	padding: 1rem;
	/* border-radius: 0.5rem; */
	font-weight: 600;
}

.weekdays {
	margin: 0;
	background-color: var(--light-grey);
	display: flex;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
}

.weekdays li {
	width: 13.6%;
	padding: 1.2rem 0;
	font-size: 1.5rem;
	color: var(--dark-grey);
	text-align: center;
}

.days {
	padding: 10px 0;
	background: var(--white);
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
}

.days li {
	min-width: 13.6%;
	height: 10rem;
	border: 1px solid var(--pure-white);
	text-align: center;
	font-size: 2rem;
	color: var(--dark-grey);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	line-height: 1;
	position: relative;
}

.lockIcon {
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	fill: var(--red);
}

.sessionData {
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	gap: .5rem;
}

.sessionData span {
	font-size: 3rem;
	color: var(--blue);
}

.days li .active {
	/* padding: .8rem;
	line-height: 1;
	background: var(--primary-color);
	border-radius: 50%; */
	color: var(--primary-color);
	/* border-bottom: 1px solid var(--primary-color); */
}

.totalSection {
	margin: 2rem 0;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
}

.totalSectionData {
	font-size: 1.3rem;
	color: var(--blue);
	display: flex;
	gap: .5rem;
	align-items: center;
}

.totalSectionData span {
	font-size: 1.5rem;
	font-weight: 600;
}

.sessionDataAddBtn {
	margin-top: .5rem;
	background-color: transparent;
	border: none;
	font-family: inherit;
	font-size: 1.1rem;
	color: var(--dark-grey);
	font-weight: 500;
	background-color: transparent;
	border-bottom: 1px solid var(--blue);
	padding: .2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;

	display: flex;
	align-items: center;
	transition: all .4s;
}

.sessionDataAddBtnIcon {
	height: 1rem;
	width: 1rem;
	fill: var(--dark-grey);
}

.sessionDataAddBtn:hover {
	border-bottom: 1px solid var(--dark-grey);
	color: var(--blue);
}

.sessionDataBtn {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.sessionDataIcon {
	height: 1rem;
	width: 1rem;
	fill: var(--primary-color);
}

.sessionInputs {
	margin-top: .5rem;
	background-color: var(--black);
	border-radius: 1rem;
	padding: .2rem 1rem;
	display: flex;
	gap: 1rem;
	align-items: center;
}

.sessionInput {
	color: var(--white);
	border: none;
	background-color: transparent;
	width: 5rem;
	font-family: inherit;
	outline: none;
}

.sessionInputBtn {
	background-color: transparent;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.sessionInputIcon_check {
	height: 1.3rem;
	width: 1.3rem;
	fill: var(--primary-color);
}

.sessionInputIcon_close {
	height: 1rem;
	width: 1rem;
	fill: var(--red);
}

.errorText {
	margin-top: .5rem;
	color: var(--red);
	font-size: .8rem;
}

/* Add media queries for smaller screens */
@media screen and (max-width: 720px) {

	.weekdays li,
	.days li {
		width: 13.1%;
	}
}

@media screen and (max-width: 420px) {

	.weekdays li,
	.days li {
		width: 12.5%;
	}

	.days li .active {
		padding: 2px;
	}
}

@media screen and (max-width: 290px) {

	.weekdays li,
	.days li {
		width: 12.2%;
	}
}

.form_input__label {
	color: var(--light-grey);
	font-size: 1.2rem;
}

.prevAvailablitys {
	background-color: var(--pure-white);
	border-radius: 2rem;
	padding: 2rem;

	display: flex;
	gap: 1rem;
	flex-direction: column;
}

.prevAvailablityHeading {
	font-size: 1.5rem;
	font-weight: 600;
}

.prevAvailablity {
	background-color: var(--lightest-white);
	border-radius: 2rem;
	padding: 1rem 1.5rem;
}

.prevAvailablityTitle {
	font-size: 1.3rem;
	font-weight: 600;
}

.prevAvailablitySubTitle {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--light-grey);
}

.prevAvailablityTime {
	font-size: 1rem;
	font-weight: 600;
	color: var(--dark-blue);
	margin-top: 1rem;
}


.calendarCSS {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}


.weekdaysStyle {
	width: 12%;
	padding: 1.2rem 0 !important;
	/* font-size: 1.5rem !important; */
	color: var(--dark-grey) !important;
	text-align: center !important;
}


.dynamicRow {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

.addButtonslot {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	outline: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.8rem;
	width: 13rem;
	position: relative;
	transition: all 1s ease;
	box-shadow: var(--box-shadow-input);
	box-shadow: var(--box-shadow-input) !important;
	background-image: linear-gradient(195deg, #c66f00, #ff9510);
	color: #fff;
}

.dayContainer {
	flex-direction: row;
	display: flex;
	align-items: center;
	gap: 2rem;
	font-size: 1.8rem;
	max-width: 100%;
	padding: 10px;
}

.slotRow {
	padding: 0rem;
	gap: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: center;
}

.busBtn{
	display: flex;
	flex-direction: column;
}

.copySlot{
	background-color: transparent;
	border: none;
	font-size: 12px;
	margin-top: 5px;
	cursor: pointer;
	text-decoration: underline;
	color: #000;
}

.slotLoading{
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	background-color: #5c5c5caf;
	border-radius: 20px;
	backdrop-filter: blur(5px);
	left: 0px;
	
}

.slotLoadingTxt{
	font-size: 1.5rem;
	position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
	color: #fff;
}

.undoButtonslot{
	display: flex;
	justify-content: flex-end;
}
.inputs label{
	margin-left: 1rem;
}
.businessHoursForm{
	height: 70vh;
}
.day{
	width: 5rem;
	color: white;
}
.container{
	height: 70vh;
 }
@media  only screen and (min-width: 810px) and (max-width: 1080px) {
	.slotRow{ 
		display: flex;
		/* flex-direction: column; */
		/* flex-wrap: wrap; */
		align-items: center;
		min-width: auto;
	 }
	 .dayContainer{
		min-width: 135%;
	 }
	 .modal{
		width: max-content;
		height: 90%;
	 }
	 .businessHoursForm{
		height: 42vh
	 }
	 .container{
		height: 45vh;
	 }
	 /* .slotRow{ 	
		margin-inline:  auto;
		max-width: 30rem;
	 }  */
}

@media only  screen and (max-width: 767px){
	 /* .dayContainer{
		width: fit-content;
	} 
	.slotRow{ 
		/* width: auto; */
	 /* }  */
	 .businessHoursForm{
		height: 42vh
	 }
}