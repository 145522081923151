.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.712); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    background-color: #171717;;
    border-radius: 10px;
    height: 34rem;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 50rem;
    display: flex;
  justify-content: center;
}

.coinText {
    color: #fff;
    font-size: 2.6rem;
    display: flex;
    justify-content: center;
}

.coins{
    color: #ff9900;
    padding-left: 1rem;
  padding-right: 1rem;
}


.animationContainer{
    position: absolute;
    display: flex;
    bottom: 10rem;
    right: 1rem;
}
.congratsAnimationContainer{
    position: absolute;
    width: 100%;
}

.animationTextContainer{
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.congratulationText{
  color: #FFD101;
  font-size: 3rem;
  margin-top: -6rem;
}


.closeButton {
  background-color: #ff9900;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0rem 0.4rem;
  z-index: 1;
 border-radius: 1.2rem;
 position: absolute;
  right: 2rem;
  width: 2rem;
}
