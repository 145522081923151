.homescreen_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 60rem; */
    padding: 2rem 0rem;
    gap: 25rem;
    /* margin-top: 8rem; */
    width: 100%;

    /* margin-top: 5rem; */
}

.filter__input--icon1 {
    height: 4rem;
    width: 4rem;
    fill: black;
    color: black;
    margin: 1rem 1rem;
}

.homescreen_motive {
    /* width: 40%; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.img-responsive {
    width: 27vw;

    /* height: 35vw; */
    /* height: auto; */
}

.homesceeen_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* padding-left: 10rem; */
}

.salon_product {
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.product_img {
    background: linear-gradient(45deg, #ffbc61b3, #fffe7c80);
    align-items: center;
    /* opacity: .7; */
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 5rem;
    border: 0px solid rgb(175, 163, 163);
    box-shadow: var(--box-shadow-input);
    height: 8rem;
    width: 8rem;
    align-items: center;
    justify-content: center;
}

.salon_images {
    display: flex;
    border-radius: 50%;
    width: 6rem;
}

.salon_images_text {
    font-size: 1.5rem;
}

.homescreen_banner_button {
    background-color: var(--sky-blue);
    border: 0px;
    color: var(--pure-white);
    height: 5rem;
    width: 25rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.homescreen_banner_button:hover {
    background-color: var(--black);
    border: 0px;
    color: var(--pure-white);
    height: 5rem;
    width: 25rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.monthly_bundle_text {
    font-size: 1.6rem;
    font-weight: 400;
}

.hero--image--container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.monthly_bundle {
    background: linear-gradient(45deg, #ffbc61b3, #fffe7c80);
    position: absolute;
    align-items: center;
    /* opacity: .7; */
    color: black;
    width: 32rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 3rem;
    border: 0px solid rgb(175, 163, 163);
    bottom: 20%;
    box-shadow: var(--box-shadow-input);
    /* height: 10rem; */
}

/* .monthly_bundle {
    background: linear-gradient(45deg, #ffbc61b3, #fffe7c80);
    align-items: center;
    color: black;
    width: 32rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-radius: 3rem;
    border: 0px solid rgb(175, 163, 163);
    margin-top: 46rem;
    margin-left: -80rem;
    box-shadow: var(--box-shadow-input);
   } */
.homescreen_banner_outer {
    display: flex;
    width: 40%;
    justify-content: flex-start;
    flex-direction: column;
}

.monthly_bundle_price {
    font-size: 4rem;
    font-weight: 600;
}

.homescreen_banner__subtitle {
    font-weight: 500;
    font-size: 4rem;
    margin-top: 5rem;
}

.homescreen_banner__title {
    font-weight: 300;
}

.homescreen_banner__desc {
    width: 70%;
    font-weight: 400;
    margin: 2rem 0;
    font-size: 1.5rem;
}

.homescreen_banner_left_padding {
    padding: 0rem 55rem 0rem 18rem;
}

.homescreen_products {
    background-color: #000;
    height: 28rem;
    display: flex;
    gap: 2rem;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 2rem 10rem;
}

.homescreen--bestProducts {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.CardHeader {
    background: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.product_card--layout {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 30rem;
    cursor: pointer;
}

.product-container {
    display: flex;
    justify-content: space-between;
}

.porduct-desc {
    display: flex;
    flex-direction: column;
    width: 20rem;
    align-items: flex-start;
}

.product_card {
    width: 10rem;
    border-radius: 8rem;
    height: 10rem;
    border: 2px solid #e7e7e7;
    box-shadow: var(--box-shadow-input);
    object-fit: cover;
}

.productCard__title {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
}

/* stars */
.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: "★ ";
}

.rate>input:checked~label {
    color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #c59b08;
}

.img-responsive1 {
    display: none;
}

.services {
    text-align: center;
    padding: 3rem 3rem;
    background-color: var(--light-grey2);
}

.servies_main_heading {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.our_services_outer {
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    margin-top: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px;
}

.our_services_inner {
    display: flex;
    border-radius: 15px;
    width: 42rem;
    padding: 2rem;
    flex-direction: column;
    background-color: var(--pure-white);
    box-shadow: var(--box-shadow1);
}

.our_services_inner:hover {
    transition: all .4s;
    transform: scale(1.1);
    cursor: pointer;
}

.our_services_inner:hover>.our_services_logo {
    background-color: black;
    color: white;
    transition: all .4s;
}

.our_services_logo {
    background-color: var(--light-grey2);
    width: 10rem;
    height: 10rem;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
}

.our_services_heading {
    font-size: 1.8rem;
    font-weight: 700;
}

.our_services_subheading {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
}

.our_services_subheading1 {
    font-size: 1.4rem;
    font-weight: 400;
}

.our_services_subheading2 {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: start;
}

.working_time {
    display: flex;
    padding: 5rem 30rem;
    box-shadow: var(--box-shadow1);
    width: 100%;
}

.working_time_left {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 10rem 0;
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: var(--box-shadow1);
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    color: var(--pure-white);
}

.days_we_work {
    display: flex;
    background-color: rgba(127, 128, 118, 0.247);
    width: 35rem;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.days_we_work-saturday {
    padding: 1rem 2rem;
    display: flex;
    width: 35rem;
    justify-content: space-between;
}

.days_we_work-sunday {
    padding: 1rem 2rem;
    display: flex;
    width: 35rem;
    justify-content: space-between;
    background-color: rgba(127, 128, 118, 0.247);
    color: gold;
}

.working_time_right {
    background-color: var(--pure-white);
    width: 50%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: var(--box-shadow1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
}

.working_time_heading {
    font-size: 2.2rem;
    font-weight: 600;
}

.hr {
    width: 35rem;
    margin: 3rem 0;
}

.bookings {
    display: flex;
    width: 35rem;
    justify-content: space-between;
    flex-direction: flex-start;
}

.chat_section {
    padding: 2rem 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.chat_subheading {
    font-size: 2rem;
    padding-top: 2rem;
}

.chat_contact_details {
    display: flex;
    padding: 2rem 30rem;
    justify-content: space-between;
    text-align: center;
    align-self: center;
    align-items: center;
}

.calling {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 20rem;
}

.calling:hover {
    transform: scale(1.1);
    transition: all .4s;
    cursor: pointer;
}

.all_products {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.all_products_button {
    padding: 1rem;
    display: inline;
    font-size: 1.2rem;
    font-weight: 700;
    text-decoration: none;
    background-color: transparent;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    border: none;
}


/* .all_products_button:hover {
    transition: all 0.3s;
    transform: scaleY(1.1);
   } */
/*Mobile View */
@media (max-width: 1400px) {
    .homescreen_banner {
        padding: 0 0rem;
    }

    .homesceeen_right {
        padding-left: 5rem;
    }

    .homescreen_products {
        padding: 2rem 20rem;
    }

    .homescreen_products {
        padding: 2rem 8rem;
    }

    .working_time {
        padding: 2rem 20rem;
    }

    .chat_contact_details {
        padding: 3rem 20rem;
    }

    .chat_section {
        padding: 3rem 20rem;
    }

    .monthly_bundle {
        margin-left: -50rem;
    }

    .all_products_button {
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-self: center;
    }

    .product_card {
        width: 7rem;
        border-radius: 8rem;
        height: 7rem;
        border: 2px solid #e7e7e7;
        box-shadow: var(--box-shadow-input);
        object-fit: cover;
    }

    .product_card--layout {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 25rem;
        cursor: pointer;
    }

    .product-container {
        display: flex;
        justify-content: space-between;
    }

    .porduct-desc {
        display: flex;
        flex-direction: column;
        width: 18rem;
        align-items: flex-start;
    }
}

@media (max-width: 1230px) {
    .homescreen_banner {
        padding: 0rem 0rem;
    }

    .contact_input input{
        width: inherit !important;
    }

    .contact_button_container{
        width: inherit !important;
    }

    .contact_input textarea{
        width: inherit !important;
    }

    .homescreen_banner__subtitle {
        font-weight: 500;
        font-size: 3rem;
    }

    .homescreen_products {
        padding: 2rem 10rem;
    }

    .services {
        padding: 3rem 10rem;
    }

    .working_time {
        padding: 2rem 10rem;
    }

    .chat_contact_details {
        padding: 3rem 10rem;
    }

    .chat_section {
        padding: 3rem 10rem;
    }

    .monthly_bundle {
        margin-left: -45rem;
        margin-top: 40rem;
        width: 20rem;
    }
}


@media (max-width: 900px) {
    .homescreen_banner {
        padding: 0rem 0rem;
        display: inherit;
        width: 100%;
    }
    .navigation__link {
        font-size: 1.2rem;
    }

    .navigation_website {
        margin-left: 22%;
      }

    .homescreen_banner_outer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
      }

      .homescreen_motive {
        width: 100%;
        padding: 10rem 2rem;
      }

    .img-responsive{
        display: none;
    }

    .new_book{
        display: none;
    }

    .homescreen_banner__subtitle {
        font-weight: 500;
        font-size: 4rem;
        margin-top: 0rem;
        line-height: 1;
    }

    .img-responsive {
        width: 45vw;
    }

    .homescreen_products {
        width: 100vw;
        padding: 2rem 1rem;
        height: fit-content;
    }

    .all_products {
        justify-content: space-around;
    }

    .product-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 4rem;

    }

    .services {
        /* display: none; */
        padding: 3rem 3rem;
        width: 100vw;
    }

    .service_list {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
        flex-wrap: wrap;
        overflow: scroll;
        justify-content: flex-start;
    }

    .our_services_outer {
        display: flex;
        grid-gap: 3rem;
        grid-gap: 3rem;
        gap: 3rem;
        margin-top: 3rem;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px;
    }

    .working_time {
    display: flex;
    padding: 2rem;
    box-shadow: var(--box-shadow1);
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }

  .working_time_left {
    padding: 5rem 2rem;
    width: 100%;
    border-radius: 10px;
  }

    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }

    .contact_input_container {
        width: 90%;
    }

    .contact_input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .contact_input input {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_input textarea {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_button_container {
        width: 100vw !important;
        justify-content: center !important;
    }

    .chat_contact_details {
        padding: 3rem 3rem;
        flex-direction: column;
    }

    .calling {
        width: 20rem;
    }

    .our_services_subheading {
        overflow-wrap: break-word;
    }

}

@media (max-width: 500px) {
    .homescreen_banner {
        display: inherit;
        padding: 0rem 0rem;
        width: 100%;
    }

    .homescreen_banner_outer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
    }

    .homescreen_motive {
        width: 100%;
        padding: 10rem 2rem;
    }

    .homescreen_banner__subtitle {
        font-weight: 600;
        font-size: 4rem;
        margin-top: 0rem;
        line-height: 1;
    }



    .img-responsive {
        display: none;
    }

    .product-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }

    .service_list {
        overflow-x: scroll;
        justify-content: flex-start !important;
    }

    .servies_main_heading {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .our_services_outer {
        display: flex;
        grid-gap: 3rem;
        grid-gap: 3rem;
        gap: 3rem;
        margin-top: 3rem;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0px;
    }

    .service_list ::-webkit-scrollbar {
        width: 0 !important;
        /* Remove scrollbar space */
        background: transparent !important;
        /* Optional: just make scrollbar invisible */
    }

    /* Optional: show position indicator in red */
    .service_list ::-webkit-scrollbar-thumb {
        background: transparent !important;
    }


    .working_time {
        display: flex;
        padding: 2rem;
        box-shadow: var(--box-shadow1);
        width: 100vw;
        flex-direction: column;
        align-items: center;
    }

    .working_time_left {
        padding: 5rem 2rem;
        width: 100%;
        border-radius: 10px;
    }

    .working_time_right {
        width: 100%;
        margin-top: 2rem;
        border-radius: 10px;
        padding: 3rem 1rem;
    }

    .contact-container {
        width: 100vw;
    }

    .contact_input_container {
        width: 90%;
    }

    .contact_input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .contact_input input {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_input textarea {
        width: 100% !important;
        height: 40px;
        font-family: inherit;
        font-size: 1.5rem;
    }

    .contact_button_container {
        width: 100vw !important;
        justify-content: center !important;
    }

    .chat_section {
        padding: 3rem 3rem;
    }

    .chat_subheading {
        font-size: 1.5rem;
        padding-top: 2rem;
    }
}


.service_list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.serviceBtn {
    padding: 10px 25px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 20px;
    color: #000;
    outline: none;
    cursor: pointer;
}

.serviceBtn:hover {
    background-color: rgb(224, 224, 224);
    color: #fff;
}

.activeBtn {
    padding: 10px 25px;
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    border: none;
}

.text_price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  gap: 2rem;

}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_input_container {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contact_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact_input label {
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
}

.contact_input input {
    width: 50rem;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #000;
    outline: none;
    padding: 10px;
    font-family: inherit;
    font-size: 1.5rem;
}

.contact_input textarea {
    width: 102rem;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #000;
    outline: none;
    padding: 10px;
    font-family: inherit;
    font-size: 1.5rem;
}

.contact_button_container {
    margin: 2rem 0;
    display: flex;
    width: 102rem;
    justify-content: flex-start;
}

.contact_button_container button {
    background-color: #000;
    border: 1px solid var(--dark-grey);
    border-radius: 0.5rem;
    font-family: inherit;
    font-size: 1.3rem;
    padding: 1rem 3rem;
    color: var(--white);
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    transition: all .4s;
}

.contact_button_container button:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}

.footer_container {
    background-color: #000;
    width: 100%;
    height: 5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* bottom: 10px; */
    gap: 3px;
}

.footer_container a {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 600;
}