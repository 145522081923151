
.button,
.button__delete {
	border-radius: .5rem;
  font-family: inherit;
  font-size: 1.3rem;
  padding: 1rem 3rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
  gap: .5rem;
  transition: all .4s;
  /* margin-top: 2rem; */
}

.button {
	/* background-image: linear-gradient(195deg, #42424a, #191919); */
	background-color: #FF9000;
	border: 1px solid var(--dark-grey);
}

.button .button__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}

/* .button:hover {
	background-color: transparent !important;
	border: 1px solid var(--dark-grey);
	color: #000;
} */

/* .button:hover .button__icon {
	fill: #000;
} */

.button__delete {
	border: 0px solid;
	box-shadow: 0 3px 3px 0 rgb(233 30 99 / 15%), 0 3px 1px -2px rgb(233 30 99 / 20%), 0 1px 5px 0 rgb(233 30 99 / 15%);
	/* background-image: linear-gradient(195deg, #ec407a, #d81b60); */
	background-color: #FF9000;
}

.button__delete .button__icon {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--white);
	transition: all .4s;
}

.button__delete:hover .button__icon {
	fill: var(--pure-white);
}

@media screen and (max-width:500px) {
	.button,
.button__delete {
	width: fit-content !important;
}

}