.navigation {
  grid-column: 1 / 2;
  /* padding-top: 2rem; */
  position: fixed;
  width: 22rem;
  height: 98vh;
  z-index: 1000;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s; 
  /* border-radius: 12px; */
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  /* margin: 1rem; */
  /* padding: 1rem; */
  /* top: 0rem; */
  overflow: hidden;
  /* background-image: linear-gradient(195deg, #42424a, #191919); */
  background-color: #000;
  background-size: 150% 150%;
  /* box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9), */
    /* 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important; */
  backdrop-filter: saturate(200%) blur(30px);
  /* background-color: hsl(0deg 0% 0% / 100%) !important; */
}

.navigation:hover {
	overflow: auto;
}

.navigation::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.navigation::-webkit-scrollbar-track-piece {
  background-color: var(--bg-secondary);
}

.navigation::-webkit-scrollbar-thumb {
  background-color: var(--light-grey);
}

.navigation.active {
  width: 7rem;
  transition: all 0.5s ease;
}

.navigation__logo {
  height: 10rem;
  padding-left: 6rem;
  padding-top: 1rem;
}
.navigation__logo.active {
  height: 5rem;
  /* padding-left: 6rem; */
  /* padding-top: 1rem; */
}
.navigation__logo2 {
  margin-top: 1rem;
  height: 4.5rem;
  padding-left: 1rem;
  display: none;
}

.sidebarnav__button_active {
  left: 4rem !important;
  overflow: visible !important;
  transition: all 0.5s ease;
}

.sidebarnav__button {
  position: absolute;
  top: 12%;
  left: 19rem;
  transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  overflow: visible !important;
}

.appointment__button {
  position: absolute;
  top: 7%;
  right: 0.4rem;
  transform: translateY(-40%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.829);
  box-shadow: var(--box-shadow-input);
  z-index: 999;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
}

.sidebarRewardIcon{
  display: flex;
  width: 16px;
  height: 42px;
  vertical-align: middle;
  margin-right: -24px;
  padding-bottom: 3px;
}

.appointment_main {
  display: grid;
  grid-column: 3/-1;
  grid-row: 1/3;
  grid-row: 1/-1;
}

.sidebarnav__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-grey);
  left: 18%;
  z-index: 1000;
  transition: all 0.4s;
  /* height: 2rem;
	width: 2rem;
	fill: var(--dark-grey);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(10%, 150%);
	z-index: 1000;
	transition: all .4s; */
}

.sidebarnav__icon--box {
  height: 8rem;
  width: 3.07rem;
  fill: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.sidebarnav__button:hover .sidebarnav__icon {
  fill: var(--primary-color);
}

.navigation__list {
  margin-top: 3rem;
  width: 100%;
  padding-left: 0px;
  list-style: none;
}

.currentNav {
  /* display: flex;
	align-items: center; */
  position: relative;
}

.activeNav {
  position: absolute;
  top: 1rem;
  right: 0;
  height: 2.5rem;
  width: 0.5rem;
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
  /* border-radius: 1rem; */
}

.currentNav .navigation__list--itemHover {
  font-weight: 400;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  background-color: hsla(0, 0%, 78%, 0.2);
  /* box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%); */
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.currentNav .navigation__list--itemHover .navigation__list--link {
  color: var(--white);
  font-weight: 500;
}

.currentNav .navigation__list--itemHover .navigation__list--link2 {
  color: var(--white);
  font-weight: 500;
}

.currentNav
  .navigation__list--itemHover
  .navigation__list--link
  .navigation__icon {
  fill: var(--white);
}

.currentNav
  .navigation__list--itemHover
  .navigation__list--link2
  .navigation__icon2 {
  fill: var(--white);
}

.navigation__list--item {
  border-radius: 1rem;
  padding: 1rem 0;
  padding-left: 2rem;
  display: none;
  transition: all 0.4s;
}

.navigation__list--copyright {
  padding: 1rem 0;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  width: 22rem;
  color: #fff;
}

.navigation__list--copyright span {
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
}

.navigation__list--copyright span a {
  font-size: inherit;
  font-weight: inherit;
  color: currentColor;
  text-decoration: none;
  transition: all 0.4s;
}

.navigation__list--copyright span a:hover {
  color: var(--primary-color);
}

.navigation__list--copyrightHidden {
  padding: 1rem 0;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  display: none;
}

.navigation__list--itemHover {
  border-radius: 1rem;
  width: 20rem;
  padding: 1rem 0;
  padding-left: 2rem;
  transition: all 0.4s;
  display: block;
  gap: 1rem;
  cursor: pointer;
}

.navigation__list--itemHover:hover,
.navigation__list--item:hover {
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
  transform: translateX(0.2rem);
}

.navigation.active .navigation__list--itemHover {
  display: none;
}

.navigation.active .navigation__list--item {
  display: block;
}

.navigation.active .navigation__list--copyright {
  display: none;
}

.navigation.active .navigation__list--copyrightHidden {
  display: block;
}

.navigation.active .navigation__logo {
  /* display: ; */
  height :12px;
}

.navigation.active .navigation__logo2 {
  display: block;
}

.navigation__list--link {
  text-decoration: none;
  color: var(--white);
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  gap: 1rem;
}

.navigation__list--link--span {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.navigation__list--link2 {
  text-decoration: none;
  color: var(--white);
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  gap: 1rem;
}

.navigation__icon {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.navigation__icon2 {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.navigation__icon1 {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}

.hasChildren {
  position: relative;
}

.hasChildren::after {
  cursor: pointer;
  padding-right: 1rem;
  content: " \002B";
  color: var(--white);
  font-size: 1.7rem;
  position: absolute;
  right: 0rem;
  top: 0.7rem;
}

.subMenuActive.hasChildren::after {
  content: " \002D";
}

.subMenuActive.hasChildren ul {
  display: block;
}

.navigation__sub__active {
  background-image: linear-gradient(195deg, #c66f00, #ff9510);
  background-color: hsla(0, 0%, 78%, 0.2);
  color: var(--white) !important;
  font-weight: 600 !important;
  padding: 1rem;
  border-radius: 0.5rem;
}

.navigation__sub__active svg {
  fill: var(--white) !important;
}

.hasChildren ul {
  margin-top: 1rem;
  display: none;
  list-style: none;
}

.hasChildren ul li {
  padding: 0.5rem 1rem;
  transition: all 0.4s;
}

.hasChildren ul li:hover {
  /* background-image:linear-gradient(195deg, #c66f00, #ff9510); */
}

.hasChildren ul li a {
  text-decoration: none;
  color: var(--white);
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.hasChildren ul li a svg {
  height: 1.4rem;
  width: 1.4rem;
  fill: var(--white);
}

.navigation__list--link2--logout {
  background: transparent;
  box-shadow: none;
  border: 0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}


.navigation__logos{
  /* display: contents; */
  /* display: block;
  background-color: var(--light-grey);
  position: relative;
  transition: all 0.5s ease; */
  display: none;
}
.active_logos{
  display: block;
  background-color:#121212;
  position: relative;
  /* transition: all 0.5s ease; */
  transition: all 0.4s;
  margin-left: 1rem;
  margin-top: 1rem;
}


@media (max-width: 809px) {
	.navigation__icon {
		height: 1.5rem;
		width: 1.5rem;
	  }
	.navigation__icon span {
		font-size: 1rem;
	  }
    .navigation{
      width: 18rem;
    }

    .sidebarnav__button {
      left: 14rem;
    }

    .navigation__list--copyright span{
     font-size: 0.8rem; 
    }

    .currentNav .navigation__list--itemHover{
      width: 14rem;
    }

    .navigation__logos{
      /* display: contents; */
      /* display: block;
      background-color: var(--light-grey);
      position: relative;
      transition: all 0.5s ease; */
      display: none;
    }
    .active_logos{
      display: block;
      background-color:#121212;
      position: relative;
      /* transition: all 0.5s ease; */
      transition: all 0.4s;
      margin-left: 1rem;
      margin-top: 1rem;
    }
}